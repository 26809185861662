
import React, { useCallback, useEffect } from "react";
import Layout from "./Layout";
import ProgramCard from "../components/ProgramCard";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { getprograms } from "../apis/programs/api";
import { useDispatch, useSelector } from "react-redux";
import { programFetchFailure, programFetchSuccess } from "../reducers/programs";
import { baseURL } from "../config";

const ViewProgram = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const org_id = userData.org.id;
  const dispatch = useDispatch();
  const programs = useSelector((state) => state.program.programs);

  const fetchPrograms = useCallback(async () => {
    try {
      const result = await getprograms(baseURL, org_id);
      await dispatch(programFetchSuccess(result.data));
    } catch (error) {
      dispatch(programFetchFailure({ error: error.message }));
    }
  }, [org_id, dispatch]);

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  return (
    <Layout>
      <div
        className="w-full flex flex-col pl-[65px] pt-[75px] pb-[10px] "
        style={{ fontFamily: "sen" }}
      >
        <div className="w-full flex flex-row px-[40px]">
          <h1
            style={{ textAlign: "center" }}
            className="mx-auto font-bold text-[25px] h-[40px] my-auto"
          >
            Programs
          </h1>
          <Link to="/add-program">
            <Button
              variant="contained"
              sx={{
                borderRadius: "50%",
                minWidth: 40,
                minHeight: 40,
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#028476",
                color: "white",
                "&:hover": {
                  backgroundColor: "#1f5e25",
                },
              }}
            >
              <AddIcon />
            </Button>
          </Link>
        </div>

        {programs?.length === 0 || !programs ? (
          <Typography 
            variant="h6" 
            align="center" 
            style={{ marginTop: '2rem', color: '#666' }}
          >
            No programs available. Click the '+' button to add a new program.
          </Typography>
        ) : (
          <div
            className="w-full grid grid-cols-2 overflow-y-auto gap-3 px-10 pt-5"
            style={{
              scrollbarWidth: "3px",
              scrollbarColor: "#028476 #f1f1f1",
              msOverflowStyle: "none",
            }}
          >
            {programs?.map((item, idx) => (
              <ProgramCard
                key={idx}
                program={item}
                fetchPrograms={fetchPrograms}
              />
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ViewProgram;