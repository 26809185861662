import React, { useState, useEffect, useCallback } from 'react';
import { Paper, TextField, Button, Typography, InputAdornment, IconButton, CircularProgress, Snackbar, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';
import Img from '../images/Phyfarm-App-footer.png'
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { loginSuccess } from '../reducers/reducers';
import { useNavigate } from 'react-router-dom'
import { Alert } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {baseURL} from '../config';
import { checkAuth, login } from '../apis/auth/api';



const Login = () => {

    const dispatch = useDispatch();


    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const isSmScreen = useMediaQuery('(max-width:850px)');
    const [styles, setStyles] = useState({});
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        const storedEmail = localStorage.getItem('rememberedEmail');
        const storedRememberMe = localStorage.getItem('rememberMe');

        if (storedRememberMe === 'true' && storedEmail) {
            setEmail(storedEmail);
            setRememberMe(true);
        }
    }, []);


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleForgotPassword = () => {
        navigate('/forgotPassword', { replace: true });
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value); // Update email state
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value); // Update password state
    };


    const getUserDataFromLocalStorage = useCallback(async () => {

        const userDataFromLocalStorage = localStorage.getItem('userData');
        const getRememberMe = localStorage.getItem('rememberMe');
        if (userDataFromLocalStorage) {
            // setUserData(JSON.parse(userDataFromLocalStorage));
            try {

                const response = await checkAuth(baseURL, JSON.parse(userDataFromLocalStorage).access_token);
                const result = await response.json();

                if (result.data) {
                    if (JSON.parse(userDataFromLocalStorage).role.name === 'agronomist' && getRememberMe) {
                        navigate('/users-dashboard', { replace: true });
                    } else if (JSON.parse(userDataFromLocalStorage).role.name === 'superadmin' && getRememberMe) {
                        navigate('/admin-management', { replace: true });
                    }
                    else if (JSON.parse(userDataFromLocalStorage).role.name === 'admin' && getRememberMe) {
                        navigate('/dashboard', { replace: true });
                    } else {
                        localStorage.removeItem('userData');
                    }
                } else {
                    localStorage.removeItem('userData');
                }
            } catch (error) {
                console.error('Authentication check error:', error);
            }
        }
    }, [navigate]);

    useEffect(() => {
        getUserDataFromLocalStorage();
    }, [getUserDataFromLocalStorage]);

    const handleLogin = async () => {

        if (email === '' || password === '') {
            setError('Empty fields');
            setSnackbarOpen(true);
            return; // Exit the function if fields are empty
        }
        try {

            setLoading(true);

            const response = await login(baseURL, email, password);
            const data = await response.json();

            if (!data.error) {

                dispatch(loginSuccess(data.data));

                if (rememberMe) {
                    localStorage.setItem('rememberedEmail', email);
                    localStorage.setItem('rememberMe', true);
                } else {
                    localStorage.removeItem('rememberedEmail');
                    localStorage.removeItem('rememberMe');
                }

                localStorage.setItem('userData', JSON.stringify(data.data));

                if (data?.data?.role?.name === 'agronomist') {
                    navigate('/users-dashboard', { replace: true });
                } else if (data?.data?.role?.name === 'superadmin') {
                    navigate('/admin-management', { replace: true });
                } else {
                    navigate('/dashboard', { replace: true });
                }
            } else {
                setLoading(false);
                setError(data.error.code);
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('An error occurred while trying to log in.');
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    useEffect(() => {
        if (isSmScreen) {
            setStyles({
                container: { width: '100%', height: '100%', maxWidth: 850 },
                // gridContainer: { display: 'flex', flexDirection: 'row', alignItems: 'flex-start' },
                paper: { position: "absolute", top: 150, left: 25, padding: 20, display: 'flex', flexDirection: 'column' },
            });
        } else {
            setStyles({
                container: { display: 'flex', flexDirection: 'column', zIndex: 1, position: 'relative' },

                paper: {
                    padding: '30px',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    left: '30%',
                    marginTop: '190px',
                    marginLeft: '50px',
                    width: '400px',
                    // height: '340px'
                },

            });
        }
    }, [isSmScreen]);

    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
    };

    return (
        <>
            <div

                style={styles.container}
            // style={{
            //     display: 'flex',
            //     flexDirection: 'column',
            //     zIndex: 1,
            //     position: 'relative',

            // }}
            >
                <Paper
                    elevation={5}
                    style={styles.paper}
                // style={{
                //     padding: '30px',
                //     borderRadius: '15px',
                //     display: 'flex',
                //     flexDirection: 'column',
                //     position: 'absolute',
                //     left: '30%',
                //     marginTop: '190px',
                //     marginLeft: '50px',
                //     width:'400px',
                //     height:'340px'
                // }}
                >
                    <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: 'bold', fontFamily: 'sen' }}>
                        Hello!
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            color='success'
                            onChange={handleEmailChange}
                            InputProps={{
                                startAdornment: <EmailIcon style={{ marginRight: 8, color: 'gray' }} />,
                            }}
                            error={email === '' && error !== ''}

                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <TextField
                            label="Password"
                            type={showPassword ? 'text' : 'password'} // Toggle between text and password
                            color='success'
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            onChange={handlePasswordChange}
                            InputProps={{
                                startAdornment: <VpnKeyIcon style={{ marginRight: 8, color: 'gray' }} />,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={togglePasswordVisibility}>
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={password === '' && error !== ''}


                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, alignItems: 'center', width: '100%' }}>
                        <FormControlLabel
                            control={<Checkbox checked={rememberMe} onChange={handleRememberMeChange} color={rememberMe ? "success" : "default"} />}
                            label="Remember me"
                        />

                        <span style={{ textDecoration: 'none', cursor: 'pointer', color: 'grey' }} onClick={handleForgotPassword}>
                            Forgot password?
                        </span>
                    </div>
                    <Button variant="contained" color="success" onClick={handleLogin} style={{ width: '100%', marginTop: '20px' }}>
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Log In'}
                    </Button>
                </Paper>
            </div>

            <div >
                <img style={{ height: '100vh', width: '100vw', backgroundRepeat: 'no-repeat' }} src={Img} alt="Background" />
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000} // Adjust as needed
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Alert onClose={handleSnackbarClose} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </>

    );
};

export default Login;