import React, { useCallback, useEffect, useState } from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSoilTestFailure,
  fetchSoilTestReportSuccess,
} from "../reducers/dashboard";
import { baseURL } from "../config";
import { getSoilTestReport, uploadSoilReport } from "../apis/dashboard/api";
import SoilTestSearchbar from "../components/SoilTestSearchbar";
import SoilTestPagination from "../components/SoilTestPagination";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Tooltip, Typography, } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DropzoneArea } from 'mui-file-dropzone';
// import CircularProgress from '@mui/material/CircularProgress';
const SoilTestReports = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState("farmer_id");
  const [openUploadReport, setOpenUploadReport] = useState(false);
  const [file, setFile] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [farmer_id, setFarmer_id] = useState(undefined);


  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleClickOpenReportPopup = () => {
    setOpenUploadReport(true);
  };

  const handleCloseReportPopup = () => {
    setOpenUploadReport(false);
    setFile(null);
  };

  const handleFileChange = (files) => {
    setFile(files[0]);
  };
  const userData = JSON.parse(localStorage.getItem("userData"));
  const orgId = userData.org.id;

  const handleUpload = async () => {
    if (!file) {
        setSnackbarMessage('Please select a file');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
   
    try {
        await uploadSoilReport(baseURL, orgId, file);
        setSnackbarMessage('File uploaded successfully');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        handleCloseReportPopup()

        await fetchSoilTestReport();
      } catch (error) {
        console.error('Error uploading file:', error);
        setSnackbarMessage('Error uploading file');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
      };
    
    
  const handleFilterChange = (event) => {
    setSelectedFilterValue(event.target.value);
  };
  const dispatch = useDispatch();
  const {report,total_reports } = useSelector((state) => state.soilReport);

  const per_page = 10;

  const total_page = Math.ceil(total_reports / per_page) || 1;

  const fetchSoilTestReport = useCallback(async () => {
    try {
      const result = await getSoilTestReport(baseURL, page, per_page, orgId, farmer_id);
      await dispatch(fetchSoilTestReportSuccess(result.data));
    } catch (error) {
      dispatch(fetchSoilTestFailure({ error: error.message }));
    }
  }, [ page, per_page, orgId, farmer_id, dispatch]); 
  useEffect(() => {
    fetchSoilTestReport();
  }, [fetchSoilTestReport]);


  const formatDate = (dateString, options = {}) => {

    const defaultOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    const formatOptions = { ...defaultOptions, ...options };
  
    const date = new Date(dateString);
    return date.toLocaleString('en-US', formatOptions);
  };
  return (
    <Layout>
      <div
        className="w-full flex flex-col pl-[75px] pr-[10px] pt-[75px] 
      pb-[10px] relative h-[100dvh]" style={{fontFamily: 'sen',}}
      >
        <div className="h-[40px] flex w-full  justify-between items-center px-[10px]">
          <div></div>
          <h1 className="font-bold text-[24px]  ">Soil Test Report</h1>
          <Tooltip title="Add Soil Test Report">
            <Button
             onClick={handleClickOpenReportPopup}
              variant="contained"
              sx={{
                borderRadius: "50%",
                minWidth: 30,
                minHeight: 30,
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#028476",

                color: "white",
                "&:hover": {
                  backgroundColor: "#1f5e25",
                },
              }}
            >
              <AddIcon />
            </Button>
            </Tooltip>
             {/* Modal */}
<Dialog open={openUploadReport} onClose={handleCloseReportPopup} sx={{'& .MuiPaper-root': {
    borderRadius: '16px', 
    padding:"10px"
  },}}>
        <DialogTitle>Add Soil Test Report</DialogTitle>
        <DialogContent>
          <DropzoneArea
          fontSize="12px"
            onChange={handleFileChange}
            showPreviewsInDropzone={false}
            showFileNames
            filesLimit={1}
            dropzoneText="Drag and drop a file here or click to select a file"
            acceptedFiles={['.csv']}
          />
        </DialogContent>
        <DialogActions>
        <Button
  onClick={handleCloseReportPopup}
  sx={{
    backgroundColor: "#d3302f",
    padding: "5px",
    borderRadius: "10px",
    color: "white",
    "&:hover": {
      backgroundColor: "#a22a28",
    },
  }}
>
  Cancel
</Button>
<Button
  onClick={handleUpload}
  sx={{
    backgroundColor: "#028476",
    padding: "5px",
    borderRadius: "10px",
    color: "white",
    "&:hover": {
      backgroundColor: "#016b5d", 
    },
  }}
>
  Upload
</Button>

        </DialogActions>
      </Dialog>
       <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
 
        </div>

       
        
        <div className="h-[60px] w-full flex items-center flex-row ">
        <SoilTestSearchbar setSearchQuery={setFarmer_id} />

          <FormControl
            variant="filled"
            sx={{
              m: 1,
              minWidth: 120,
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <InputLabel id="demo-simple-select-filled-label">Filter</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={selectedFilterValue}
              onChange={handleFilterChange}
              sx={{
                borderRadius: "10px",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              {/* <MenuItem value="org_id">Ord ID</MenuItem> */}
              <MenuItem value="farmer_id">Farmer ID</MenuItem>
              {/* <MenuItem value="farm_id">Farm ID</MenuItem> */}
            </Select>
          </FormControl>
        </div>
        {!report || Object.keys(report).length === 0 ? (
    <Box sx={{ display: 'flex',height:"calc(100dvh - 65px)",justifyContent:"center",color:"#028476" }}>
    {/* <CircularProgress  sx={{ color: '#028476' }} /> */}

    <Typography 
            variant="h6" 
            align="center" 
            style={{ marginTop: '2rem', color: '#666' }}
          >
            No programs available. Click the '+' button to add a new program.
          </Typography>
  </Box>
      ) : (
        <>
        <div
          className="w-full overflow-y-auto flex flex-col border-b border-gray-300 shadow-md">

          <div className="flex flex-row w-full justify-center items-center font-semibold h-[40px] p-2 pr-[15px]">
            <h2 className="w-full text-center">Report ID</h2>
            <h2 className="w-full text-center">Org ID</h2>
            <h2 className="w-full text-center">Farmer ID</h2>
            <h2 className="w-full text-center">Farm ID</h2>
            <h2 className="w-full text-center">Harvest ID</h2>
            <h2 className="w-full text-center">pH</h2>
            <h2 className="w-full text-center">Compost Added</h2>
            <h2 className="w-full text-center">Created At</h2>
            <h2 className="w-full text-center">Updated At</h2>
          </div>
          <div
        className="w-full overflow-y-auto flex flex-col gap-10 border-b border-gray-300 shadow-md px-2 "
        style={{
          height: "calc(100dvh - 220px)",
          scrollbarWidth: "3px",
          scrollbarColor: "#028476 #f1f1f1",
          msOverflowStyle: "none",
        }}
      >
        {report && report?.map((item, index) => (
          <div className="flex flex-row w-full justify-center items-center font-semibold" key={index}>
            <div
              className="flex flex-row w-full justify-center items-center font-normal bg-[#F2F5F4] py-2 rounded-xl transition-colors duration-300 hover:bg-[#028476] hover:text-white cursor-pointer"
              onClick={() => handleOpen(item)}
            >
              <h2 className="w-full text-center">{item.id}</h2>
              <h2 className="w-full text-center">{item.org_id}</h2>
              <h2 className="w-full text-center">{item.farmer_id}</h2>
              <h2 className="w-full text-center">{item.farm_id}</h2>
              <h2 className="w-full text-center">{item.harvest_id}</h2>
              <h2 className="w-full text-center">{item.ph}</h2>
              <h2 className="w-full text-center"> {item.compost_added ? "Yes" : "No"}</h2>
              <h2 className="w-full text-center text-[13px] text-balance">{formatDate(item.created_at)}</h2>
              <h2 className="w-full text-center text-[13px] text-balance">August 16, 2024</h2>
            </div>

          
          </div>

          
        ))}

<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        PaperProps={{
          style: {
            minWidth: '500px',
            borderRadius: '16px',
            border:"3px dashed #028476",
         
          },
        }}
      >
        <DialogTitle id="dialog-title" sx={{ borderBottom: '1px solid #e0e0e0',textAlign:'center' }}>
          Detailed Report {selectedItem?.id}
        </DialogTitle>
        <DialogContent>
          {selectedItem && (
            <DialogContentText id="dialog-description" component="div">
              <div className="w-full flex flex-col  items-center justify-center " style={{    fontFamily:"sen"}}>
                <div className="w-full flex flex-row justify-center items-center font-semibold text-black border-b-2 border-[#F2F5F4] py-3">
                  <h1 className="w-full h-full text-center">Name</h1>
                  <h1 className="w-full text-center">Value</h1>
                  <h1 className="w-full text-center">Status</h1>
                </div>
                <div className="w-full flex flex-row justify-center items-center font-normal text-black border-b-2 border-[#F2F5F4] py-2">
                  <h1 className="w-full text-center">EC</h1>
                  <h1 className="w-full text-center">{selectedItem.ec}</h1>
                  <h1 className="w-full text-center">{selectedItem.ec_status}</h1>
                </div>
                <div className="w-full flex flex-row justify-center items-center font-normal text-black border-b-2 border-[#F2F5F4] py-2">
                  <h1 className="w-full text-center">OC</h1>
                  <h1 className="w-full text-center">{selectedItem.oc}</h1>
                  <h1 className="w-full text-center">{selectedItem.oc_status}</h1>
                </div>
                <div className="w-full flex flex-row justify-center items-center font-normal text-black border-b-2 border-[#F2F5F4] py-2">
                  <h1 className="w-full text-center">Nitrogen</h1>
                  <h1 className="w-full text-center">{selectedItem.nitrogen}</h1>
                  <h1 className="w-full text-center">{selectedItem.nitrogen_status}</h1>
                </div>
                <div className="w-full flex flex-row justify-center items-center font-normal border-b-2 border-[#F2F5F4] py-2 text-black ">
                  <h1 className="w-full text-center">Phosphorus</h1>
                  <h1 className="w-full text-center">{selectedItem.phosphorus}</h1>
                  <h1 className="w-full text-center">{selectedItem.phosphorus_status}</h1>
                </div>
                <div className="w-full flex flex-row justify-center items-center font-normal border-b-2 border-[#F2F5F4] py-2 text-black">
                  <h1 className="w-full text-center">Potassium</h1>
                  <h1 className="w-full text-center">{selectedItem.potassium}</h1>
                  <h1 className="w-full text-center">{selectedItem.potassium_status}</h1>
                </div>
                <div className="w-full flex flex-row gap-3 items-center justify-center flex-wrap  text-white text-[16px] leading-6 tracking-tighter-2 pt-4">
                <h2 className="p-2 rounded-xl bg-[#028476] font-semibold">
                      Latitude:{" "}
                      <span className="font-normal">{selectedItem.latitude}</span>
                    </h2>
                    <h2 className="p-2 rounded-xl bg-[#028476] font-semibold">
                      Longitude:{" "}
                      <span className="font-normal">{selectedItem.longitude}</span>
                    </h2>
               
                    <h2 className="p-2 rounded-xl bg-[#028476] font-semibold">
                      Remark: <span className="font-normal">{selectedItem.remark}</span>
                    </h2>
                  </div>
          
              </div>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px solid #e0e0e0' }}>
          <Button onClick={handleClose} sx={{ color: '#028476' }}>Close</Button>
        </DialogActions>
      </Dialog>
      </div>

        </div>
        </>)}
        <div className="h-[60px] w-full flex items-center ">
          <SoilTestPagination totalPages={total_page} page={page} setPage={setPage} />
        </div>
   
      </div>
    </Layout>
  );
};

export default SoilTestReports;
