import React, { useState, useEffect, useCallback } from 'react';
import Layout from './Layout';
import { TextField, Typography, Button, Paper, Table, TableCell, MenuItem, CircularProgress, TableContainer, TablePagination, TableRow, TableBody, TableHead, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { GoVerified } from 'react-icons/go';
import RegisterUser from '../components/RegisterUser';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import { IoMdAdd } from 'react-icons/io';
import { LuBadgeInfo } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { environmentSuccess } from '../reducers/environment';
import { getAllUsers, getUserById, verifyUser } from '../apis/platform-users/api';
import { createPermission, viewPermission } from '../utils/helpers';


const PROD_BASE_URL = 'https://v3.api.phyfarm.com';
const DEV_BASE_URL = 'https://dev.phyfarm.com';

export const Users = () => {

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [searchDropdown, setSearchDropdown] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const baseURL = useSelector((state) => state.environmentReducer.environment);
  const userData = JSON.parse(localStorage.getItem('permissions'));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();

  const handleChangeEnvironment = useCallback((currentUrl) => {
    if (currentUrl === PROD_BASE_URL) {
      dispatch(environmentSuccess(DEV_BASE_URL));
    } else {
      dispatch(environmentSuccess(PROD_BASE_URL));
    }
    setSearchQuery('');
    setSearchDropdown('');
  }, [dispatch]);

  const isProd = baseURL === PROD_BASE_URL;

  const fetchData = useCallback(async (searchQuery, searchDropdown) => {

    let filters = {};

    if (searchQuery === '' || searchQuery === '') {
      filters = {};
    }

    if (searchDropdown === 'phone') {
      filters.phone = searchQuery;
    }

    if (searchDropdown === 'email') {
      filters.email = searchQuery;
    }

    setLoading(true);

    const requestData = {
      pagination: {
        current_page: page + 1,
      },
      filters,
    };

    try {

      const response = await getAllUsers(baseURL, requestData);
      const data = await response.json();
      if (!response.ok) {
        console.error('API Error:', data);
        return;
      }
      setData(data);
      setTotalCount(data.data.users.total_count);
      setLoading(false);
    } catch (error) {
      console.error('API Error:', error);
    }

  }, [page, baseURL]);

  useEffect(() => {
    fetchData({});
  }, [page, fetchData]);

  const handleSearchChange = (e) => {
    const sQ = e.target.value;
    setSearchQuery(sQ);
    fetchData({})
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleUserVerfification = async (userID) => {

    if (!userID) {
      return;
    }

    try {
      const response = await verifyUser(baseURL, userID);
      const data = await response.json();
      if (!response.ok) {
        console.error('API Error:', data);
        return;
      }
      fetchData({});
    } catch (error) {
      console.error('API Error:', error);
    }

  }

  const getUserbyID = useCallback(async (userId) => {

    try {
      const response = await getUserById(baseURL, userId);
      const data = await response.json();
      if (!response.ok) {
        console.error('API Error:', data);
        return;
      }
      navigate(`/userdetails/${userId}`);
    } catch (error) {
      console.error('API Error:', error);
    }
  }, [baseURL, navigate]);


  const hasCreatePermission = createPermission('platformUser_mgmt', userData);
  const hasFarmPermission = viewPermission('farm_mgmt', userData);

  return (
    <Layout>
      <div style={{ textAlign: 'center', marginTop: 90 }}>
        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 25 }}>Farm Users</Typography>
        <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>(Register & check User Informations) </Typography>
      </div>
      <Grid container style={{ marginTop: 50 }} spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <div style={{ display: "flex" }}>
            <TextField
              fullWidth
              label={searchDropdown ? `Search by ${searchDropdown.toUpperCase()} ID` : 'Search'}
              value={searchQuery}
              color='success'
              variant='outlined'
              onChange={handleSearchChange}
              onKeyPress={e => e.key === 'Enter' && fetchData(searchQuery, searchDropdown)}
              InputProps={{
                startAdornment: (
                  <TextField
                    select
                    value={searchDropdown}
                    color='success'
                    sx={{ minWidth: 120 }}
                    variant='standard'
                    onChange={event => {
                      const sD = event.target.value;
                      setSearchDropdown(sD);
                      setSearchQuery('');
                      fetchData(searchQuery, sD);
                    }}
                  >
                    <MenuItem value={""}>ALL</MenuItem>
                    <MenuItem value={'phone'}>PHONE NO</MenuItem>
                    <MenuItem value={'email'}>EMAIL ID</MenuItem>
                  </TextField>
                ),
                endAdornment: (
                  <IconButton onClick={() => fetchData(searchQuery, searchDropdown)}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />{
              hasCreatePermission && (
                <Button
                  onClick={handleOpen}
                  style={{
                    borderRadius: '50%',
                    marginLeft: 5,
                    width: '45px',
                    height: '55px',
                    minWidth: '55px',
                    minHeight: '55px',
                    fontSize: '1.2rem',
                  }}
                  variant='contained'
                  color='success'
                >
                  <IoMdAdd />
                </Button>
              )
            }
          </div>
        </Grid>
      </Grid>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: 30, marginTop: 40 }}>
        <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray', fontWeight: isProd ? '' : 'bold', marginRight: 1 }}>DEV</Typography>
        <Switch
          checked={isProd}
          onChange={() => handleChangeEnvironment(baseURL)}
          color='success'
        />
        <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray', fontWeight: isProd ? 'bold' : '', marginLeft: 1 }}>PROD</Typography>
      </div>



      <div>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
            <CircularProgress />
          </div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Name
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Phone No.
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Email ID
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Verification
                  </TableCell>
                  {
                    hasFarmPermission &&
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      Farm Information
                    </TableCell>
                  }
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  data && data.data && data.data.users && Array.isArray(data.data.users.users) && data.data.users.users.length > 0 ? (
                    data.data.users.users.map((item, index) => (

                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>


                        <TableCell align="center">{item.firstName + " " + item.lastName}</TableCell>
                        <TableCell align="center">{item.phone}</TableCell>
                        <TableCell align="center">{item.email}</TableCell>
                        <TableCell align="center">{item.emailVerified ? (<GoVerified style={{ color: '#5F9C6A' }} />) : (<Button style={{ color: 'green' }} onClick={() => { handleUserVerfification(item.id) }}>Verify Email</Button>)}</TableCell>
                        {
                          hasFarmPermission &&
                          <TableCell align="center">
                            <Button onClick={() => getUserbyID(item.id)}><LuBadgeInfo /></Button>
                          </TableCell>
                        }
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        {data &&
                          data.data &&
                          data.data.users &&
                          data.data.users.total_count === 0 ? (
                          <Typography variant="subtitle1">No data available.</Typography>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      <div style={{ marginTop: 10, marginBottom: 30, textAlign: "center" }}>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
          labelDisplayedRows={({ from, to, count }) => `Navigate to Next Page: ${from}-${to} of ${count}`}
        />
      </div>
      <RegisterUser open={open} handleClose={handleClose} baseURL={baseURL} />
    </Layout>
  )
}