import { useState, useCallback, useRef, useEffect } from "react";
import { addTags } from "../../apis/360-tags-mgmt/api";
import { baseURL } from "../../config";

const useTagsAdd = () => {

  const orgId = useRef(null);
  const token = useRef(null);
  const [state, setState] = useState({
    loading: false,
    error: null,
    open: false,
    name: "",
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarSeverity: "success",
  });

  const handleOpenDialog = () => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
      error: null,
      name: "",
    }));
  };

  const handleAddTagChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };

  const handleSnackbarClose = () => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };


  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    orgId.current = userData.org.id;
    token.current = userData.access_token;
  }, []);

  const addTagHandler = useCallback(async (name) => {

    if (name === "") {
      setState((prevState) => ({
        ...prevState,
        error: "Please enter a tag name",
      }));

      return false;
    }

    if (name[0] !== name[0].toUpperCase()) {
        setState((prevState) => ({
          ...prevState,
          error: "Tag name must start with an uppercase letter",
        }));
        return false;
      }

    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
        

      const response = await addTags(baseURL, orgId.current, token.current, name);
      if (!response.ok) {
        throw new Error("Failed to add tag");
      }

      setState((prevState) => ({
        ...prevState,
        loading: false,
        open: false,
        name: "",
        error: null,
        snackbarOpen: true,
        snackbarMessage: "Tag added successfully",
        snackbarSeverity: "success",
      }));

      return true;
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error: error.message,
        snackbarOpen: true,
        snackbarMessage: "Failed to add tag",
        snackbarSeverity: "error",
      }));

      return false;
    }
  }, []);


  return {
    state,
    addTagHandler,
    handleOpenDialog,
    handleCloseDialog,
    handleAddTagChange, 
    handleSnackbarClose,
  };
};

export default useTagsAdd;
