import { createSlice } from "@reduxjs/toolkit";

const programSlice = createSlice({
    name: 'program',
    initialState: {
        programs: [],
        particularProgram:[],
        activities:[],
        createProgramId: null,
        error: '',
    },
    reducers: {
        programFetchSuccess: (state, action) => {
            state.programs=action.payload.programs
            state.error = null;
        },
        programFetchFailure: (state, action) => {
            state.error = action.payload.error;
        },
        particularProgramFetchSuccess:(state,action)=>{
            state.particularProgram=action.payload
            state.error = null;
        },
        particularProgramFetchFailure:(state,action)=>{
            state.error = action.payload.error;
        },
        createProgramSuccess:(state,action)=>{
            state.createProgramId=action.payload.activity_id;
            state.error=null;
        },
        createProgramFailure:(state,action)=>{
            state.error = action.payload.error;
        },
        activityFetchSuccess: (state, action) => {
            state.activities=action.payload.activities
            state.error = null;
        },
        activityFetchFailure: (state, action) => {
            state.error = action.payload.error;
        },
    }
});





export default programSlice.reducer;
export const { programFetchFailure,programFetchSuccess,particularProgramFetchFailure,particularProgramFetchSuccess,createProgramFailure,createProgramSuccess,activityFetchFailure,activityFetchSuccess } = programSlice.actions;
