import React from "react";
import { CircularProgress } from "@mui/material";

const Loading = () => {
    return (
        <div>
            <CircularProgress />
        </div>
    )
}

export default Loading;