import React from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ProgramStatsProgressbar = ({value,count}) => {
    return (
        <Stack sx={{ width: '100%', height: '100%',paddingY:2}} spacing={2} style={{justifyContent:"center"}}>
          {/* <Typography  sx={{textAlign:'center' ,fontSize:'30px',fontWeight:'700'}}></Typography> */}
          <div className="flex flex-row w-full justify-center text-[20px]">
                    Count:{" "}
                    <span className="font-bold text-[#028476]">{count}</span>
                  </div>
          <Box sx={{ display: 'flex', alignItems: 'center',justifyContent:'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={value}   sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#028476',
                    borderRadius:20
                  },
                  height:12,
                  borderRadius:20
                }}/>
            </Box>
            <Box sx={{ minWidth: 35 ,justifyContent:'center' }}>
              <Typography variant="body2" color="textSecondary" sx={{fontWeight:'700',color:"#028476"}}>{`${Math.round(value)}%`}</Typography>
            </Box>
          </Box>
        </Stack>
      );
}

export default ProgramStatsProgressbar