

import React, { useState, useEffect, useCallback } from 'react';
import Layout from './Layout';
import { baseURL } from '../config';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const ProgramMembers = () => {
  const [programUsers, setProgramUsers] = useState([]);
  const { id } = useParams();

  const fetchProgramUsers = useCallback(async () => {
    try {
      const response = await fetch(`${baseURL}/api/mgmt/360-program-users/list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accessToken: JSON.parse(localStorage.getItem('userData')).access_token,
        },
        body: JSON.stringify({
          filter: {
            program_id: id,
          },
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch program users');
      }
      const data = await response.json();
      setProgramUsers(data.data.user_programs);
    } catch (error) {
      console.error(error);
    } finally {

    }
  }, [id]);

  useEffect(() => {
    fetchProgramUsers();
  }, [fetchProgramUsers]);

  return (
    <Layout>
      <div className="w-full flex flex-col pl-[65px] pt-[75px] gap-5 pb-[10px]">
        <h1 className="mx-auto font-bold text-[25px] text-center">Program Members</h1>

    {programUsers.length === 0 || !programUsers ? (
          <Typography align="center" style={{ marginTop: '2rem', color: '#666' }}>
            No program members available.
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{backgroundColor:"#f0f0f0"}}>
                <TableRow>
                  <TableCell align="center">Id</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Program Name</TableCell>
                  <TableCell align="center">Org Id</TableCell>
                  <TableCell align="center">Completed</TableCell>
                  <TableCell align="center">Active</TableCell>
                  <TableCell align="center">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {programUsers.map((user, idx) => (
                  <TableRow key={user.id} style={{ backgroundColor: idx%2 === 1 ? "#f0f0f0" : "white" }}>
                    <TableCell align="center">{user.user_id}</TableCell>
                    <TableCell align="center">{user.user_name}</TableCell>
                    <TableCell align="center">{user.program_name}</TableCell>
                    <TableCell align="center">{user.org_id}</TableCell>
                    <TableCell align="center">{user.completed ? 'Yes' : 'No'}</TableCell>
                    <TableCell align="center">{user.active ? 'Yes' : 'No'}</TableCell>
                    <TableCell align="center">
                      {new Date(user.created_at).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Layout>
  );
};

export default ProgramMembers;
