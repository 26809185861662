import { BEARER_TOKEN } from '../../config'

export const createAdmin = async (
    baseUrl,
    payload
) => {

    const response = await fetch(`${baseUrl}/api/mgmt/360-user/createAdmin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
        },
        body: JSON.stringify(payload)
    })

    return response;
}


export const getAdmin = async (
    baseUrl,
    userId
) => {

    const response = await fetch(`${baseUrl}/api/mgmt/360-user/listAdmins`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
        },
        body: JSON.stringify({
            "user_id": userId
        }),
    });

    return response;
}

export const updateAdmin = async (
    baseUrl,
    payload
) => {

    const response = await fetch(`${baseUrl}/api/mgmt/360-user/updateAdmin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
        },
        body: JSON.stringify(payload)
    })

    return response;
}

export const deleteAdmin = async (
    baseUrl,
    payload
) => {

    const response = await fetch(`${baseUrl}/api/mgmt/360-user/deleteAdmin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
        },
        body: JSON.stringify(payload)
    })
    return response;
}