import {BEARER_TOKEN} from '../../config'

export const listFarmsByOrgID = async (
    baseUrl,
    orgId
) => {
    const response = await fetch(`${baseUrl}/api/mgmt/farmcrm/listFarms`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
             // Replace with your actual access token
        },
        body: JSON.stringify({
            org_id: orgId,
        }),
    })

    return response;
}

export const createFarm = async (
    baseUrl,
    payload
) => {
    const response = await fetch(`${baseUrl}/api/mgmt/farmcrm/createFarm`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify(payload),
    });

    return response;
}

export const updateFarm = async (
    baseUrl,
    payload
) => {
    const response = await fetch(`${baseUrl}/api/mgmt/farmcrm/editFarm`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify(payload),
    });

    return response;
}

export const deleteFarm = async (
    baseUrl,
    userId,
    orgId,
    farmId
) => {

    const response = await fetch(`${baseUrl}/api/mgmt/farmcrm/removeFarm`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({ user_id: userId, org_id: orgId, farm_id: farmId })
    }
    );

    return response;
}

export const getFarmByOrg = async (
    baseURL,
    payload
) => {
    const response = await fetch(`${baseURL}/api/mgmt/farmcrm/getFarmById`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify(payload),
    });

    return response;
}
