import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polygon,
    Marker,
} from "react-google-maps";
import { Button } from "@mui/material";
import { useDispatch} from "react-redux";
import { clearCoordinate, coordinateSuccess } from "../reducers/coordinates";

const WrappedMap = withScriptjs(

    withGoogleMap((props) => {

        const mapRef = useRef(null);
        const polygonPathRef = useRef(props.polygonPaths);


        const fitBounds = useCallback(() => {
            if (polygonPathRef.current.length === 0) {
                mapRef.current.panTo(props.defaultCenter);
                return;
            }
            const bounds = new window.google.maps.LatLngBounds();
            Array.isArray(polygonPathRef.current) && polygonPathRef.current.map(item => bounds.extend(item));
            mapRef.current.fitBounds(bounds);
        }, [props.defaultCenter]);

        useEffect(() => {
            fitBounds();
        }, [fitBounds]);


        return (
            <GoogleMap
                defaultZoom={16}
                ref={mapRef}
                defaultCenter={props.defaultCenter}
                onClick={props.handleMapClick}
                options={{
                    mapTypeId:"satellite",
                }}
            >
                {props.polygonPaths.length > 0 && (
                    <Polygon paths={props.polygonPaths} options={{ strokeColor: "green" }} />
                )}
                {props.markers.map((marker, index) => (
                    <Marker key={index} position={marker} />
                ))}
            </GoogleMap>
        );
    })
);

const FarmMap = ({ location, calculateArea, initialFarmBoundaries }) => {

    const [polygonPaths, setPolygonPaths] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [defaultCenter, setDefaultCenter] = useState({ lat: location?.latitude, lng: location?.longitude });
    const dispatch = useDispatch();


    const handleMapClick = useCallback((event) => {

        if (isDrawing) {
            const newPoint = { lat: event.latLng.lat(), lng: event.latLng.lng() };
            setPolygonPaths([...polygonPaths, newPoint]);
            setMarkers([...markers, newPoint]);
        }
    }, [isDrawing, markers, polygonPaths]);

    const handleStartDrawing = () => {
        setIsDrawing(true);
    };

    const handleFinish = () => {
        setIsDrawing(false);
        calculateArea(polygonPaths);
        dispatch(coordinateSuccess(polygonPaths));
    };

    useEffect(() => {

        if (Array.isArray(initialFarmBoundaries) && initialFarmBoundaries.length > 0) {

            if (isDrawing) {
                return;
            }

            const sumLat = initialFarmBoundaries.reduce((acc, loc) => acc + loc.latitude, 0);
            const sumLng = initialFarmBoundaries.reduce((acc, loc) => acc + loc.longitude, 0);
            const avgLat = sumLat / initialFarmBoundaries.length;
            const avgLng = sumLng / initialFarmBoundaries.length;

            setDefaultCenter({ lat: avgLat, lng: avgLng });

            if (polygonPaths.length === 0) {
                setPolygonPaths(
                    initialFarmBoundaries.map((boundary) => ({
                        lat: boundary.latitude,
                        lng: boundary.longitude,
                    }))
                );
            }
        }

    }, [initialFarmBoundaries, isDrawing, polygonPaths, location?.latitude, location?.longitude]);

   useEffect(() => {
    setDefaultCenter({ lat: location?.latitude, lng: location?.longitude });
    }, [location?.latitude, location?.longitude])


    return (
        <>
            <div style={{ width: "100%", height: "400px" }}>
                <WrappedMap
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwMUqV9i_a7ILMTIwmgxBAzymf_T6hIR4&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    defaultCenter={defaultCenter}
                    handleMapClick={handleMapClick}
                    polygonPaths={polygonPaths}
                    markers={markers}
                
                />
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center",
                    marginTop: 10,
                    padding: 10,
                }}
            >
                {   

                    !initialFarmBoundaries && (
                        <Button variant="contained" color="success" onClick={handleStartDrawing} disabled={isDrawing}>
                            Draw
                        </Button>
                    )
                    
                }
                {isDrawing && (
                    <>
                        <Button variant="contained"  style={{margin:10}} color="success" onClick={handleFinish}>
                            Finish
                        </Button>
                    </>
                )}
                {isDrawing && (
                    <div>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setPolygonPaths([]);
                                setMarkers([]);
                                dispatch(clearCoordinate());
                            }}
                        >
                            Clear
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};

export default FarmMap;