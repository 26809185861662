import React, { useState, useEffect, useCallback } from 'react';
import { Typography, TextField, Button, Grid, Tooltip, Select, MenuItem, InputLabel, Snackbar } from '@mui/material';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Layout from '../pages/Layout';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import LocationMap from './LocationMap';
import { useSelector } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import { baseURL} from '../config';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearLocation } from '../reducers/location';
import {BEARER_TOKEN} from '../config'

const AddLead = () => {

    const [isMap, setIsMap] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [userleadData, setUserleadData] = useState({});


    const [loading, setLoading] = useState(false);

    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [fieldError, setFieldError] = useState('');

    const locationFromRedux = useSelector(state => state.locationReducer.location);

    const dispatch = useDispatch();

    const getUserData = () => {
        const userData = localStorage.getItem('userData');
        if (userData) {
            setUserleadData(JSON.parse(userData));
        }
    }


    const getEmptyData = useCallback(() => {
        return {
            lead: {
                fullName: "",
                clientType: "",
                preferredLanguage: "Marathi",
                companyName: "",
                phone: "",
                email: ""
            },
            leadRequirements: {
                customerNote: "",
                leadDate: "",
                productID: "ABC123",
                currentProduce: "",
                isFarmAvailable: false,
                farm: {
                    // name: "",
                    // size: "",
                    // sizeUnit: "",
                    // type: "",
                    // location: ""
                },
                leadStage: "Enquiry"
            }
        }
    }, []);


    const [formData, setFormData] = useState(getEmptyData());

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');


    const handleShowFarmForm = () => {

        // setShowFarmForm(true);
        setFormData({
            ...formData,
            leadRequirements: {
                ...formData.leadRequirements,
                isFarmAvailable: true,
                farm: {
                    name: "",
                    size: "",
                    sizeUnit: "",
                    type: "",
                    location: ""
                }
            }
        });

    }

    const handleHideFarm = () => {
        // setShowFarmForm(false);
        setIsMap(false);

        setFormData({
            ...formData,
            leadRequirements: {
                ...formData.leadRequirements,
                isFarmAvailable: false,
                farm: {}
            }
        });
    }


    const handleClose = () => {
        setOpenModal(false);
    };

    const handleMapOpen = () => {
        setOpenModal(true);
        setIsMap(true);
    }

    const clearFormFields = useCallback(() => {

        setFormData(getEmptyData());
    }, [getEmptyData]);


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const validatePhone = (phone) => {

        const phoneRegex = /^[0-9]{10,}$/;
        return phoneRegex.test(phone);
    };


    const handleAddLead = () => {

        const { lead, leadRequirements } = formData;
        const { address } = locationFromRedux;



        if (lead.email && !validateEmail(lead.email)) {
            setEmailError('Enter valid email ID');
            return;
        } else {
            setEmailError('');
        }


        if (lead.phone && !validatePhone(lead.phone)) {
            setPhoneError('Enter valid phone number');
            return;
        } else {
            setPhoneError('');
        }


        if (
            !lead.fullName ||
            !lead.clientType ||
            !lead.phone ||
            !lead.email ||
            !leadRequirements.customerNote ||
            !leadRequirements.leadDate ||
            !leadRequirements.productID ||
            !leadRequirements.currentProduce
        ) {
            setFieldError('Enter all field values');
            return;
        } else {
            setFieldError('');
        }

        try {

            setLoading(true);

            const payload = {
                userId: userleadData.id,
                ...formData,

                leadRequirements: {
                    ...formData.leadRequirements,
                    farm: {
                        ...formData.leadRequirements.farm,
                        location: address
                    }
                }
            }

            fetch(`${baseURL}/api/mgmt/leads/addLead`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + BEARER_TOKEN
                     // Replace with your actual access token
                },
                body: JSON.stringify(payload),
            })
                .then(response => response.json())
                .then(data => {
                    // Handle the API response as needed
                   
                    if (!data.error) {
                        // Show the success Snackbar and clear the form fields
                        setSnackbarOpen(true);
                        setSnackbarMessage('Lead added successfully');
                        setSnackbarSeverity('success');
                        setLoading(false);
                        getEmptyData();
                        dispatch(clearLocation());
                        clearFormFields();
                        setIsMap(false);


                    } else {
                        console.log(data);
                    }
                })
                .catch(error => {
                    console.error('Error making API call:', error);
                });
        } catch (error) {
            // Handle any errors here
            console.error('API Error:', error.error.message);
            alert('Error:', error.message);
            setLoading(false);
        }


    }

    useEffect(() => {
        getUserData();
    }, []);



    return (
        <Layout>

            <div style={{ margin: 'auto', padding: 20, marginLeft: 250, marginBottom: 10, fontFamily: 'sen' }}>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ fontSize: '25px', fontWeight: 'bold', fontFamily: 'sen' }}>
                        <span style={{ color: 'gray' }}>Leads</span> <span>&gt;</span> Add new lead
                    </Typography>
                </div>

                <form style={{ marginTop: '20px' }}>

                    <Typography variant="h6" style={{ marginTop: '10px', marginBottom: '10px', fontFamily: 'sen', fontWeight: 'bold' }}>Personal Information</Typography>


                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <div style={{ width: '300px' }}>
                                <label>Full Name</label>
                                <TextField variant="outlined" label="Enter your Name" name="fullName" style={{ marginTop: 5, width: '100%' }} InputLabelProps={{
                                    style: { fontFamily: 'sen' }
                                }}
                                    value={formData.lead.fullName}
                                    onChange={(e) => setFormData({ ...formData, lead: { ...formData.lead, fullName: e.target.value } })}
                                // error={formData.lead.fullName == ''}
                                // helperText={fieldError}

                                />
                            </div>
                        </Grid>



                        <Grid item xs={12} sm={4}>
                            <div style={{ width: '300px' }}>
                                <label>Type of Client</label>
                                <Tooltip
                                    title="For better clarity describe the level of understanding that your client has for your product. Is the client tech-savvy or are they more habitual to the old ways."
                                >
                                    <span style={{ cursor: 'pointer', marginLeft: 5 }}><AiOutlineInfoCircle /></span>
                                </Tooltip>
                                <TextField variant="outlined" value={formData.lead.clientType} label="Customer Identification" name="clientType" style={{ marginTop: 5, width: '100%' }} InputLabelProps={{
                                    style: { fontFamily: 'sen' }
                                }} onChange={(e) => setFormData({ ...formData, lead: { ...formData.lead, clientType: e.target.value } })}
                                // error={formData.lead.clientType == ''}
                                //     helperText={fieldError}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
                                <InputLabel id="test-select-label">Language Preferred</InputLabel>
                                <Select
                                    // Set the value from your formData state
                                    value={formData.lead.preferredLanguage}
                                    onChange={(e) => setFormData({
                                        ...formData,
                                        lead: {
                                            ...formData.lead,
                                            preferredLanguage: e.target.value
                                        }
                                    })}
                                    name="preferredLanguage"
                                // error={formData.lead.preferredLanguage == ''}
                                // helperText={fieldError}
                                >
                                    <MenuItem value="English">English</MenuItem>
                                    <MenuItem value="Hindi">Hindi</MenuItem>
                                    <MenuItem value="Marathi">Marathi</MenuItem>
                                    {/* Add more options as needed */}
                                </Select>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <div style={{ width: '300px' }}>
                                <label>Company Name</label>
                                <TextField variant="outlined" label="Optional" name="companyName"
                                    value={formData.lead.companyName}
                                    style={{ marginTop: 5, width: '100%' }} InputLabelProps={{
                                        style: { fontFamily: 'sen' }
                                    }} onChange={(e) => setFormData({ ...formData, lead: { ...formData.lead, companyName: e.target.value } })}
                                // error={formData.lead.companyName == ''}
                                //     helperText={fieldError}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <div style={{ width: '300px' }}>
                                <label>Phone No.</label>
                                <TextField variant="outlined" label="Enter Phone No."
                                    value={formData.lead.phone}
                                    name="phone" style={{ marginTop: 5, width: '100%' }} InputLabelProps={{
                                        style: { fontFamily: 'sen' }
                                    }} onChange={(e) => setFormData({ ...formData, lead: { ...formData.lead, phone: e.target.value } })}

                                // error={formData.lead.phone == ''}
                                //     helperText={fieldError}
                                />

                            </div>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <div style={{ width: '300px' }}>
                                <label>Email Address</label>
                                < TextField variant="outlined" label="Enter Email Address"
                                    value={formData.lead.email}
                                    name="email" style={{ marginTop: 5, width: '100%' }} InputLabelProps={{
                                        style: { fontFamily: 'sen' }
                                    }}
                                    onChange={(e) => setFormData({ ...formData, lead: { ...formData.lead, email: e.target.value } })}
                                // error={formData.lead.email == ''}
                                // helperText={fieldError}
                                />
                            </div>
                        </Grid>


                    </Grid>
                    <Typography variant="h6" style={{ marginTop: '20px', fontFamily: 'sen', marginBottom: '10px', fontWeight: 'bold' }}>Customer Requirements:</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <label>Notes by Customer</label>
                            <TextField
                                value={formData.leadRequirements.customerNote}
                                variant="outlined"
                                label="Describe user requirements"
                                multiline
                                name="customerNote"
                                rows={4}
                                style={{ marginTop: '10px', width: '89%' }}
                                // error={formData.leadRequirements.customerNote == ''}
                                //     helperText={fieldError}
                                InputLabelProps={{
                                    style: { fontFamily: 'sen' }
                                }}
                                onChange={(e) => setFormData({ ...formData, leadRequirements: { ...formData.leadRequirements, customerNote: e.target.value } })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div style={{ width: '300px' }}>
                                <label>Lead Date</label>
                                <TextField

                                    variant="outlined" type="date" name="leadDate" style={{ marginTop: 5, width: '100%' }}
                                    // error={formData.leadRequirements.leadDate == ''}
                                    // helperText={fieldError}
                                    InputLabelProps={{
                                        style: { fontFamily: 'sen' }
                                    }}
                                    onChange={(e) => {
                                        const selectedDate = e.target.value;
                                        const formattedDate = new Date(selectedDate + 'T00:00:00Z').toISOString();
                                     
                                        setFormData({
                                            ...formData,
                                            leadRequirements: {
                                                ...formData.leadRequirements,
                                                leadDate: formattedDate
                                            }
                                        });
                                    }}
                                />

                            </div>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <div style={{ width: '300px' }}>
                                <label>Intrested In</label>
                                <TextField
                                    value={formData.leadRequirements.productID}
                                    variant="outlined" label="Product Name" style={{ marginTop: 5, width: '100%' }}
                                    // error={formData.leadRequirements. == ''}
                                    // helperText={fieldError} 
                                    InputLabelProps={{
                                        style: { fontFamily: 'sen' }
                                    }}

                                    onChange={(e) => setFormData({ ...formData, leadRequirements: { ...formData.leadRequirements, productID: e.target.value } })}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <div style={{ width: '300px' }}>
                                <label>Current Produce</label>
                                <TextField
                                    value={formData.leadRequirements.currentProduce}
                                    variant="outlined" label="Describe current produce" name="currentProduce" style={{ marginTop: 5, width: '100%' }} InputLabelProps={{
                                        style: { fontFamily: 'sen' }
                                    }}
                                    // error={formData.leadRequirements.currentProduce == ''}
                                    // helperText={fieldError}
                                    onChange={(e) => setFormData({ ...formData, leadRequirements: { ...formData.leadRequirements, currentProduce: e.target.value } })}
                                />
                            </div>
                        </Grid>


                    </Grid>

                    <div style={{ display: 'flex', marginTop: 30 }}>
                        <Typography variant="h6" style={{ fontFamily: 'sen', fontWeight: 'bold' }}>Does the user have a farm?</Typography>
                        <Button color='success' onClick={handleShowFarmForm} >YES</Button>
                        <Button color='success' onClick={handleHideFarm}>No</Button>
                    </div>

                    {formData && formData.leadRequirements && formData.leadRequirements.isFarmAvailable ? <div style={{ display: 'flex', marginTop: 20 }}>
                        <Grid container>

                            {isMap ? (
                                <Grid item xs={12} sm={8}>

                                    <div style={{ display: 'flex' }}>

                                        <Typography
                                        >{locationFromRedux && locationFromRedux.address}</Typography>
                                        <Button color='success' onClick={handleMapOpen} sx={{ marginLeft: 5 }}>Change</Button>
                                    </div>
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={8}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>

                                        <Button variant="outlined" onClick={handleMapOpen} style={{ backgroundColor: 'white', color: 'black', width: 800 }}>
                                            <AddLocationAltOutlinedIcon style={{ marginRight: 5 }} />
                                            Pin location
                                        </Button>
                                    </div>

                                </Grid>
                            )}

                            <Grid container>
                                <Grid item xs={12} sm={3}>
                                    <div style={{ width: '300px' }}>
                                        <label>Farm name</label>
                                        <TextField variant="outlined"
                                            value={formData.leadRequirements.farm.name}
                                            label="Enter farm name" name="Name" style={{ marginTop: 5, width: '100%' }} InputLabelProps={{
                                                style: { fontFamily: 'sen' }
                                            }}
                                            // error={fieldError.leadRequirements.farm.Name !== ''}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    leadRequirements: {
                                                        ...formData.leadRequirements,
                                                        farm: {
                                                            ...formData.leadRequirements.farm,
                                                            name: e.target.value
                                                        }
                                                    }
                                                });
                                            }}

                                        />
                                    </div>
                                </Grid>


                                <Grid item xs={12} sm={3}>
                                    <div style={{ width: '300px' }}>
                                        <label>Farm size </label>
                                        <TextField
                                            value={formData.leadRequirements.farm.size}
                                            variant="outlined" label="Enter farm size" name="size" style={{ marginTop: 5, width: '100%' }} InputLabelProps={{
                                                style: { fontFamily: 'sen' }
                                            }}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    leadRequirements: {
                                                        ...formData.leadRequirements,
                                                        farm: {
                                                            ...formData.leadRequirements.farm,
                                                            size: parseInt(e.target.value, 10)
                                                        }
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={2}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '160px' }}>
                                        <label>Units </label>
                                        <Select label
                                            value={formData.leadRequirements.farm.sizeUnit}
                                            name="sizeUnit"
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    leadRequirements: {
                                                        ...formData.leadRequirements,
                                                        farm: {
                                                            ...formData.leadRequirements.farm,
                                                            sizeUnit: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >
                                            <MenuItem value="acres">Acres</MenuItem>
                                            <MenuItem value="hectare">Hectare</MenuItem>
                                            <MenuItem value="sqft">Sqft</MenuItem>
                                            <MenuItem value="sqmt">Sqmt</MenuItem>
                                            {/* Add more options as needed */}
                                        </Select>
                                    </div>

                                </Grid>



                                <Grid item xs={12} sm={4}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '300px', marginTop: '-5px' }}>
                                        <label>Farm type </label>
                                        <TextField
                                            value={formData.leadRequirements.farm.type}
                                            name="type"
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    leadRequirements: {
                                                        ...formData.leadRequirements,
                                                        farm: {
                                                            ...formData.leadRequirements.farm,
                                                            type: e.target.value
                                                        }
                                                    }
                                                });
                                            }}
                                        >

                                            {/* Add more options as needed */}
                                        </TextField>
                                    </div>
                                </Grid>

                            </Grid>

                            <LocationMap style={{ display: openModal ? 'block' : 'none' }} open={openModal} handleClose={handleClose} />
                        </Grid>
                    </div> : null
                    }
                    <Grid container >
                        <Grid item xs={6}>
                            <Button variant="contained" style={{ marginTop: '40px', width: '545px', fontFamily: 'sen', backgroundColor: "#A6B5A4" }}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="success" style={{ marginTop: '40px', width: '600px', fontFamily: 'sen' }} onClick={handleAddLead}>
                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Save Lead'}
                            </Button>
                        </Grid>

                    </Grid>

                </form>

                <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                    <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
                <Snackbar open={Boolean(emailError || phoneError || fieldError)} autoHideDuration={3000} onClose={() => setEmailError('')} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                    <MuiAlert elevation={6} variant="filled" severity="error">
                        {emailError || phoneError || fieldError}
                    </MuiAlert>
                </Snackbar>
            </div>
        </Layout>
    );
}

export default AddLead;
