import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polygon,
    Marker
} from "react-google-maps";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearCoordinate, coordinateSuccess } from "../reducers/coordinates";
import { Snackbar } from "@mui/material";


const WrappedMap = withScriptjs(
    withGoogleMap(({
        defaultCenter,
        handleMapClick,
        farmBoundaries,
        polygonPaths,
        markers,
        zones
    }) => {
        const mapRef = useRef(null);

        const farmBoundariesTransformed = Array.isArray(farmBoundaries) && farmBoundaries.map((boundary) => ({
            lat: boundary.latitude,
            lng: boundary.longitude,
        }));

        const fitBounds = useCallback(() => {
            const bounds = new window.google.maps.LatLngBounds();
            Array.isArray(farmBoundariesTransformed) && farmBoundariesTransformed.map(item => bounds.extend(item));
            mapRef.current.fitBounds(bounds);
        }, [farmBoundariesTransformed]);

        useEffect(() => {
            fitBounds();
        }, [fitBounds]);

        return (
            <GoogleMap
                defaultZoom={16}
                ref={mapRef}
                defaultCenter={defaultCenter}
                options={{
                    mapTypeId:"satellite",
                }}
            >

                {farmBoundaries && (
                    <Polygon
                        paths={farmBoundariesTransformed}
                        options={{ strokeColor: "red" }}
                        onClick={handleMapClick}
                    />
                )}

                {zones && zones.map(zone => (
                    <Polygon paths={zone.zone_boundaries.map(b => (
                        { lat: b.latitude, lng: b.longitude }
                    ))} options={{ strokeColor: "blue",fillColor: 'blue' }} />
                ))}
                {polygonPaths.length > 0 && (
                    <Polygon paths={polygonPaths} options={{ strokeColor: "green" }} />
                )}
                {markers.map((marker, index) => (
                    <Marker key={index} position={marker} />
                ))}
            </GoogleMap>
        )
    })
);

const MultiselectionMap = ({ initialZoneBoundaries, calculateArea, farmData, editingZoneId }) => {

    const [polygonPaths, setPolygonPaths] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [open, setOpen] = useState(false);
    const location = useSelector((state) => state.locationReducer.location);
    const { latitude, longitude } = location;
    const centerCurrent = { lat: latitude, lng: longitude };
    const [defaultCenter, setDefaultCenter] = useState(centerCurrent);
    const dispatch = useDispatch();
    const farmBoundaries = farmData?.farm?.farm_boundaries;
    const zones = farmData?.farm?.zones?.filter(zone => zone.id !== editingZoneId);
    console.log("initialZoneBoundaries", initialZoneBoundaries)

    const handleMapClick = useCallback((event) => {
        if (isDrawing) {
            const newPoint = { lat: event.latLng.lat(), lng: event.latLng.lng() };
            setPolygonPaths([...polygonPaths, newPoint]);
            setMarkers([...markers, newPoint]);
        } else {
            setOpen(true);
        }
    }, [isDrawing, markers, polygonPaths]);

    const handleStartDrawing = () => {
        setIsDrawing(true);
    };

    const handleFinish = useCallback(() => {
        setIsDrawing(false);
        calculateArea(polygonPaths);
        dispatch(coordinateSuccess(polygonPaths));
    }, [calculateArea, polygonPaths, dispatch]);

    useEffect(() => {

        if (Array.isArray(farmBoundaries) && farmBoundaries.length > 0) {

            const sumLat = farmBoundaries.reduce((acc, loc) => acc + loc.latitude, 0);
            const sumLng = farmBoundaries.reduce((acc, loc) => acc + loc.longitude, 0);
            const avgLat = sumLat / farmBoundaries.length;
            const avgLng = sumLng / farmBoundaries.length;

            setDefaultCenter({ lat: avgLat, lng: avgLng });

        }
    }, [farmBoundaries]);

    useEffect(() => {

        if (Array.isArray(initialZoneBoundaries) && initialZoneBoundaries.length > 0) {

            if (isDrawing) {
                return;
            }

            const sumLat = initialZoneBoundaries.reduce((acc, loc) => acc + loc.latitude, 0);
            const sumLng = initialZoneBoundaries.reduce((acc, loc) => acc + loc.longitude, 0);
            const avgLat = sumLat / initialZoneBoundaries.length;
            const avgLng = sumLng / initialZoneBoundaries.length;

            setDefaultCenter({ lat: avgLat, lng: avgLng });

    

            if(polygonPaths.length === 0){
            setPolygonPaths(
                initialZoneBoundaries.map((boundary) => ({
                    lat: boundary.latitude,
                    lng: boundary.longitude,
                }))
            );
        }
        }
    }, [initialZoneBoundaries, isDrawing,polygonPaths]);

  

    return (
        <>
            <div style={{ width: "100%", height: "400px" }}>
                <WrappedMap
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwMUqV9i_a7ILMTIwmgxBAzymf_T6hIR4&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    defaultCenter={defaultCenter}
                    handleMapClick={handleMapClick}
                    zones={zones}
                    farmBoundaries={farmBoundaries}
                    polygonPaths={polygonPaths}
                    markers={markers}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent:"center",
                    alignItems: "center",
                    marginTop: 10,
                    padding: 10,
                }}
            >
                <Button
                    variant="contained"
                    color="success"
                    onClick={handleStartDrawing}
                    disabled={isDrawing}

                >
                    Draw
                </Button>

                {isDrawing && (
                    <>
                        <Button variant="contained" style={{margin:10}} color="success" onClick={handleFinish}>
                            Finish
                        </Button>
                    </>
                )}
                {isDrawing && (

                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setPolygonPaths([]);
                            setMarkers([]);
                            dispatch(clearCoordinate());
                        }}
                    >
                        Clear
                    </Button>

                )}
            </div>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                sx={{ marginTop: 6 }}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                severity="error"
                message="Please click on draw button and draw zone boundaries."
            />
        </>
    );
};

export default MultiselectionMap;