import {BEARER_TOKEN} from '../../config'

export const getUserData = async (
    baseURL,
) => {

    const response = await fetch(`${baseURL}/api/mgmt/gptthread/listUser`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
    });

    return response
}

export const getChats = async (
    baseURL,
    phone
) => {

    const response = await fetch(`${baseURL}/api/mgmt/gptthread/listUserChats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + BEARER_TOKEN,
        //   'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
          'accessToken': '764d4892430bf3a8887d8e2e92db62ddd1073316bc460bc5375fbdda4df0c4d5',
        },
        body: JSON.stringify({ phone }),
      });

      return response
}