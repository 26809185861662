export const getprograms = async (baseURL, org_id) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;
    const response = await fetch(`${baseURL}/api/mgmt/360-programs/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken: accessToken,
      },
      body: JSON.stringify({
        org_id,
      }),
    });
  
    if (!response.ok) {
      throw new Error("Failed to fetch stats");
    }
  
    return response.json();
  };
export const getParticularprogram = async (baseURL, org_id,id) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;
    const response = await fetch(`${baseURL}/api/mgmt/360-programs/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken: accessToken,
      },
      body: JSON.stringify({
        org_id,
        id
      }),
    });
  
    if (!response.ok) {
      throw new Error("Failed to fetch stats");
    }
  
    return response.json();
  };
  export const createProgram = async (baseURL, programData) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;
  
    const formData = new FormData();
  
    Object.keys(programData).forEach(key => {
      if (key === 'file') {
        formData.append('file', programData.file, programData.file.name);
      } else {
        formData.append(key, programData[key]);
      }
    });
  
    const response = await fetch(`${baseURL}/api/mgmt/360-programs/create`, {
      method: "POST",
      headers: {
        accessToken: accessToken,
      },
      body: formData,
    });
  
    if (!response.ok) {
      throw new Error("Failed to create program");
    }
  
    return response.json();
  };
  export const deleteProgram = async (baseURL, programData) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;
  
    const response = await fetch(`${baseURL}/api/mgmt/360-programs/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken: accessToken,
      },
      body: JSON.stringify(programData),
    });
  
    if (!response.ok) {
      throw new Error("Failed to delete program");
    }
  
    return response.json();
  };
  export const updateProgram = async (baseURL, programData) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;

  
    const formData = new FormData();
    formData.append('id', programData.id);
    formData.append('org_id', programData.org_id);
    formData.append('name', programData.name);
    formData.append('description', programData.description);
    formData.append('type', programData.type);
    formData.append('location', programData.location);
    formData.append('file_url', programData.file_url);


    if (programData.file !== null && programData.file !== undefined) {
      formData.append('file', programData.file);
    }
  
    const response = await fetch(`${baseURL}/api/mgmt/360-programs/update`, {
      method: 'POST',
      headers: {
        accessToken: accessToken,
      },
      body: formData,
    });
  
    if (!response.ok) {
      throw new Error('Failed to update program');
    }
  
    return response.json();
  };
  // export const updateProgram = async (baseURL, programData) => {
  //   const userData = JSON.parse(localStorage.getItem("userData"));
  //   const accessToken = userData.access_token;
  
  //   const formData = new FormData();
  //   formData.append('id', programData.id);
  //   formData.append('org_id', programData.org_id);
  //   formData.append('name', programData.name);
  //   formData.append('description', programData.description);
  //   formData.append('type', programData.type);
  //   formData.append('location', programData.location);
  //   formData.append('file_url', programData.file_url);
  //   formData.append('file', programData.file);
  
  //   const response = await fetch(`${baseURL}/api/mgmt/360-programs/update`, {
  //     method: 'POST',
  //     headers: {
  //       accessToken: accessToken,
  //     },
  //     body: formData,
  //   });
  
  //   if (!response.ok) {
  //     throw new Error('Failed to update program');
  //   }
  
  //   return response.json();
  // };
  export const createActivity = async (baseURL, activityData) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;
  
    const response = await fetch(`${baseURL}/api/mgmt/360-programs/createActivity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken: accessToken,
      },
      body: JSON.stringify(activityData),
    });
  
    if (!response.ok) {
      throw new Error("Failed to create activity");
    }
  
    return response.json();
  };


export const listActivities = async (baseURL, orgId, programId) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;
  
    try {
      const response = await fetch(`${baseURL}/api/mgmt/360-programs/listActivity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accessToken': accessToken
        },
        body: JSON.stringify({
          org_id: orgId,
          program_id: programId
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch activities');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error listing activities:', error);
      throw error;
    }
  };

  export const deleteActivity = async (baseURL, activityData) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;
  
    const response = await fetch(`${baseURL}/api/mgmt/360-programs/deleteActivity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken: accessToken,
      },
      body: JSON.stringify({
        id: activityData.id,
        program_id: activityData.program_id,
        org_id: activityData.org_id,
      }),
    });
  
    if (!response.ok) {
      throw new Error("Failed to delete activity");
    }
  
    return response.json();
  };

  export const getProgramUsers = async (baseURL, programId) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;
  
    const response = await fetch(`${baseURL}/api/mgmt/360-program-users/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken: accessToken,
      },
      body: JSON.stringify({
        filter: {
          program_id: programId
        }
      }),
    });
  
    if (!response.ok) {
      throw new Error("Failed to fetch program users");
    }
  
    return response.json();
  };