import React, { useState, useCallback, useEffect } from "react";
import Layout from "./Layout";
import {
  Typography,
  Grid,
  Card,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  Button,
  TableRow,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Switch from "@mui/material/Switch";
import { environmentSuccess } from "../reducers/environment";
import { getDevices, deleteDevice } from "../apis/devices/api";
import { useParams } from "react-router-dom";
import { listAllEnvs } from "../apis/all-envs/api";

const PROD_BASE_URL = "https://v3.api.phyfarm.com";
const DEV_BASE_URL = "https://dev.phyfarm.com";

const Devices = () => {
  const baseURL = useSelector((state) => state.environmentReducer.environment);
  const userData = JSON.parse(localStorage.getItem("permissions"));
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const isProd = baseURL === PROD_BASE_URL;
  const dispatch = useDispatch();
  const { envId } = useParams();
  const [envData, setEnvData] = useState([]);

  const handleChangeEnvironment = useCallback(
    (currentUrl) => {
      if (currentUrl === PROD_BASE_URL) {
        dispatch(environmentSuccess(DEV_BASE_URL));
      } else {
        dispatch(environmentSuccess(PROD_BASE_URL));
      }
      // setSearchQuery('');
      // setSearchDropdown('');
      setDevices([]);
    },
    [dispatch]
  );

  const getAllDevices = useCallback(
    async (envId) => {
      setLoading(true);

      const requestData = {
        envId,
      };

      try {
        const response = await getDevices(baseURL, requestData);
        const data = await response.json();
        if (data.data.devices && data.data.devices.length > 0) {
          setLoading(false);
          setDevices(data);
        } else {
          setLoading(false);
          setDevices([]);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [baseURL]
  );

  const handleDelete = useCallback(
    async (deviceId) => {

      if (deviceId === "") {
        return;
      }

      setDeleteLoading(true);

      const payload = {
        deviceID: deviceId._id,
        rev: deviceId._rev,
      };

      try {
        const response = await deleteDevice(baseURL, payload);
        const data = await response.json();
        if (data) {
          setDeleteLoading(false);
          getAllDevices(envId);
        } else {
          setDeleteLoading(false);
        }
      } catch (error) {
        console.log(error);
        setDeleteLoading(false);
      }
    },
    [baseURL, getAllDevices, envId]
  );

  const setDeleteDeviceConfirmation = (value) => {
    setOpenDialog(value);
  };

  const hasDeletePermission = userData?.user_permissions?.permissions?.some(
    (permission) =>
      permission.feature_name === "device_mgmt" && permission.permissions.delete
  );

  useEffect(() => {
    const getInitialData = async () => {
      if (envId) {
        getAllDevices(envId);
        const payload = {
          pagination: {
            current_page: 1,
          },
          filters: {
            env_id: envId,
          },
          status: null,
        };

        const data = await listAllEnvs(baseURL, payload);
        const response = await data.json();
        if (
          response.data &&
          response.data.envs &&
          response.data.envs.envs &&
          response.data.envs.envs.length > 0
        ) {
          setEnvData(response.data.envs.envs);
        }
      }
    };

    getInitialData();
  }, [envId, baseURL, getAllDevices]);

  console.log("envData", envData);

  return (
    <div>
      <Layout>
        <div style={{ height: "100vh", marginTop: 90 }}>
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Typography
              style={{ fontFamily: "sen", fontWeight: "bold", fontSize: 25 }}
            >
              List of Devices
            </Typography>
            <Typography
              style={{ fontFamily: "sen", fontSize: 16, color: "gray" }}
            >
              (Search devices using Env ID){" "}
            </Typography>
          </div>
          <Grid
            container
            style={{ marginTop: 50 }}
            spacing={1}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Card>
              <div style={{ padding: 15 }}>
                  <Typography
                    style={{
                      fontFamily: "sen",
                      fontWeight: "bold",
                      fontSize: 18,
                      textAlign: "center",
                    }}
                  >
                    Hub Info
                  </Typography>
                  <Grid container spacing={4} style={{ marginTop: 10 }} >
                    <Grid item sm={12} xs={12}>
                      <Typography
                        style={{ fontFamily: "sen", fontWeight: "bold" }}
                      >
                        Env ID:
                      </Typography>
                      <Typography style={{ fontFamily: "sen" }}>
                        {envId}
                      </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12} >
                      <Typography
                        style={{ fontFamily: "sen", fontWeight: "bold" }}
                      >
                        Env Name:
                      </Typography>
                      <Typography style={{ fontFamily: "sen" }}>
                        {envData?.find((env) => env.env_id === envId)?.env_name}
                      </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12} >
                      <Typography
                        style={{ fontFamily: "sen", fontWeight: "bold" }}
                      >
                        Serial ID:
                      </Typography>
                      <Typography style={{ fontFamily: "sen" }}>
                        {envData?.find((env) => env.env_id === envId)?.serial_id}
                      </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography
                        style={{ fontFamily: "sen", fontWeight: "bold" }}
                      >
                        Created By:
                      </Typography>
                      <Typography style={{ fontFamily: "sen" }}>
                        {envData?.find((env) => env.env_id === envId)?.created_by}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginRight: 30,
              marginTop: 40,
            }}
          >
            <Typography
              style={{
                fontFamily: "sen",
                fontSize: 16,
                color: "gray",
                fontWeight: isProd ? "" : "bold",
                marginRight: 1,
              }}
            >
              DEV
            </Typography>
            <Switch
              checked={isProd}
              onChange={() => handleChangeEnvironment(baseURL)}
              color="success"
            />
            <Typography
              style={{
                fontFamily: "sen",
                fontSize: 16,
                color: "gray",
                fontWeight: isProd ? "bold" : "",
                marginLeft: 1,
              }}
            >
              PROD
            </Typography>
          </div>

          <div>
            {loading ? (
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <CircularProgress />
              </div>
            ) : (
              <TableContainer component={Paper} style={{ marginTop: 10 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        Device ID
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        Env ID
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        Name
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        Mac Address
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        Type
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        Status
                      </TableCell>
                      {hasDeletePermission && (
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Remove
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devices &&
                    devices.data &&
                    Array.isArray(devices.data.devices) &&
                    devices.data.devices.length > 0 ? (
                      devices.data.devices.map((item) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={item._id}
                        >
                          <TableCell align="center">{item._id}</TableCell>
                          <TableCell align="center">{item.envId}</TableCell>
                          <TableCell align="center">{item.name}</TableCell>
                          <TableCell align="center">
                            {item.macAddress}
                          </TableCell>
                          <TableCell align="center">{item.type}</TableCell>
                          <TableCell align="center">
                            {item.online ? (
                              <span style={{ color: "green" }}>TRUE</span>
                            ) : (
                              <span style={{ color: "red" }}>FALSE</span>
                            )}
                          </TableCell>
                          {hasDeletePermission && (
                            <TableCell align="center">
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  setDeleteDeviceConfirmation(true);
                                  setDeviceId(item);
                                }}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Typography variant="subtitle1">
                            No data available
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          disableEnforceFocus
        >
          <div style={{ backgroundColor: "#d0e7b7" }}>
            <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>
              Delete Device
            </DialogTitle>
          </div>
          <DialogContent>
            Are you sure you want to delete this device?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={{ backgroundColor: "gray", color: "white" }}
              onClick={() => setDeleteDeviceConfirmation(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleDelete(deviceId, envId);
              }}
              color="error"
              disabled={deleteLoading}
            >
              {deleteLoading ? (
                <CircularProgress color="error" size={24} />
              ) : (
                "Delete"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
    </div>
  );
};

export default Devices;
