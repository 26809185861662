import React, { useState, useEffect, useCallback } from 'react'
import Layout from './Layout';
import {
    Typography, Grid, TextField, MenuItem, IconButton, Table, TableCell, TableBody, TableContainer, Paper, TableHead, Button, TableRow, DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    InputAdornment,
    Snackbar,
    CircularProgress,
    Select,
    FormControl,
    InputLabel
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { IoMdAdd } from 'react-icons/io';
import {baseURL} from '../config';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MuiAlert from '@mui/material/Alert';
import { createAdmin, deleteAdmin, getAdmin, updateAdmin } from '../apis/admin-mgmt/api';
import { checkAuth } from '../apis/auth/api';
import { createPermission, deletePermission, editPermission, validPhoneNumber } from '../utils/helpers';

const AdminManagement = () => {

    const [searchQuery, setSearchQuery] = useState('');
    const [searchDropdown, setSearchDropdown] = useState('org');
    const [open, setOpen] = useState(false);
    const [organisation, setOrganisation] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [permissions, setPermissions] = useState({});
    const [error, setError] = useState({
        orgName: false,
        fullName: false,
        email: false,
        password: false,
        phone: false
    });
    const [filteredOrganisation, setFilteredOrganisation] = useState([]);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [orgId, setOrgId] = useState('');
    const userID = userData?.id;
    const [updateData, setUpdateData] = useState({
        fullName: '',
        email: '',
        phone: '',
        orgName: '',
        active: false

    });
    const [addAdmin, setAddAdmin] = useState({
        orgName: '',
        fullName: '',
        email: '',
        phone: '',
        password: '',
    });

    const checkAuthApi = useCallback(async (token) => {
        try {
          
            const response = await checkAuth(baseURL, token);
           const data =  await response.json();
           if(data?.data){
                return data.data;
           }

        } catch (error) {
            console.error('Authentication check error:', error);
        }
    }, []);


    const getAllOrganisation = (searchQuery, searchDropdown) => {
        if (searchDropdown === 'org') {
            const filtered = organisation.filter(org =>
                org?.org?.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredOrganisation(filtered);
        }
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        getAllOrganisation(event.target.value, searchDropdown);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const getAllAdmin = useCallback(async (id) => {

        if (!id) {
            return;
        }

        try {
            const response = await getAdmin(baseURL, id);
            const data = await response.json();
            setOrganisation(data.data.users);
            setFilteredOrganisation(data.data.users);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleaddAdmin = async () => {

        if(addAdmin?.orgName === ""){
            setLoading(false);
            setError({...error, orgName: true});
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage("Organisation name is required");
            return;
        }else{
            setError({...error, orgName: false});
        }

        if(addAdmin.fullName === ''){
            setError({...error, fullName: true});
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage("Full name is required");
            return;
        }else{
            // setError({...error, fullName: false});
            setError(prevState => ({ ...prevState, fullName: false }));
        }

        if(addAdmin.email === ''){
            setError({...error, email: true});
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage("Email is required");
            return;
        }else{
            setError({...error, email: false});
        }

        const regex = /^[0-9\b]+$/;

        if(addAdmin.phone === '' || !regex.test(addAdmin.phone) || addAdmin.phone.length !== 10){
            setError({...error, phone: true});
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage("Invalid phone number");
            return;
        }else{
            setError({...error, phone: false});
        }

        if(addAdmin.password === '' ){
            setError({...error, password: true});
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage("Password is required");
            return;
        }else{
            setError({...error, password: false});
        }

        const payload =  {
            org: {
                name: addAdmin.orgName
            },
            user: {
                full_name: addAdmin.fullName,
                email: addAdmin.email,
                phone: addAdmin.phone,
                password: addAdmin.password
            },
            user_id: userID
        }

        try {

            const response = await createAdmin(baseURL, payload);
            if (response.ok) {
                setOpen(false);
                getAllAdmin(userID);
                setSnackbarOpen(true);
                setSnackbarMessage("Admin added successfully");
                setSnackbarSeverity('success');
                setAddAdmin({
                    orgName: '',
                    fullName: '',
                    email: '',
                    phone: '',
                    password: '',
                });
                setError({
                    orgName: false,
                    fullName: false,
                    email: false,
                    password: false,
                    phone: false
                });
            }else {
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage("Error adding admin");
                setSnackbarSeverity('error');
            
            }
        }
        catch (error) {
            console.log(error);
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage("Error adding admin");
            setSnackbarSeverity('error');
        }
    }

    useEffect(() => {

        const fetchIntialData = async () => {
            if(userData?.access_token){
            const permissions = await  checkAuthApi(userData?.access_token); 
            setPermissions(permissions?.user_permissions);
            }
        }
        
        fetchIntialData();
    },[checkAuthApi, userData?.access_token]);

    useEffect(() => {
        if(!userID){
            return;
        }
        getAllAdmin(userID);
    }, [userID, getAllAdmin ]);

    const hasCreatePermission = createPermission('admin_mgmt', permissions);
    const hasDeletePermission = deletePermission('admin_mgmt', permissions);
    const hasUpdatePermission = editPermission('admin_mgmt', permissions);


    const handleOpenUpdate = (org) => {

        setUpdateData({
            fullName: org.full_name,
            email: org.email,
            phone: org.phone,
            orgName: org.org.name,
            active: org.active,
            is_custom_role: org.is_custom_role,
            is_super_admin: org.is_super_admin,
            is_admin: org.is_admin,
            role_id: org.role_id
        });
        setUserId(org.id);
        setOrgId(org.org.id);
        setOpenUpdate(true);

    }

    const handleOpenDelete = () => {
        setOpenDelete(!openDelete);
    }

    const handleDelete = async (userid, orgid) => {

        setLoading(true);

        const payload  =  {
            user: {
                id: userid,
                org: {
                    id: orgid
                }
            },
            user_id: userData?.id
        }

        try {

            const response = await deleteAdmin(baseURL, payload);

            if (response.ok) {
                setLoading(false);
                setOpenDelete(false);
                getAllAdmin(userID);
                setSnackbarOpen(true);
                setSnackbarMessage("Admin deleted successfully");
                setSnackbarSeverity('success');
            }else{
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage("Error deleting admin");
                setSnackbarSeverity('error');
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }

    const handleUpdate = async () => {


        if(updateData.phone === '' || !validPhoneNumber(updateData.phone)){
            setError({...error, phone: true});
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage("Invalid phone number");
            return;
        }else{
            setError({...error, phone: false});
        }

        setLoading(true);

        const payload =  {
            user: {
                id: userId,
                org: {
                    id: orgId,
                    name: updateData.orgName
                },
                role_id: updateData.role_id,
                full_name: updateData.fullName,
                phone: updateData.phone,
                is_custom_role: updateData.is_custom_role,
                is_super_admin: updateData.is_super_admin,
                is_admin: updateData.is_admin,
                active: updateData.active
            },
            user_id: userID
        }
        try {

            const response = await updateAdmin(baseURL, payload);
            
            if (response.ok) {
                setLoading(false);
                setOpenUpdate(false);
                getAllAdmin(userID);
                setSnackbarOpen(true);
                setSnackbarMessage("Admin updated successfully");
                setSnackbarSeverity('success');
            }else {
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage("Error updating admin");
                setSnackbarSeverity('error');
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage("Error updating admin");
            setSnackbarSeverity('error');
        }
    }

    return (
        <>
            <Layout>
                <div style={{ height: '100vh', marginTop: 90 }}>
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 25 }}>List of Admins</Typography>
                        <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>(Search using organisation name) </Typography>
                    </div>
                    <Grid container style={{ marginTop: 50 }} spacing={1} justifyContent={"center"}>
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <div style={{ display: "flex" }}>
                                <TextField
                                    fullWidth
                                    label={searchDropdown ? `Search by ${searchDropdown.toUpperCase()}` : 'Search'}
                                    value={searchQuery}
                                    color='success'
                                    variant="outlined"
                                    onChange={handleSearchChange}
                                    onKeyPress={e => e.key === 'Enter' && getAllOrganisation(searchQuery, searchDropdown)}
                                    InputProps={{
                                        startAdornment: (
                                            <TextField
                                                select
                                                value={searchDropdown}
                                                color='success'
                                                sx={{ minWidth: 120 }}
                                                variant='standard'

                                                onChange={event => {
                                                    const sD = event.target.value;
                                                    setSearchDropdown(sD);
                                                    setSearchQuery('');
                                                    // setOrganisation([]);
                                                    // getAllOrganisation(searchQuery, sD);
                                                }}
                                            >

                                                <MenuItem value={"org"}>ORG NAME</MenuItem>
                                            </TextField>
                                        ),
                                        endAdornment: (
                                            <IconButton onClick={() => getAllOrganisation(searchQuery, searchDropdown)}>
                                                <SearchIcon />
                                            </IconButton>
                                        ),
                                    }}
                                />{
                                    hasCreatePermission &&
                                    <Button
                                        onClick={handleOpen}
                                        style={{
                                            borderRadius: '50%',
                                            marginLeft: 5,
                                            width: '45px',
                                            height: '55px',
                                            minWidth: '55px',
                                            minHeight: '55px',
                                            fontSize: '1.2rem',
                                        }}
                                        variant='contained'
                                        color='success'
                                    >
                                        <IoMdAdd />
                                    </Button>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{
                        marginLeft: 65
                    }}>
                        <TableContainer component={Paper} style={{ marginTop: 20 }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Name
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Email
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Phone
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Organisation
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Status
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        filteredOrganisation?.map((org, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{org.full_name}</TableCell>
                                                <TableCell align="center">{org.email}</TableCell>
                                                <TableCell align="center">{org.phone}</TableCell>
                                                <TableCell align="center">{org?.org?.name}</TableCell>
                                                <TableCell align="center" style={{ color: org.active ? 'green' : 'red' }}>
                                                    {org.active ? 'ACTIVE' : 'INACTIVE'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        hasUpdatePermission &&
                                                        <Button
                                                            variant='outlined'
                                                            color='primary'
                                                            onClick={() => handleOpenUpdate(org)}
                                                            style={{ marginRight: 5 }}
                                                        >
                                                            Update
                                                        </Button>
                                                    }
                                                    {hasDeletePermission &&
                                                        <Button
                                                            variant='outlined'
                                                            color='error'
                                                            onClick={() => {
                                                                handleOpenDelete();
                                                                setUserId(org.id);
                                                                setOrgId(org?.org?.id);
                                                            }}
                                                        >
                                                            Delete
                                                        </Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>

                <Dialog open={open} onClose={() => setOpen(false)}
                onBackdropClick={() =>{
                    setAddAdmin({
                        orgName: '',
                        fullName: '',
                        email: '',
                        phone: '',
                        password: '',
                    });
                    setOpen(false)
                    setError({
                        orgName: false,
                        fullName: false,
                        email: false,
                        password: false,
                        phone: false
                    });
                }
                }
                >
                    <DialogTitle>
                        Add Admin
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            label="Organisation Name"
                            variant="outlined"
                            color='success'
                            margin='dense'
                            value={addAdmin.orgName}
                            onChange={(e) => setAddAdmin({ ...addAdmin, orgName: e.target.value })}
                            // onChange={(e) => setAddAdmin({ ...addAdmin, orgName: e.target.value })}
                            error={error?.orgName === true? true: false}
                        />
                        <TextField
                            fullWidth
                            label="Full Name"
                            variant="outlined"
                            color='success'
                            margin='dense'
                            value={addAdmin.fullName}
                            onChange={(e) => setAddAdmin({ ...addAdmin, fullName: e.target.value })}
                            error={error?.fullName? true: false}
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            color='success'
                            margin='dense'
                            value={addAdmin.email}
                            onChange={(e) => setAddAdmin({ ...addAdmin, email: e.target.value })}
                            error={error?.email? true: false}
                        />
                        <TextField
                            fullWidth
                            label="Phone"
                            variant="outlined"
                            color='success'
                            margin='dense'
                            value={addAdmin.phone}
                            onChange={(e) => {
                                const value = e.target.value;
                                const regex = /^[0-9\b]+$/;
                                if (value === '' || regex.test(value)) {
                                    setAddAdmin({ ...addAdmin, phone: value });
                                    setError({...error, phone: false});
                                }else{
                                    setError({...error, phone: true});
                                }
                            }}
                            error={error?.phone? true: false}

                        />
                        <TextField
                            fullWidth
                            label="Password"
                            variant="outlined"
                            color='success'
                            margin='dense'
                            value={addAdmin.password}
                            onChange={(e) => setAddAdmin({ ...addAdmin, password: e.target.value })}
                            error={error?.password? true: false}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </DialogContent>
                    <DialogActions>

                        <Button
                            fullWidth
                            variant='contained'
                            onClick={handleaddAdmin}
                            color='success'
                        >
                            Add Admin
                        </Button>
                    </DialogActions>

                </Dialog>
                <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                    <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>

                <Dialog open={openDelete} onClose={() => setOpenDelete(false)} disableEnforceFocus>
                    <div style={{ backgroundColor: "#d0e7b7" }}>
                        <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Delete Organisation</DialogTitle>
                    </div>
                    <DialogContent>
                        Are you sure you want to delete this Organisation?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => setOpenDelete(false)}>
                            Cancel
                        </Button>
                        <Button variant="outlined" onClick={
                            () => {
                                handleDelete(userId, orgId)
                            }
                        } color="error">

                            {loading ? <CircularProgress color='error' size={24} /> : 'Delete'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openUpdate} onClose={() => setOpenUpdate(false)} disableEnforceFocus>
                    <div style={{ backgroundColor: "#d0e7b7" }}>
                        <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Update Organisation</DialogTitle>
                    </div>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    fullWidth
                                    label="Organisation Name"
                                    variant="outlined"
                                    color='success'
                                    margin='dense'
                                    disabled
                                    value={updateData.orgName}
                                    onChange={(e) => setUpdateData({ ...updateData, orgName: e.target.value })}
                                />
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    color='success'
                                    margin='dense'
                                    disabled
                                    value={updateData.email}
                                    onChange={(e) => setUpdateData({ ...updateData, email: e.target.value })}

                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Full Name"
                                    variant="outlined"
                                    color='success'
                                    margin='dense'
                                    value={updateData.fullName}
                                    onChange={(e) => setUpdateData({ ...updateData, fullName: e.target.value })}
                                />
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    variant="outlined"
                                    color='success'
                                    margin='dense'
                                    value={updateData.phone}
                                    onChange={(e) => setUpdateData({ ...updateData, phone: e.target.value })}
                                    error={error?.phone? true: false}

                                />
                            </Grid>
                            <Grid item xs={12}>

                                <FormControl color='success' fullWidth variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={updateData.active}
                                        onChange={(e) => setUpdateData({ ...updateData, active: e.target.value })}
                                    >
                                        <MenuItem value={true}>Active</MenuItem>
                                        <MenuItem value={false}>Inactive</MenuItem>
                                    </Select>
                                </FormControl>


                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => setOpenUpdate(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={
                            () => {

                                handleUpdate()
                            }
                        } color="success" >

                            {loading ? <CircularProgress color='success' size={24} /> : 'Update'}
                        </Button>
                    </DialogActions>
                </Dialog>

            </Layout>
        </>

    )
}

export default AdminManagement