import {BEARER_TOKEN} from '../../config'

export const login = async (
    baseURL,
    email,
    password
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-user/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
             // Replace with your access token
        },
        body: JSON.stringify({
            email: email,
            password: password,
        }),
    });
    
    return response;
}

export const logout = async (
    baseURL,
    userId,
    email,
    token
) => {


    console.log(token);
    const response = await fetch(`${baseURL}/api/mgmt/360-user/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + BEARER_TOKEN
            'accessToken':token
             // Replace with the actual access token
        },
        body: JSON.stringify({
            user_id: userId,
            email: email,
            access_token: token,
        }),
    });
    
    return response;
}

export const forgotPassword = async (
email
) => {

    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/crm/forgotPassword',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
             // Replace with your access token
        },
        body: JSON.stringify({
            email: email,
        }),
    });

    return response;
}

export const resetPassword = async (
    token,
    password
) => {

    const response = fetch('https://v3.api.phyfarm.com/api/mgmt/crm/resetPassword', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
             // Replace with your access token
        },
        body: JSON.stringify({
            access_token: token,
            password: password,
        }),
    })

    return response;
} 

export const checkAuth = async (
    baseURL,
    token,
) => {
    const response = await fetch(`${baseURL}/api/mgmt/360-user/checkAuthentication`, {
        method: 'POST',
        headers: {
            'accessToken': token
        }
    });
    return response;
}