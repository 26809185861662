import React, { useState, useCallback, useEffect } from 'react'
import { Modal, Paper, Typography, TextField, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import { IoCloseOutline } from "react-icons/io5";
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { RxHamburgerMenu } from "react-icons/rx";
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import dayjs from 'dayjs';
import CustomRepetitionSelector from './CustomRepetitionSelector';
import { IoMdSend } from "react-icons/io";
import { createATask } from '../apis/agro-chat/api';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? 'red' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


const Task = ({ open, handleClose, userChat, getTasks }) => {

    const [isRepeat, setIsRepeat] = useState(false);
    const [selectedFrequency, setSelectedFrequency] = useState(null);
    const [selectedDays, setSelectedDays] = useState([]);
    const [agroDataLocalStorage, setAgroDataLocalStorage] = useState({});
    const [taskName, setTaskName] = useState("");
    const [taskDescription, setTaskDescription] = useState("");
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [selectedTime, setSelectedTime] = useState(dayjs());
    const [selectedDayOfMonth, setSelectedDayOfMonth] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(dayjs());


    const chatID = userChat && userChat.id;
    const phone = userChat && userChat.phone;

    const getAgroData = useCallback(() => {
        const agroData = localStorage.getItem('userData');
        if (agroData) {
            setAgroDataLocalStorage(JSON.parse(agroData));
        }
    }, [])

    const agroID = agroDataLocalStorage?.id;

    useEffect(() => {
        getAgroData();
    }, [getAgroData]);


    const handleRepeat = () => {
        setIsRepeat(!isRepeat);
    }

    const handleCloseModal = useCallback(() => {
        setIsRepeat(false);
        handleClose();
    }, [handleClose])

    const handleAddTask = useCallback(async () => {

        if (!agroID) {
            return;
        }

        let startDate = selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        let endDate = selectedEndDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        let weekDays = [];

        if (isRepeat) {
            if (selectedFrequency === "week") {
                weekDays = selectedDays;
            } else if (selectedFrequency === "month") {
            
                weekDays = []; 
            }
        } else {
            endDate = null;
        }

        const taskData = {
            chat_id: chatID,
            agronomist_id: agroID,
            country_code: "91",
            phone: phone,
            name: taskName,
            description: taskDescription,
           date: selectedDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            time: selectedTime.format("HH:mm:ss"),
            is_repeat: isRepeat,
            repeat_every: isRepeat ? selectedFrequency : "",
            start_date: startDate ? startDate : "2024-02-14T00:00:00.000Z",
            end_date: endDate ? endDate : "2024-03-20T00:00:00.000Z",
            week_days: weekDays,
            day_of_month: selectedDayOfMonth ? selectedDayOfMonth : null
        };

        try {
            const response = await createATask(taskData);
            if (response.ok) {

                handleCloseModal();
                getTasks(chatID);
                setSelectedFrequency(null);
                setSelectedDays([]);
                setSelectedTime(dayjs());
                setSelectedDate(dayjs());
                setSelectedDayOfMonth(null);
                setSelectedEndDate(dayjs());
            } else {
                console.error("Error adding task:", response.statusText);

            }
        } catch (error) {
            console.error("Error adding task:", error);

        }
    }, [agroID, chatID, phone, isRepeat, selectedFrequency, selectedDays, handleCloseModal, taskName, taskDescription, selectedDate, selectedTime, selectedDayOfMonth, getTasks,selectedEndDate]);


    return (
        <Modal open={open} onClose={handleClose}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: 700, backgroundColor: '#FFFFFF', }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10, backgroundColor: '#D9D3D3' }}>
                    <Typography variant="h6" gutterBottom style={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                        Create Task
                    </Typography>
                    <IconButton onClick={handleCloseModal}><IoCloseOutline /></IconButton>
                </div>
                <Paper style={{ padding: 20, width: '100%', height: '100%', overflowY: 'scroll', maxHeight: 550, backgroundColor: '#EFEDED' }}>

                    <div>
                        <label>Task Name</label>
                        <TextField placeholder='Task name' color='success' fullWidth name="notes" onChange={
                            (e) => setTaskName(e.target.value)
                        } style={{ marginBottom: 10, fontFamily: 'sen', backgroundColor: '#F3F3F3', marginTop: 5 }} />
                    </div>

                    <div style={{ marginTop: 15 }}>
                        <Typography> Task Details</Typography>
                        <OutlinedInput
                            onChange={(e) => setTaskDescription(e.target.value)}
                            fullWidth
                            color='success'
                            placeholder='Add Description'
                            sx={{ fontFamily: 'sen', backgroundColor: '#F3F3F3', marginTop: 1 }}
                            id="outlined-adornment-weight"
                            startAdornment={<InputAdornment position="start"><RxHamburgerMenu style={{ color: '#5F9C6A' }} /></InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>

                        <FormControlLabel
                            label="Repeat"
                            control={<IOSSwitch sx={{ m: 2 }} onChange={handleRepeat} />}
                        />
                        {isRepeat ? (
                            <>
                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                    <div style={{ marginRight: '8px' }}>
                                        <label style={{ marginLeft: 8 }}>Time</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopTimePicker defaultValue={dayjs('2022-04-17T15:30')}
                                                onChange={(newValue) => setSelectedTime(newValue)}
                                                sx={{
                                                    width: '85%',
                                                    marginLeft: 1,
                                                    backgroundColor: '#F3F3F3',
                                                    marginTop: 1,
                                                }} />
                                        </LocalizationProvider>
                                    </div>
                                    <div style={{ marginRight: '8px' }}>
                                        <label>Start Date</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker label="Select Date"
                                                onChange={(newValue) => setSelectedDate(newValue)}
                                                sx={{
                                                    width: '85%',
                                                    backgroundColor: '#F3F3F3',
                                                    marginTop: 1,
                                                }} />
                                        </LocalizationProvider>
                                    </div>
                                    <div>
                                        <label>End Date</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker label="Select Date"
                                               onChange={(newValue) => setSelectedEndDate(newValue)}
                                                sx={{
                                                    width: '85%',
                                                    backgroundColor: '#F3F3F3',
                                                    marginTop: 1,
                                                }} />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div>
                                    <CustomRepetitionSelector
                                        selectedDays={selectedDays}
                                        selectedFrequency={selectedFrequency}
                                        setSelectedDays={setSelectedDays}
                                        setSelectedFrequency={setSelectedFrequency}
                                        setSelectedDayOfMonth={setSelectedDayOfMonth}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div style={{ display: 'flex', marginBottom: '8px' }}>
                                    <div>
                                        <label style={{ marginLeft: 8 }}>Time</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopTimePicker defaultValue={dayjs('2022-04-17T15:30')}
                                                onChange={(newValue) => setSelectedTime(newValue)}
                                                sx={{
                                                    width: '85%',
                                                    marginLeft: 1,
                                                    backgroundColor: '#F3F3F3',
                                                    marginTop: 1,
                                                }} />
                                        </LocalizationProvider>
                                    </div>
                                    <div>
                                        <label style={{ marginLeft: 8 }}>Date</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker label="Select Date"
                                                onChange={(newValue) => setSelectedDate(newValue)}
                                                sx={{
                                                    width: '85%',
                                                    marginLeft: 1,
                                                    backgroundColor: '#F3F3F3',
                                                    marginTop: 1,
                                                }} />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>



                </Paper>
                <div style={{ textAlign: 'right', backgroundColor: '#D9D3D3', position: 'sticky', padding: 5 }}>
                    <IconButton onClick={handleAddTask} style={{ backgroundColor: '#5F9C6A', color: 'white', height: '20%', marginTop: 5, marginBottom: 5, marginRight: 5 }}>
                        <IoMdSend style={{ marginLeft: 4 }} />
                    </IconButton>
                </div>
            </div>

        </Modal>
    )
}

export default Task