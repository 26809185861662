import React from 'react';

const GoogleSheetsEmbed = () => {
  return (
    <div className="google-sheets-embed" style={{ display: 'flex', justifyContent: 'center', margin: '0 auto', backgroundColor: '#EFEDED', padding: '20px', borderRadius: '10px', width: '90%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
      <iframe
        title="Google Sheets Embed"
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQH0I_mMnylMXuYqnhFp6N3SXs07fJ8CMBmEHxOYzYYsezQQCW-4dO88A5P37gMZhGvgxdI4PULvwC4/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"
        width="100%"
        height="420px"
      ></iframe>
    </div>
  );
};

export default GoogleSheetsEmbed;
