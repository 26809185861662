import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Layout from './Layout'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import moment from 'moment';
import {baseURL} from '../config';
import ChatElement from '../components/ChatElement';
import { TextField } from '@mui/material';
import { getUserData, getChats } from '../apis/users-chats/api';


const UserChat = () => {

  const [user, setUser] = useState([]);
  const [userChat, setUserChat] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const chatContainerRef = useRef();


  const fetchUserData = useCallback(async () => {
    try {
      const response = await getUserData(baseURL);
      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error('Error fetching organization ID:', error);
    }
  }, []);

  const fetchUserChats = useCallback(async (phone) => {
    try {
      const response = await getChats(baseURL,phone);
      const data = await response.json();
      setUserChat(data.data.data);
    } catch (error) {
      console.error('Error fetching user chats:', error);
    }

  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData])

  useEffect(() => {
    if (selectedPhone) {
      fetchUserChats(selectedPhone);
    }
  }, [selectedPhone, fetchUserChats]);

  useEffect(() => {
    if (selectedPhone && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [selectedPhone, userChat]);


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = user?.data?.data?.filter((data) =>
    data.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    data.phone.includes(searchTerm)
  ) || [];


  return (
    <>
      <Layout>

        <Stack direction={"row"}>
          <div style={{ position: 'fixed', width: 400, height: '100vh', overflowY: 'auto', marginTop: 60, backgroundColor: "#F8F5F5", boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)' }}>
            <div style={{ position: 'sticky', top: 0, backgroundColor: "#F8F5F5", zIndex: 1, paddingTop: 8, paddingBottom: 8 }}>
              <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', textAlign: 'center', fontSize: 25, marginTop: 10, marginLeft: 50 }}>Farm GPT Users</Typography>
              <TextField
                variant="outlined"
                placeholder='Search using name or phone'
                style={{
                  marginTop: 2,
                  marginBottom: 3,
                  marginLeft: 60,
                  padding: '10px 18px',
                  width: '86%',
                }}
                value={searchTerm}
                onChange={handleSearchChange}
                color='success'

              ></TextField>
            </div>
            <div style={{ maxHeight: 'calc(100vh - 130px)', overflowY: 'auto' }}>
              <Stack>
                {
                  filteredUsers.length > 0 ? (
                    <ChatElement user={{ data: { data: filteredUsers } }} onUserClick={setSelectedPhone} selectedPhone={selectedPhone} />
                  ) : (
                    <Typography style={{ textAlign: 'center', marginTop: 20, marginLeft: 50 }}>No results</Typography>
                  )
                }
              </Stack>
            </div>
          </div>

          <div style={{ marginLeft: 400, height: '100vh', width: "calc(100vw - 400px)", backgroundColor: 'white', overflowY: 'auto', marginTop: 100 }} ref={chatContainerRef}>
            {
              selectedPhone ? (

                userChat.map((userChat, index) => (
                  <React.Fragment key={index}>
                    <Stack key={index} spacing={2} direction="column" >
                      {
                        userChat && userChat.chat && userChat.chat.map((messages, chatIndex) => (
                          <>
                            {chatIndex === 0 && (
                              <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                <div style={{ backgroundColor: '#EFEDED', padding: '5px 10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                  <Typography style={{ color: '#333', fontWeight: 'bold', fontFamily: 'sen' }}>
                                    {moment(messages[0].created_at).format('MMM D')}
                                  </Typography>
                                </div>
                              </div>
                            )}

                            <Box key={chatIndex} sx={{ marginTop: 2 }}>
                              {messages?.map((message, messageIndex) => (
                                <Box
                                  key={messageIndex}
                                  p={1.5}
                                  sx={{
                                    borderRadius: 1,
                                    // display: 'flex',
                                    marginTop: 3,
                                    marginBottom: 3,
                                    width: message.role === "assistant" ? '50%' : 'fit-content',
                                    marginLeft: message.role === "user" ? 'auto' : 1,
                                    marginRight: message.role === "user" ? 1 : 'auto',
                                    // backgroundColor: message.role === "user" ? '#E7F2E7' : '#F4F7FB',
                                    backgroundColor: message.message_type === "audio"
                                      ? '#FFE3BB'
                                      : message.role === "static" ? '#ff7961' :
                                        message.role === "user" ? '#E7F2E7' : '#F4F7FB',
                                  }}

                                >
                                  <Typography style={{ marginLeft: 8, fontWeight: message.role === "user" ? "bold" : "normal" }}>
                                    {message.message}
                                  </Typography>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                                    {message.message_type === "audio" && <KeyboardVoiceIcon style={{ marginLeft: 8, fontSize: 15 }} />}
                                    <div style={{ textAlign: message.message_type !== "audio" ? 'right' : 'left' }}>
                                      <Typography style={{ fontSize: 10, color: 'gray' }}>
                                        {new Date(message.created_at).toLocaleTimeString('en-US', { timeZone: 'UTC', hour: '2-digit', minute: '2-digit', hour12: true })}
                                      </Typography>
                                    </div>
                                  </div>

                                </Box>
                              ))}
                            </Box>
                            {chatIndex < userChat.chat.length - 1 && (
                              <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                <div style={{ backgroundColor: '#EFEDED', padding: '5px 10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                  <Typography style={{ color: '#333', fontWeight: 'bold', fontFamily: 'sen' }}>
                                    {moment(userChat.chat[chatIndex + 1][0].created_at).format('MMM D')}
                                  </Typography>
                                </div>
                              </div>
                            )}
                          </>
                        ))}

                    </Stack>

                  </React.Fragment>
                ))
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography style={{ fontFamily: 'sen' }}>
                    Select a user to view the chat
                  </Typography>
                </div>
              )
            }

          </div>
        </Stack>
      </Layout>
    </>
  )
}

export default UserChat