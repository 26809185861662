
// import React, { useContext, useCallback, useState, useEffect } from 'react';
// import { debounce } from 'lodash';
// import SearchIcon from '@mui/icons-material/Search';
// const SoilTestSearchbar = ({ setSearchQuery }) => {
//   const [inputValue, setInputValue] = useState('');

//   const debouncedSearch = useCallback(
//     debounce((query) => {
//       setSearchQuery(query); // Set the search query to filter by farmer_id
//     }, 700),
//     []
//   );

//   const handleInputChange = (event) => {
//     const { value } = event.target;
//     setInputValue(value);
//     debouncedSearch(value);
//   };

//   return (
//     <div className="flex items-center bg-[#F2F5F4] p-2 rounded-3xl w-[100%]" style={{border:"1px solid #028476"}}>
//       <SearchIcon sx={{ color: "#028476" }} className="ml-2 mr-4" />
//       <input
//         type="text"
//         className="bg-transparent border-none text-black placeholder-black placeholder-opacity-50 focus:outline-none w-full"
//         placeholder="Search by Farmer ID"
//         value={inputValue}
//         onChange={handleInputChange}
//       />
//     </div>
//   );
// };

// export default SoilTestSearchbar;
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';

const SoilTestSearchbar = ({ setSearchQuery }) => {
  const [inputValue, setInputValue] = useState('');

  const handleSearchClick = () => {
    setSearchQuery(inputValue);  // Set the search query only when the button is clicked
  };

  return (
    <div className="flex items-center bg-[#F2F5F4] p-2 rounded-3xl w-[100%]" style={{ border: "1px solid #028476" }}>
      <SearchIcon sx={{ color: "#028476" }} className="ml-2 mr-4" />
      <input
        type="text"
        className="bg-transparent border-none text-black placeholder-black placeholder-opacity-50 focus:outline-none w-full"
        placeholder="Search by Farmer ID"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)} 
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#028476",
          marginLeft: '10px',
          "&:hover": {
            backgroundColor: "#016b5d",
          },
        }}
        onClick={handleSearchClick}  
      >
        Search
      </Button>
    </div>
  );
};

export default SoilTestSearchbar;
