import {BEARER_TOKEN} from '../../config'

export const handleAddZone = async (
    baseUrl,
    payload
) => {

    const response = await fetch(`${baseUrl}/api/mgmt/zonescrm/addZone`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify(payload),
    });

    return response;
}

export const handleEditZone = async (
    baseUrl,
    payload
) => {

    const response = await fetch(`${baseUrl}/api/mgmt/zonescrm/editZone`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify(payload),
    });

    return response;
}

export const getZoneTypes = async (
    baseUrl
) => {
    const response = await fetch(`${baseUrl}/api/mgmt/zonescrm/listZoneTypes`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: ""
    });

    return response;
}

export const calculateZoneArea = async (
    baseUrl,
    payload
) => {

    const response = await fetch(`${baseUrl}/api/mgmt/zonecrm/calculateArea`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify(payload),
    });

    return response;
}

export const handleDeleteZone = async (
    baseUrl,
    payload
) => {

    const response = fetch(`${baseUrl}/api/mgmt/zonescrm/deleteZone`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify(payload),
    });

    return response;

}