import React, { useCallback, useState, useEffect } from 'react';
import Layout from './Layout';
import {
    Typography, Grid, TextField, MenuItem, IconButton, Select, FormControl, InputLabel, Button,
    TableContainer, Paper, TableHead, TableRow, TableCell, Table, TableBody, Dialog, DialogTitle, DialogContent, DialogActions,
    CircularProgress, Snackbar,
} from '@mui/material';
import { IoMdAdd } from 'react-icons/io';
import SearchIcon from '@mui/icons-material/Search';
import {baseURL} from '../config';
import MuiAlert from '@mui/material/Alert';
import { getAllOrg } from '../apis/api';
import { getFeatures, addFeature, deleteFeature, updateFeature } from '../apis/feature-mgmt/api';
import { createPermission } from '../utils/helpers';

const FeatureManagement = () => {

    const userData = JSON.parse(localStorage.getItem('userData'));
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    const [searchQuery, setSearchQuery] = useState('');
    const [searchDropdown, setSearchDropdown] = useState('feature');
    const [features, setFeatures] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState("");
    const [organisation, setOrganisation] = useState([]);
    const [selectedOrgId, setSelectedOrgId] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [featureName, setFeatureName] = useState("");
    const [error, setError] = useState({
        featureName: false,
    })
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteFeatureId, setDeleteFeatureId] = useState("");
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState({});
    const [filteredFeatures, setFilteredFeatures] = useState([]);


    const getAllOrganistations = useCallback(async () => {
        setLoading(true);
        try {

            const response = await getAllOrg(baseURL);
            const data = await response.json();
            if (data.data) {
                setLoading(false);
                return data;
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }, []);

    const getAllFeatures = useCallback(async (orgId) => {
        if (!orgId) {
            return;
        }

        try {
          
            const response = await getFeatures(baseURL, orgId);
            const data = await response.json();
            if (data.data) {
                setFeatures(data.data.features);
                setFilteredFeatures(data.data.features);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    const hasCreatePermission = createPermission('feature_mgmt', permissions);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        if (event.target.value === '') {
            setFilteredFeatures(features);
        } else {
            const word = event.target.value.toLowerCase().split(' ');
            const filtered = features.filter(feature =>
                word.every(w => feature.name.toLowerCase().includes(w))
            )
            setFilteredFeatures(filtered);
        }
    };

    const handleOrgChange = (event) => {
        const selectedId = event.target.value;
        const selectedOrgName = organisation.find(org => org.id === selectedId)?.name || '';
        setSelectedOrgId(selectedId);
        setSelectedOrg(selectedOrgName);
    };

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleAddFeature = async () => {

        if (!featureName) {
            return;
        }

        console.log("feature name",featureName);

        const featureNameRegex = /^[a-zA-Z0-9_]+_mgmt$/;
        if (!featureNameRegex.test(featureName)) {
            setError({ featureName: true });
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage('Feature name should end with _mgmt');
            return;
        }

        setLoading(true);

        try {

            const response = await addFeature(baseURL, selectedOrgId, featureName, userData.id);
            const data = await response.json();
            if (data?.data?.feature_id) {
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarSeverity('success');
                setSnackbarMessage('Feature added successfully');
                setOpenDialog(false);
                setFeatureName('');
                getAllFeatures(selectedOrgId);
            }else{
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarSeverity('error');
                setSnackbarMessage('Feature name already exists');
            }

        } catch (err) {
            console.log(err);

        }
    };

    const handleDeleteFeature = async (featureId, featureName) => {

        if (!featureId || featureName === "") {
            return;
        }

        try {

            const response = await deleteFeature(baseURL, featureId, featureName, selectedOrgId, userData.id);
            const data = await response.json();
            if (data?.data) {
                setSnackbarOpen(true);
                setSnackbarSeverity('success');
                setSnackbarMessage('Feature deleted successfully');
                getAllFeatures(selectedOrgId);
            }else {
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarSeverity('error');
                setSnackbarMessage('Feature cannot be deleted');
            }

        } catch (err) {
            console.log(err);
        }

    }

    const handleFeatureNameChange = (e) => {
        const value = e.target.value;
        const alphabeticRegex = /^[a-zA-Z0-9_]*$/; // Allow alphabets and underscore only
        if (alphabeticRegex.test(value)) {
            setFeatureName(value);
            setError({ featureName: false });
        } else {
            setError({ featureName: true });
        }
    };

    function formatFeatureName(featureName) {
        return featureName.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')
            .replace('Mgmt', 'Management');
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const handleUpdateFeature = async () => {

        const featureNameRegex = /^[a-zA-Z]+_mgmt$/;
        if (!featureNameRegex.test(selectedFeature.name)) {
            setError({ featureName: true });
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage('Feature name should end with _mgmt');
            return;
        }

        try {
            
            const response = await updateFeature(baseURL, selectedFeature, selectedOrgId, userData.id);
            const data = await response.json();
            console.log(data);

            if (data?.data) {
                setSnackbarOpen(true);
                setSnackbarSeverity('success');
                setSnackbarMessage('Feature updated successfully');
                getAllFeatures(selectedOrgId);
                setOpenUpdateDialog(false);
            }else {
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarSeverity('error');
                setSnackbarMessage('Error updating feature');
            }

        } catch (err) {
            console.log(err);
        }

    }

    useEffect(() => {
        getAllOrganistations().then((data) => {
            setOrganisation(data.data.orgs);
        });
    }, [getAllOrganistations]);

    useEffect(() => {
        if (selectedOrgId) {
            getAllFeatures(selectedOrgId);
        }
    }, [selectedOrgId, getAllFeatures]);


    return (
        <>
            <Layout>
                <div style={{ height: '100vh', marginTop: 90 }}>
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 25 }}>List of Features</Typography>
                        <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>(Search using feature name) </Typography>
                    </div>
                    <Grid container style={{ marginTop: 50 }} spacing={2} justifyContent={"center"} >
                        <Grid item xs={12} md={1} >
                            <FormControl sx={{ width: "100%" }} >
                                <InputLabel id="demo-simple-select-label" color='success'>Org</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedOrgId}
                                    label="Org"
                                    color='success'
                                    onChange={handleOrgChange}
                                >
                                    <MenuItem value={""}>None</MenuItem>
                                    {organisation.map((org, index) => (
                                        <MenuItem key={index} value={org.id}>{org.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <TextField
                                    fullWidth
                                    label={selectedOrg === "" ? "Please select organisation" : searchDropdown ? `Search by ${searchDropdown.toUpperCase()}` : 'Search'}
                                    value={searchQuery}
                                    color='success'
                                    variant="outlined"
                                    disabled={selectedOrg === "" ? true : false}
                                    onChange={handleSearchChange}
                                    onKeyPress={e => e.key === 'Enter'}
                                    InputProps={{
                                        startAdornment: (
                                            <TextField
                                                select
                                                value={searchDropdown}
                                                color='success'
                                                sx={{ minWidth: 120 }}
                                                variant='standard'
                                                disabled={selectedOrg === "" ? true : false}
                                                onChange={event => {
                                                    const sD = event.target.value;
                                                    setSearchDropdown(sD);
                                                    setSearchQuery('');
                                                }}
                                            >
                                                <MenuItem value={"feature"}>NAME</MenuItem>
                                            </TextField>
                                        ),
                                        endAdornment: (
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        ),
                                    }}
                                />
                                {hasCreatePermission && (
                                    <Button
                                        onClick={handleOpen}
                                        style={{
                                            borderRadius: '50%',
                                            marginLeft: 10,
                                            width: '45px',
                                            height: '55px',
                                            minWidth: '55px',
                                            minHeight: '55px',
                                            fontSize: '1.2rem',
                                        }}
                                        variant='contained'
                                        color='success'
                                        disabled={selectedOrg === "" ? true : false}
                                    >
                                        <IoMdAdd />
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </Grid>

                    <div style={{ marginLeft: 65 }}>
                        <TableContainer component={Paper} style={{ marginTop: 20 }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Name
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Org ID
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Created At
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Status
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Action
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedOrg === "" ? (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">
                                                Select Organisation to view features
                                            </TableCell>
                                        </TableRow>
                                    ) : filteredFeatures.length > 0 ? (
                                        filteredFeatures.map((feature, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{formatFeatureName(feature.name)}</TableCell>
                                                <TableCell align="center">{feature.org_id}</TableCell>
                                                <TableCell align="center">{formatDate(feature.created_at)}</TableCell>
                                                <TableCell align="center" sx={{ color: feature.active ? "green" : "red" }}>{feature.active ? "ACTIVE" : "INACTIVE"}</TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        variant='outlined'
                                                        color='primary'
                                                        style={{ marginRight: 10 }}
                                                        // disabled={!hasUpdatePermission}
                                                        onClick={() => {
                                                            setSelectedFeature(feature);
                                                            setOpenUpdateDialog(true);
                                                        }}
                                                        disabled
                                                    >
                                                        Update
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        
                                                        disabled
                                                        onClick={() => {
                                                            setOpenDeleteDialog(true);
                                                            setDeleteFeatureId(feature.id);
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                No features available for the selected organisation
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} disableEnforceFocus
                    onBackdropClick={() => {
                        setOpenDialog(false);
                        setFeatureName('');
                        setError({ featureName: false });
                    }}
                >
                    <div style={{ backgroundColor: "#d0e7b7" }}>
                        <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Add Feature</DialogTitle>
                    </div>
                    <DialogContent>
                        <div>
                            <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>
                                You are adding a new feature for <span style={{ fontWeight: 'bold' }}>{selectedOrg.charAt(0).toUpperCase() + selectedOrg.slice(1)}</span> organisation
                            </Typography>
                            <TextField
                                fullWidth
                                label="Feature Name"
                                variant="outlined"
                                color='success'
                                value={featureName}
                                error={error.featureName ? true : false}
                                style={{ marginTop: 30 }}
                                placeholder='Add _mgmt at the end of your feature name'
                                onChange={handleFeatureNameChange}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => {
                            setOpenDialog(false)
                            setFeatureName('');
                            setError({ featureName: false });
                        }}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleAddFeature} color="success">
                            {loading ? <CircularProgress color='success' size={24} /> : 'Add'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} disableEnforceFocus>
                    <div style={{ backgroundColor: "#d0e7b7" }}>
                        <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Delete Feature</DialogTitle>
                    </div>
                    <DialogContent>
                        <div>
                            <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>
                                Are you sure you want to delete this feature?
                            </Typography>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => {
                            setOpenDeleteDialog(false);
                        }}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={() => {
                            handleDeleteFeature(
                                deleteFeatureId,
                                features.find(feature => feature.id === deleteFeatureId)?.name
                            );
                            setOpenDeleteDialog(false);
                        }} color="error">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openUpdateDialog} onClose={() => setOpenUpdateDialog(false)} fullWidth>
                    <div style={{ backgroundColor: "#d0e7b7" }}>
                        <DialogTitle>Update Feature</DialogTitle>
                    </div>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    label="Feature Name"
                                    color='success'
                                    placeholder='Add _mgmt at the end of your feature name'
                                    value={selectedFeature.name}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const alphabeticRegex = /^[a-zA-Z_]*$/;
                                        if (alphabeticRegex.test(value)) {
                                            setSelectedFeature({ ...selectedFeature, name: e.target.value });
                                            setError({ featureName: false });
                                        } else {
                                            setError({ featureName: true });
                                        }
                                    }}
                                    error={error.featureName}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setError({ featureName: false });
                            setOpenUpdateDialog(false)
                        }} variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} >
                            Cancel
                        </Button>
                        <Button onClick={handleUpdateFeature} variant="contained" color='success'>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>


                <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                    <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </Layout>
        </>
    );
};

export default FeatureManagement;
