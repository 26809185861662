import React from 'react'
import Layout from './Layout'

const SingleSoilTestReport = () => {
  return (
    <Layout>
    <div className="w-full flex flex-col pl-[75px] pr-[10px] pt-[75px] 
    pb-[10px] relative h-[100dvh]">
   
      <div className="h-[40px] flex w-full justify-center items-center">
      <h1
       
       className="font-boldtext-[20px] font-bold "
     >
       Soil Test Report
     </h1>
     </div>
     </div>
     </Layout>
  )
}

export default SingleSoilTestReport