import React, { useState } from 'react'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import OrderDetails from './OrderDetails';
// import EditRequirement from './EditRequirement';
import { useNavigate } from 'react-router-dom';



const ListRequirement = ({ requirement, getRequirementData, leadData }) => {



    const leadId = leadData.data.lead.id;

    const [openOrderDetails, setOpenOrderDetails] = useState(false);
   

    const navigate = useNavigate();
  

    const handleOpenOrderDetails = () => {
        setOpenOrderDetails(!openOrderDetails);
    };


    const formatCreatedAtDate = (createdAt) => {
        const date = new Date(createdAt);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    };


    


    const handleEditRequirementDetails = (id) => {

        console.log(id)

        navigate(`/edit-requirement/${id}/${leadId}`)
    }
console.log(requirement);
   

    return (
        <>
            <div >
                <Card sx={{ minWidth: 275, marginBottom: 2 }}>


                    <CardHeader

                        title={
                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 22 }}>
                                Requirements
                            </Typography>
                        }

                        action={
                            <>

                                <IconButton aria-label="settings">
                                    {
                                       
                                            <EditIcon onClick={() => handleEditRequirementDetails(requirement.id)} />
                                        
                                    }

                                </IconButton>

                            </>


                        }


                    ></CardHeader>
                    <CardContent>
                        
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>



                                        <div >
                                            <Typography style={{ fontFamily: 'sen' }}>Farm Name</Typography>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 16 }}>{requirement.farm.name}</Typography>
                                        </div>
                                        <div style={{maxWidth: 160}}>
                                            <Typography style={{ fontFamily: 'sen' }}>Farm Location</Typography>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 16 }}>{requirement.farm.location}</Typography>
                                        </div>
                                        <div>
                                            <Typography style={{ fontFamily: 'sen' }}>Farm Size</Typography>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 16 }}>{requirement.farm.size} {requirement.farm.sizeUnit}</Typography>
                                        </div>
                                        <div>
                                            <Typography style={{ fontFamily: 'sen' }}>Added On</Typography>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 16 }}>{formatCreatedAtDate(requirement.leadDate)}</Typography>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 50 }}>
                                        <div>
                                            <Typography style={{ fontFamily: 'sen', fontSize: 16 }}>Current Produce</Typography>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 16 }}>{requirement.currentProduce}</Typography>
                                        </div>
                                        <div>
                                            <Typography style={{ fontFamily: 'sen' }}>Intrested In</Typography>
                                            {/* <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 16 }}>{requirement.farm.location}</Typography> */}
                                        </div>
                                        <div style={{ maxWidth: 200 }}>
                                            <Typography style={{ fontFamily: 'sen' }}>Notes</Typography>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', maxHeight: 60, overflow: 'hidden' }}>{requirement.customerNote}</Typography>
                                        </div>
                                    </div>

                                </>

                        

                        <div style={{ marginTop: 50, textAlign: 'center' }}>
                            <Button style={{ fontFamily: 'sen', color: 'gray', backgroundColor: 'white' }} onClick={handleOpenOrderDetails}>View Order Details</Button>
                        </div>



                    </CardContent>

                </Card>
            </div>
            <OrderDetails open={openOrderDetails} handleClose={handleOpenOrderDetails} />
           
        </>
    )
}

export default ListRequirement