import {BEARER_TOKEN} from '../../config'

export const listUserByOrgId = async (
    baseURL,
    orgId
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-user/listUsers`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            "org_id": orgId
        })
    }
    );
    return response
}

//list of features
export const listFeatures = async (
    baseURL,
    orgId
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-features/listAll`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            "org_id": orgId
        })
    }
    );
    return response

}

//list of roles
export const listRoles = async (
    baseURL,
    orgId
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-roles/listAll`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            "org_id": orgId
        })
    }
    );
    return response

}

//add user
export const addUser = async (
    baseURL,
    payload,
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-user/createUser`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify(payload)
    }
    );
    return response
}

export const getUserData = async (
    baseURL,
    userId,
    orgId
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-user/getUser`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            id: userId,
            org_id: orgId
        })

    })

    return response
}

export const updateUser = async (
    baseURL,
    payload,
    userId
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-user/updateUser`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            user: payload,
            user_id: userId
        })
    }
    );
    return response
}

export const deleteUser = async (
    userId,
    orgId,
    baseURL,
    loginUserId
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-user/deleteUser`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            user: {
                id: userId,
                org_id: orgId,
            },
            user_id: loginUserId
        })

    })
    return response
}