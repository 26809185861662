import React, { useState } from 'react';

import { Modal, Paper, Typography, TextField, Button } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomRepetitionSelector from './CustomRepetitionSelector';



import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import dayjs from 'dayjs';

const UserTask = ({ open, handleClose }) => {

  // const [fieldErrors, setFieldErrors] = useState({});

  // const [loading, setLoading] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);

  


  const handleChange = () => {
    console.log('changed');
  }


  const handleSignUp = () => {
    // try {
    //   const errors = {};
    //   let hasErrors = false;

    //   setLoading(true);

    //   for (const key in formData) {
    //     if (!formData[key]) {
    //       errors[key] = 'This field is required';
    //       hasErrors = true;
    //       setLoading(false);
    //     }
    //   }

    //   setFieldErrors(errors);

    //   if (hasErrors) {
    //     return;
    //   }

    //   // Step 1: Register the user
    //   fetch(`${baseURL}/api/users`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(formData),
    //   })
    //     .then((response) => response.json())
    //     .then((data) => {
    //       // Handle the API response here 
    //       console.log('API Response:', data);
    //       if (data.success) {
    //         // Step 2: Fetch the user data based on the provided email
    //         fetch(`${baseURL}/api/mgmt/phyfarmUsers/listAll`, {
    //           method: 'POST',
    //           headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', // Replace with your actual access token
    //           },
    //           body: JSON.stringify({
    //             "pagination": {
    //               "current_page": 1,
    //             },
    //             "filters": {
    //               "email": formData.email, // Use the email from the form data
    //             }
    //           }),
    //         })
    //           .then((response) => response.json())
    //           .then((userData) => {
    //             if (userData.data.users.users.length > 0) {
    //               // Step 3: Verify the user using their ID
    //               const userId = userData.data.users.users[0].id;
    //               const user = userData.data.users.users[0];
    //               const fullName = `${user.firstName} ${user.lastName}`;
    //               navigate(`/register-form/${fullName}/${userId}`);
    //               // Assuming the user ID is in the first result
    //               fetch(`${baseURL}/api/mgmt/phyfarmUsers/verify`, {
    //                 method: 'POST',
    //                 headers: {
    //                   'Content-Type': 'application/json',
    //                   'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', // Replace with your actual access token
    //                 },
    //                 body: JSON.stringify({
    //                   "user_id": userId,
    //                 }),
    //               })
    //                 .then((response) => response.json())
    //                 .then((verificationData) => {
    //                   console.log('User Verification Data:', verificationData);

    //                   if (verificationData.success) {
    //                     // Clear the form fields and close the modal
    //                     setFormData({
    //                       firstName: '',
    //                       lastName: '',
    //                       email: '',
    //                       phone: '',
    //                       password: '',
    //                       checked: [],
    //                     });
    //                     handleClose(); // Close the modal
    //                     navigate('/register-form'); // Navigate to the register-form page only if the user is verified
    //                   }
    //                 })
    //                 .catch((verificationError) => {
    //                   console.error('User Verification Error:', verificationError);
    //                 });
    //             }
    //           })
    //           .catch((fetchError) => {
    //             console.error('User Data Fetch Error:', fetchError);
    //           });
    //       }
    //     })
    //     .finally(() => {
    //       setLoading(false);
    //     });
    // } catch (error) {
    //   // Handle any errors here
    //   console.error('API Error:', error.error.message);
    //   alert('Error:', error.message);
    //   setLoading(false);
    // }
  };




  return (
    <Modal open={open} onClose={handleClose}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', maxWidth: 750 }}>
        <Paper style={{ padding: 20, backgroundColor: '#F2F9F2' }}>
          <Typography variant="h4" gutterBottom style={{ fontFamily: 'sen' }}>
            Add a Task

          </Typography>
          <Typography variant="body1" gutterBottom style={{ fontFamily: 'sen', marginBottom: 10 }}>
            Manage activities with ease
          </Typography>

          <TextField multiline rows={4} maxRows={10} label="Description of the Task" fullWidth name="notes" onChange={handleChange} style={{ marginBottom: 10, fontFamily: 'sen', backgroundColor: 'white' }}/>

          <div style={{ display: 'flex', margin: '10px auto' }}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <DemoContainer components={['DatePicker']} > */}
              <DatePicker label="Select Date" sx={{
                width: '100%',
                marginRight: 1,
                backgroundColor: 'white'
              }} />
              {/* </DemoContainer> */}
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker defaultValue={dayjs('2022-04-17T15:30')} sx={{
                width: '100%',
                marginLeft: 1,
                backgroundColor: 'white',
              }} />
            </LocalizationProvider>

          </div>

          <div>
            <CustomRepetitionSelector
              selectedDays={selectedDays}
              selectedFrequency={selectedFrequency}
              setSelectedDays={setSelectedDays}
              setSelectedFrequency={setSelectedFrequency}
            />
          </div>

          <div style={{ textAlign: 'right' }}>
            <Button variant="contained" style={{
              backgroundColor: '#5F9C6A', marginTop: 20
            }} onClick={handleSignUp}>

              {/* {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'} */}
              Save
            </Button>
          </div>

        </Paper>
      </div>

    </Modal>
  );
};


export default UserTask;

