import { useState, useCallback, useRef, useEffect } from "react";
import { deleteTags } from "../../apis/360-tags-mgmt/api";
import { baseURL } from "../../config";

const useTagsDelete = () => {
  const orgId = useRef(localStorage.getItem("orgId"));
  const token = useRef(localStorage.getItem("token"));

  const [state, setState] = useState({
    open: false,
    loading: false,
    tagId: null,
    name: "",
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarSeverity: "success",
  });

  const handleOpenDeleteDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      open: false,
      tagId: null,
      name: "",
    }));
  }, []);

  const setDeleteTag = useCallback((tagId, name) => {
    setState((prevState) => ({
      ...prevState,
      tagId,
      name,
    }));
  }, []);

  const handleSnackbarClose = () => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    orgId.current = userData.org.id;
    token.current = userData.access_token;
  }, []);

  const deleteTagHandler = useCallback(async () => {
    if (!orgId.current || !token.current || state.tagId === null) {
      console.error("orgId, token, or tagId is missing");
      return;
    }

    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await deleteTags(
        baseURL,
        orgId.current,
        token.current,
        state.tagId,
        state.name
      );
      const data = await res.json();

      if (data.code === "DELETE_TAG_SUCCESS") {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          open: false,
          snackbarOpen: true,
          snackbarMessage: "Tag deleted successfully",
          snackbarSeverity: "success",
        }));
        return true;
      } else {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          open: false,
          snackbarOpen: true,
          snackbarMessage: "Failed to delete tag",
          snackbarSeverity: "error",
        }));
        return false;
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        open: false,
        snackbarOpen: true,
        snackbarMessage: "Failed to delete tag",
        snackbarSeverity: "error",

      }));
      return false;
    }
  }, [state.tagId, state.name]);

  return {
    state,
    deleteTagHandler,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    setDeleteTag,
    handleSnackbarClose,
  };
};

export default useTagsDelete;
