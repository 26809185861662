import React, { useCallback, useEffect, useState } from "react";
import Layout from "./Layout";
import { TextField, Button, Typography, Box, Paper, Snackbar, Alert, FormControl, InputLabel, Select, MenuItem, createTheme, ThemeProvider, styled, Stack , Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import AddIcon from "@mui/icons-material/Add";
import AddchecklistDialog from "../components/AddchecklistDialog";
import EndUserLineChart from "../components/EndUserLineChart";
import { Link, useParams } from "react-router-dom";
import { useDispatch,} from "react-redux";
import { deleteActivity, getParticularprogram, listActivities, updateProgram } from "../apis/programs/api";
import { particularProgramFetchFailure, particularProgramFetchSuccess } from "../reducers/programs";
import { baseURL } from "../config";
import CreateActivityModal from "../components/CreateAcitvityModal";
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}
const SingleProgram = () => {
const { id } = useParams();
const dispatch = useDispatch();
const [edit, setEdit] = useState(false);
const [openErrorToast, setOpenErrorToast] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const [open, setOpen] = useState(false);
const [activities, setActivities] = useState([]);
const [program, setProgram] = useState("");
const [openActivityModal, setOpenActivityModal] = useState(false);
// const [opens, setOpens] = useState(false);
const [deleteActivityId,setDeleteActivityId]=useState(null)

const handleClose = () => {
  setOpen(false);
  // setOpens(false);
};


const [formData, setFormData] = useState({
  name: '',
  description: '',
  type: '',
  location: '',
  file: null,
  file_url:''
});
const [openToast, setOpenToast] = useState(false);
const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

const org_id = JSON.parse(localStorage.getItem("userData")).org.id;


const fetchParticularProgram = useCallback(async () => {
  try {
    const result = await getParticularprogram(baseURL, org_id, id);
    dispatch(particularProgramFetchSuccess(result.data));
    setProgram(result.data);
    setFormData({
      name: result.data.name,
      description: result.data.description,
      type: result.data.type,
      location: result.data.location,
      file: result.data.file,
    });
  } catch (error) {
    dispatch(particularProgramFetchFailure({ error: error.message }));
  }
}, [org_id, id, dispatch, setProgram, setFormData]);

useEffect(() => {
  fetchParticularProgram();
}, [fetchParticularProgram]);
const StyledDeleteButton = styled(Button)({
  backgroundColor: '#028476',
  color: 'white',
  '&:hover': {
    backgroundColor: '#1f5e25',
  },
});
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};
const toggleEdit = () => {
  setEdit((prevEdit) => !prevEdit);
};

const handleFileChange = (e) => {
  setFormData((prevData) => ({
    ...prevData,
    file: e.target.files[0],
  }));
};

const handleSubmit = async (e) => {
  e.preventDefault();
  // if (!formData.name || !formData.description || !formData.type || !formData.location ) {
  //   setErrorMessage('All fields are required!');
  //   setOpenErrorToast(true);
  //   setTimeout(() => setOpenErrorToast(false), 2000); 
  //   return;
  // }
  const programData = {
    id,
    org_id,
    ...formData,
  };
  try {
 programData.file_url=program.file_url
    await updateProgram(baseURL, programData);
    setOpenToast(true);
    setEdit(false)
    await fetchParticularProgram()
  } catch (error) {
    console.error('Failed to update program:', error.message);
    setErrorMessage('Failed to update program. Please try again.');
    setOpenErrorToast(true);
    setTimeout(() => setOpenErrorToast(false), 2000); 
  
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#028476',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#028476',
            },
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#028476',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#028476',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#028476',
          },
        },
      },
    },
  },
});


const ScrollableDiv = styled(Box)({
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
    borderRadius: "200px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#028476",
    borderRadius: "100px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#1f5e25",
  },
});


const fetchActivities = useCallback(async () => {
  try {
    const data = await listActivities(baseURL, org_id, id);
    setActivities(data.data.activities);
  } catch (err) {
    console.log(err.message);
  }
}, [org_id, id]);

useEffect(() => {
  fetchActivities();
}, [fetchActivities]);

const handleDeleteActivity = async (id) => {
  setOpenDeleteConfirm(true);
  setDeleteActivityId(id)
};
const handleDeleteConfirm  = async (activityId) => {
  setOpenDeleteConfirm(false);

  try {
    await deleteActivity(baseURL, {
      id: activityId,
      program_id: id,
      org_id: org_id,
    });

    fetchActivities()

  
    console.log('Activity deleted successfully');
  } catch (error) {
    console.error('Error deleting activity:', error.message);
  }
};
const handleDeleteCancel = () => {
  setOpenDeleteConfirm(false);
};
const BlurredDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Adjust opacity as needed
  },
}));
  return (
    <Layout>
      <div className="w-full flex flex-col pl-[75px] pr-[10px] h-[100dvh] gap-5 pb-[10px] pt-[75px] " style={{fontFamily: 'sen',}}>
      <div className="w-full h-full flex flex-row gap-5">
        <div className="w-full flex h-full flex-col gap-5 items-center justify-center">
      <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <Paper 
                elevation={3} 
                sx={{ 
                  padding: 4, 
                  width: '100%',
                  border: '1px dashed #028476',
                  boxShadow: '0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)',
                  transition: 'box-shadow 0.3s ease-in-out', 
                  '&:hover': {
                    boxShadow: '0 0 15px rgba(2, 132, 118, 0.3), 0 0 30px rgba(2, 132, 118, 0.2)',
                  },
                  borderRadius: '10px',
                }}
              >
                <div className="flex flex-row justify-between items-center">
                <Typography variant="h5" component="h2" gutterBottom>
                 {edit ? "Update Program" : "Program Details"} 
                </Typography>
                <Button onClick={toggleEdit}     variant="contained" >{edit ? "Cancel":"Edit"}</Button>
                </div>
            
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Program Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              autoComplete="off"
              disabled={!edit}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Description"
              name="description"
              multiline
              rows={4}
              value={formData.description}
              onChange={handleChange}
              autoComplete="off"
              disabled={!edit}
            />
            <FormControl fullWidth margin="normal" disabled={!edit}>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleChange}
                label="Type"
              >
                <MenuItem value="soil">Soil</MenuItem>
                <MenuItem value="water">Water</MenuItem>
                <MenuItem value="air">Air</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              autoComplete="off"
              disabled={!edit}
            />
            {
              program.file_url &&  <a href={program.file_url} target="_blank" rel="noreferrer">
              <OpenInNewIcon className="text-[#028476]" / >
              </a>
            }
           

       
     
                              <div className="w-full flex flex-row items-center gap-10 pt-4">

            <div className="w-full flex flex-col items-center">
                      <Box sx={{ mt: 2, width: '100%', my: 'auto' }}>
                        <input
                          accept="application/pdf"
                          style={{ display: 'none' }}
                          id="raised-button-file"
                          type="file"
                          onChange={handleFileChange}
                          disabled={!edit} 
                        />
                        <label htmlFor="raised-button-file">
                          <Button variant="outlined" component="span" fullWidth disabled={!edit}>
                            Upload File
                          </Button>
                        </label>
                      </Box>
                      {formData.file && (
                        <Typography variant="body2" sx={{ mt: 1, color: '#028476' }}>
                          File selected: {formData.file.name}
                        </Typography>
                      )}
                    </div>
         {edit && (
       <Button 
       type="submit" 
       variant="contained" 
       color="primary" 
       fullWidth 
       sx={{ mt: 3, my: 'auto' }}
     >
       Save Changes
     </Button>
      )}
      </div>
      <Snackbar
  open={openErrorToast}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  autoHideDuration={2000}
>
  <Alert severity="error" sx={{ width: '100%' }}>
    {errorMessage}
  </Alert>
</Snackbar>
          </form>
        </Paper>
        <Snackbar
          open={openToast}
          autoHideDuration={6000}
          onClose={() => setOpenToast(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Program updated successfully!
          </Alert>
        </Snackbar>
      </Box> 
      </ThemeProvider>
      <div className="w-full flex flex-col gap-2">
      <Stack sx={{ width: '100%', height: '100%',paddingY:2}} spacing={2} style={{justifyContent:"center"}}>
          <div className="flex flex-row w-full justify-center text-[20px]">
          <Typography variant="h5" component="h2" gutterBottom>
          Progress Bar
          </Typography>
                  </div>
          <Box sx={{ display: 'flex', alignItems: 'center',justifyContent:'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" value={program ? program?.progress: 99}   sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#028476',
                    borderRadius:20
                  },
                  height:10,
                  borderRadius:20
                }}/>
            </Box>
            <Box sx={{ minWidth: 35 ,justifyContent:'center' }}>
              <Typography variant="body2" color="textSecondary" sx={{fontWeight:'700'}}>{`${Math.round(program.progress)}%`}</Typography>
            </Box>
          </Box>
        </Stack>      </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center gap-5">
      <Box
            className=" w-full col-span-2 px-2 pb-2  rounded-xl min-h-[40vh] max-h-[40vh] overflow-hidden"
            sx={{ border: "1px dashed #028476" }}
          >
            <div className="w-full flex flex-row justify-between items-center h-[50px]">
              <Link to={`/program-view-members/${id}`}>
                <Button
                  sx={{
                    backgroundColor: "#028476",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#1f5e25",
                    },
                  }}
                >
                  View
                </Button>
              </Link>
              <h1 className="font-semibold ">Add End User</h1>
              <p></p>
              {/* <Button
  variant="contained"
  sx={{
    borderRadius: "50%",
    minWidth: 40,
    minHeight: 40,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#028476",
    color: "white",
    "&:hover": {
      backgroundColor: "#1f5e25",
    },
  }}
  onClick={handleClickOpen2}
  disabled={!edit}
>
  <AddIcon />
</Button> */}


              <AddchecklistDialog
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
              />
            </div>
            <div className="h-full overflow-hidden flex flex-col">
              <EndUserLineChart programId={id} />
            </div>
          </Box>
          <Box
            className=" w-full col-span-2 px-2 pb-2  rounded-xl min-h-[40vh] max-h-[40vh] overflow-hidden"
            sx={{ border: "1px dashed #028476" }}
          >
            <div className="w-full flex flex-row justify-between items-center h-[50px]">
              <Link to={`/view-activity/${id}`}>
                <Button
                  sx={{
                    backgroundColor: "#028476",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#1f5e25",
                    },
                  }}
                >
                  View
                </Button>
              </Link>
              <h1 className="font-semibold ">Program Activity</h1>
              <Button
                    variant="contained"
                    sx={{
                      borderRadius: "50%",
                      minWidth: 40,
                      minHeight: 40,
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#028476",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#1f5e25",
                      },
                    }}
                    onClick={() => setOpenActivityModal(true)}
                  >
                    <AddIcon />
                  </Button>
                  <CreateActivityModal
                  open={openActivityModal}
                  onClose={() => setOpenActivityModal(false)}
                  programId={id} 
                  orgId={org_id}
                  fetchActivities={ fetchActivities}
                />
              </div>
              <div className="h-full overflow-hidden flex flex-col">
                <div className="flex flex-row justify-between items-center font-bold h-[50px]   border-b border-gray-200 gap-2" style={{backgroundColor:"#f0f0f0"}}>
                <h2 className="w-full text-center">Title</h2>
                  <h2 className="w-full text-center">Type</h2>
                  <h2 className="w-full text-center">Created At</h2>
                </div>
                <ScrollableDiv
                  className="h-full overflow-y-auto flex flex-col gap-1"
                  style={{ height: "calc(40vh - 100px)" }}
                >
 {Array.isArray(activities) && activities.map(activity => (
                  <div className="w-full relative flex flex-row flex-around items-center p-2 rounded-lg transition duration-300 hover:bg-[#F2F5F4] text-[14px]" key={activity.id} >
                    <div className="w-full text-center">{activity.title}</div>
                    <div className="w-full text-center">{activity.type}</div>
                    <div className="w-full text-center">{convertTimestamp(activity.created_at)}</div>                 
                    <DeleteIcon className="absolute cursor-pointer right-1 text-[#028476] transition duration-300 hover:text-[#1f5e25] "   onClick={() => handleDeleteActivity(activity.id)}  />
                    <BlurredDialog 
  open={openDeleteConfirm} 
  onClose={handleDeleteCancel}
  PaperProps={{
    style: {
      minWidth: '500px',
      borderRadius: '16px',
      border: "3px dashed #028476",
      padding: "10px"
    },
  }}
> 
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this Activity?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <StyledDeleteButton onClick={()=>{handleDeleteConfirm(deleteActivityId)}}>Delete</StyledDeleteButton>
        </DialogActions>
      </BlurredDialog>
                  </div>
                ))}
                </ScrollableDiv>
                </div>
              </Box>
        
      </div>
      </div>
      </div>
    </Layout>

  );
};

export default SingleProgram;
