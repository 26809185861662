import React, { useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const SoilTestPagination = ({ isMobile = false, totalPages, page, setPage }) => {
  const [pageToShow, setPageToShow] = useState(5);
  const pagesInPagination = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handlePrevious = () => {
    if (page !== pageToShow - 4) {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else {
      setPageToShow(pageToShow - 5);
      if (page !== 1) {
        setPage(page - 1);
      }
    }
  };

  const handleNext = () => {
    if (page !== pageToShow) {
      if (page !== pagesInPagination.length) {
        setPage(page + 1);
      }
    } else {
      setPageToShow(pageToShow + 5);
      if (page !== pagesInPagination.length) {
        setPage(page + 1);
      }
    }
  };

  return (
    <div className="" style={{ width: isMobile ? "" : "calc(100% - 1rem)" }}>
      <div
        className={`flex justify-between ${
          isMobile ? "px-[15px]" : ""
        } items-center mt-2`}
      >
        <button
          disabled={page === 1}
          onClick={handlePrevious}
          className={`${
            page === 1
              ? "cursor-not-allowed"
              : "cursor-pointer border-none bg-transparent"
          } text-white flex items-center gap-2 lato`}
        >
          <ChevronLeftIcon fontSize="large" className="bg-[#028476] rounded-full w-8 h-8 p-2" />
          {!isMobile && <div>Previous</div>}
        </button>
        <div className={`flex ${isMobile ? "" : "gap-1"}`}>
          {pagesInPagination.map((number, index) => {
            return (
              <button
                key={index}
                onClick={() => setPage(number)}
                className="border-none bg-transparent"
              >
                {index + 1 <= pageToShow && index + 1 > pageToShow - 5 && (
                  <p
                    className={`cursor-pointer ${
                      page === number ? "bg-[#028476] text-white rounded-[5px]" : "text-black"
                    } py-1 px-[0.4rem] `}
                  >
                    {number}
                  </p>
                )}
              </button>
            );
          })}
        </div>
        <button
          disabled={page === pagesInPagination.length}
          onClick={handleNext}
          className={`${
            page === pagesInPagination.length
              ? "cursor-not-allowed"
              : "cursor-pointer"
          } text-white flex items-center cursor-pointer gap-2 border-none bg-transparent lato`}
        >
          {!isMobile && <div>Next</div>}
          <ChevronRightIcon fontSize="large" className="bg-[#028476] rounded-full p-2" />
        </button>
      </div>
    </div>
  );
};

export default SoilTestPagination;
