
import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Paper, Typography, Grid, IconButton, CircularProgress, TextField, Snackbar } from '@mui/material';
import { IoMdCloseCircle } from "react-icons/io";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dropzone from 'react-dropzone';
import { IoMdSend } from "react-icons/io";
import MuiAlert from '@mui/material/Alert';
import { uploadAVideo } from '../apis/agro-chat/api';

const UploadVideo = ({ open, handleClose, userChat, fetchUserChats, getMediaMessage }) => {

    const [agroDataLocalStorage, setAgroDataLocalStorage] = useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const getAgroData = useCallback(() => {
        const agroData = localStorage.getItem('userData');
        if (agroData) {
            setAgroDataLocalStorage(JSON.parse(agroData));
        }
    }, [])

    const agroID = agroDataLocalStorage?.id;
    useEffect(() => {
        getAgroData();
    }, [getAgroData]);


    const [uploadedFiles, setUploadedFiles] = useState([]);

    const chatID = userChat && userChat.id;
    const phone = userChat && userChat.phone;


    const handleDrop = (acceptedFiles) => {
        setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
    }

    const handleRemove = (index) => {

        const newFiles = [...uploadedFiles]
        newFiles.splice(index, 1);
        setUploadedFiles(newFiles);
    }

    const handleSave = async () => {

        if (uploadedFiles.length === 0) {
            setOpenSnackbar(true);
            setSnackbarMessage('Please upload a video file');
            setSnackbarSeverity('error');
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append('chat_id', chatID);
        formData.append('country_code', '91');
        formData.append('phone', phone);
        formData.append('agronomist_id', agroID);
        formData.append('message', message || "");
        formData.append('message_type', 'video');

        uploadedFiles.forEach((file) => {
            formData.append('files', file);
        });
        try {

            const response = await uploadAVideo(formData);
            console.log(response);
            if (response.ok) {
                setLoading(false);
                handleClose();
                fetchUserChats();
                setUploadedFiles([]);
                getMediaMessage();
                setMessage('');
                setOpenSnackbar(true);
                setSnackbarMessage('Media sent successfully');
                setSnackbarSeverity('success');
            } else {
                console.error('Failed to send media');
                setLoading(false);
                handleClose();
                setUploadedFiles([]);
                setMessage('');
                setOpenSnackbar(true);
                setSnackbarMessage('Failed to send media');
                setSnackbarSeverity('error');

            }
        } catch (err) {
            setLoading(false);
            handleClose();
            setUploadedFiles([]);
            setMessage('');
            console.error(err);
        }
    }


    return (
        <>

            <Modal open={open} onClose={handleClose}>
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', maxWidth: 750 }}>
                    <Paper style={{ padding: 24, backgroundColor: '#D9D3D3' }}>
                        <Typography variant="h4" gutterBottom style={{ fontFamily: 'sen' }}>
                            Upload Video
                        </Typography>

                        <Dropzone onDrop={handleDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} style={{
                                    background: '#EFEDED',
                                    border: '1px dashed orange',
                                    textAlign: 'center',
                                    height: 142,
                                }}>
                                    <input {...getInputProps()} />

                                    <CloudUploadIcon style={{ fontSize: '40px', marginTop: 12, color: '#5F9C6A' }} />
                                    <p
                                        style={{ fontFamily: 'sen', fontSize: 20, margin: '10px auto' }}
                                    >Click to upload or drag and drop</p>

                                </div>
                            )}

                        </Dropzone>

                        {uploadedFiles.length > 0 && (
                            <div style={{ marginTop: 20 }}>
                                <Typography variant="h6" style={{ marginBottom: 10 }}>
                                    Uploaded Files:
                                </Typography>
                                <Grid container spacing={2}>
                                    {uploadedFiles.map((file, index) => (
                                        <Grid item key={index}>
                                            <div style={{ position: 'relative' }}>
                                                <Typography variant="body1" style={{ fontFamily: 'sen' }}>{file.name}</Typography>
                                                <IconButton onClick={() => handleRemove(index)} style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'white', marginTop: 2, marginLeft: 3 }}>
                                                    <IoMdCloseCircle size={12} />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        )}


                        <div style={{ display: 'flex', marginTop: 20, justifyContent: 'flex-end' }}>

                            <TextField placeholder='Add a caption...' color='success' onChange={(event) => setMessage(event.target.value)} fullWidth sx={{ backgroundColor: '#F0EDED' }}></TextField>
                            <IconButton onClick={handleSave} style={{ backgroundColor: '#5F9C6A', color: 'white', height: '20%', marginLeft: 8, marginTop: 10 }}>
                                {
                                    loading ? <CircularProgress sx={{ color: 'white' }} /> : <IoMdSend style={{ marginLeft: 4 }} />
                                }
                            </IconButton>
                        </div>
                    </Paper>
                </div>

            </Modal>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)} ><MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                {snackbarMessage}
            </MuiAlert>
            </Snackbar>
        </>
    );
};


export default UploadVideo;

