import {BEARER_TOKEN} from '../../config'

export const listAllEnvs = async (
    baseURL,
    payload,
) => {

  const response =   fetch(baseURL + '/api/mgmt/env/listAllEnvsCms', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })

    return response;

}

export const updateENV = async (
    baseURL,
    payload,
) => {

    const response = fetch(baseURL + '/api/mgmt/env/updateOrgAndFarm', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })

    return response;
}

export const deleteEnv = async (
    baseURL,
    payload,
) => {

    const response = fetch(baseURL + '/api/mgmt/env/archiveEnv', {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify(payload)
    })

    return response;
}