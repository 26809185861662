// import React, { useCallback, useEffect, useState } from 'react'
// import Layout from './Layout'
// import { makeStyles } from '@material-ui/core/styles';
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from '@material-ui/core';
// import { listActivities } from '../apis/programs/api';
// import { baseURL } from '../config';
// import { useParams } from 'react-router-dom';

// const useStyles = makeStyles((theme) => ({
//   table: {
//     minWidth: 650,
//   },
//   tableHead: {
//     height: '60px', 
//   },
//   tableContainer: {
//     marginTop: theme.spacing(4),
//   },
//   tableCell: {
//     backgroundColor: theme.palette.grey[200],
//     color: theme.palette.grey[800],
//     fontWeight: 'bold',
//     padding: theme.spacing(2),
//   },
//   textAlign:"center",
//   row: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
//   cellContent: {
//     padding: theme.spacing(2),
//   },
// }));
// function convertTimestamp(timestamp) {
//   const date = new Date(timestamp);
//   const options = { year: 'numeric', month: 'long', day: 'numeric' };
//   return date.toLocaleDateString('en-US', options);
// }

// const ProgramActivity = () => {
//   const { id } = useParams();
//   const org_id = JSON.parse(localStorage.getItem("userData")).org.id;
//   const [activities, setActivities] = useState([]);
//   // const [error, setError] = useState(null);

//   const classes = useStyles();
//   // const fetchActivities = async () => {
//   //   try {
//   //     const data = await listActivities(baseURL,org_id,id);
//   //     setActivities(data.data.activities);
//   //   } catch (err) {
//   //     setError(err.message);
//   //   } 
//   // };
//   // useEffect(()=>{
//   // fetchActivities()
//   // },[])
//   const fetchActivities = useCallback(async () => {
//     try {
//       const data = await listActivities(baseURL, org_id, id);
//       setActivities(data.data.activities);
//     } catch (err) {
//       // setError(err.message);
//       console.log(err.message);
//     }
//   }, [org_id, id]);

//   useEffect(() => {
//     fetchActivities(); 
//   }, [fetchActivities]); 

//   return (
//     <Layout>
//     <div className="w-full flex flex-col pl-[65px] pt-[85px] pb-[10px] ">

//       <h1
//         style={{ textAlign: "center" }}
//         className="mx-auto font-bold text-[25px] h-[40px] my-auto"
//       >
//        Program Activities
//       </h1>
//       <div className='flex flex-col w-full overflow-y-auto px-4' style={{height:"calc(100dvh - 140px)"}}>
//       <TableContainer component={Paper} className={classes.tableContainer}>
//       <Table className={classes.table} aria-label="custom table">
//         <TableHead >
//           <TableRow>
//             <TableCell className={classes.tableCell}>ID</TableCell>
//             {/* <TableCell className={classes.tableCell}>Program ID</TableCell> */}
//             <TableCell className={classes.tableCell}>Org ID</TableCell>
//             <TableCell className={classes.tableCell}>Title</TableCell>
//             <TableCell className={classes.tableCell}>Type</TableCell>
//             <TableCell className={classes.tableCell}>Created At</TableCell>
//             {/* <TableCell className={classes.tableCell}>Created By</TableCell> */}
//             <TableCell className={classes.tableCell}>Updated At</TableCell>
//             {/* <TableCell className={classes.tableCell}>Updated By</TableCell> */}
//             <TableCell className={classes.tableCell}>Active</TableCell>
//             <TableCell className={classes.tableCell}>Archive</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//         {activities.map((activity) => (
//             <TableRow key={activity.id} className={classes.row}>
//               <TableCell className={classes.cellContent}>{activity.id}</TableCell>
//               {/* <TableCell className={classes.cellContent}>{activity.program_id}</TableCell> */}
//               <TableCell className={classes.cellContent}>{activity.org_id}</TableCell>
//               <TableCell className={classes.cellContent}>{activity.title}</TableCell>
//               <TableCell className={classes.cellContent}>{activity.type}</TableCell>
//               <TableCell className={classes.cellContent}>{convertTimestamp(activity.created_at)}</TableCell>
//               {/* <TableCell className={classes.cellContent}>{activity.created_by}</TableCell> */}
//               <TableCell className={classes.cellContent}>{convertTimestamp(activity.updated_at)}</TableCell>
//               {/* <TableCell className={classes.cellContent}>{activity.updated_by}</TableCell> */}
//               <TableCell className={classes.cellContent}>{activity.active.toString()}</TableCell>
//               <TableCell className={classes.cellContent}>{activity.archive.toString()}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//         </Table>
//         </TableContainer>
//       </div>
//       </div>

//       </Layout>
//   )
// }

// export default ProgramActivity

import React, { useCallback, useEffect, useState } from 'react'
import Layout from './Layout'
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { listActivities } from '../apis/programs/api';
import { baseURL } from '../config';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  // ... (keep your existing styles)
  noActivitiesMessage: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

const ProgramActivity = () => {
  const { id } = useParams();
  const org_id = JSON.parse(localStorage.getItem("userData")).org.id;
  const [activities, setActivities] = useState([]);
  // const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const fetchActivities = useCallback(async () => {
    try {
      const data = await listActivities(baseURL, org_id, id);
      setActivities(data.data.activities);
    } catch (err) {
      console.log(err.message);
    } finally {
      // setLoading(false);
    }
  }, [org_id, id]);

  useEffect(() => {
    fetchActivities(); 
  }, [fetchActivities]); 

  return (
    <Layout>
      <div className="w-full flex flex-col pl-[65px] pt-[85px] pb-[10px] ">
        <h1
          style={{ textAlign: "center" }}
          className="mx-auto font-bold text-[25px] h-[40px] my-auto"
        >
          Program Activities
        </h1>
        <div className='flex flex-col w-full overflow-y-auto px-4' style={{height:"calc(100dvh - 140px)"}}>
      {activities.length === 0 || !activities ? (
            <Typography className={classes.noActivitiesMessage}>
              No program activities available.
            </Typography>
          ) : (
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table className={classes.table} aria-label="custom table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>ID</TableCell>
                    <TableCell className={classes.tableCell}>Org ID</TableCell>
                    <TableCell className={classes.tableCell}>Title</TableCell>
                    <TableCell className={classes.tableCell}>Type</TableCell>
                    <TableCell className={classes.tableCell}>Created At</TableCell>
                    <TableCell className={classes.tableCell}>Updated At</TableCell>
                    <TableCell className={classes.tableCell}>Active</TableCell>
                    <TableCell className={classes.tableCell}>Archive</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activities.map((activity) => (
                    <TableRow key={activity.id} className={classes.row}>
                      <TableCell className={classes.cellContent}>{activity.id}</TableCell>
                      <TableCell className={classes.cellContent}>{activity.org_id}</TableCell>
                      <TableCell className={classes.cellContent}>{activity.title}</TableCell>
                      <TableCell className={classes.cellContent}>{activity.type}</TableCell>
                      <TableCell className={classes.cellContent}>{convertTimestamp(activity.created_at)}</TableCell>
                      <TableCell className={classes.cellContent}>{convertTimestamp(activity.updated_at)}</TableCell>
                      <TableCell className={classes.cellContent}>{activity.active.toString()}</TableCell>
                      <TableCell className={classes.cellContent}>{activity.archive.toString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default ProgramActivity