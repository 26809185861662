import { useState, useRef, useEffect, useCallback } from "react";
import { getAllTags } from "../../apis/360-tags-mgmt/api";
import { baseURL } from "../../config";

const useTags = () => {
  const orgId = useRef(null);
  const token = useRef(null);
  const tagsPerPage = useRef(10);
  const tagsRef = useRef([]);

  const [state, setState] = useState({
    searchQuery: '',
    searchDropdown: 'name',
    tags: [],
    allTags: [],
    loading: false,
    currentPage: 1,
    totalTags: 0,
  });

  const handleSearchChange = useCallback((e) => {
    const searchQuery = e.target.value;

    const filteredTags = state.allTags.filter((tag) =>
      tag.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setState((prevState) => ({
      ...prevState,
      tags: filteredTags,
      searchQuery: searchQuery,
    }));
  }, [state.allTags]);

  const setSearchDropdown = (value) => {
    setState((prevState) => ({
      ...prevState,
      searchDropdown: value,
    }));
  };

  const setCurrentPage = (page) => {
    setState((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  const setTotalTags = useCallback((total) => {
    setState((prevState) => ({
      ...prevState,
      totalTags: total,
    }));
  }, []);

  const setTags = useCallback((tags) => {
    setState((prevState) => ({
      ...prevState,
      tags: tags,
    }));
  }, []);

  const listAllTags = useCallback(async (currentPage) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      currentPage
    }));

    try {
      const tagData = await getAllTags(
        baseURL,
        orgId.current,
        currentPage,
        tagsPerPage.current,
        token.current
      );
      const response = await tagData.json();
      if (response?.data?.tags?.length > 0) {
        tagsRef.current = response.data.tags;

        setState((prevState) => ({
          ...prevState,
          totalTags: response.data.totalCount,
          allTags: response.data.tags,
          tags: response.data.tags,
          currentPage: response.data.page,
          loading: false,
        }));
      }else {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    } catch (error) {
      console.error("Failed to fetch tags:", error);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    orgId.current = userData.org.id;
    token.current = userData.access_token;

    const fetchInitialData = async () => {
      if (orgId.current && token.current) {
        listAllTags(1);
      }
    };

    fetchInitialData();
  }, [listAllTags]);

  return {
    state,
    tagsPerPage,
    handleSearchChange,
    setSearchDropdown,
    setCurrentPage,
    setTotalTags,
    listAllTags,
    setTags,
  };
};

export default useTags;