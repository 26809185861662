import React, { useState, useEffect, useCallback } from "react";
import Layout from "../pages/Layout";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Paper,
  TablePagination,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  DialogContentText,
  Tooltip
} from "@mui/material";
// import baseURL from '../config'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from 'react-redux';
import Switch from "@mui/material/Switch";
import { listAllEnvs, updateENV, deleteEnv } from "../apis/all-envs/api";
import { FaEye } from "react-icons/fa";
import { MdOutlineModeEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { environmentSuccess } from '../reducers/environment';
const PROD_BASE_URL = 'https://v3.api.phyfarm.com';
const DEV_BASE_URL = 'https://dev.phyfarm.com';

const ListAllEnvs = () => {
  const [searchDropdown, setSearchDropdown] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [openUpdate, setOpenUpdate] = useState(false);
  const baseURL = useSelector((state) => state.environmentReducer.environment);
  const [orgId, setOrgId] = useState("");
  const [farmId, setFarmId] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [envID, setEnvID] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedENVSToDelete, setSelectedENVSToDelete] = useState(null);
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("permissions"));
  const isProd = baseURL === PROD_BASE_URL;

  const handleChangeEnvironment = useCallback((currentUrl) => {
    if (currentUrl === PROD_BASE_URL) {
        dispatch(environmentSuccess(DEV_BASE_URL));
    } else {
        dispatch(environmentSuccess(PROD_BASE_URL));
    }
    setSearchQuery('');
    setSearchDropdown('');
    setResponseData([]);
}, [dispatch]);


  const getAllEnvs = useCallback(async () => {
    if (searchQuery === "" && searchDropdown !== "") {
      return;
    }

    console.log(searchQuery, searchDropdown);

    const filters = {};

    if (searchDropdown === "env") {
      filters.env_id = searchQuery;
    }

    if (searchDropdown === "org") {
      filters.org_id = searchQuery;
    }

    if (searchDropdown === "farm") {
      filters.farm_id = searchQuery;
    }

    if (searchDropdown === "serial") {
      filters.serial_id = searchQuery;
    }

    if (searchDropdown === "macAddress") {
      filters.mac_address = searchQuery;
    }

    const requestData = {
      pagination: {
        current_page: page + 1,
      },
      filters,
      status: status === "All" ? null : status,
    };

    setLoading(true);
    const response = await listAllEnvs(baseURL, requestData);
    const data = await response.json();
    if (data.data) {
      setResponseData(data?.data?.envs?.envs || []);
      setTotalCount(data?.data?.envs?.totalCount || 0);
      setLoading(false);
    }
  }, [page, status, searchQuery, searchDropdown, baseURL]);

  const handleChange = (event) => {
    setSearchDropdown(event.target.value);
    setSearchQuery("");
    getAllEnvs();
  };

  const handleSearch = () => {
    const filters = {};

    if (searchDropdown === "env") {
      filters.env_id = searchQuery;
    }

    if (searchDropdown === "org") {
      filters.org_id = searchQuery;
    }

    if (searchDropdown === "farm") {
      filters.farm_id = searchQuery;
    }

    if (searchDropdown === "serial") {
      filters.serial_id = searchQuery;
    }

    const requestData = {
      pagination: {
        current_page: page + 1,
      },
      filters,
      status: status === "All" ? null : status,
    };

    getAllEnvs(requestData);
  };

  const handleChangePage = useCallback(
    (event, newPage) => {
      console.log("New page", newPage);
      setPage(newPage);
      console.log("After set", page);
    },
    [page]
  );

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleSaveChanges = useCallback(async () => {
    const isActive = updateStatus === "active" ? true : false;

    const requestData = {};

    if (farmId !== "") {
      requestData.farm_id = parseInt(farmId, 10);
    }
    if (orgId !== "") {
      requestData.org_id = parseInt(orgId, 10);
    }
    if (updateStatus !== "") {
      requestData.active = isActive;
    }

    if (Object.keys(requestData).length === 0) {
      handleCloseUpdateModal();
      return;
    }

    requestData.env_id = envID;
    try {
      const response = await updateENV(baseURL, requestData);
      const data = await response.json();
      if (data.error) {
        alert(data.error.code);
      } else {
        handleCloseUpdateModal();
        getAllEnvs();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error, "An error occurred while fetching data");
    }
  }, [getAllEnvs, updateStatus, baseURL, envID, orgId, farmId]);

  const handleCloseUpdateModal = () => {
    setOpenUpdate(false);
  };

  const deleteConfirmation = (env) => {
    setIsDeleteDialogOpen(true);
    setSelectedENVSToDelete(env);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDelete = useCallback(async () => {
    const envs = selectedENVSToDelete;
    setIsDeleteDialogOpen(false);

    const requestBody = JSON.stringify({
      org_id: envs.org_id,
      farm_id: envs.farm_id,
      env_id: envs.env_id,
    });

    try {
      const response = await deleteEnv(baseURL, requestBody);
      const data = await response.json();
      if (data.error) {
        alert(data.error.code);
      } else {
        const updatedEnvsList = responseData.filter(
          (item) => item.env_id !== envs.env_id
        );
        setResponseData(updatedEnvsList);
        getAllEnvs();
      }
    } catch (error) {
      console.error("Error deleting payment:", error);
    }
  }, [selectedENVSToDelete, baseURL, getAllEnvs, responseData]);

  useEffect(() => {
    getAllEnvs();
  }, [status, page, getAllEnvs, baseURL]);

  const hasEditPermission = userData?.user_permissions?.permissions?.some(
    (permission) =>
      permission.feature_name === "env_mgmt" && permission.permissions.edit
  );

  const hasDeletePermission = userData?.user_permissions?.permissions?.some(
    (permission) =>
      permission.feature_name === "env_mgmt" && permission.permissions.delete
  );

  const hasViewDevicesPermission =
    userData?.user_permissions?.permissions?.some(
      (permission) =>
        permission.feature_name === "device_mgmt" && permission.permissions.view
    );

  return (
    <Layout>
      <div style={{ height: "100vh", marginTop: 90 }}>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Typography
            style={{ fontFamily: "sen", fontWeight: "bold", fontSize: 25 }}
          >
            List of Environments
          </Typography>
          <Typography
            style={{ fontFamily: "sen", fontSize: 16, color: "gray" }}
          >
            (Update Org ID's , Farm ID's & Status){" "}
          </Typography>
        </div>

        <Grid
          container
          style={{ marginTop: 50 }}
          spacing={1}
          justifyContent={"center"}
        >
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <TextField
              fullWidth
              label={
                searchDropdown
                  ? `Search by ${searchDropdown.toUpperCase()} ID`
                  : "Search"
              }
              value={searchQuery}
              color="success"
              variant="outlined"
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && getAllEnvs()}
              InputProps={{
                startAdornment: (
                  <TextField
                    select
                    value={searchDropdown}
                    color="success"
                    sx={{ minWidth: 120 }}
                    variant="standard"
                    onChange={handleChange}
                  >
                    <MenuItem value={""}>None</MenuItem>
                    <MenuItem value={"env"}>ENV ID</MenuItem>
                    <MenuItem value={"farm"}>FARM ID</MenuItem>
                    <MenuItem value={"org"}>ORG ID</MenuItem>
                    <MenuItem value={"serial"}>SERIAL ID</MenuItem>
                    <MenuItem value={"macAddress"}>MAC ADD</MenuItem>
                  </TextField>
                ),
                endAdornment: (
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={"flex-end"}>
          <Grid item xs={12} sm={2} md={4} lg={3} justifyContent="flex-end">
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Status
                </InputLabel>
                <Select
                  sx={{ minWidth: 120 }}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={status || ""}
                  label="Status"
                  color="success"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={""}>All</MenuItem>
                  <MenuItem value={"true"}>Active</MenuItem>
                  <MenuItem value={"false"}>Inactive</MenuItem>
                </Select>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "sen",
                    fontSize: 16,
                    color: "gray",
                    fontWeight: isProd ? "" : "bold",
                  }}
                >
                  DEV
                </Typography>
                <Switch
              checked={isProd}
              onChange={() => handleChangeEnvironment(baseURL)}
              color="success"
            />
                <Typography
                  style={{
                    fontFamily: "sen",
                    fontSize: 16,
                    color: "gray",
                    fontWeight: isProd ? "bold" : "",
                  }}
                >
                  PROD
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        {loading ? (
          <div style={{ textAlign: "center", marginBottom: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              marginLeft: 65,
            }}
          >
            <TableContainer component={Paper} style={{ marginTop: 10 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sen",
                        fontSize: 16,
                      }}
                    >
                      Env ID
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sen",
                        fontSize: 16,
                      }}
                    >
                      Serial ID
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sen",
                        fontSize: 16,
                      }}
                    >
                      Env Name
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sen",
                        fontSize: 16,
                      }}
                    >
                      Org ID
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sen",
                        fontSize: 16,
                      }}
                    >
                      Farm ID
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sen",
                        fontSize: 16,
                      }}
                    >
                      Created At
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sen",
                        fontSize: 16,
                      }}
                    >
                      Mac Address
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sen",
                        fontSize: 16,
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sen",
                        fontSize: 16,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(responseData) && responseData.length > 0 ? (
                    responseData.map((item) => (
                      <TableRow
                        key={item.env_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={{ height: 90 }}
                      >
                        <TableCell align="center" component="td" scope="row">
                          {item.env_id}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {item.serial_id}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {item.env_name}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {item.org_id}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {item.farm_id}
                        </TableCell>
                        <TableCell align="center">{item.updated_at}</TableCell>
                        <TableCell align="center">{item.mac_address}</TableCell>
                        <TableCell align="center">
                          {item.active ? (
                            <span style={{ color: "green" }}>ACTIVE</span>
                          ) : (
                            <span style={{ color: "red" }}>IN-ACTIVE</span>
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ width: 250 }}>

                        {hasViewDevicesPermission ? (
                          <Tooltip title="View Devices">
                            <Button
                              variant="outlined"
                              color="success"
                              style={{ marginRight: 5 }}
                              onClick={() => {
                                window.location.href = `/devices/${item.env_id}`;
                              }}
                            >
                              <FaEye
                                style={{
                                  color: "green",
                                }}
                              />
                            </Button>
                            </Tooltip>
                          ) : null}

                          {hasEditPermission ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ marginRight: 5 }}
                              onClick={() => {
                                setEnvID(item.env_id);
                                setOrgId(item.org_id);
                                setFarmId(item.farm_id);
                                setUpdateStatus(
                                  item.active ? "active" : "inactive"
                                );
                                setOpenUpdate(true);
                              }}
                            >
                           <MdOutlineModeEdit />

                            </Button>
                          ) : null}
                          {hasDeletePermission ? (
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => deleteConfirmation(item)}
                            >
                           <MdDeleteOutline />
                            </Button>
                          ) : null}
                          
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={9}>
                        <Typography variant="subtitle1">
                          No data available
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        <div style={{ marginTop: 10, marginBottom: 40, textAlign: "center" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            labelDisplayedRows={({ from, to, count }) => (
              <Typography variant="body2">
                <strong>Navigate to Next Page:</strong> {from}-{to} of {count}
              </Typography>
            )}
            style={{
              borderBottom: "1px solid #ccc",
              borderTop: "1px solid #ccc",
            }}
          />
        </div>
      </div>

      <Dialog open={openUpdate} onClose={handleCloseUpdateModal}>
        <div style={{ backgroundColor: "#d0e7b7" }}>
          <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>
            Update Environment
          </DialogTitle>
        </div>
        <DialogContent>
          {/* Input fields for org ID, farm ID, and status with prefilled values */}
          <TextField
            label="Org ID"
            value={orgId}
            onChange={(e) => setOrgId(e.target.value)}
            fullWidth
            margin="normal"
            color="success"
          />
          <TextField
            label="Farm ID"
            value={farmId}
            onChange={(e) => setFarmId(e.target.value)}
            fullWidth
            margin="normal"
            color="success"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel color="success">Status</InputLabel>
            <Select
              sx={{ minWidth: 120 }}
              color="success"
              value={updateStatus}
              onChange={(e) => setUpdateStatus(e.target.value)}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseUpdateModal}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveChanges}
            variant="contained"
            style={{ backgroundColor: "#5F9C6A", color: "white" }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        disableEnforceFocus
      >
        <div style={{ backgroundColor: "#d0e7b7" }}>
          <DialogTitle
            id="delete-dialog-title"
            sx={{ fontWeight: "bold", fontFamily: "sen" }}
          >
            Delete Confirmation
          </DialogTitle>
        </div>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to archive this env?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={closeDeleteDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            style={{ backgroundColor: "#5F9C6A", color: "white" }}
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default ListAllEnvs;
