import React, { useCallback, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { IoCloseCircleOutline } from "react-icons/io5";
import { IconButton, Typography } from '@mui/material';
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Chip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { locationSuccess } from '../reducers/location';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import {baseURL} from '../config';
import { coordinateSuccess } from '../reducers/coordinates';
import { deleteFarm } from '../apis/farm-mgmt/farm';
import { handleDeleteZone } from '../apis/farm-mgmt/zone';
import {BEARER_TOKEN} from '../config'
import { createPermission, deletePermission, editPermission } from '../utils/helpers';
// import { listFarmsByOrgID } from '../utils/farms/farm';


export default function Farm({ open, onClose, farmData, userId, fetchFarmData }) {

    const navigate = useNavigate();
    const [sidebar, setState] = useState({
        right: false,
    });
    const [openDeleteZone, setOpenDeleteZone] = useState(false);
    const [openDeleteFarm, setOpenDeleteFarm] = useState(false);
    const [zoneID, setZoneID] = useState(null);
    const [getFarmData, setFarmData] = useState({});
    const [deleteLoading, setDeleteLoading] = useState(false);
    const dispatch = useDispatch();
    const farmId = farmData?.farm?.id;
    const orgId = farmData?.org?.id;
    const location = farmData?.location;
    const { pincode, city, state, country, latitude, longitude, address } = location;
    const userData = JSON.parse(localStorage.getItem('permissions'));


    const toggleDrawer = (isOpen) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...sidebar, right: isOpen });
    };

    const handleAddZone = () => {
        navigate(`/add-zone/${farmData?.farm?.id}/${userId}`);

    }

    const getFarmById = useCallback(async () => {
        const url = `${baseURL}/api/mgmt/farmcrm/getFarmById`;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        };

        const requestData = {
            "farm_id": farmId,
            "org_id": orgId
        };

        try {
            let response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setFarmData(data.data.farm);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [orgId, farmId]);


    useEffect(() => {
        getFarmById();
    }, [getFarmById]);


    const handleDelete = useCallback(async() => {
        
        setDeleteLoading(true);

        try {

            const payload = {
                id: zoneID,
                org_id: orgId,
                farm_id: farmId
            }
            const response = await handleDeleteZone (baseURL, payload);
            const data = await response.json();

            if (!response.ok) {
                throw new Error('Network response was not ok');
            } else {
                console.log(data);
                setDeleteLoading(false);
                setOpenDeleteZone(false);
                getFarmById(); // Fetch
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setDeleteLoading(false);
        }
    }, [orgId, farmId, getFarmById, zoneID]);


    const setDeleteFarmConfirmationOpen = (value) => {
        setOpenDeleteFarm(value);
    }

    const setDeleteZoneConfirmationOpen = (value) => {
        setOpenDeleteZone(value);
    }

    const handleDeleteFarm = useCallback(async () => {

        try {

            const response = await deleteFarm(baseURL, userId, orgId, farmId);
            const data = await response.json();
            console.log(data);

            if(!response.ok) {
                throw new Error('Network response was not ok');
            }else{

            console.log(data);
            setOpenDeleteFarm(false);
            onClose();
            fetchFarmData(orgId)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }, [userId, orgId, farmId, onClose,fetchFarmData]);

    const hasCreatePermission = createPermission('farm_mgmt', userData);
    const hasDeletePermission = deletePermission('farm_mgmt', userData);
    const hasUpdatePermission = editPermission('farm_mgmt', userData);


    const list = (
        <Box
            sx={{ width: 450, marginTop: 7}}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>

                <Divider />

                <ListItem>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        {/* Left side */}
                        <div>
                            <IconButton onClick={onClose}>
                                <IoCloseCircleOutline />
                            </IconButton>
                        </div>
                        {/* Right side */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            {hasUpdatePermission &&
                                <IconButton onClick={() => {
                                    navigate(`/edit-farms/${farmId}/${userId}`)
                                    dispatch(locationSuccess({ pincode, city, state, country, latitude, longitude, address }))
                                }}>
                                    <MdOutlineModeEdit />
                                </IconButton>
                            }
                            {
                                hasDeletePermission &&
                                <IconButton onClick={() => {
                                    setOpenDeleteFarm(true);
                                }}>
                                    <RiDeleteBin5Line />
                                </IconButton>
                            }
                        </div>
                    </div>
                </ListItem>
                <Divider />
                <ListItem >
                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                        <ListItemText
                            primary={<span style={{ fontWeight: 'bold' }}>Farm Name</span>}
                        />
                        <div style={{ marginTop: 1, backgroundColor: "#ECF1F1", width: "100%", height: "40px", borderRadius: "5px", border: "1px solid gray", display: "flex", alignItems: "center", paddingTop: 5, paddingBottom: 5 }}>
                            <Typography style={{ marginLeft: 5, fontFamily: 'sen' }}>  {getFarmData?.farm?.name}</Typography>
                        </div>
                    </div>
                </ListItem>
                <ListItem >
                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                        <ListItemText
                            primary={<span style={{ fontWeight: 'bold' }}>Farm Location</span>}

                        />
                        <div style={{ marginTop: 1, backgroundColor: "#ECF1F1", width: "100%", height: "auto", borderRadius: "5px", border: "1px solid gray", display: "flex", alignItems: "center", paddingTop: 5, paddingBottom: 5 }}>
                            <Typography style={{ marginLeft: 5, fontFamily: 'sen' }}>  {getFarmData?.location?.address}</Typography>
                        </div>
                    </div>
                </ListItem>
                <ListItem >
                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                        <div style={{ display: 'flex' }}>
                            <ListItemText
                                primary={<span style={{ fontWeight: 'bold' }}>Zone Details</span>}
                                secondary={farmData?.name}
                                key={farmData?.id}
                            />
                            {
                                hasCreatePermission &&
                                <Button color='success' variant='contained' 
                                onClick={handleAddZone }
                                >+ Add Zone</Button>
                            }
                        </div>


                        {
                            getFarmData?.zones && Array.isArray(getFarmData?.zones) && getFarmData?.zones.length > 0 ? (

                                getFarmData?.zones.map((zone, zoneIndex) => (
                                    <div style={{ position: 'relative', marginTop: 10, backgroundColor: "#ECF1F1", width: "95%", borderRadius: "5px", border: "1px solid gray", padding: 10 }}>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>{zone?.name}</Typography>
                                        </div>
                                        <div style={{ marginBottom: '5px' }}>
                                            <Typography style={{ fontWeight: 'bold', fontFamily: 'sen', fontSize: 15, marginBottom: 5 }}>Field Type</Typography>
                                            <Chip
                                                label={zone?.zone_type?.name}
                                                avatar={<Avatar alt={zone?.zone_type?.name} src={zone?.zone_type?.icon_url} />}
                                            />
                                        </div>
                                        <div>
                                            <Typography style={{ fontWeight: 'bold', fontFamily: 'sen', fontSize: 15 }}>Zone Area</Typography>
                                            <Chip label={`${zone?.size} ${zone?.size_unit}`} />
                                        </div>

                                        <div style={{ position: 'absolute', bottom: 5, right: 5 }}>

                                            {

                                                hasUpdatePermission &&
                                                <IconButton onClick={() => {
                                                    navigate(`/edit-zone/${farmId}/${zone?.id}/${userId}`)
                                                    dispatch(locationSuccess({ pincode, city, state, country, latitude, longitude, address }))
                                                    dispatch(coordinateSuccess(zone?.zone_boundaries))

                                                }}>
                                                    <MdOutlineModeEdit color='green' />
                                                </IconButton>
                                            }
                                            {
                                                hasDeletePermission &&
                                            <IconButton

                                                onClick={() => {
                                                    setOpenDeleteZone(true);
                                                    setZoneID(zone?.id);
                                                }}
                                            >
                                                <RiDeleteBin5Line color='green' />
                                            </IconButton>
                                            }
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div style={{ marginTop: 10, backgroundColor: "#ECF1F1", width: "100%", height: "auto", borderRadius: "5px", border: "1px solid gray", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                    <Typography style={{ marginLeft: 5, fontFamily: 'sen', fontWeight: "bold" }}>  No Zones Added</Typography>
                                    <Typography style={{ marginLeft: 5, fontFamily: 'sen', fontWeight: "bold", color: "gray" }}>  Add zones to begin crop cultivation</Typography>
                                </div>
                            )

                        }

                    </div>
                </ListItem>
            </List>
        </Box >
    );

    return (
        <div>
            <SwipeableDrawer
                anchor="right"
                open={open}
                onClose={onClose}
                onOpen={() => toggleDrawer(true)}
            >
                {list}
            </SwipeableDrawer>

            <Dialog open={openDeleteZone} onClose={() => setOpenDeleteZone(false)} disableEnforceFocus>
                <div style={{ backgroundColor: "#d0e7b7" }}>
                    <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Delete Zone</DialogTitle>
                </div>
                <DialogContent>
                    Are you sure you want to delete this zone?
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => setDeleteZoneConfirmationOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleDelete} color="error" disabled={deleteLoading}>

                        {deleteLoading ? <CircularProgress color='error' size={24} /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openDeleteFarm} onClose={() => setOpenDeleteFarm(false)} disableEnforceFocus>
                <div style={{ backgroundColor: "#d0e7b7" }}>
                    <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Delete Farm</DialogTitle>
                </div>
                <DialogContent>
                    Are you sure you want to delete this Farm?
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => setDeleteFarmConfirmationOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleDeleteFarm} color="error" disabled={deleteLoading}>

                        {deleteLoading ? <CircularProgress color='error' size={24} /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}
