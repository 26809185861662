import React, { useState, useEffect, useCallback } from 'react';
import Layout from './Layout';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    TextField,
    Paper,
    Typography,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Dialog,
} from '@mui/material'
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { IoMdAdd } from "react-icons/io";
import Switch from '@mui/material/Switch';
import moment from 'moment';
import CustomPaymentModal from '../components/CustomPaymentModal';
import { getAllPricing, listPayments, paymentStatus, updatePaymentStatus, deletePayment } from '../apis/payments/api';
import { createPermission, deletePermission, editPermission } from '../utils/helpers';



const Payment = () => {

    const [searchDropdown, setSearchDropdown] = useState('org');
    const [searchQuery, setSearchQuery] = useState('');
    const [apiResponse, setApiResponse] = useState([]);
    const [listPricing, setListPricing] = useState([]);
    const [error, setError] = useState(null);
    const [openError, setOpenError] = useState(false);
    const [isProd, setIsProd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState("");
    const [selectedStatus, setSelectedStatus] = useState('initiated');
    const [modalOpen, setModalOpen] = useState(false);
    const [pricingId, setPricingId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [deviceCounts, setDeviceCounts] = useState('');
    const [envCounts, setEnvCounts] = useState('');
    const [amount, setAmount] = useState('');
    const [selectedPaymentToDelete, setSelectedPaymentToDelete] = useState(null);
    const [paymentStatusData, setPaymentStatusData] = useState({});
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const userData = JSON.parse(localStorage.getItem('permissions'));

    const handleChangeEnvironment = useCallback(() => {
        setIsProd(!isProd);
        setSearchQuery('');
        setSearchDropdown('');
        setApiResponse([]);
        setPaymentStatusData({});
    }, [isProd]);

    const baseURL = isProd ? 'https://v3.api.phyfarm.com' : 'https://dev.phyfarm.com';

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const fetchPaymentData = useCallback(async (searchQuery, searchDropdown) => {

        if (searchQuery === '' && searchDropdown !== '') {
            setError('Please enter a valid search query.');
            return;
        }

        setLoading(true); // Start loading

        console.log('searchQuery from func', searchQuery);
        console.log('searchDropdown from func', searchDropdown);

        let requestData = {};

        if (searchDropdown === 'org') {
            requestData = { org_id: parseInt(searchQuery) };
        } else if (searchDropdown === 'email') {
            requestData = { email: searchQuery };
        } else if (searchDropdown === 'phone') {
            requestData = { phone: searchQuery };
        } else {
            return;
        }

        try {

            const response = await listPayments(baseURL, requestData);
            const data = await response.json();
            if (data.error) {
                setError(data.error);
            }
            setApiResponse(data.data.payments);
            setLoading(false); // Stop loading
            setOpen(false);
        } catch (error) {
            console.error('Error fetching payment data:', error);
            setLoading(false);
        }

        const payload = {
            org_id: searchDropdown === 'org' ? parseInt(searchQuery) : null,
            email: searchDropdown === 'email' ? searchQuery : null,
            phone: searchDropdown === 'phone' ? searchQuery : null,
        }

        try {
            const paymentStatusResponse = await paymentStatus(baseURL, payload);
            const paymentData = await paymentStatusResponse.json();
            if (paymentData.error) {
                setError(paymentData.error);
            }
            setPaymentStatusData(paymentData);
        } catch (error) {
            console.error('Error fetching payment status data:', error);
            setLoading(false);
        }
    }, [baseURL]);

    const handleStatusUpdate = async () => {

        setUpdateLoading(true);

        const requestData = {

            id: parseInt(selectedPayment.id),
            org_id: parseInt(selectedPayment.org_id),
            transaction_id: selectedPayment.transaction_id,
            pricing_id: selectedPayment.pricing_id,
            start_date: selectedPayment.start_date,
            end_date: selectedPayment.end_date,
            device_counts: selectedPayment.device_counts,
            env_counts: selectedPayment.env_counts,
            amount: selectedPayment.amount,
            source: "cms",
            active: true,
            pricing_name: selectedPayment.pricing_name,
            transaction_status: selectedStatus, // Use selectedStatus here
        };
        try {

            const response = await updatePaymentStatus(baseURL, requestData);
            const data = await response.json();

            if (data.error) {
                setError(data.error);
                setOpenError(true);
            }

            const selectedPaymentID = selectedPayment.id;
            const updatedPaymentIndex = apiResponse.findIndex(payment => payment.id === selectedPaymentID);
            const updatedPaymentList = [...apiResponse];
            updatedPaymentList[updatedPaymentIndex].status = selectedStatus;

            setUpdateLoading(false);

            fetchPaymentData(data?.data?.org_id, searchDropdown);
            handleCloseModal();
        } catch (error) {
            console.error('Error updating payment status:', error);
            setUpdateLoading(false);
        }
    };

    const handleStatusChange = (event) => {
        const newStatus = event.target.value;
        setSelectedStatus(newStatus); // Store the selected status for updating later

    };

    const handleSearchChange = (e) => {
        const sQ = e.target.value;
        setSearchQuery(sQ);
    }

    const handleOpenModal = (Payment) => {

        console.log(Payment);
        setSelectedPayment(Payment);
        setPricingId(Payment.pricing_id);
        setStartDate(Payment.start_date);
        setEndDate(Payment.end_date);
        setDeviceCounts(Payment.device_counts);
        setEnvCounts(Payment.env_counts);
        setSelectedStatus(Payment.transaction_status);
        setAmount(Payment.amount);
        setModalOpen(true);
    };

    const handleClick = () => {

        if (searchQuery === '' && searchDropdown !== '') {
            return;
        }
        fetchPaymentData(searchQuery, searchDropdown);
    };

    const getPricing = useCallback(async () => {
        try {

            const response = await getAllPricing(baseURL);
            const data = await response.json();
            if (data?.data?.pricing && data?.data?.pricing.length > 0) {
                setListPricing(data);
            }

        } catch (error) {
            console.error('Error fetching pricing:', error);
        }
    }, [baseURL]);

    useEffect(() => {
        getPricing();
    }, [getPricing]);

    const deleteConfirmation = (payment) => {
        setDeleteConfirmationOpen(true);
        setSelectedPaymentToDelete(payment);
    };

    const handleDeletePayment = () => {

        const payment = selectedPaymentToDelete;

        setDeleteConfirmationOpen(false);
        setDeleteLoading(true);

        const requestData = {
            id: payment.id,
            org_id: payment.org_id,
            transaction_id: payment.transaction_id,
        };

        try {
            const response = deletePayment(baseURL, requestData);
            const data = response.json();
            if (data.error) {
                setError(data.error);
                setOpenError(true);
            }

            const updatedPaymentList = apiResponse.filter(item => item.id !== payment.id);
            setApiResponse(updatedPaymentList);
            fetchPaymentData(payment.org_id, searchDropdown);
            setDeleteLoading(false);
        } catch (error) {
            console.error('Error deleting payment:', error);
            setDeleteLoading(false);
        }
    };

    function formatDate(dateString) {

        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        const day = date.getDate();
        let dayWithOrdinal;
        switch (day % 10) {
            case 1:
                dayWithOrdinal = day + 'st';
                break;
            case 2:
                dayWithOrdinal = day + 'nd';
                break;
            case 3:
                dayWithOrdinal = day + 'rd';
                break;
            default:
                dayWithOrdinal = day + 'th';
                break;
        }

        // Replace the day part in the formatted date with the one with ordinal suffix
        const formattedDateWithOrdinal = formattedDate.replace(String(day), dayWithOrdinal);
        return formattedDateWithOrdinal;
    }

    const renderPaymentStatus = () => {

        if (!paymentStatusData || !paymentStatusData.data || !paymentStatusData.data.org_payment_status || !paymentStatusData.data.org_payment_status.counts) {
            return null;
        }

        const expiryDate = new Date(paymentStatusData.data.org_payment_status.due_date);
        const formattedExpiryDate = expiryDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });


        return (

            <div>
                <Paper maxWidth="sm" style={{ border: '1px solid #ccc', marginLeft: 30, borderRadius: 5, padding: '16px', width: 300 }}>
                    <Typography variant='h5' style={{ fontSize: 18, marginBottom: '6px', fontWeight: 'bold' }}>
                        Subscription Info
                    </Typography>

                    <Typography variant='subtitle1' style={{ textAlign: 'left', fontSize: 14 }}>
                        Org ID: {paymentStatusData.data.org_payment_status.org_id}
                    </Typography>

                    <Typography variant='subtitle1' style={{ textAlign: 'left', fontSize: 14 }}>
                        Remaining ENV Counts: {paymentStatusData.data.org_payment_status.counts.remaining_env_counts}
                    </Typography>

                    <Typography variant='subtitle1' style={{ textAlign: 'left', fontSize: 14 }}>
                        Remaining Device Counts: {paymentStatusData.data.org_payment_status.counts.remaining_device_counts}
                    </Typography>


                    <Typography variant='subtitle1' style={{ textAlign: 'left', fontSize: 14 }}>

                        Status: {paymentStatusData.data.org_payment_status.status === 'paid' ? (
                            //show the status in Uppercase
                            <span style={{ textTransform: 'uppercase', color: paymentStatusData.data.org_payment_status.color }}>{paymentStatusData.data.org_payment_status.status}</span>
                        ) : (
                            <span style={{ color: paymentStatusData.data.org_payment_status.color, textTransform: 'uppercase' }}>{paymentStatusData.data.org_payment_status.status}</span>
                        )}
                    </Typography>

                    <Typography variant='subtitle1' style={{ textAlign: 'left', fontSize: 14 }}>
                        Plan Ends: {formattedExpiryDate}
                    </Typography>

                </Paper>
            </div>

        );
    };

    const hasCreatePermission = createPermission('payment_mgmt', userData);
    const hasDeletePermission = deletePermission('payment_mgmt', userData);
    const hasUpdatePermission = editPermission('payment_mgmt', userData);
    return (
        <Layout>
            <div style={{ height: '100vh', marginTop: 90 }}>
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 25 }}>List of Payments</Typography>
                    <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>(Check & Update Payment Status) </Typography>
                </div>
                <Grid container style={{ marginTop: 50 }} spacing={1} justifyContent={'center'}>

                    <Grid item xs={12} sm={8} md={6} lg={8} container justifyContent={"flex-end"}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 15 }}>
                            <TextField
                                fullWidth
                                label={searchDropdown ? `Search by ${searchDropdown.toUpperCase()} ID` : 'Search'}
                                value={searchQuery}
                                color='success'
                                variant='outlined'
                                onChange={handleSearchChange}
                                onKeyPress={e => e.key === 'Enter' && fetchPaymentData(searchQuery, searchDropdown)}
                                InputProps={{
                                    startAdornment: (
                                        <TextField
                                            select
                                            value={searchDropdown}
                                            color='success'
                                            sx={{ minWidth: 120 }}
                                            variant='standard'
                                            onChange={event => {
                                                const sD = event.target.value;
                                                setSearchDropdown(sD);
                                                fetchPaymentData(searchQuery, sD);
                                                setSearchQuery('');
                                                setApiResponse([]);
                                                setPaymentStatusData({});

                                            }}
                                        >
                                            <MenuItem value={"phone"}>PHONE</MenuItem>
                                            <MenuItem value={"org"}>ORG ID</MenuItem>
                                            <MenuItem value={"email"}>EMAIL ID</MenuItem>
                                        </TextField>
                                    ),
                                    endAdornment: (
                                        <IconButton onClick={
                                            () => {
                                                fetchPaymentData(searchQuery, searchDropdown);
                                            }
                                        } style={{ marginLeft: 5 }}>
                                            <SearchIcon />
                                        </IconButton>
                                    ),
                                }}
                            />

                            {hasCreatePermission &&

                                <Button
                                    onClick={handleOpen}
                                    style={{
                                        borderRadius: '50%',
                                        marginLeft: 5,
                                        width: '45px',
                                        height: '55px',
                                        minWidth: '55px',
                                        minHeight: '55px',
                                        fontSize: '1.2rem',
                                    }}
                                    variant='contained'
                                    color='success'
                                >
                                    <IoMdAdd />
                                </Button>
                            }
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'right' }}>
                            {paymentStatusData && renderPaymentStatus()}
                        </div>
                    </Grid>
                </Grid>
                <Grid container justifyContent={"flex-end"} sx={{ marginTop: 8 }}>
                    <Grid item xs={12} sm={2} md={4} lg={3} justifyContent="flex-end">

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: 40 }}>
                            <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray', fontWeight: isProd ? "" : "bold" }}>DEV</Typography>
                            <Switch
                                checked={isProd}
                                onChange={handleChangeEnvironment}
                                color='success'
                            />
                            <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray', fontWeight: isProd ? "bold" : "" }}>PROD</Typography>
                        </div>

                    </Grid>
                </Grid>

                <div>
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div
                            style={
                                {
                                    marginLeft: 65,
                                }
                            }
                        >

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Org ID
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Transaction ID
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Pricing Name
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Subs. Start Date
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Subs. End Date
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Device Counts
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Env Counts
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Amount
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Transaction Status
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Status
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Edit
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            apiResponse && Array.isArray(apiResponse) && apiResponse.length > 0 ? (


                                                apiResponse.map((item) => (
                                                    < TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        style={{ height: 90, cursor: 'pointer' }}>
                                                        <TableCell align="center">{item.org_id}</TableCell>
                                                        <TableCell align="center">{item.transaction_id}</TableCell>
                                                        <TableCell align="center">{item.pricing_name}</TableCell>
                                                        <TableCell align="center">{moment(item.start_date).format("LL")}</TableCell>
                                                        {/* <TableCell align="center"> {new Date(item.end_date).toISOString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).split('T')[0]}</TableCell> */}
                                                        <TableCell align="center">
                                                            {item.end_date ? moment.utc(item.end_date).format("LL") : null}
                                                        </TableCell>
                                                        < TableCell align="center" > {item.device_counts}</TableCell>
                                                        <TableCell align="center">{item.env_counts}</TableCell>
                                                        <TableCell align="center">₹{item.amount}</TableCell>
                                                        <TableCell align="center">
                                                            <span
                                                                style={{
                                                                    color:
                                                                        item.transaction_status === 'failed' ? 'red'
                                                                            : item.transaction_status === 'initiated' ? 'blue'
                                                                                : item.transaction_status === 'completed' ? 'green'
                                                                                    : 'inherit', // Default text color
                                                                }}
                                                            >
                                                                {item.transaction_status}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <span style={{
                                                                color:
                                                                    item.active ? 'green' : 'red'
                                                            }}>
                                                                {item.active ? "Active" : "Inactive"}
                                                            </span>
                                                        </TableCell>

                                                        <TableCell align="center">

                                                            <>
                                                                {

                                                                    hasUpdatePermission &&
                                                                    <Button variant="outlined" color="primary" onClick={() => handleOpenModal(item)} style={{ marginRight: 5 }}>
                                                                        Update
                                                                    </Button>
                                                                }

                                                                {
                                                                    hasDeletePermission && (
                                                                        item.transaction_status === "completed" ?
                                                                            <Button variant="outlined" color="error" disabled onClick={() => handleDeletePayment(item)}>
                                                                                Delete
                                                                            </Button>
                                                                            :
                                                                            <Button variant="outlined" color="error" onClick={() => deleteConfirmation(item)}>
                                                                                Delete
                                                                            </Button>
                                                                    )
                                                                }

                                                            </>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (

                                                <TableRow>
                                                    <TableCell align="center" colSpan={12}>
                                                        {apiResponse === null ? (
                                                            <Typography variant="subtitle1">No data available</Typography>
                                                        ) : null}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    )}
                </div >
            </div>

            <CustomPaymentModal baseURL={baseURL} fetchPaymentData={fetchPaymentData} searchQuery={searchQuery} searchDropdown={searchDropdown} open={open} onClose={handleClose} paymentStatusData={paymentStatusData}
                handleClick={handleClick} onSubmit={handleSubmit} pricing={listPricing} />


            {/* update dialog */}
            <Dialog open={modalOpen} onClose={handleCloseModal} disableEnforceFocus>
                <div style={{ backgroundColor: "#d0e7b7" }}>
                    <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Payment Details</DialogTitle>
                </div>
                <DialogContent>
                    {selectedPayment && (
                        <Grid container spacing={3}>

                            <Grid item xs={6}>
                                <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>Org ID</Typography>
                                <Typography variant="body1" style={{ fontFamily: 'sen', color: 'gray' }}>{selectedPayment.org_id}</Typography>
                                <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>Transaction ID</Typography>
                                <Typography variant="body1" style={{ fontFamily: 'sen', color: 'gray' }}>{selectedPayment.transaction_id}</Typography>
                                <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>Pricing ID</Typography>
                                <Typography variant="body1" style={{ fontFamily: 'sen', color: 'gray' }}>{pricingId}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }} >Start Date</Typography>
                                <Typography variant="body1" style={{ fontFamily: 'sen', color: 'gray' }}>{formatDate(startDate)}</Typography>
                                <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>End Date</Typography>
                                <Typography variant="body1" style={{ fontFamily: 'sen', color: 'gray' }}>{formatDate(endDate)}</Typography>
                            </Grid>

                            <Grid item xs={6} spacing={5}>
                                <TextField
                                    label="Device Counts"
                                    fullWidth
                                    variant="outlined"
                                    style={{ marginBottom: 10 }}
                                    value={deviceCounts}
                                    disabled={true}
                                    onChange={(e) => setDeviceCounts(e.target.value.trim())}
                                />
                                <TextField
                                    label="Env Counts"
                                    fullWidth
                                    variant="outlined"
                                    style={{ marginBottom: 10 }}
                                    value={envCounts}
                                    disabled={true}
                                    onChange={(e) => setEnvCounts(e.target.value.trim())}
                                />
                                <TextField
                                    label="Amount"
                                    fullWidth
                                    variant="outlined"
                                    value={amount}
                                    disabled={true}
                                    onChange={(e) => setAmount(e.target.value.trim())}

                                />
                            </Grid>

                            <Grid item xs={6} spacing={5}>
                                <FormControl fullWidth >
                                    <InputLabel id='demo-simple-select-helper-label'>Status</InputLabel>
                                    <Select
                                        labelId='demo-simple-select-helper-label'
                                        id='demo-simple-select-helper'
                                        value={selectedStatus}
                                        label='Status'
                                        color='success'
                                        fullWidth
                                        onChange={handleStatusChange}>
                                        <MenuItem value="initiated" >Initiated</MenuItem>
                                        <MenuItem value="completed">Completed</MenuItem>
                                        <MenuItem value="refund" >Refund</MenuItem>
                                        <MenuItem value="failed">Failed</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} variant="contained" color="error">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleStatusUpdate} style={{ backgroundColor: '#5F9C6A', color: 'white' }} color="primary">
                        {updateLoading ? <CircularProgress size={24} color="inherit" /> : 'Update'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)} disableEnforceFocus>
                <div style={{ backgroundColor: "#d0e7b7" }}>
                    <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Delete Payment</DialogTitle>
                </div>
                <DialogContent>
                    Are you sure you want to delete this payment?
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => setDeleteConfirmationOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleDeletePayment} color="error" disabled={deleteLoading}>

                        {deleteLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openError}
                autoHideDuration={5000}
                onClose={() => setOpenError(false)}
                message={error}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
        </Layout>
    )
}

export default Payment