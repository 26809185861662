import React, { useState } from "react";
import {
  Card,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/system";
import ProgramChecklist from "./ProgramChecklist";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteProgram } from "../apis/programs/api";
import { baseURL } from "../config";

function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

const StyledCard = styled(Card)({
  width: "100%",
  position: "relative",
  padding: "16px",
  borderRadius: "12px",
  border: "2px solid #ccc",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  transition: "background-color 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
});

const StyledDeleteButton = styled(Button)({
  backgroundColor: "#028476",
  color: "white",
  "&:hover": {
    backgroundColor: "#1f5e25",
  },
});

const ProgramCard = ({ program, fetchPrograms }) => {
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const orgId = userData.org.id;
  const navigate = useNavigate();

  const toggleDescription = () => {
    navigate(`/view-program/${program.id}`);
  };

  const truncateDescription = (description) => {
    if (description.length > 120) {
      return description.substring(0, 120) + "...";
    }
    return description;
  };

  const handleDeleteActivity = async () => {
    setOpenDeleteConfirm(true);
  };

  const handleDeleteConfirm = async () => {
    setOpenDeleteConfirm(false);
    try {
      await deleteProgram(baseURL, { id: program.id, org_id: orgId });
      fetchPrograms();
    } catch (error) {
      console.error("Error deleting activity:", error.message);
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteConfirm(false);
  };

  return (
    // <StyledCard style={{ fontFamily: "sen" }}>
    //   <div className="w-full h-full flex flex-col">
    //     <h1 className="font-semibold">{program.name}</h1>
    //     <h3 className="text-[#868686]">{program.type}</h3>
    //     <p className="text-[14px] leading-6">
    //       {truncateDescription(program.description)}{" "}
    //       {program.description.length > 40 && (
    //         <Button
    //           onClick={toggleDescription}
    //           size="small"
    //           variant="text"
    //           sx={{ color: "#028476" }}
    //         >
    //           {showFullDescription ? "Show less" : "See more"}
    //         </Button>
    //       )}
    //     </p>
    //     <div className="h-full">
    //       <DeleteIcon
    //         className="text-[#028476] transition duration-300 hover:text-[#028477a8] mt-auto mr-auto  "
    //         onClick={handleDeleteActivity}
    //       />
    //     </div>
    //   </div>
    //   <div className="w-[40%] h-full flex flex-col">
    //     <ProgramChecklist progress={program.progress} />
    //     <p className="text-[12px]">
    //       {convertTimestamp(program.created_at)}{" "}
    //       <span>
    //         <h1 className="font-semibold">{program.location}</h1>
    //       </span>
    //     </p>
    //     <Link to={`/view-program/${program.id}`} className="ml-auto mt-auto">
    //       <Button
    //         variant="contained"
    //         sx={{
    //           borderRadius: "50%",
    //           padding: "8px",
    //           minWidth: "auto",
    //           backgroundColor: "#028476",
    //           color: "white",
    //           "&:hover": {
    //             backgroundColor: "#1f5e25",
    //           },
    //         }}
    //       >
    //         <ArrowOutwardIcon />
    //       </Button>
    //     </Link>
    //   </div>

    //   <Dialog
    //     open={openDeleteConfirm}
    //     onClose={handleDeleteCancel}
    //     PaperProps={{
    //       style: {
    //         minWidth: "500px",
    //         borderRadius: "16px",
    //         border: "3px dashed #028476",
    //         padding: "10px",
    //       },
    //     }}
    //   >
    //     <DialogTitle>Confirm Delete</DialogTitle>
    //     <DialogContent>
    //       <Typography>Are you sure you want to delete this program?</Typography>
    //     </DialogContent>
    //     <DialogActions>
    //       <Button onClick={handleDeleteCancel}>Cancel</Button>
    //       <StyledDeleteButton onClick={handleDeleteConfirm}>
    //         Delete
    //       </StyledDeleteButton>
    //     </DialogActions>
    //   </Dialog>
    // </StyledCard>
    <StyledCard style={{ fontFamily: "sen" }}>
    <div className="w-full h-full flex flex-col">
      <h1 className="font-semibold">{program.name}</h1>
      <h3 className="text-[#868686]">{program.type}</h3>
      <p className="text-[14px] leading-6">
        {truncateDescription(program.description)}{" "}
        {program.description.length > 40 && (
          <Button
            onClick={toggleDescription}
            size="small"
            variant="text"
            sx={{ color: "#028476" }}
          >
            See more
          </Button>
        )}
      </p>
      <div className="h-full">
        <DeleteIcon
          className="text-[#028476] transition duration-300 hover:text-[#028477a8] mt-auto mr-auto"
          onClick={handleDeleteActivity}
        />
      </div>
    </div>
    <div className="w-[40%] h-full flex flex-col">
      <ProgramChecklist progress={program.progress} />
      <p className="text-[12px]">
        {convertTimestamp(program.created_at)}{" "}
        <span>
          <h1 className="font-semibold">{program.location}</h1>
        </span>
      </p>
      <Link to={`/view-program/${program.id}`} className="ml-auto mt-auto">
        <Button
          variant="contained"
          sx={{
            borderRadius: "50%",
            padding: "8px",
            minWidth: "auto",
            backgroundColor: "#028476",
            color: "white",
            "&:hover": {
              backgroundColor: "#1f5e25",
            },
          }}
        >
          <ArrowOutwardIcon />
        </Button>
      </Link>
    </div>

    <Dialog
      open={openDeleteConfirm}
      onClose={handleDeleteCancel}
      PaperProps={{
        style: {
          minWidth: "500px",
          borderRadius: "16px",
          border: "3px dashed #028476",
          padding: "10px",
        },
      }}
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this program?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteCancel}>Cancel</Button>
        <StyledDeleteButton onClick={handleDeleteConfirm}>
          Delete
        </StyledDeleteButton>
      </DialogActions>
    </Dialog>
  </StyledCard>
  );
};

export default ProgramCard;
