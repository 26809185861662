import { createSlice } from "@reduxjs/toolkit";

const statsSlice = createSlice({
    name: 'stats',
    initialState: {
        program: {},
        farmer: {},
        farm: {},
        soil_reports:{},
        error: '',
    },
    reducers: {
        fetchStatsSuccess: (state, action) => {
            state.program = action.payload.program;
            state.farmer = action.payload.farmer;
            state.farm = action.payload.farm;
            state.soil_reports = action.payload.soil_reports;
            state.error = null;
        },
        fetchStatsFailure: (state, action) => {
            state.error = action.payload.error;
        }
    }
});

const soilTestReportSlice = createSlice({
    name: 'soilReport',
    initialState: {
        report: {},
        total_reports:1,
        error: '',
    },
    reducers: {
        fetchSoilTestReportSuccess: (state, action) => {
            state.report = action.payload.soil_reports;
            state.total_reports=action.payload.totalCount || 0
            state.error = null;
        },
        fetchSoilTestFailure: (state, action) => {
            state.error = action.payload.error;
        }
    }
});

export const { fetchStatsSuccess, fetchStatsFailure } = statsSlice.actions;
export const statsReducer = statsSlice.reducer;

export const { fetchSoilTestReportSuccess, fetchSoilTestFailure } = soilTestReportSlice.actions;
export const soilTestReportReducer = soilTestReportSlice.reducer;
