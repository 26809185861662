import React, { useEffect } from 'react';
import { Modal, Paper, Typography, Button, Grid, IconButton } from '@mui/material';
import { IoCloseOutline } from "react-icons/io5";

const ShowAllMedia = ({ open, handleClose,mediaMessages, getMediaMessage }) => {

    
    useEffect(() => {
        getMediaMessage();
    }, [getMediaMessage]);


    const groupedMediaMessages = {};
    mediaMessages && mediaMessages.forEach(media => {
        const date = new Date(media.created_at).toDateString();
        if (!groupedMediaMessages[date]) {
            groupedMediaMessages[date] = [];
        }
        groupedMediaMessages[date].push(media);
    });

    return (
        <Modal open={open} onClose={handleClose}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '60%', maxWidth: 620, backgroundColor: "#D9D3D3" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: "#D9D3D3", padding: 10, position: "sticky", top: 0 }}>
                    <Typography variant="h6" gutterBottom style={{ fontFamily: 'sen', fontWeight: 'bold', color:"#7E7E7E" }}>
                      Media, Links & Docs
                    </Typography>
                    <IconButton onClick={handleClose} style={{  marginTop: 2, marginLeft: 3 }}>
                    <IoCloseOutline />
                    </IconButton>
                </div>
                <Paper style={{ padding: 20, backgroundColor: "#EFEDED", overflowY: 'scroll', maxHeight: 400 }}>


                    {Object.keys(groupedMediaMessages).map((date, index) => (
                        <div key={index} style={{ marginBottom: 20 }}>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: 10 }}>{date}</Typography>
                            <Grid container spacing={2}>
                                {groupedMediaMessages[date].map((media, index) => (
                                    <Grid item key={index} xs={6} md={3}>
                                        <img style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }} src={media.url} alt="media" />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    ))}


                </Paper>
                <div style={{ textAlign: 'right', backgroundColor: '#D9D3D3', position: 'sticky' , padding: 5}}>
                    <Button style={{ backgroundColor: '#5F9C6A', margin: 7, color: 'white' }} onClick={handleClose}>Done</Button>
                </div>
            </div>
        </Modal>
    );
}

export default ShowAllMedia;
