import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Grid,
    Snackbar,
    FormHelperText,
    DialogActions,
    DialogTitle,
    DialogContent,
    Dialog

} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';
import {BEARER_TOKEN} from '../config'


const CustomPaymentModal = ({ open, baseURL, onClose, handleClick, pricing, onOrgIdChange, onEmailChange, paymentStatusData }) => {

    const [envCounts, setEnvCounts] = useState('');
    const [deviceCounts, setDeviceCounts] = useState('');
    const [orgId, setOrgId] = useState('');
    const [subId, setSubId] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [totalPrice, setTotalPrice] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [countError, setCountError] = useState(false); // State for count error [env, device]
    const [isLoading, setIsLoading] = useState(false);
    const [remainingEnvCounts, setRemainingEnvCounts] = useState(0); // State for remaining env counts
    const [remainingDeviceCounts, setRemainingDeviceCounts] = useState(0);
    const [orgIdError, setOrgIdError] = useState(false);
    const [subIdError, setSubIdError] = useState(false);
    const [envCountsError, setEnvCountsError] = useState(false);
    const [deviceCountsError, setDeviceCountsError] = useState(false);
    const [totalPriceError, setTotalPriceError] = useState(false);


    useEffect(() => {
        if (paymentStatusData.data && paymentStatusData.data.org_payment_status) {
            const { remaining_env_counts, remaining_device_counts } = paymentStatusData.data.org_payment_status.counts;
            setRemainingEnvCounts(remaining_env_counts);
            setRemainingDeviceCounts(remaining_device_counts);
        }
    }, [paymentStatusData]);



    const handleSubmit = async (e) => {

        e.preventDefault();

        let hasError = false;

        if (!orgId) {
            setOrgIdError(true);
            hasError = true;
        } else {
            setOrgIdError(false);
        }

        if (!subId) {
            setSubIdError(true);
            hasError = true;

        } else {
            setSubIdError(false);
        }

        if (!envCounts) {
            setEnvCountsError(true);
            hasError = true;

        } else {
            setEnvCountsError(false);
        }

        if (!deviceCounts) {
            setDeviceCountsError(true);
            hasError = true;

        } else {
            setDeviceCountsError(false);
        }

        if (totalPrice === 0) {
            setTotalPriceError(true);
            hasError = true;

        } else {
            setTotalPriceError(false);
        }

        if (parseInt(envCounts) > remainingEnvCounts || parseInt(deviceCounts) > remainingDeviceCounts) {
            setCountError(true);
            return;
        }
        setCountError(false);
        if (hasError) {
            setIsLoading(false); // Set isLoading to false if there are errors
            return;
        }

        setIsLoading(true);


        const payload = {
            org_id: parseInt(orgId),
            transaction_status: 'initiated',
            pricing_id: pricing.data.pricing.find(plan => plan.plan_name === subId).id,
            env_counts: parseInt(envCounts),
            device_counts: parseInt(deviceCounts),
            amount: totalPrice,
            source: 'cms',
        };

        try {
            const response = await fetch(baseURL +
                '/api/mgmt/payments/initiatePaymentCms', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + BEARER_TOKEN
                    
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const responseData = await response.json();
                handleClick();
                console.log("API response data:", responseData);
                setSnackbarOpen(true);
                onClose();
                onOrgIdChange(''); // Clear orgID
                onEmailChange('');
                setEnvCounts('');
                setDeviceCounts('');
                setTotalPrice(0);
                setOrgId('');
                setSubId('');
            } else {
                throw new Error("API response not ok");
            }
        } catch (error) {
            console.error('Failed to fetch API:', error);
        } finally {
            setIsLoading(false);
        }

    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbarExited = () => {
        // Reset fields after snackbar has exited
        setOrgId('');
        setSubId('');
        // setPaymentId('');
        setStartDate(new Date());
        setEnvCounts('');
        setDeviceCounts('');
        setTotalPrice(0);
    };

    const handleSubIdChange = (e) => {

        setSubId(e.target.value);

        if (e.target.value === 'annual') {
            const oneYearBefore = moment(startDate).subtract(1, 'years').tz('Asia/Kolkata');
            // Set the time to 23:59:59
            oneYearBefore.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
        } 
    };


    const handleClose = () => {
        setIsLoading(false)
        onClose();
        setOrgId('');
        setSubId('');
        // setPaymentId('');
        setEnvCounts('');
        setDeviceCounts('');
        setTotalPrice(0);

        setOrgIdError(false);
        setSubIdError(false);
        // setPaymentIdError(false);
        setEnvCountsError(false);
        setDeviceCountsError(false);
        setTotalPriceError(false);
    };

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };


    const handleTotalPriceChange = (e) => {
        const newTotalPrice = e.target.value;
        setTotalPrice(newTotalPrice);
    };



    useEffect(() => {

        if (!pricing.data || !pricing.data.pricing) {
            return;
        }

        const selectedPlan = pricing.data.pricing.find(plan => {
            if (subId === 'monthly' && plan.plan_name === 'monthly') {
                return true;
            } else if (subId === 'annual' && plan.plan_name === 'annual') {
                return true;
            }
            return false;
        });

        if (!selectedPlan) {
            return;
        }

        const envPrice = selectedPlan.env_amount;
        const devicePrice = selectedPlan.device_amount;

        const calculatedTotalPrice =
            parseInt(envCounts) * envPrice + parseInt(deviceCounts) * devicePrice;

        setTotalPrice(calculatedTotalPrice);
    }, [envCounts, deviceCounts, subId, pricing]);

    return (
        <>
            <Dialog
                open={open}

                onClose={onClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                disableBackdropClick
                onBackdropClick={handleBackdropClick}
                //center the modal to the screen
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 400, marginRight: 400 }}
            >

                <div style={{ backgroundColor: "#d0e7b7" }}>
                    <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Payment Details</DialogTitle>
                </div>

                <div maxWidth="sm" style={{ backgroundColor: "white", boxShadow: "inherit", padding: 20 }}>
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <TextField
                                        label="ORG ID"
                                        fullWidth
                                        variant="outlined"
                                        value={orgId}
                                        color='success'
                                        error={orgIdError}
                                        helperText={orgIdError && 'ORG ID is required'}
                                        onChange={(e) => setOrgId(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="sub-id">Sub ID</InputLabel>
                                        <Select
                                            id="sub-id"
                                            value={subId}
                                            onChange={handleSubIdChange}
                                            error={subIdError}
                                            color='success'
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="monthly">Monthly</MenuItem>
                                        </Select>
                                        {subIdError && <FormHelperText error>Sub ID is required</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Device Counts"
                                        fullWidth
                                        variant="outlined"
                                        color='success'
                                        value={deviceCounts}
                                        onChange={(e) => setDeviceCounts(e.target.value)}
                                        error={deviceCountsError}
                                        helperText={deviceCountsError && 'Device Counts is required'}

                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label="Env Counts"
                                        fullWidth
                                        variant="outlined"
                                        color='success'
                                        value={envCounts}
                                        onChange={(e) => setEnvCounts(e.target.value)}
                                        error={envCountsError}
                                        helperText={envCountsError && 'Env Counts is required'}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        label="Total Price (in INR)"
                                        fullWidth
                                        variant="outlined"
                                        color='success'
                                        value={`${totalPrice ? totalPrice + (totalPrice * pricing.data.pricing[0].gst) / 100 : 0}`}
                                        onChange={handleTotalPriceChange}
                                        error={totalPriceError}
                                        helperText={totalPriceError && 'Total Price is required'}
                                    />
                                </Grid>
                            </Grid>

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" color="error">
                                Cancel
                            </Button>
                            <Button style={{ backgroundColor: '#5F9C6A', color: 'white' }} type="submit" color="primary" variant='contained' disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </Button>
                        </DialogActions>
                    </form>
                </div>
            </Dialog >

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000} // Adjust the duration as needed
                onClose={handleSnackbarClose}
                onExited={handleSnackbarExited}
                message="Payment added successfully !"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />

            <Snackbar
                open={countError}
                autoHideDuration={5000}
                onClose={() => setCountError(false)}
                message="Env or Device Counts exceeds"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />

        </>
    );
};

export default CustomPaymentModal;
