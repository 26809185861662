

import React,{useState, useCallback, useEffect}  from 'react';
import { Paper,  Typography} from '@mui/material';
import Img from '../images/Phyfarm-App-footer.png'
import { AiOutlineCheckCircle } from "react-icons/ai";
import useMediaQuery from '@mui/material/useMediaQuery';





const Confirmation = () => {

    const [ resetMail, setResetMail] = useState("");
    const isSmScreen = useMediaQuery('(max-width:850px)');
    const [styles, setStyles] = useState({});
    
    const getUserEmail = useCallback(() => {
        const data = localStorage.getItem('resetMail');
        if (data) {
            setResetMail(data);
            localStorage.removeItem('resetMail');
        }
    },[])

    useEffect(() => {
        getUserEmail();
    },[getUserEmail]);

    useEffect(() => {
        if (isSmScreen) {
            setStyles({
                container: { width: '100%', height: '100%', maxWidth: 850 },
                paper: { position: "absolute", top: 150,margin:10, padding: 20, display: 'flex', flexDirection: 'column' }, 
                
            });
        } else {
            setStyles({
                container: {  display: 'flex',
                flexDirection: 'column',
                zIndex: 1,
                position: 'relative'},
           
                paper: {  padding: '30px',
                borderRadius: '15px',
                width: '400px',
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                left: '30%',
                marginTop: '190px',
                marginLeft: '50px' },
            
            });
        }
    }, [isSmScreen]);




    return (
        <>


            <div
                // style={{
                //     display: 'flex',
                //     flexDirection: 'column',
                //     zIndex: 1,
                //     position: 'relative',
                // }}
                style={styles.container}
            >
                <Paper
                    elevation={5}
                    style={styles.paper}
                    // style={{
                    //     padding: '30px',
                    //     borderRadius: '15px',
                    //     width: '400px',
                    //     height: '300px',
                    //     display: 'flex',
                    //     flexDirection: 'column',
                    //     position: 'absolute',
                    //     left: '30%',
                    //     marginTop: '190px',
                    //     marginLeft: '50px'
                    // }}
                >
                    <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: 'bold', fontFamily: 'sen', textAlign: 'center' }}>
                        Check your email !
                    </Typography>
                  
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginTop: 35 }}>
                        <AiOutlineCheckCircle style={{ fontSize: '4rem', color: 'green' }} />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <Typography>Reset password link has been sent to <span style={{ fontWeight: 'bold', color: 'green' }}>{resetMail.replace(/"/g, '')}</span></Typography>
                    </div>
                </Paper>
            </div>

            <div >
                <img style={{ height: '100vh', width: '100vw', backgroundRepeat: 'no-repeat' }} src={Img} alt="Background" />
            </div>
        </>

    );
};

export default Confirmation;