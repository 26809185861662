import React, { useCallback, useEffect, useState } from 'react'
import Layout from './Layout'
import {
    Typography, Grid, TextField,
    MenuItem,
    DialogTitle,
    InputLabel,
    Dialog,
    Button,
    IconButton,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    DialogContent,
    DialogActions,
    Snackbar,

} from '@mui/material'
import { IoMdAdd } from 'react-icons/io';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {baseURL} from '../config';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MuiAlert from '@mui/material/Alert';
import { listUserByOrgId,listRoles,listFeatures, addUser ,getUserData, updateUser, deleteUser} from '../apis/user-mgmt/api';
import { createPermission , editPermission, deletePermission, isValidEmail} from '../utils/helpers';


const getDefaultPermissions = () => {
    return {
        "platformUser_mgmt": {
            "view": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "user_mgmt": {
            "view": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "gptChat_mgmt": {
            "view": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "agroChat_mgmt": {
            "view": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "farm_mgmt": {
            "view": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "env_mgmt": {
            "view": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "device_mgmt": {
            "view": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "payment_mgmt": {
            "view": false,
            "create": false,
            "edit": false,
            "delete": false
        }
    }
}

const mergeDataWithPermissions = (updatedData, rolePermissions) => {

    const mutableUpdatedData = JSON.parse(JSON.stringify(updatedData));
    const mutableRolePermissions = JSON.parse(JSON.stringify(rolePermissions));
    const { user_permissions } = mutableUpdatedData;
    const { permissions } = user_permissions;
    
    permissions.forEach((permission) => {
        const { feature_name } = permission;
        permission.permissions = rolePermissions[feature_name];
    });

    mutableRolePermissions.forEach((permission) => {

        const { feature_id} = permission;
        const foundPermission = permissions.find(p => p.feature_id === feature_id);
        if (!foundPermission) {
            permissions.push(permission);
        }
    });

    mutableUpdatedData.user_permissions.permissions = mutableUpdatedData.user_permissions.permissions.filter(permission => permission.feature_id !== '');
    return mutableUpdatedData;
};

const UserManagement = () => {

    const userData = JSON.parse(localStorage.getItem('userData'));
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    const [searchQuery, setSearchQuery] = useState('');
    const [addUserModal, setAddUserModal] = useState(false);
    const [searchDropdown, setSearchDropdown] = useState('phone');
    const [roles, setRoles] = useState([]);
    const [features, setFeatures] = useState([]);
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        phone: '',
        role: '',
        password: '',
        is_custom_role: false,
    });
    const [rolePermissions, setRolePermissions] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [isCustomRole, setIsCustomRole] = useState(false);
    const [users, setUser] = useState([])
    const [loading, setLoading] = useState(false);
    const [disabledRole, setDisableRole] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [userId, setUserId] = useState('');
    const [orgId, setOrgId] = useState('');
    const [error, setError] = useState({
        name: false,
        email: false,
        password: false,
        phone: false
    });
    const [updateModal, setUpdateModal] = useState(false);
    const [updatedData, setUpdatedData] = useState({
        user: {
            id: "",
            org_id: null,
            role: {
                id: "",
                name: ""
            },
            full_name: "",
            email: "",
            phone: "",
            is_custom_role: false,
            is_super_admin: false,
            is_admin: false,
            active: true,
            user_permissions: {
                id: "",
                permissions: null
            }
        },

    })
    const getRoles = useCallback(async (orgID) => {
     
        const response = await listRoles(baseURL,orgID);
        const data = await response.json();
        if (data.data) {
            setRoles(data.data.roles);
        } else {
            console.log(data);
        }
    }, []);

    const getFeatures = useCallback(async (orgID) => {
    
        const response = await listFeatures(baseURL,orgID);
        const data = await response.json();
        if (data.data) {
            setFeatures(data.data.features);
        } else {
            console.log(data);
        }
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    }

    const handleNewUserChange = (event) => {
        const { name, value } = event.target;

        const regex = /^[0-9\b]+$/;

        if (name === 'phone' && value !== '' && !regex.test(value)) {
            setError(prevState => ({ ...prevState, phone: true }));
            return;
        }else{
            setError(prevState => ({ ...prevState, phone: false }));
            setNewUser(prevState => ({ ...prevState, [name]: value }));
        }

        if (name === 'role') {
            const selectedRole = roles.find(role => role.id === value);
            if (selectedRole) {
                const permissions = {};
                selectedRole?.user_permissions?.permissions.forEach(permission => {
                    permissions[permission.feature_name] = permission.permissions;
                });
                setRolePermissions(permissions);
                setIsCustomRole(false);
            } else {
                setRolePermissions({});
            }
        }
    };

    const handleCustomRoleChange = (event) => {
        setIsCustomRole(event.target.checked);
        if (event.target.checked) {
            setRolePermissions(getDefaultPermissions());
            setNewUser(prevState => ({ ...prevState, is_custom_role: true }));
            setNewUser(prevState => ({ ...prevState, role: '' }));

        } else {
            const selectedRole = roles.find(role => role.id === newUser.role);
            const permissions = {};
            selectedRole?.user_permissions?.permissions.forEach(permission => {
                permissions[permission.feature_name] = permission.permissions;
            });
            setRolePermissions(permissions);
            setDisableRole(false);
        }
    }

    const hasCreatePermission = createPermission('user_mgmt', permissions);

    const handleOpen = () => {
        setAddUserModal(true);
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const getAllUsers = useCallback(async (orgId) => {

        if (!orgId) return;
        setLoading(true);

        try {
            const response = await listUserByOrgId(baseURL,orgId);
            const data = await response.json();
            if (data.data) {
                setUser(data.data.users);
                setLoading(false);
            } else {
                console.log(data);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, []);

    function formatFeatureName(name) {
        return name.replace(/_/g, ' ')
            .replace(/\b\w/g, c => c.toUpperCase());
    }

    const hasUpdatePermission = editPermission('user_mgmt', permissions);
    const hasDeletePermission = deletePermission('user_mgmt', permissions);

    const filteredUsers = users?.filter((user) => {
        const query = searchQuery.toLowerCase();
        switch (searchDropdown) {
            case 'phone':
                return user.phone.toLowerCase().includes(query);
            case 'email':
                return user.email.toLowerCase().includes(query);
            case 'role':
                return user.role?.name.toLowerCase().includes(query) || (user.is_custom_role && 'custom'.includes(query));
            case 'name':
                return user.full_name.toLowerCase().includes(query);
            default:
                return true;
        }
    });

    const handleOpenDelete = () => {
        setOpenDelete(!openDelete);
    }

    const handleDelete = async (userid, orgid) => {

        setLoading(true);
        try {

            const response = await deleteUser(userid,orgid,baseURL,userData.id);
            if (response.ok) {
                setLoading(false);
                setOpenDelete(false);
                getAllUsers(orgid);
                setSnackbarOpen(true);
                setSnackbarMessage("Admin deleted successfully");
                setSnackbarSeverity('success');
            }else{
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage("An error occurred");
                setSnackbarSeverity('error');
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

    }

    function isValidPhoneNumber(phoneNumber) {

        const phoneRegex = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
        if (!phoneRegex.test(phoneNumber)) {
            return false;
        }
        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
        return cleanedPhoneNumber.length === 10;
    }

    const handleAddUser = async () => {

        if (!newUser.name) {
            setLoading(false);
            setError(prevState => ({ ...prevState, name: true }));
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage("Please enter name of user");
            return;
        }else{
            setError(prevState => ({ ...prevState, name: false }));
        }

        if (!newUser.email) {
            setLoading(false);
            setError(prevState => ({ ...prevState, email: true }));
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage("Please enter email of user");
            return;
        }else{
            setError(prevState => ({ ...prevState, email: false }));
        }

        if (!newUser.password) {
            setLoading(false);
            setError(prevState => ({ ...prevState, password: true }));
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage("Please enter password of user");
            return;
        }else{
            setError(prevState => ({ ...prevState, password: false }));
        }

        if (newUser.email !== "" && !isValidEmail(newUser?.email)) {
            setLoading(false);
            setError(prevState => ({ ...prevState, email: true }));
            setSnackbarOpen(true);
            setSnackbarMessage("Invalid email");
            setSnackbarSeverity('error');
            return;
        }else{
            setError(prevState => ({ ...prevState, email: false }));
        }

        if(newUser.role === "" && !isCustomRole){
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage("Please select a role");
            setSnackbarSeverity('error');
            return;
        }

        setLoading(true);

        const formattedPermissions = Object.keys(rolePermissions).map(feature => ({
            feature_name: feature,
            feature_id: features.find(f => f.name === feature).id,
            permissions: rolePermissions[feature]
        }));

        const payload = {
            user :{
                org: {
                    id: userData.org.id
                },
                full_name: newUser.name,
                email: newUser.email,
                phone: newUser.phone,
                password: newUser.password,
                role: {
                    id: isCustomRole ? "" : newUser.role
                },
                is_custom_role: isCustomRole,
                user_permissions: {
                    permissions: isCustomRole ? formattedPermissions : []
                }
            },
            user_id: userData.id
            }

        try {

            const response = await addUser(baseURL,payload);
            if (response.ok) {
                setAddUserModal(false);
                setLoading(false);
                getAllUsers(userData.org.id);
                setSnackbarOpen(true);
                setSnackbarMessage("User added successfully");
                setSnackbarSeverity('success');
                setNewUser({
                    name: '',
                    email: '',
                    phone: '',
                    role: '',
                    password: '',
                    is_custom_role: false
                });
                setError({
                    name: false,
                    email: false,
                    password: false,
                    phone: false
                });
                setRolePermissions({});
            }else{
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage("An error occurred");
                setSnackbarSeverity('error');
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage("An error occurred");
            setSnackbarSeverity('error');
        }
    }

    const getUser = async (userid, orgid) => {
        
        try {

            const response = await getUserData(baseURL,userid,orgid);
            const data = await response.json();
            if (data?.data) {
                setUpdatedData(data?.data?.user);
                const permissions = {};
                data?.data?.user?.user_permissions?.permissions.forEach(permission => {
                    permissions[permission.feature_name] = permission.permissions;
                });
                setRolePermissions(permissions);
                if (data?.data?.user?.is_custom_role) {
                    setIsCustomRole(true);
                    setDisableRole(true);
                }
                if (data?.data?.user?.role?.id === "") {
                    setDisableRole(true);
                    setIsCustomRole(true);
                } else {
                    setIsCustomRole(false);
                }
            }else{
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage("An error occurred");
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleUpdateUser = async () => {

        setLoading(true);

        if (!isValidEmail(updatedData.email)) {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage("Invalid email");
            setSnackbarSeverity('error');
            return;
        }

        if (updatedData.phone && !isValidPhoneNumber(updatedData.phone)) {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage("Invalid phone number");
            setSnackbarSeverity('error');
            return;
        }

        const formattedPermissions = Object.keys(rolePermissions).map(feature => ({
            feature_name: feature,
            feature_id: features.find(f => f.name === feature).id,
            permissions: rolePermissions[feature]
        }));

        const payload = mergeDataWithPermissions(updatedData, formattedPermissions)

        try {

            const response = await updateUser(baseURL,payload,userData.id);
            if (response.ok) {
                setUpdateModal(false);
                setLoading(false);
                getAllUsers(orgId);
                setSnackbarOpen(true);
                setSnackbarMessage("User updated successfully");
                setSnackbarSeverity('success');
                setNewUser({
                    name: '',
                    email: '',
                    phone: '',
                    role: '',
                    password: '',
                    is_custom_role: false
                });
                setRolePermissions({});
                getAllUsers(userData.org.id);
            }else{
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage("An error occurred");
                setSnackbarSeverity('error');
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage("An error occurred");
            setSnackbarSeverity('error');
        }

    }

    useEffect(() => {
        getRoles(userData?.org?.id);
        getFeatures(userData?.org?.id);
        getAllUsers(userData?.org?.id);
    }, [getRoles, getFeatures, getAllUsers, userData.org?.id]);

    return (
        <Layout>
            <div style={{ height: '100vh', marginTop: 80 }}>
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 25 }}>List of Users</Typography>
                    <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>(Search using phone and email of users) </Typography>
                </div>
                <Grid container style={{ marginTop: 50 }} spacing={1} justifyContent={"center"}>
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <div style={{ display: "flex" }}>
                            <TextField
                                fullWidth
                                label={searchDropdown ? `Search by ${searchDropdown.toUpperCase()}` : 'Search'}
                                value={searchQuery}
                                color='success'
                                variant="outlined"
                                onChange={handleSearchChange}
                                onKeyPress={e => e.key === 'Enter'}
                                InputProps={{
                                    startAdornment: (
                                        <TextField
                                            select
                                            value={searchDropdown}
                                            color='success'
                                            sx={{ minWidth: 120 }}
                                            variant='standard'
                                            onChange={event => {
                                                const sD = event.target.value;
                                                setSearchDropdown(sD);
                                                setSearchQuery('');
                                            }}
                                        >
                                            <MenuItem value={"phone"}>PHONE</MenuItem>
                                            <MenuItem value={"email"}>EMAIL</MenuItem>
                                            <MenuItem value={"role"}>ROLE</MenuItem>
                                        </TextField>
                                    ),
                                    endAdornment: (
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    ),
                                }}
                            />
                            {hasCreatePermission && (
                                <Button
                                    onClick={handleOpen}
                                    style={{
                                        borderRadius: '50%',
                                        marginLeft: 5,
                                        width: '45px',
                                        height: '55px',
                                        minWidth: '55px',
                                        minHeight: '55px',
                                        fontSize: '1.2rem',
                                    }}
                                    variant='contained'
                                    color='success'
                                >
                                    <IoMdAdd />
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>
                {
                    loading ? (
                        <div style={{ textAlign: 'center', marginBottom: 20 }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div style={{
                            marginLeft: 65,
                        }}>

                            <TableContainer component={Paper} style={{ marginTop: 20 }}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Name
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Email
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Phone
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Role
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Status
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filteredUsers?.map((user, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center">{user.full_name}</TableCell>
                                                    <TableCell align="center">{user.email}</TableCell>
                                                    <TableCell align="center">{user.phone}</TableCell>
                                                    <TableCell align="center">{user.is_custom_role ? "CUSTOM" : user?.role?.name}</TableCell>
                                                    <TableCell align="center" style={{ color: user.active ? 'green' : 'red' }}>
                                                        {user.active ? 'ACTIVE' : 'INACTIVE'}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            hasUpdatePermission &&
                                                            <Button
                                                                variant='outlined'
                                                                color='primary'
                                                                onClick={() => {
                                                                    setUpdateModal(true);
                                                                    getUser(user.id, user.org_id)
                                                                }}
                                                                // onClick={() => handleOpenUpdate(org)}
                                                                style={{ marginRight: 5 }}
                                                            >
                                                                Update
                                                            </Button>
                                                        }
                                                        {hasDeletePermission &&
                                                            <Button
                                                                variant='outlined'
                                                                color='error'
                                                                onClick={() => {
                                                                    handleOpenDelete();
                                                                    setUserId(user.id);
                                                                    setOrgId(user.org_id);
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )
                }

            </div>

            {/* add user */}
            <Dialog

                open={addUserModal}
                onClose={() => setAddUserModal(false)}
                disableEnforceFocus
                maxWidth="md"
                sx={{ marginTop: 6 }}
                onBackdropClick={() => {
                    setNewUser({
                        name: '',
                        email: '',
                        phone: '',
                        role: '',
                        password: '',
                        is_custom_role: false
                    });
                    setRolePermissions({});
                    setIsCustomRole(false);
                    setDisableRole(false);
                    setError({
                        name: false,
                        email: false,
                        password: false,
                        phone: false
                    });
                }}
            >
                <div style={{ position: 'sticky', top: 0, zIndex: 999, backgroundColor: "#d0e7b7" }}>
                    <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Add User</DialogTitle>
                </div>
                <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontWeight: 'bold', margin: '20px 20px 0 20px' }}>
                    Enter personal details of user:
                </Typography>
                <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Name"
                            variant="outlined"
                            color='success'
                            name="name"
                            value={newUser.name}
                            onChange={handleNewUserChange}
                            error={
                                error.name ? true : false
                            }

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            color='success'
                            name="email"
                            value={newUser.email}
                            onChange={handleNewUserChange}
                            autoComplete='off'
                            error={
                                error.email ? true : false
                            }


                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Phone"
                            variant="outlined"
                            color='success'
                            name="phone"
                            value={newUser.phone}
                            onChange={handleNewUserChange}
                            autoComplete='off'
                            type='text'
                            error={error.phone ? true : false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Password"
                            variant="outlined"
                            color='success'
                            name="password"
                            error={
                                error.password ? true : false
                            }
                            value={newUser.password}
                            onChange={handleNewUserChange}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={togglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="role-select-label">{isCustomRole ? "CUSTOM" : "Role"}</InputLabel>
                            <Select
                                labelId="role-select-label"
                                id="role-select"
                                color='success'
                                name="role"
                                value={newUser.role}
                                // disabled={disabledRole}
                                onChange={handleNewUserChange}
                                label="Role"
                            >
                                {roles?.map(role => (
                                    <MenuItem key={role.id} value={role.id}>{role.name.charAt(0).toUpperCase() + role.name.slice(1)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox color="success" checked={isCustomRole} />}
                            label="Custom Role"
                            disabled={disabledRole}
                            onChange={handleCustomRoleChange}
                        />
                    </Grid>
                </Grid>
                <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontWeight: 'bold', margin: '5px 20px 0 20px' }}>
                    Permissions
                </Typography>
                <Grid container spacing={2} style={{ padding: 20 }}>
                    {features.map((feature, index) => (
                        <Grid item xs={12} key={feature.id}>
                            <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                                {index + 1}. {formatFeatureName(feature.name)}
                            </Typography>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={<Checkbox color="success"
                                        checked={(rolePermissions[feature.name]?.view || false)}
                                        onChange={(e) => {
                                            isCustomRole && setRolePermissions(prevState => ({ ...prevState, [feature.name]: { ...prevState[feature.name], view: e.target.checked } }))
                                        }}
                                    />}
                                    label="View"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="success"
                                        checked={(rolePermissions[feature.name]?.create || false)}
                                        onChange={(e) => {
                                            isCustomRole && setRolePermissions(prevState => ({ ...prevState, [feature.name]: { ...prevState[feature.name], create: e.target.checked } }))
                                        }}
                                    />}
                                    label="Create"

                                />
                                <FormControlLabel

                                    control={<Checkbox color="success"
                                        checked={(rolePermissions[feature.name]?.edit || false)}
                                        onChange={(e) => {
                                            isCustomRole && setRolePermissions(prevState => ({ ...prevState, [feature.name]: { ...prevState[feature.name], edit: e.target.checked } }))
                                        }
                                        }
                                    />}
                                    label="Edit"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="success"
                                        checked={(rolePermissions[feature.name]?.delete || false)}
                                        onChange={(e) => {
                                            isCustomRole && setRolePermissions(prevState => ({ ...prevState, [feature.name]: { ...prevState[feature.name], delete: e.target.checked } }))
                                        }
                                        }
                                    />}
                                    label="Delete"
                                />
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Grid container style={{ padding: '20px', gap: '10px', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="error" style={{ width: 'calc(50% - 5px)' }} onClick={() => {
                        setAddUserModal(false)
                        setRolePermissions({});
                        setNewUser({
                            name: '',
                            email: '',
                            phone: '',
                            role: '',
                            password: '',
                            is_custom_role: false
                        })
                        setIsCustomRole(false);
                        setDisableRole(false);
                        setError({
                            name: false,
                            email: false,
                            password: false,
                            phone: false
                        })
                    }}>Cancel</Button>
                    <Button variant="contained" color="success" style={{ width: 'calc(50% - 5px)' }} onClick={handleAddUser}>Add User</Button>
                </Grid>
            </Dialog>
            {/* delete user */}
            <Dialog open={openDelete} onClose={() => setOpenDelete(false)} disableEnforceFocus>
                <div style={{ backgroundColor: "#d0e7b7" }}>
                    <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Delete User</DialogTitle>
                </div>
                <DialogContent>
                    Are you sure you want to delete this User?
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => {
                        setOpenDelete(false)
                        setLoading(false)
                    }}>
                        Cancel
                    </Button>
                    <Button variant="outlined" onClick={
                        () => {
                            handleDelete(userId, orgId)
                        }
                    } color="error">

                        {loading ? <CircularProgress color='error' size={24} /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Update user */}
            <Dialog open={updateModal} onClose={() => setUpdateModal(false)} disableEnforceFocus maxWidth="md">
                <div style={{ position: 'sticky', top: 0, zIndex: 999, backgroundColor: "#d0e7b7" }}>
                    <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Update User</DialogTitle>
                </div>
                <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontWeight: 'bold', margin: '20px 20px 0 20px' }}>
                    Update personal details of user:
                </Typography>
                <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Name"
                            variant="outlined"
                            color='success'
                            name="full_name"
                            value={updatedData?.full_name || ''}
                            onChange={(e) => {
                                setUpdatedData(prevState => ({ ...prevState, full_name: e.target.value }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            color='success'
                            name="email"
                            value={updatedData?.email || ''}
                            onChange={(e) => {
                                setUpdatedData(prevState => ({ ...prevState, email: e.target.value }))
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Phone"
                            variant="outlined"
                            color='success'
                            name="phone"
                            type='number'
                            value={updatedData?.phone || ''}
                            onChange={(e) => {
                                setUpdatedData(prevState => ({ ...prevState, phone: e.target.value }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="role-select-label">Status</InputLabel>
                            <Select
                                labelId="role-select-label"
                                id="role-select"
                                color='success'
                                name="status"
                                value={updatedData?.active === true ? true : false}
                                onChange={(e) => {
                                    setUpdatedData(prevState => ({ ...prevState, active: e.target.value }))
                                }}
                                label="Status"
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ padding: 20 }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="role-select-label">{newUser.is_custom_role ? "CUSTOM" : "Role"}</InputLabel>
                            <Select
                                labelId="role-select-label"
                                id="role-select"
                                color='success'
                                name="role"
                                value={updatedData?.role?.id || ''}
                                disabled={isCustomRole}
                                onChange={(e) => {
                                    setUpdatedData(prevState => ({
                                        ...prevState, role: {
                                            id: e.target.value,
                                            name: roles.find(role => role.id === e.target.value).name
                                        }
                                    }))
                                    handleNewUserChange(e)
                                }
                                }
                                label="Role"
                            >
                                {roles?.map(role => (
                                    <MenuItem key={role.id} value={role.id}>{role.name.charAt(0).toUpperCase() + role.name.slice(1)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox color="success" checked={isCustomRole} />}
                            label="Custom Role"
                            disabled={true}
                            onChange={handleCustomRoleChange}
                        />
                    </Grid>
                </Grid>
                <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontWeight: 'bold', margin: '5px 20px 0 20px' }}>
                    Permissions
                </Typography>
                <Grid container spacing={2} style={{ padding: 20 }}>
                    {features.map((feature, index) => (
                        <Grid item xs={12} key={feature.id}>
                            <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                                {index + 1}. {formatFeatureName(feature.name)}
                            </Typography>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={<Checkbox color="success"
                                        checked={(rolePermissions[feature.name]?.view || false)}
                                        onChange={(e) => {
                                            isCustomRole && setRolePermissions(prevState => ({ ...prevState, [feature.name]: { ...prevState[feature.name], view: e.target.checked } }))
                                        }}
                                    />}
                                    label="View"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="success"
                                        checked={(rolePermissions[feature.name]?.create || false)}
                                        onChange={(e) => {
                                            isCustomRole && setRolePermissions(prevState => ({ ...prevState, [feature.name]: { ...prevState[feature.name], create: e.target.checked } }))
                                        }}
                                    />}
                                    label="Create"

                                />
                                <FormControlLabel

                                    control={<Checkbox color="success"
                                        checked={(rolePermissions[feature.name]?.edit || false)}
                                        onChange={(e) => {
                                            isCustomRole && setRolePermissions(prevState => ({ ...prevState, [feature.name]: { ...prevState[feature.name], edit: e.target.checked } }))
                                        }
                                        }
                                    />}
                                    label="Edit"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="success"
                                        checked={(rolePermissions[feature.name]?.delete || false)}
                                        onChange={(e) => {
                                            isCustomRole && setRolePermissions(prevState => ({ ...prevState, [feature.name]: { ...prevState[feature.name], delete: e.target.checked } }))
                                        }
                                        }
                                    />}
                                    label="Delete"
                                />
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Grid container style={{ padding: '20px', gap: '10px', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="error" style={{ width: 'calc(50% - 5px)' }} onClick={() => {
                        setUpdateModal(false)
                        setRolePermissions({});
                        setIsCustomRole(false);
                        setDisableRole(false);
                        setNewUser({
                            name: '',
                            email: '',
                            phone: '',
                            role: '',
                            password: '',
                            is_custom_role: false
                        })
                    }}>Cancel</Button>
                    <Button variant="contained" color="success" style={{ width: 'calc(50% - 5px)' }} onClick={handleUpdateUser}>Update User</Button>
                </Grid>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Layout>
    )
}

export default UserManagement