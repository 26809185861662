import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { TextField, MenuItem, CircularProgress } from '@mui/material';
import { baseURL } from "../config";


const EndUserLineChart = ({  programId }) => {
  const [programUsers, setProgramUsers] = useState([]);
  const [selectedYear, setSelectedYear] = useState(2024);
  const [loading, setLoading] = useState(true);

  const fetchProgramUsers = useCallback(async () => {
    try {
      const response = await fetch(`${baseURL}/api/mgmt/360-program-users/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken: JSON.parse(localStorage.getItem("userData")).access_token,
        },
        body: JSON.stringify({
          filter: {
            program_id: programId
          }
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch program users');
      }
      const data = await response.json();
      setProgramUsers(data.data.user_programs);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [programId]);
  useEffect(() => {
    fetchProgramUsers();
  }, [fetchProgramUsers]);

  // const fetchProgramUsers = async () => {
  //   try {
  //     const response = await fetch(`${baseURL}/api/mgmt/360-program-users/list`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         accessToken: JSON.parse(localStorage.getItem("userData")).access_token,
  //       },
  //       body: JSON.stringify({
  //         filter: {
  //           program_id: programId
  //         }
  //       }),
  //     });
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch program users');
  //     }
  //     const data = await response.json();
  //     setProgramUsers(data.data.user_programs);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchProgramUsers();
  // }, [programId, baseURL]);

  const groupDataByMonth = (data, year) => {
    const monthlyData = Array(12).fill(0);

    data.forEach(user => {
      const date = new Date(user.created_at);
      if (date.getFullYear() === year) {
        const month = date.getMonth();
        monthlyData[month] += 1;
      }
    });

    return monthlyData;
  };

  const years = useMemo(() => {
    const uniqueYears = [...new Set(programUsers.map(user => new Date(user.created_at).getFullYear()))];
    return uniqueYears;
  }, [programUsers]);

  const data = useMemo(() => groupDataByMonth(programUsers, selectedYear), [selectedYear, programUsers]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <TextField
        select
        label="Select Year"
        value={selectedYear}
        onChange={(e) => setSelectedYear(Number(e.target.value))}
        variant="outlined"
        sx={{
          width: '200px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#028476',
            },
            '&:hover fieldset': {
              borderColor: '#028476',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#028476',
            },
          },
        }}
        margin="normal"
      >
        {years.map(year => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </TextField>

      <div className='flex justify-center' style={{ width: '100%', height: 'calc(40vh - 100px)' }}>
        <LineChart
          sx={{ marginX: 'auto' }}
          xAxis={[
            {
              scaleType: 'point',
              data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              label: 'Month',
            },
          ]}
          series={[
            {
              data,
              label: `User Registrations in ${selectedYear}`,
              color: '#028476',
            },
          ]}
          width={700}
          height={200}
        />
      </div>
    </div>
  );
};

export default EndUserLineChart;

