import { createSlice } from "@reduxjs/toolkit";

const leadSlice = createSlice({
    name: 'lead',
    initialState: {
      
        lead:{},
        
    },
    reducers: {
      

        leadSuccess: (state, action) => {
            state.lead = action.payload;
        }
    }
});





export default leadSlice.reducer;
export const { leadSuccess } = leadSlice.actions;