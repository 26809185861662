import {BEARER_TOKEN} from '../../config'

export const getFeatures = async (
    baseURL,
    orgId
) => {
    const response = await fetch(`${baseURL}/api/mgmt/360-features/listAll`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            "org_id": orgId
        })
    });

    return response;
}

export const addFeature = async (
    baseURL,
    selectedOrgId,
    featureName,
    userId
) => {
    const response = await fetch(`${baseURL}/api/mgmt/360-features/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            feature: {
                "org_id": selectedOrgId,
                "name": featureName
            },
            user_id: userId
        })

    });

    return response;
}

export const updateFeature = async (
    baseURL,
    selectedFeature,
    selectedOrgId,
    userData
) => {
    const response = await fetch(`${baseURL}/api/mgmt/360-features/update`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            feature: {
                id: selectedFeature.id,
                name: selectedFeature.name,
                org_id: selectedOrgId
            },
            user_id: userData.id
        })

    });

    return response;
}

export const deleteFeature = async (
    baseURL,
    featureId,
    featureName,
    selectedOrgId,
    userId
) => {
    const response = await fetch(`${baseURL}/api/mgmt/360-features/delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            feature: {
                id: featureId,
                name: featureName,
                org_id: selectedOrgId
            },
            user_id: userId
        })

    });
    return response;
}