import React, { useState } from 'react';
//import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, Tooltip, IconButton } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import Timeline from '@mui/lab/Timeline';
// import TimelineItem from '@mui/lab/TimelineItem';
// import TimelineSeparator from '@mui/lab/TimelineSeparator';
// import TimelineConnector from '@mui/lab/TimelineConnector';
// import TimelineContent from '@mui/lab/TimelineContent';
// import TimelineDot from '@mui/lab/TimelineDot';
// import { Tooltip } from '@mui/material';
// import { IconButton } from '@mui/material';

// const stages = ['Assembling', 'Ready to ship', 'Installation', 'Training', 'Rating and Review'];
// const days = [5, 3, 3, 6, 2];

const STAGES = [{
  id: 0,
  name: 'Assembling',
  days: 5
}, {
  id: 1,
  name: 'Ready to ship',
  days: 3
}, {
  id: 2,
  name: 'Installation',
  days: 3
}, {
  id: 3,
  name: 'Training',
  days: 6
}, {
  id: 4,
  name: 'Rating and Review',
  days: 2
}];

const OrderTimeline = () => {
  const [lastCompletedStage, setLastCompletedStage] = useState(3);

  const handleStageCompletion = (stageId) => {
    setLastCompletedStage(stageId)
  };

  const stagesWithHrs = [];
  for (let i = 0; i < STAGES.length - 1; i++) {
    stagesWithHrs.push(STAGES[i]);
    stagesWithHrs.push({
      type: 'HR_RULE'
    });
  }
  stagesWithHrs.push(STAGES[STAGES.length - 1]);

  return (
    <div style={{
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '15px 10px'
    }}>
      {
        stagesWithHrs.map((stage) => (
          stage.type === 'HR_RULE'
          ? <hr style={{width: 30, margin: '0 10px'}}/>
          : (
            <div style={{
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleStageCompletion(stage.id)}
            >
              <div>
                {stage.id <= lastCompletedStage ? <CheckCircleIcon style={{color: '#FDB67B'}}/> : <AccessTimeIcon />}
              </div>
              <div>
                {stage.name}
              </div>
              <div>
                {`${stage.days} Days`}
              </div>
            </div>
          )
        ))
      }
    </div>
  )

  // return (
  //   <Timeline style={{display: 'flex', flexDirection: 'row'}}>
  //     {stages.map((stage, index) => (
  //       <TimelineItem key={index} style={{ display: 'flex',flexDirection: 'column' }}>
  //         <TimelineSeparator >
  //           <TimelineDot color={completedStages[index] ? 'success' : 'primary'}>
  //             {completedStages[index] ? <CheckCircleIcon /> : <AccessTimeIcon />}
  //           </TimelineDot>
  //           {index < stages.length - 1 && <TimelineConnector />}
  //         </TimelineSeparator>
  //         <TimelineContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  //           <Tooltip title={completedStages[index] ? 'Stage Completed' : 'Mark as Completed'} arrow>
  //             <IconButton onClick={() => handleStageCompletion(index)}>
  //               {completedStages[index] ? <CheckCircleIcon /> : <AccessTimeIcon />}
  //             </IconButton>
  //           </Tooltip>
  //           <div>
  //             <div>{stage}</div>
  //             <div>{`${days[index]} days`}</div>
  //           </div>
  //         </TimelineContent>
  //       </TimelineItem>
  //     ))}
  //   </Timeline>
  // );
};

export default OrderTimeline;
