import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Grid, Typography, Button, Avatar, OutlinedInput, InputAdornment, IconButton, Paper, Stack, Menu, MenuItem, Dialog, DialogTitle, DialogContent, TextField, CircularProgress, Divider, Modal } from '@mui/material';
import { IoSearchOutline } from "react-icons/io5";
import { BsFillFilterCircleFill } from "react-icons/bs";
import { IoIosArrowDropright } from "react-icons/io";
import { IoMdSend } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import Snackbar from '@mui/material/Snackbar';
import { RxCross1 } from "react-icons/rx";
import NavigationBar from '../components/NavigationBar';
import { RiArrowDropDownFill } from "react-icons/ri";
import { IoMdArrowDropup } from "react-icons/io";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Task from '../components/Task';
import UserFiles from '../components/UserFiles';
import UploadFile from '../components/UploadFile';
import UploadVideo from '../components/UploadVideo';
import { Suspense } from 'react';
import { FaChevronRight } from "react-icons/fa";
import ShowAllMedia from '../components/ShowAllMedia';
import { useSearchParams } from 'react-router-dom';
import { LuClock3 } from "react-icons/lu";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SnackbarContent from '@mui/material/SnackbarContent';
import MessageIcon from '@mui/icons-material/Message';
import { MdDelete } from "react-icons/md";
import { FaFile } from 'react-icons/fa';
import {formatTimeTo12HR, formatDateToMMDDYY, formatDateLong, formatTimeLong} from '../utils/helpers'
import moment from 'moment';
import { getUserChats, listAllAgroUsers, sendMessage, unreadMessages, markMessageRead,deleteATask, getAllTasks, getFarmDetails, getAllMediaMessages } from '../apis/agro-chat/api';


const UsersCard = ({ user, onUserClick, selectedPhone, userChat }) => {

    return (
        <>

            {
                user && user.map((item, index) => (

                    <Button
                        key={index}
                        sx={{
                            borderRadius: 3,
                            marginTop: 2,
                            width: '70%',
                            marginRight: 2,
                            color: 'black',
                            backgroundColor: selectedPhone === item.phone ? '#cfd8dc' : 'white',
                            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
                            padding: '10px 20px',
                        }}
                        onClick={() => onUserClick(item.phone, item.id, item.user_id)}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar>{item.fullname[0]}</Avatar>
                                <div style={{ marginLeft: 20, textAlign: 'left' }}>
                                    <Typography variant="subtitle2">{item.fullname}</Typography>
                                    <Typography variant="caption">+{item.country_code}{item.phone}</Typography>
                                    <div>
                                        {/*                                        
                                        {
                                            item.remainingHours == null  ? (
                                                <Typography variant="caption" style={{ color: 'red', fontSize: 12 }}>Chat has expired</Typography>
                                            ) : (
                                                <Typography variant="caption" style={{ color: 'red' }}>{item.remainingHours}hrs</Typography>
                                            )
                                        } */}
                                    </div>

                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{ fontWeight: 600 }} variant="caption">
                                    {formatTimeTo12HR(item.updated_at)}
                                </Typography>

                                {
                                    item.unread_messages > 0 ? (
                                        <div style={{
                                            backgroundColor: 'green',
                                            color: 'white',
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontWeight: 'bold',
                                        }}>
                                            {item.unread_messages}
                                        </div>
                                    ) : null
                                }

                            </div>
                        </div>
                    </Button>
                ))
            }
        </>
    );
};

const AgroChat = () => {

    const [showInfo, setShowInfo] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOpenTask, setIsOpenTask] = useState(false);
    const [isOpenImages, setIsOpenImages] = useState(false);
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [isOpenFiles, setIsOpenFiles] = useState(false);
    const [userList, setUserList] = useState([]);
    const [unreadMSG, setUnreadMSG] = useState(0);
    const [selectedPhone, setSelectedPhone] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userID, setUserID] = useState(null);
    const [userChat, setUserChat] = useState({})
    const [message, setMessage] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [farmDetails, setFarmDetails] = useState({});
    const [showFarmDetailsDropdown, setShowFarmDetailsDropdown] = useState(true);
    const [agroDataLocalStorage, setAgroDataLocalStorage] = useState({});
    const [openImageSlider, setOpenImageSlider] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState("");
    const [mediaMessages, setMediaMessages] = useState([]);
    const [showAllMedia, setShowAllMedia] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [showUnreadUsers, setShowUnreadUsers] = useState(false);
    const [filterButtonClicked, setFilterButtonClicked] = useState(false);
    const [listTask, setListTask] = useState([]);
    const messageDivRef = useRef(null);
    const params = useSearchParams();
    const [userScroll, setUserScroll] = useState(false);
    const [lastChatID, setlastChatID] = useState(null);
    const [showMessageBadge, setShowMessageBadge] = useState(false);
    const [mediaAllMessages, setMediaAllMessages] = useState([]);
    const [deleteTask, setDeleteTask] = useState(false);
    const [taskIdToDelete, setTaskIdToDelete] = useState(null);
    const [chatIdToDelete, setChatIdToDelete] = useState(null);


    const remainingHours = null;

    const paramsData = params[0].get('unread') === "true" ? true : false;

    const getAgroData = useCallback(() => {
        const agroData = localStorage.getItem('userData');
        if (agroData) {
            setAgroDataLocalStorage(JSON.parse(agroData));
        }
    }, [])

    const agroID = agroDataLocalStorage?.id;

    useEffect(() => {
        getAgroData();
    }, [getAgroData]);

    const handleFarmDetailsToggle = () => {
        setShowFarmDetailsDropdown(!showFarmDetailsDropdown);
    };

    let prevDate = null;

    const open = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpenTask = () => {
        setIsOpenTask(!isOpenTask);
    };
    const handleOpenImages = () => {
        setIsOpenImages(!isOpenImages);
    }
    const handleOpenVideo = () => {
        setIsOpenVideo(!isOpenVideo);
    }
    const handleOpenFiles = () => {
        setIsOpenFiles(!isOpenFiles);
    }
    const handleShowUserInfo = () => {
        setShowInfo(true);
    }
    const handleClostUserInfo = () => {
        setShowInfo(false);
    }
    const handleSaveFiles = (files) => {
        setUploadedFiles([...uploadedFiles, ...files]);
    };
    const handleOpenMedia = () => {
        setShowAllMedia(!showAllMedia);
    }
    const fetchUserList = useCallback(async () => {

        if (!agroID) {
            return;
        }

        try {

            const response = await listAllAgroUsers(agroID);
            const data = await response.json();

            setUserList(data.data.data.map((item) => {


                let remainingHours = null;
                const lastMessageTime = new Date(item.updated_at);
                const currentTime = new Date();
                const timeDifferenceHours = (currentTime - lastMessageTime) / (1000 * 60 * 60);

                if (timeDifferenceHours < 24) {
                    remainingHours = Math.floor(24 - timeDifferenceHours);
                }

                return {
                    ...item,
                    remainingHours,
                };

            }))

        } catch (error) {
            console.log("ERROR IN FETCHING USER LIST", error);
        }
    }, [agroID])

    const getUnreadMessages = useCallback(async () => {

        if (!agroID) {

            return;
        }

        const response = await unreadMessages(agroID);
        const data = await response.json();

        setUnreadMSG(data.data.data.unread_chat_count)


    }, [agroID]);

    const fetchUserChats = useCallback(async (phone, id) => {

        try {
            const response = await getUserChats(id, phone);
            const data = await response.json();

            if (data.data.data.chat.length > 0) {

                const newLastChatID = (data.data.data.chat[data.data.data.chat.length - 1].id);
                setlastChatID(newLastChatID);

            }

            setUserChat(data.data.data)
        } catch (err) {
            console.log("Error in fetching user chat", err);

        }
    }, [])
    const imageChat = userChat && userChat.chat && userChat.chat.filter(chat => chat.message_type === 'image');

    const handleImageClick = (url) => {
        setOpenImageSlider(true);
        setCurrentImageIndex(imageChat.findIndex(chat => chat.url === url));
    };

    const handleCloseImageSlider = () => {
        setOpenImageSlider(false);
    };

    const handlePrevImage = () => {

        setCurrentImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));

    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => Math.min(prevIndex + 1, imageChat.length - 1));
    };

    const sendReply = useCallback(async (phone, id) => {

        if (!agroID) {
            return;
        }

        setUserScroll(false);

        try {
            const response = await sendMessage(phone, id, agroID, message);
            if (response.ok) {
                setMessage("");
                fetchUserChats(phone, id);
            }

        } catch (error) {
            console.error("Error in sendReply:", error);
        }
    }, [message, fetchUserChats, agroID]);

    const markAsRead = useCallback(async (phone, id) => {

        try {

            const response = await markMessageRead(id, phone);
            if (response.ok) {
                fetchUserList();
                getUnreadMessages();
            }

        } catch (error) {
            console.error("Error in sendReply:", error);
        }
    }, [fetchUserList, getUnreadMessages]);

    const getUserFarmDetails = useCallback(async (id) => {

        try {
            const response = await getFarmDetails(id);
            const data = await response.json();
            setFarmDetails(data.data.farm_details);

        } catch (error) {
            console.error("Error in Farm Details:", error);
        }
    }, []);

    const getMediaMessages = useCallback(async (limit) => {
        try {

            const response = await getAllMediaMessages(userID, selectedPhone, limit);
            const data = await response.json();
            return data?.data?.media_messages || [];
        } catch (error) {
            console.error("Error in media msg", error);

            return [];
        }
    }, [selectedPhone, userID]);

    const getMediaMessage = useCallback(async () => {

        const mediaMessages = await getMediaMessages(3);
        setMediaMessages(mediaMessages);

    }, [getMediaMessages])

    const getAllMediaMessage = useCallback(async () => {

        const mediaMessages = await getMediaMessages(-1);
        setMediaAllMessages(mediaMessages);

    }, [getMediaMessages])

    const getTasks = useCallback(async (id) => {

        try {
            const response = await getAllTasks(id);
            const data = await response.json();
            setListTask(data.data.tasks);

        } catch (error) {
            console.error("Error in media msg", error);
        }
    }, [])

    const Loading = () => {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    useEffect(() => {
        fetchUserList();
        getUnreadMessages();

    }, [fetchUserList, getUnreadMessages]);

    useEffect(() => {
        if (selectedPhone && userID) {
            fetchUserChats(selectedPhone, userID);
            getMediaMessage();
            getUserFarmDetails(selectedUser);
            getTasks(userID)
        }
    }, [selectedPhone, userID, fetchUserChats, getMediaMessage, getUserFarmDetails, getTasks, selectedUser]);

    const pollForNewMessages = useCallback(() => {
        if (selectedPhone && userID) {

            fetchUserChats(selectedPhone, userID);
            getUnreadMessages();
            getUserFarmDetails(selectedUser);
        }
        fetchUserList();
    }, [selectedPhone, userID, fetchUserChats, fetchUserList, getUnreadMessages, selectedUser, getUserFarmDetails]);

    useEffect(() => {

        const intervalId = setInterval(pollForNewMessages, 3000);
        return () => clearInterval(intervalId);

    }, [pollForNewMessages]);

    useEffect(() => {

        function updateScrollPosition() {
            const isScrollAtBottom = Math.abs(messageDivRef.current.scrollTop - messageDivRef.current.scrollHeight + messageDivRef.current.offsetHeight) < 2;
            if (isScrollAtBottom && userScroll) {
                setUserScroll(false);
            }
            if (!isScrollAtBottom && !userScroll) {
                setUserScroll(true);
            }
        }

        if (messageDivRef && messageDivRef.current) {
            updateScrollPosition();
        }
        if (messageDivRef.current && !userScroll) {
            messageDivRef.current.scrollTop = messageDivRef.current.scrollHeight;
        }

    }, [lastChatID, userChat, userScroll]);

    useEffect(() => {
        function updateScrollPosition() {
            const currentRef = messageDivRef.current;
            if (currentRef) {
                const isScrollAtBottom = Math.abs(currentRef.scrollTop - currentRef.scrollHeight + currentRef.offsetHeight) < 2;

                if (isScrollAtBottom && userScroll) {
                    setUserScroll(false);
                }


                if (!isScrollAtBottom && !userScroll) {
                    setUserScroll(true);
                }
            }
        }

        if (messageDivRef && messageDivRef.current) {
            const currentRef = messageDivRef.current;
            currentRef.addEventListener("scroll", updateScrollPosition, false);
            return function cleanup() {
                currentRef.removeEventListener("scroll", updateScrollPosition, false);
            };
        }
    }, [userScroll, lastChatID, userChat]);

    const handleSearchInputChange = event => {
        setSearchQuery(event.target.value);
    };

    const filterUsers = () => {
        let filteredUsers = userList;

        if (searchQuery) {
            filteredUsers = filteredUsers.filter(user => user.fullname.toLowerCase().includes(searchQuery.toLowerCase()));
        }

        if (filterButtonClicked && showUnreadUsers) {
            filteredUsers = filteredUsers.filter(user => user.unread_messages > 0);
        }

        return filteredUsers;
    };

    const handleToggleUnreadUsers = () => {
        setShowUnreadUsers(prevState => !prevState);
        setFilterButtonClicked(true);
    };
    function getOrdinalSuffix(day) {
        if (day === 1 || day === 21 || day === 31) return "st";
        if (day === 2 || day === 22) return "nd";
        if (day === 3 || day === 23) return "rd";
        return "th";
    }
    
    useEffect(() => {
        if (paramsData && paramsData) {
            setShowUnreadUsers(prevState => !prevState);
            setFilterButtonClicked(true);
        }
    }, [paramsData]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowMessageBadge(false);
    }

    const handleOpenDelete = (taskId, chatId) => {
        setTaskIdToDelete(taskId);
        setChatIdToDelete(chatId);
        setDeleteTask(true);
    }

    const handleCloseDelete = () => {
        setDeleteTask(false);
    }

    const handleDelete = useCallback(async (taskID, chatID) => {

        try {
            const response = await deleteATask(taskID, chatID);
            const data = await response.json();
            if (Object.keys(data.data).length === 0) {
                getTasks(chatID);
                handleCloseDelete();
            }
            console.log(data)
        } catch (error) {
            console.error("Error in Farm Details:", error);
        }
    }, [getTasks]);


    return (
        <>

            <div style={{ backgroundColor: "#F7F6F0", height: 'auto' }}>

                <NavigationBar unreadMSG={unreadMSG} />

                <div style={{ overflowY: 'hidden', height: '90vh', display: 'flex', flexDirection: 'column', marginTop: 85, marginLeft: 50 }}>

                    <Grid container style={{ marginTop: 0, overflowY: 'hidden', flexGrow: 1, paddingTop: 25 }} >
                        <Grid item xs={12} sm={showInfo ? 3 : 4} style={{ textAlign: 'center', overflowY: 'auto', height: '100%' }}>
                            <div style={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: '#F7F6F0' }}>
                                <OutlinedInput
                                    style={{ backgroundColor: '#D9D9D9', borderRadius: '40px', width: '85%' }}
                                    id="outlined-adornment-search"

                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IoSearchOutline />
                                        </InputAdornment>
                                    }
                                    color='success'
                                    placeholder='Search'
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '72%', alignItems: 'center', marginLeft: showInfo ? 50 : 67, marginTop: 15 }}>
                                    <Typography style={{ fontWeight: 'bold' }}>Conversations</Typography>
                                    <IconButton onClick={handleToggleUnreadUsers} style={{ color: showUnreadUsers ? '#5F9C6A' : 'inherit' }}>
                                        <BsFillFilterCircleFill />
                                    </IconButton>
                                </div>
                            </div>
                            <div style={{ marginTop: 15, zIndex: 1 }}>
                                <UsersCard
                                    user={filterUsers()}
                                    onUserClick={
                                        (phone, id, user_id) => {
                                            setSelectedPhone(phone);
                                            setUserID(id);
                                            setSelectedUser(user_id);
                                            setUserScroll(false);

                                        }}
                                    userChat={userChat}
                                    selectedPhone={selectedPhone}
                                    remainingHours={remainingHours}
                                />
                            </div>
                        </Grid>


                        <Grid item xs={12} sm={showInfo ? 5 : 7} style={{
                            backgroundColor: '#D9D3D3',
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            overflow: 'hidden',
                            height: '100%',
                            marginLeft: 25,
                            width: '100%',
                        }}>
                            {selectedPhone && userID ? (
                                <>
                                    <div style={{
                                        display: 'flex',
                                        padding: 20,
                                        justifyContent: 'space-between',
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 2,
                                        backgroundColor: '#D9D3D3',
                                    }}>
                                        <div style={{ display: 'flex' }}>
                                            <Avatar>
                                                {userChat && userChat.fullname && userChat.fullname.length > 0
                                                    ? userChat.fullname[0]
                                                    : ''}
                                            </Avatar>
                                            <div style={{ marginLeft: 20, textAlign: 'left' }}>
                                                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{userChat.fullname}</Typography>
                                                <Typography variant="caption" style={{ fontWeight: 'bold' }}> +{userChat.country_code}{userChat.phone}</Typography>
                                                <div>
                                                    <Typography variant="caption" style={{ color: 'red', fontWeight: 'bold' }}>{userChat.remaning_time}</Typography>
                                                </div>

                                            </div>

                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>

                                            {
                                                userChat.unread_messages > 0 ? (
                                                    <div style={{ marginTop: 5, marginRight: 10 }}>
                                                        <Button style={{ backgroundColor: '#5F9C6A', color: 'black' }} onClick={
                                                            () => {
                                                                markAsRead(selectedPhone, userID);
                                                            }
                                                        } >Read</Button>
                                                    </div>) : null
                                            }


                                            {showInfo ? null : (
                                                <div style={{ marginRight: 10 }}>
                                                    <IconButton onClick={handleShowUserInfo} style={{ backgroundColor: "#ECF1F1" }}>
                                                        <IoIosArrowDropright style={{ color: "black" }} />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <hr style={{ borderColor: '#F7F6F0', borderWidth: '2px', zIndex: 1, position: 'sticky', top: 64 }} />

                                    <div ref={messageDivRef} style={{ position: 'relative', height: 'calc(100% - 128px)', overflowY: 'auto', padding: 20, paddingBottom: 115 }}>
                                        {userChat && userChat.chat && userChat.chat.map((chat, index) => {

                                            const currentDate = moment(chat.created_at).format('MMM D, YYYY');
                                            const showDate = prevDate !== currentDate;
                                            prevDate = currentDate;

                                            return (
                                                <React.Fragment key={index}>
                                                    {showDate && (
                                                        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                                            <div style={{ backgroundColor: '#EFEDED', padding: '5px 10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                                                <Typography style={{ color: '#333', fontWeight: 'bold', fontFamily: 'sen' }}>
                                                                    {currentDate}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Stack direction="row" justifyContent={chat.role === "user" ? "start" : "end"} alignItems="center" key={index}>
                                                        <div style={{ padding: 12, backgroundColor: chat.role === "user" ? "#F4F7FB" : "#E7F2E7", color: "#285428", borderRadius: 8, width: "50%", marginBottom: 15, maxWidth: '100%' }}>
                                                            {
                                                                (chat && chat.message_type === "text") ? (
                                                                    <Typography variant="body2" style={{ color: "#285428" }}>
                                                                        {chat.message.split('\n').map((line, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {line}
                                                                                <br />
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </Typography>
                                                                ) : (chat && chat.message_type === "video") ? (
                                                                    <>
                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'start' }}>
                                                                            <video controls style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '8px', cursor: 'pointer' }}>
                                                                                <source src={chat.url} type="video/mp4" />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        </div>
                                                                        <div style={{ marginLeft: 8, marginTop: 10 }}>
                                                                            <Typography variant='caption' style={{ color: '#7E7E7E', fontWeight: 'bold', fontSize: 15 }}>{chat.message}</Typography>
                                                                        </div>
                                                                    </>
                                                                ) : (chat && chat.message_type === "file") ? (
                                                                    <>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: '2px solid #D9D3D3', borderRadius: 5, padding: 10 }}>
                                                                            <FaFile style={{ marginRight: 5, color: "#D9D9D9" }} />
                                                                            <a href={chat.url} download target="_blank" rel="noreferrer noopener" style={{ color: 'black', textDecoration: 'none', fontWeight: 'bold', cursor: 'pointer' }}>{(chat && chat.message) || "PDF File"}</a>
                                                                            {/* <FaFileDownload style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => handleDownloadClick(chat.url)} /> */}
                                                                        </div>
                                                                        <div style={{ marginLeft: 8, marginTop: 10 }}>
                                                                            <Typography variant='caption' style={{ color: '#7E7E7E', fontWeight: 'bold', fontSize: 15 }}>{chat.message}</Typography>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <Suspense fallback={<Loading />}>
                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                            <img style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '8px', cursor: 'pointer' }} src={chat.url} alt="user_img" onClick={() => handleImageClick(chat.url)} />
                                                                            <div style={{ marginLeft: 8, marginTop: 10 }}>
                                                                                <Typography variant='caption' style={{ color: '#7E7E7E', fontWeight: 'bold', fontSize: 15 }}>{chat.message}</Typography>
                                                                            </div>
                                                                        </div>
                                                                    </Suspense>
                                                                )
                                                            }

                                                            <div style={{ textAlign: 'right' }}>
                                                                <Typography variant="caption" style={{ color: '#7E7E7E' }}>{formatTimeTo12HR(chat.created_at)}</Typography>
                                                            </div>
                                                        </div>
                                                    </Stack>

                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                    {
                                        (userChat && userChat.disable ? (
                                            <div style={{
                                                display: 'flex',
                                                padding: 25,
                                                position: 'sticky',
                                                bottom: 0,
                                                zIndex: 2,
                                                backgroundColor: '#D9D3D3',
                                            }}>


                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                >
                                                    <MenuItem onClick={handleOpenImages} divider style={{ backgroundColor: '#F7F2F2' }}>Images</MenuItem>
                                                    {/* <MenuItem onClick={handleClose} divider style={{ backgroundColor: '#F7F2F2' }}>Doc</MenuItem> */}
                                                    <MenuItem onClick={handleOpenTask} style={{ backgroundColor: '#F7F2F2' }}>Task</MenuItem>
                                                </Menu>
                                                <IconButton onClick={handleOpenMenu} disabled style={{ marginRight: 5 }}>+</IconButton>

                                                <TextField
                                                    style={{ width: '100%', marginTop: 8 }}
                                                    startAdornment={<IconButton onClick={handleOpenMenu}><InputAdornment position="start"><IoMdAdd /></InputAdornment></IconButton>}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    placeholder='This chat is inactive'
                                                    disabled
                                                    color='success'
                                                    InputProps={{
                                                        style: { borderRadius: '46px', backgroundColor: "#F3ECEC", color: 'red' },
                                                    }}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    value={message}
                                                    onKeyPress={(e) => e.key === 'Enter' && sendReply(selectedPhone, userID)}
                                                />
                                                <IconButton disabled onClick={() => {
                                                    sendReply(selectedPhone, userID);
                                                }} style={{ backgroundColor: 'gray', color: 'white', height: '20%', marginLeft: 8, marginTop: 15 }}>
                                                    <IoMdSend style={{ marginLeft: 4 }} />
                                                </IconButton>
                                            </div>

                                        ) : (
                                                    <div style={{
                                                        display: 'flex',
                                                        padding: 25,
                                                        position: 'sticky',
                                                        bottom: 0,
                                                        zIndex: 2,
                                                        backgroundColor: '#D9D3D3',
                                                    }}>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                        >
                                                            <MenuItem onClick={handleOpenImages} divider style={{ backgroundColor: '#F7F2F2' }}>Images</MenuItem>
                                                            <MenuItem onClick={handleOpenTask} divider style={{ backgroundColor: '#F7F2F2' }}>Task</MenuItem>
                                                            <MenuItem divider onClick={handleOpenFiles} style={{ backgroundColor: '#F7F2F2' }}>Files</MenuItem>
                                                            <MenuItem divider onClick={handleOpenVideo} style={{ backgroundColor: '#F7F2F2' }}>Videos</MenuItem>
                                                        </Menu>
                                                        <IconButton onClick={handleOpenMenu} style={{ marginRight: 5 }}>+</IconButton>

                                                        <TextField
                                                            style={{ width: '100%', marginTop: 8 }}
                                                            // endAdornment={<IconButton><MdKeyboardVoice postion="end"></MdKeyboardVoice></IconButton>}
                                                            startAdornment={<IconButton onClick={handleOpenMenu}><InputAdornment position="start"><IoMdAdd /></InputAdornment></IconButton>}
                                                            aria-describedby="outlined-weight-helper-text"
                                                            placeholder='Type a message'
                                                            color='success'
                                                            InputProps={{
                                                                style: { borderRadius: '46px', backgroundColor: "#F3ECEC" }
                                                            }}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                            value={message}
                                                            onKeyPress={(e) => e.key === 'Enter' && sendReply(selectedPhone, userID)}
                                                        />
                                                        <IconButton onClick={() => {
                                                            sendReply(selectedPhone, userID);
                                                        }} style={{ backgroundColor: '#5F9C6A', color: 'white', height: '20%', marginLeft: 8, marginTop: 15 }}>
                                                            <IoMdSend style={{ marginLeft: 4 }} />
                                                        </IconButton>
                                                    </div>
                                                )
                                        )
                                    }
                                </>
                            ) : (
                                <div style={{ padding: 20, textAlign: 'center', fontFamily: 'sen' }}>
                                    Please select a user from the left to view chat.
                                </div>
                            )}
                        </Grid>


                        {/* user info */}
                        {
                            selectedPhone && userID && showInfo ?
                                (
                                    <Grid item xs={12} sm={3} style={{
                                        textAlign: 'center', overflowY: 'auto', height: '100%', backgroundColor: '#D9D9D9', border: '2px solid', borderColor: "#E5E5E5", borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10, marginLeft: 70
                                    }} >
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '20px',
                                            backgroundColor: '#F8F5F5',
                                            position: 'sticky', top: 0, zIndex: 2,
                                        }}>
                                            <Typography >Contact Info</Typography>
                                            <IconButton onClick={handleClostUserInfo} >
                                                <RxCross1 style={{ color: "black" }} />
                                            </IconButton>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            padding: 20,
                                            justifyContent: 'space-between',
                                            backgroundColor: '#D9D9D9',
                                        }}>
                                            <div style={{ display: 'flex' }}>
                                                <Avatar>    {userChat && userChat.fullname && userChat.fullname.length > 0
                                                    ? userChat.fullname[0]
                                                    : ''}</Avatar>
                                                <div style={{ marginLeft: 20, textAlign: 'left', fontSize: 20 }}>
                                                    <Typography style={{ fontWeight: 'bolder' }}>{userChat.fullname}</Typography>
                                                    <Typography variant="caption" style={{ fontWeight: 'bolder' }}>+{userChat.country_code}{userChat.phone}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{ borderColor: '#F7F6F0', borderWidth: '3px' }} />
                                        <div style={{ textAlign: 'left', marginLeft: 20, margin: 25 }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography style={{ color: '#7E7E7E', fontWeight: 'bold' }}>Farm Details</Typography>

                                                <IconButton onClick={handleFarmDetailsToggle} style={{ marginLeft: 5 }}>
                                                    {
                                                        showFarmDetailsDropdown ? <IoMdArrowDropup /> : <RiArrowDropDownFill />
                                                    }
                                                </IconButton>
                                            </div>


                                            {
                                                Object.keys(farmDetails).length > 0 && showFarmDetailsDropdown ? (
                                                    <div>
                                                        <div>
                                                            <Typography style={{ marginBottom: '5px', fontWeight: 'bold', color: '#594633' }}>
                                                                Size
                                                            </Typography>
                                                            <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                                                                {farmDetails?.farm?.size} {farmDetails?.farm?.size_unit}
                                                            </Typography>
                                                        </div>

                                                        <div>
                                                            <Typography style={{ marginBottom: '5px', marginTop: '5px', fontWeight: 'bold', color: '#594633' }}>
                                                                Location
                                                            </Typography>
                                                            <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                                                                {farmDetails?.location?.address}

                                                            </Typography>
                                                        </div>

                                                        <div>
                                                            <Typography style={{ marginBottom: '5px', marginTop: '5px', fontWeight: 'bold', color: '#594633' }}>
                                                                Crops
                                                            </Typography>
                                                            <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                                                                {farmDetails && farmDetails.preferred_crops && farmDetails.preferred_crops.map((crop) => crop.name).join(', ')}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>


                                        <hr style={{ borderColor: '#F7F6F0', borderWidth: '3px' }} />
                                        <div style={{ textAlign: 'left', marginLeft: 20, margin: 25 }}>
                                            <Typography style={{ color: '#7E7E7E', fontWeight: 'bold' }}>Media,link and docs</Typography>
                                            {
                                                mediaMessages && mediaMessages.length > 0 ? (

                                                    <div style={{ display: 'flex', marginTop: 15, alignItems: 'center' }}>
                                                        {
                                                            mediaMessages && mediaMessages.map((media, index) => (
                                                                <div key={index} style={{ display: 'flex', flexDirection: 'column', marginRight: 20, justifyContent: 'center' }}>
                                                                    <img style={{ maxWidth: '100%', maxHeight: '100px', borderRadius: '8px', cursor: 'pointer', border: '2px solid gray' }} src={media.url} alt="user_img" onClick={() => handleImageClick(media.url)} />

                                                                </div>
                                                            ))
                                                        }
                                                        <IconButton onClick={handleOpenMedia}>
                                                            <FaChevronRight />
                                                        </IconButton>

                                                    </div>) : (
                                                    <div style={{ fontFamily: 'sen' }}>
                                                        No media messages for this user
                                                    </div>

                                                )
                                            }
                                        </div>

                                        <hr style={{ borderColor: '#F7F6F0', borderWidth: '3px' }} />
                                        <div style={{ textAlign: 'left', marginLeft: 20, margin: 25 }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography style={{ color: '#7E7E7E', fontWeight: 'bold', marginTop: 5 }}>Tasks Assigned</Typography>
                                                {
                                                    !(
                                                        (userChat &&
                                                            userChat.chat &&
                                                            userChat.chat.length > 0 &&
                                                            userChat.chat.filter(item => item.role === "user").length === 0) ||
                                                        (userChat &&
                                                            userChat.chat &&
                                                            userChat.chat.length > 0 &&
                                                            userChat.chat.some(chat => chat.role === "user") &&
                                                            (new Date() - new Date(userChat.chat[userChat.chat.length - 1].created_at)) / (1000 * 60 * 60) >= 24)
                                                    ) && (
                                                        <Button onClick={handleOpenTask} color='success'>+ Add Task</Button>
                                                    )
                                                }
                                            </div>
                                            <div style={{ marginTop: 5 }}>

                                                {
                                                    listTask && listTask.length > 0 ? (

                                                        <div style={{ marginTop: 10, padding: 10 }}>
                                                            {
                                                                listTask &&
                                                                Object.entries(
                                                                    listTask.reduce((acc, task) => {
                                                                        if (!acc[task.date]) {
                                                                            acc[task.date] = [];
                                                                        }
                                                                        acc[task.date].push(task);
                                                                        return acc;
                                                                    }, {})
                                                                ).map(([date, tasksForDate]) => (
                                                                    <div key={date}>
                                                                        <Typography style={{ fontFamily: 'sen', color: '#666666', marginTop: 5, marginLeft: 5 }}>
                                                                            {formatDateLong(date)}
                                                                        </Typography>
                                                                        {
                                                                            tasksForDate.map((task, index) => (
                                                                                <Accordion key={task.id} style={{ marginTop: 10 }} >

                                                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={{ backgroundColor: '#E7F2E7' }}>

                                                                                        <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%' }}>
                                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                                <Typography style={{ fontFamily: 'sen', fontSize: 20 }} variant='h6'>{task.name}</Typography>

                                                                                            </div>
                                                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}>
                                                                                                <LuClock3 style={{ color: 'green', marginRight: 5 }} />
                                                                                                {
                                                                                                    task.is_repeat ? (
                                                                                                        <>
                                                                                                            <Typography style={{ fontFamily: 'sen' }} variant='caption'>{formatDateLong(task.start_date)}</Typography>-
                                                                                                            <Typography style={{ fontFamily: 'sen' }} variant='caption'>{formatDateLong(task.end_date)}</Typography>
                                                                                                        </>
                                                                                                    ) : (

                                                                                                        <Typography style={{ fontFamily: 'sen' }} variant='caption'>{formatDateLong(task.date)}</Typography>
                                                                                                    )
                                                                                                }

                                                                                            </div>
                                                                                        </div>

                                                                                    </AccordionSummary>
                                                                                    <Divider />
                                                                                    <AccordionDetails sx={{ backgroundColor: '#F7F6F0' }}>
                                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }} variant="subtitle1">Description:</Typography>
                                                                                        <Typography style={{ fontFamily: 'sen' }} >{task.description}</Typography>

                                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }} variant="subtitle1">Date:</Typography>
                                                                                        <Typography style={{ fontFamily: 'sen' }} >{new Date(task.date).toDateString()}</Typography>

                                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }} variant="subtitle1">Time:</Typography>
                                                                                        <Typography style={{ fontFamily: 'sen' }} >{formatTimeLong(task.time)}</Typography>

                                                                                        {
                                                                                            task.is_repeat ? (
                                                                                                <>
                                                                                                    <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }} variant="subtitle1">Start Date</Typography>
                                                                                                    <Typography style={{ fontFamily: 'sen' }}>{formatDateLong(task.start_date)}</Typography>

                                                                                                    <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }} variant="subtitle1">End Date</Typography>
                                                                                                    <Typography style={{ fontFamily: 'sen' }}>{formatDateLong(task.end_date)}</Typography>
                                                                                                </>
                                                                                            ) : null
                                                                                        }

                                                                                        <div>
                                                                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }} variant="subtitle1">Repeat</Typography>
                                                                                            {
                                                                                                task.is_repeat ? (
                                                                                                    task.repeat_every === "Weekly" || task.repeat_every === "week" ? (
                                                                                                        <Typography style={{ fontFamily: 'sen' }}>
                                                                                                            {`Repeat on ${task.week_days.join(", ")}s of every week`}
                                                                                                        </Typography>
                                                                                                    ) : task.repeat_every === "Monthly" || task.repeat_every === "month" ? (
                                                                                                        <Typography style={{ fontFamily: 'sen' }}>
                                                                                                            {`On ${task.day_of_month}${getOrdinalSuffix(task.day_of_month)} of every month`}
                                                                                                        </Typography>
                                                                                                    ) : task.repeat_every === "Daily" || task.repeat_every === "day" ? (
                                                                                                        <Typography style={{ fontFamily: 'sen' }}>
                                                                                                            {`Daily repeat`}
                                                                                                        </Typography>
                                                                                                    ) : null
                                                                                                ) : (
                                                                                                    <Typography style={{ fontFamily: 'sen' }}>Does not repeat</Typography>
                                                                                                )
                                                                                            }
                                                                                        </div>

                                                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                            <IconButton
                                                                                                style={{ backgroundColor: '#D9D3D3' }}
                                                                                                onClick={(event) => { event.stopPropagation(); handleOpenDelete(task.id, task.chat_id); }}>
                                                                                                <MdDelete style={{ color: 'red' }} />
                                                                                            </IconButton>
                                                                                        </div>

                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            ))}
                                                                    </div>
                                                                ))}
                                                        </div>)
                                                        : (

                                                            <div style={{ fontFamily: 'sen' }}>
                                                                No tasks assigned for this user
                                                            </div>
                                                        )
                                                }
                                            </div>

                                        </div>
                                    </Grid>) : (

                                    <Grid item xs={12} sm={3} style={{
                                        textAlign: 'center', overflowY: 'auto', height: '100%', backgroundColor: '#D9D9D9', border: '2px solid', borderColor: "#E5E5E5", borderTopLeftRadius: 10,
                                        borderTopRightRadius: 10, marginLeft: 70
                                    }} >
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '20px',
                                            backgroundColor: '#F8F5F5',
                                            position: 'sticky', top: 0, zIndex: 2,
                                        }}>
                                            <Typography >Contact Info</Typography>
                                            <IconButton onClick={handleClostUserInfo} >
                                                <RxCross1 style={{ color: "black" }} />
                                            </IconButton>
                                        </div>
                                        <div >
                                            <Typography style={{ padding: 20, textAlign: 'center', fontFamily: 'sen' }}>Please select a user to view info</Typography>
                                        </div>
                                    </Grid>

                                )
                        }

                    </Grid>
                </div>
            </div >
            {selectedPhone && userID ? (
                <>
                    <Task open={isOpenTask} handleClose={handleOpenTask} userChat={userChat} getTasks={getTasks} />
                    <UserFiles open={isOpenImages} handleClose={handleOpenImages} onSaveFiles={handleSaveFiles} userChat={userChat} fetchUserChats={fetchUserChats} getMediaMessage={getAllMediaMessage} />
                    <ShowAllMedia open={showAllMedia} handleClose={handleOpenMedia} getMediaMessage={getAllMediaMessage} mediaMessages={mediaAllMessages} />
                    <UploadFile open={isOpenFiles} handleClose={handleOpenFiles} userChat={userChat} fetchUserChats={fetchUserChats} />
                    <UploadVideo open={isOpenVideo} handleClose={handleOpenVideo} userChat={userChat} fetchUserChats={fetchUserChats} />
                </>

            ) : null
            }

            <Dialog open={openImageSlider} onClose={handleCloseImageSlider}>
                <DialogTitle style={{ fontSize: 18, fontWeight: 'bold', backgroundColor: '#EFEDED' }}>

                    {
                        imageChat && imageChat.map((chat, index) => (
                            index === currentImageIndex ? (
                                <div key={index}>
                                    <Typography style={{ color: '#7E7E7E', fontWeight: 'bold' }}>{chat.role} on {formatDateToMMDDYY(chat.created_at)}, {formatTimeTo12HR(chat.created_at)}</Typography>
                                </div>
                            ) : null
                        ))
                    }

                </DialogTitle>
                <DialogContent style={{ position: 'relative', width: '600px', height: '400px', backgroundColor: '#D9D3D3' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
                        {imageChat && imageChat[currentImageIndex] && (
                            <img
                                style={{ maxWidth: '100%', maxHeight: '400px', borderRadius: '8px', cursor: 'pointer', marginTop: 20 }}
                                src={imageChat[currentImageIndex].url}
                                alt="user_img"
                            />
                        )}
                        <IconButton style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', backgroundColor: 'gray' }} onClick={handlePrevImage} disabled={imageChat && imageChat.currentImageIndex === 1}>
                            <ChevronLeftIcon style={{ color: 'white' }} />
                        </IconButton>
                        <IconButton style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', backgroundColor: 'gray' }} onClick={handleNextImage} disabled={currentImageIndex === imageChat && imageChat.length - 1}>
                            <ChevronRightIcon style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                </DialogContent>
                <div style={{ textAlign: 'right', backgroundColor: '#EFEDED' }}>
                    <Button style={{ backgroundColor: '#5F9C6A', margin: 7, color: 'white' }} onClick={handleCloseImageSlider}>Done</Button>
                </div>
            </Dialog>

            <Modal open={deleteTask} onClose={handleCloseDelete}>
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', maxWidth: 450 }}>
                    <Paper style={{ padding: 24, backgroundColor: '#D9D3D3' }}>
                        <Typography style={{ fontFamily: 'sen' }}>Are you sure you want to delete this task?</Typography>
                        <div style={{ display: 'flex', marginTop: 20, justifyContent: 'flex-end' }}>
                            <Button variant='contained' onClick={handleCloseDelete} style={{ marginRight: 10, backgroundColor: 'gray' }}>Cancel</Button>
                            <Button variant='contained' color='error' onClick={() =>
                                handleDelete(taskIdToDelete, chatIdToDelete)

                            }>Delete</Button>
                        </div>
                    </Paper>
                </div>

            </Modal>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showMessageBadge}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                style={{ marginTop: '20px', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)' }}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: 'green',
                        display: 'flex',
                        alignItems: 'center',
                        width: 10,
                        height: 'fit-content',
                        padding: '8px',
                        borderRadius: '4px'
                    }}
                    message={
                        <span style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                            <MessageIcon style={{ marginRight: '8px' }} />
                            New Message
                        </span>
                    }
                />
            </Snackbar>
        </>
    );
};

export default AgroChat;