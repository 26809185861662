import React from 'react';
import { Chip } from '@mui/material';


const RepetitionSelector = ({
  selectedDays,
  selectedFrequency,
  setSelectedDays,
  setSelectedFrequency,
  setSelectedDayOfMonth
}) => {
  const frequencies = {
    "day": "day",
    "week": "week",
    "month": "month"
  };

  const daysOfWeek = [
    { smallDay: "M", fullDay: "Monday" },
    { smallDay: "T", fullDay: "Tuesday" },
    { smallDay: "W", fullDay: "Wednesday" },
    { smallDay: "T", fullDay: "Thursday" },
    { smallDay: "F", fullDay: "Friday" },
    { smallDay: "S", fullDay: "Saturday" },
    { smallDay: "S", fullDay: "Sunday" }
  ];

  const handleDayClick = (day) => {
    if (selectedFrequency === "week") {
   
      const updatedSelectedDays = selectedDays.includes(day)
        ? selectedDays.filter(selectedDay => selectedDay !== day)
        : [...selectedDays, day];

      setSelectedDays(updatedSelectedDays);
    } else {
     
      setSelectedDays([day]);
      if (selectedFrequency === "month") {
        setSelectedDayOfMonth(day);
      }
    }
  };
;


  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div style={{
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: 10
      }}>
        {
          Object.keys(frequencies).map((key) => (
            <Chip sx={{
              margin: '0 5px',
              ...(key === selectedFrequency ? {
                backgroundColor: '#5F9C6A',
                color: 'white'
              } : {})
            }} label={key === "day" ? "Daily" : key === "week" ? "Weekly" : "Monthly"}
              variant={key === selectedFrequency ? "filled" : "outlined"}
              onClick={() => {
                setSelectedFrequency(key);
                if (key === "day") {
                  setSelectedDays([]);
                }
              }} />
          ))
        }
      </div>
      {
        selectedFrequency === "week" ? (
          <div style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10
          }}>
            {
              daysOfWeek.map((item) => (
                <Chip
                  key={item.fullDay}
                  sx={{
                    margin: '0 2px',
                    ...(selectedDays.includes(item.fullDay) ? {
                      backgroundColor: '#5F9C6A',
                      color: 'white'
                    } : {})
                  }}
                  label={item.smallDay}
                  onClick={() => handleDayClick(item.fullDay)}
                  variant={selectedDays.includes(item.fullDay) ? "filled" : "outlined"}
                />
              ))
            }
          </div>
        ) : selectedFrequency === "month" ? (
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10
          }}>
            {[...Array(31).keys()].map((day) => (
              <Chip
                key={day + 1}
                sx={{
                  margin: '5px',
                  width: 'calc(14.28% - 10px)',
                  ...(selectedDays.includes(day + 1) ? {
                    backgroundColor: '#5F9C6A',
                    color: 'white'
                  } : {})
                }}
                label={day + 1}
                onClick={() => handleDayClick(day + 1)}
                variant={selectedDays.includes(day + 1) ? "filled" : "outlined"}
              />
            ))}
          </div>
        ) : null
      }
    </div>
  )
}


export default RepetitionSelector;