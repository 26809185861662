import {BEARER_TOKEN} from '../../config'

export const listAllAgroUsers = async (
    agroID
) => {

    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/listAll', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN,
        },
        body: JSON.stringify({
            "agronomist_id": agroID,
        }),
    });

    return response;
}

export const unreadMessages = async (
    agroID
) => {

    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/unreadChats', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN 
        },
        body: JSON.stringify({
            "agronomist_id": agroID,
        }),
    });

    return response;
}

export const getUserChats = async ( 
    userId,
    phone
) => {
    
    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/getChat', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            "id": userId,
            "phone": phone
        }),

    })

    return response;
}

export const sendMessage = async (
    phone,
    id,
    agroID,
    message
) => {

     const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/reply', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
         body: JSON.stringify({

                    "id": id,
                    "phone": phone,
                    "country_code": "91",
                    "message": message,
                    "agronomist_id": agroID,
                    "message_type": "text"

                }),
    });

    return response;
}

export const markMessageRead = async (
    userID,
    phone
) => {
    
    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/markAsRead', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            "id": userID,
            "phone": phone,
        }),
    });

    return response;
}

export const getFarmDetails = async (
    userId,
) => {
    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/getFarmDetails', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            "user_id": userId
        }),
    });

    return response;
}

export const getAllMediaMessages = async (
    userID,
    selectedPhone,
    limit
) => {
    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/getMedia', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            "chat_id": userID,
            "phone": selectedPhone,
            "limit": limit
        }),
    });

    return response;
}

export const getAllTasks = async (
    chatID
) => {

    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/listAllTasks', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            "chat_id": chatID,
        }),
    });

    return response;
}

export const deleteATask = async (
    taskId,
    chatId
) => {

    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/deleteTask', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            task_id: taskId,
            chat_id: chatId
        }),
    });

    return response;
}

export const createATask = async (
    payload
) => {
    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/addTask', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify(payload),
    });

    return response;
}

export const sendAMediaMessage = async (
    payload
) => {
    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/sendMedia', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN
        //   'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
        },
        body: payload,
      });

    return response;
}

export const uplaoadAFile = async (
    payload
) => {
    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/sendFile', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: payload,
    });

    return response;
}

export const uploadAVideo = async (
    payload
) => {
    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/sendVideo', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: payload,
    });

    return response;
}

export const dashboardCounts = async (
    agroId
) => {
    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/getCounts', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            
        },
        body: JSON.stringify({
            "agronomist_id": agroId,
        }),
    })

    return response;
}