import {BEARER_TOKEN} from '../config'

export const getOrgId = async (
    baseUrl,
    userId
) => {

   const response = fetch(`${baseUrl}/api/mgmt/phyfarmUsers/getOrgId`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
        },
        body: JSON.stringify({
            user_id: userId,
        }),
    });

    return response;
}

// get all organisation - feature-mgmt and role-mgmt

export const getAllOrg = async (
    baseURL
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-org/listAll`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
        },
        body: JSON.stringify({
            filters: {}
        })
    });

    return response;
}

