import React from 'react'
// import NavigationBar from './NavigationBar';
import { Typography } from '@mui/material';
import Layout from '../pages/Layout';
import GoogleSheetsEmbed from './GoogleSheet';

const UserStats = () => {
    return (
        <div style={{ backgroundColor: 'white', height: 'auto' }}>
            {/* <NavigationBar /> */}
            <Layout />
            <div style={{ textAlign:'center', marginTop: 30 }}>
                <Typography style={{ fontSize: 20, fontWeight: 'bold', fontFamily: 'sen' }}>User Stats</Typography>
            </div>
            <div style={{ overflowY: 'auto', height: '90vh', display: 'flex', flexDirection: 'column', marginTop: 85, marginLeft: 50 }}>
                <GoogleSheetsEmbed />
            </div>
        </div>

    )
}

export default UserStats