import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Snackbar,
  Alert,
  ThemeProvider,
  createTheme,
  Chip
} from '@mui/material';
import { baseURL } from '../config';
import { createActivity } from '../apis/programs/api';

const theme = createTheme({
  palette: {
    primary: {
      main: '#028476',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#028476',
            },
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#028476',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#028476',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#028476',
          },
        },
      },
    },
  },
});

const CreateActivityModal = ({ open, onClose, programId, orgId, fetchActivities }) => {
  const [activityData, setActivityData] = useState({
    org_id: orgId,
    program_id: programId,
    title: '',
    type: '',
    options: []
  });
  const [openToast, setOpenToast] = useState(false);
  const [newOption, setNewOption] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setActivityData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddOption = () => {
    if (newOption.trim() !== '') {
      setActivityData(prevState => ({
        ...prevState,
        options: [...prevState.options, newOption.trim()]
      }));
      setNewOption('');
    }
  };

  const handleRemoveOption = (optionToRemove) => {
    setActivityData(prevState => ({
      ...prevState,
      options: prevState.options.filter(option => option !== optionToRemove)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createActivity(baseURL, { ...activityData, program_id: programId });
      setOpenToast(true);
      onClose();
      fetchActivities();
      setActivityData({
        org_id: orgId,
        program_id: programId,
        title: '',
        type: '',
        options: []
      });
    } catch (error) {
      console.error('Failed to create activity:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={onClose} maxWidth="sm" PaperProps={{
        style: {
          minWidth: '500px',
          borderRadius: '16px',
          border: "3px dashed #028476",
          padding: "10px"
        },
      }} fullWidth >
        <DialogTitle>Create New Activity</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={activityData.title}
                onChange={handleChange}
                required
                autoComplete="off"
                variant="outlined"
              />
              <FormControl fullWidth required variant="outlined">
                <InputLabel>Type</InputLabel>
                <Select
                  name="type"
                  value={activityData.type}
                  onChange={handleChange}
                  label="Type"
                >
                  <MenuItem value="file">File</MenuItem>
                  <MenuItem value="string">String</MenuItem>
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="bool">Boolean</MenuItem>
                </Select>
              </FormControl>
              {activityData.type === 'string' && (
                <Box>
                  <TextField
                    fullWidth
                    label="Add Option"
                    value={newOption}
                    onChange={(e) => setNewOption(e.target.value)}
                    variant="outlined"
                  />
                  <Button onClick={handleAddOption} variant="contained" color="primary" sx={{ mt: 1 }}>
                    Add Option
                  </Button>
                  <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {activityData.options.map((option, index) => (
                      <Chip
                        key={index}
                        label={option}
                        onDelete={() => handleRemoveOption(option)}
                        color="primary"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              Create Activity
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setOpenToast(false)} severity="success" sx={{ width: '100%' }}>
          Activity created successfully!
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default CreateActivityModal;