
import React, { useEffect, useState, useCallback } from 'react'
import Layout from './Layout'
import { Typography, Button, Grid, Paper } from '@mui/material'
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { clearLocation } from '../reducers/location';
import {baseURL} from '../config';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete from '@mui/material/Autocomplete';
import { RxCross2 } from "react-icons/rx";
import { locationSuccess } from '../reducers/location';
import FarmMap from '../components/FarmMap';
import { clearCoordinate } from '../reducers/coordinates';
import { getOrgId } from '../apis/api';
import { createFarm } from '../apis/farm-mgmt/farm';
import { calculateZoneArea } from '../apis/farm-mgmt/zone';


const RegisterForm = ({ onChange }) => {

    const { fullName, userId } = useParams();
    const [orgId, setOrgId] = useState(null);
    const [isMap, setIsMap] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [farmData, setFarmData] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formattedAddress, setformattedAddress] = useState('');
    const [newArea, setNewArea] = useState(null);
    const locationFromRedux = useSelector(state => state.locationReducer.location);
    const coordinates = useSelector(state => state.coordinateReducer.coordinate);
    const [suggestions, setSuggestions] = useState([]);


    const getEmptyData = useCallback(() => {
        return {
            farm: {
                name: '',
                size: null,
                size_unit: ""
            }
        };
    }, []);

    const handleMapOpen = () => {
        setOpenModal(true);
        setIsMap(true);
    }

    const handleClose = () => {
        setOpenModal(false);
    }

    const fetchSuggestions = (inputValue) => {
        // You should make an actual API request here with the provided inputValue
        fetch(`https://dev.phyfarm.com/api/mgmt/googleapis/autocomplete?input=${inputValue}&sessiontoken=2d204b76-9888-490b-ab9b-ffde09ab80dc&components=country%3AIN`)
            .then((response) => response.json())

            .then((data) => {
                if (data.status === 'OK' && data.predictions) {
                    // Assuming the response has a "predictions" array
                    setSuggestions(data.predictions);

                } else {
                    console.error('No predictions or invalid response:', data);
                }
            })
            .catch((error) => {
                console.error('API request failed:', error);
            });
    };

    const onSelectLocation = useCallback((location) => {

        if (location) {
            setLoading(true);

            fetch(`https://dev.phyfarm.com/api/mgmt/googleapis/getCoordinates?address=${location.replace(/\s+/g, '')}&sessiontoken=5c3b47ad-e9b9-4664-8607-9aa7e11424d2&components=country%3AIN`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'OK' && data.results.length > 0) {
                        const { lat, lng } = data.results[0].geometry.location;

                        fetch(`https://dev.phyfarm.com/api/mgmt/googleapis/getAddress?latlng=${lat}%2C${lng}&sessiontoken=7ab8bd12-4ef4-4eb8-9540-3f65d2794e2a`)
                            .then((response) => response.json())
                            .then((addressData) => {
                                if (addressData.status === 'OK' && addressData.results.length > 0) {


                                    const firstResult = addressData && addressData.results && Array.isArray(addressData.results) && addressData.results.length >= 1
                                        ? addressData.results[0]
                                        : null;

                                    if (firstResult) {
                                        const address = firstResult.formatted_address;
                                        const pincode = firstResult.address_components.find(el => el.types.includes('postal_code'))?.long_name;
                                        const city = firstResult.address_components.find(el => el.types.includes('administrative_area_level_2'))?.long_name;
                                        const state = firstResult.address_components.find(el => el.types.includes('administrative_area_level_1'))?.short_name;
                                        const country = firstResult.address_components.find(el => el.types.includes('country'))?.long_name;
                                        const latitude = firstResult.geometry.location.lat;
                                        const longitude = firstResult.geometry.location.lng;


                                        setformattedAddress(address)
                                        dispatch(locationSuccess({ pincode, city, state, country, latitude, longitude, address }));

                                        if (typeof onChange === 'function') {
                                            onChange({
                                                pincode, city, state, country,
                                                latitude: lat,
                                                longitude: lng,
                                                address,
                                            });
                                        }
                                    }


                                }
                            })
                            .catch((error) => {
                                console.error('API request for address failed:', error);
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    }

                    else {
                        console.error('No coordinates data or invalid response:', data);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.error('API request for coordinates failed:', error);
                    setLoading(false);
                });
        }
    }, [dispatch, onChange]);

    const clearFormFields = useCallback(() => {
        setFarmData(getEmptyData());
    }, [getEmptyData]);

    const fetchOrgId = useCallback(async (userId) => {

        if (!userId) {
            return;
        }

        const response = await getOrgId(baseURL, userId);
        const data = await response.json();
        return data

    }, []);

    const transformZoneBoundaries = useCallback((coordinates) => {
        const transformedBoundaries =
            Array.isArray(coordinates) &&
            coordinates.map((boundary) => {
                return {
                    latitude: boundary.latitude || boundary.lat,
                    longitude: boundary.longitude || boundary.lng,
                };
            });

        return transformedBoundaries;
    }, []);

    const calculateArea = useCallback(async (coordinates, size_unit) => {

            if (!coordinates || coordinates.length === 0) {
                return;
            }

            if (!size_unit) {
                setSnackbarOpen(true);
                setSnackbarMessage('Please select a unit');
                setSnackbarSeverity('error');
            }

            const updatedZoneBoundaries = transformZoneBoundaries(coordinates);

            if (!Array.isArray(updatedZoneBoundaries) || 
                updatedZoneBoundaries.some((boundary) => boundary.latitude === null || boundary.longitude === null)
            ) {
                return;
            }

            const requestData = {
                zone_boundaries: updatedZoneBoundaries,
                unit: size_unit,
            };

            try {
          
                const response = await calculateZoneArea(baseURL, requestData);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                return data;
                
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        [transformZoneBoundaries]
    );

    const handleSave = useCallback(async () => {

        setLoading(true);

        const { formattedAddress, ...location } = locationFromRedux;
        const updatedCoordinates = transformZoneBoundaries(coordinates);

        if (!farmData?.farm?.name || !farmData?.farm?.size || !farmData?.farm?.size_unit) {
            setSnackbarOpen(true);
            setSnackbarMessage('Please fill all the fields');
            setSnackbarSeverity('error');
            setLoading(false);
            return;
        }

        const payload = {
            user_id: userId,
            org: orgId ? { id: orgId } : { name: farmData?.farm?.name },
            farm: {
                ...farmData.farm,
            },
            location,
            farm_boundaries: updatedCoordinates ? updatedCoordinates : [],

        };

        try {

            const response = await createFarm(baseURL, payload);
            const data = await response.json();
            if (data.data.farm_id) {
                setSnackbarOpen(true);
                setSnackbarMessage('Congratulations !! Farm added successfully! You can add more farms.');
                setSnackbarSeverity('success');
                fetchOrgId(userId);
                clearFormFields();
                dispatch(clearLocation());
                setIsMap(false);
                dispatch(clearCoordinate());
                setTimeout(() => {
                    navigate(`/userdetails/${userId}`);
                }, 2000);

            } else {

                setSnackbarOpen(true);
                setSnackbarMessage('There was an error while creating your farm');
                setSnackbarSeverity('error');

            }
        }
        catch (error) {
            setSnackbarOpen(true);
            setSnackbarMessage('There was an error while creating your farm');
            setSnackbarSeverity('error');
        }
        setLoading(false);


    }, [clearFormFields, farmData, fetchOrgId, dispatch, locationFromRedux, orgId, userId, navigate, coordinates, transformZoneBoundaries]);

    useEffect(() => {

        const getIntialData = async () => {
            const orgId = await fetchOrgId(userId);
            if (orgId?.data?.org_id) {
                setOrgId(orgId.data.org_id);
            }
        }
        getIntialData();
    }, [userId, fetchOrgId]);


    return (
        <Layout style={{ position: 'relative', zIndex: 1 }}>
            <div style={{ backgroundColor: "#ECF1F1", height: "100vh", marginTop: 60 }}>

                <Grid container spacing={2} >
                    {/* First Column (Empty) */}
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8} style={{ position: 'relative', zIndex: 0, height: 'calc(100vh - 20px)', overflowY: 'auto' }}>
                        <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>


                            <Button style={{

                                borderRadius: '50%',
                                width: '35px',
                                height: '45px',
                                minWidth: '45px',
                                minHeight: '45px',
                                fontSize: '1.2rem',
                                color: 'white',
                                backgroundColor: '#888',
                            }}
                                variant='contained'
                                onClick={() => {
                                    navigate(`/userdetails/${userId}`);
                                    dispatch(clearLocation());
                                }
                                }
                            >
                                <IoArrowBack />
                            </Button>
                            <Typography style={{ fontSize: '25px', fontWeight: 'bold', fontFamily: 'sen', marginLeft: 10 }}>
                                <span style={{ color: 'gray' }}>Add New Farm</span> <span>&gt;</span> {fullName}
                            </Typography>
                        </div>

                        <Typography style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'sen', marginTop: 25, color: 'gray' }}>
                            New Farm
                        </Typography>

                        <Paper style={{ marginTop: 10 }}>

                            <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label style={{ marginBottom: 10, fontFamily: 'sen', fontWeight: 'bold' }}>Farm Name</label>
                                    <TextField color='success' label="Enter farm name" fullWidth style={{ marginRight: '10px', width: "50%" }} value={farmData?.farm?.name}
                                        onChange={(e) => setFarmData({ ...farmData, farm: { ...farmData.farm, name: e.target.value } })}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 18 }}>
                                    <label style={{ marginBottom: 10, fontFamily: 'sen', fontWeight: 'bold' }}>How much area your farm covers?</label>
                                    <TextField label="Enter farm size" style={{ marginRight: '10px', width: "50%" }}

                                        onChange={(e) => {
                                            let size = parseInt(e.target.value, 10); // Parse the input as an integer
                                            if (!isNaN(size)) {
                                                setFarmData({ ...farmData, farm: { ...farmData.farm, size } });
                                            }
                                        }}
                                        value={newArea ? newArea : farmData?.farm?.size || ""}
                                        color='success'
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 18 }}>
                                    <label style={{ marginBottom: 10, fontFamily: 'sen', fontWeight: 'bold' }}>Units</label>
                                    <FormControl style={{ flex: 1 }}>
                                        <InputLabel>Select an option</InputLabel>
                                        <Select
                                            color='success'
                                            value={farmData?.farm?.size_unit || ""}
                                            onChange={(e) => setFarmData({ ...farmData, farm: { ...farmData.farm, size_unit: e.target.value } })}
                                            style={{ width: 300, marginRight: 10 }}
                                        >
                                            <MenuItem value="acre">Acres</MenuItem>
                                            <MenuItem value="hectare">Hectare</MenuItem>
                                            <MenuItem value="sqft">Sqft</MenuItem>
                                            <MenuItem value="sqmt">Sqmt</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 18 }}>
                                    <label style={{ marginBottom: 10, fontFamily: 'sen', fontWeight: 'bold' }}>Where is your farm located?</label>
                                    {isMap ? (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', border: locationFromRedux?.address ? '2px solid green' : '2px solid red', borderRadius: 5, alignItems: "center", padding: 10 }}>
                                            {
                                                locationFromRedux && locationFromRedux.address ? (
                                                    <Typography style={{ marginRight: 5 }}>{locationFromRedux.address}</Typography>
                                                ) : (
                                                    <Typography style={{ marginRight: 5, color: "gray", fontFamily: 'sen' }}>Please select a location</Typography>
                                                )
                                            }
                                            <Button color='success' onClick={handleMapOpen} sx={{ marginLeft: 5 }}>Change</Button>
                                        </div>

                                    ) : (

                                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>

                                            <Button variant="outlined" color='success' onClick={handleMapOpen} style={{ backgroundColor: 'white', color: 'black', width: 800 }}>
                                                <AddLocationAltOutlinedIcon style={{ marginRight: 5 }} />
                                                Pin location
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                {
                                    locationFromRedux && locationFromRedux.address && (

                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 18 }}>
                                            <FarmMap location={locationFromRedux} calculateArea={async (coordinates) => {
                                                const data = await calculateArea(coordinates, farmData?.farm?.size_unit);
                                                setNewArea(data?.data?.area)
                                                setFarmData({ ...farmData, farm: { ...farmData.farm, size: parseFloat(data?.data?.area, 10) } });
                                            }} />
                                        </div>

                                    )
                                }
                            </div>
                        </Paper>


                        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 15 }}>
                            <Button variant="contained" style={{ marginBottom: 20, width: '600px', backgroundColor: "#A6B5A4", fontFamily: 'sen', marginRight: 25 }} onClick={
                                () => {
                                    dispatch(clearLocation());
                                    navigate(`/userdetails/${userId}`);
                                }
                            }>Cancel</Button>
                            <Button variant="contained" color="success" style={{ marginBottom: 20, width: '600px', fontFamily: 'sen' }}
                                onClick={handleSave}
                            > {loading ? (
                                <CircularProgress size={24} color="success" />
                            ) : (
                                "Save"
                            )}</Button>

                        </div>

                    </Grid>
                    <Grid item xs={2}></Grid>


                </Grid>
            </div>

            <Dialog open={openModal} disableEnforceFocus onClose={handleClose} >
                <div style={{ backgroundColor: "#d0e7b7", display: "flex", justifyContent: "space-between" }}>
                    <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Farm Location</DialogTitle>
                    <Button onClick={handleClose}><RxCross2 style={{ color: "black" }} /></Button>
                </div>
                <DialogContent style={{ minHeight: "500px" }}>
                    <div>
                        <Typography>
                            Select you location?
                        </Typography>

                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={suggestions.map((option) => option.description)}
                            sx={{ width: 500, marginTop: 2 }}
                            renderInput={(params) => <TextField {...params} label="Search" />}
                            onChange={(event, newValue) => onSelectLocation(newValue)}
                            onInputChange={(event, newInputValue) => {
                                fetchSuggestions(newInputValue);
                            }}

                        />
                    </div>
                    <div style={{ marginTop: 30 }}>
                        <FmdGoodOutlinedIcon style={{ color: 'green' }} />
                        <Typography style={{ fontWeight: 'bold', fontFamily: 'sen' }}>Location Selected</Typography>
                        <Typography style={{ fontFamily: 'sen', overflow: 'auto' }} >{formattedAddress}</Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>


            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Layout>

    )
}

export default RegisterForm