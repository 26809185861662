import React, { useState } from "react";
import {
  Modal,
  Box,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  DialogTitle,
} from "@mui/material";

const AddchecklistDialog = ({ open, handleClose }) => {
  const [itemId, setItemId] = useState("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState("boolean");

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          borderRadius: "20px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "600px",
          maxWidth: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <DialogTitle
          className="text-2xl font-bold text-[#028476] "
          sx={{ padding: 0 }}
        >
          Add Checklist
        </DialogTitle>
        <TextField
          label="Item ID"
          value={itemId}
          onChange={(e) => setItemId(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#028476",
              },
              "&:hover fieldset": {
                borderColor: "#028476",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#028476",
              },
            },
          }}
        />
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#028476",
              },
              "&:hover fieldset": {
                borderColor: "#028476",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#028476",
              },
            },
          }}
        />
        <FormControl
          fullWidth
          margin="normal"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#028476",
              },
              "&:hover fieldset": {
                borderColor: "#028476",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#028476",
              },
            },
          }}
        >
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={handleTypeChange}
            label="Type"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#028476",
                },
                "&:hover fieldset": {
                  borderColor: "#028476",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#028476",
                },
              },
            }}
          >
            <MenuItem value="boolean">Boolean</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="file">File</MenuItem>
          </Select>
        </FormControl>


        <div className="flex justify-end gap-4 mt-4">
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#028476",
              color: "white",
              "&:hover": {
                backgroundColor: "#1f5e25",
              },
            }}
          >
            Add
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddchecklistDialog;
