import React, { useCallback, useState, useEffect } from 'react';
import Layout from './Layout';
import {
    Typography, Grid, TextField, MenuItem, IconButton, Select, FormControl, InputLabel, Button, Checkbox, FormControlLabel,
    TableContainer, Paper, TableHead, TableRow, TableCell, Table, TableBody, Dialog, DialogTitle, DialogContent, DialogActions,
    CircularProgress, Snackbar
} from '@mui/material';
import { IoMdAdd } from 'react-icons/io';
import SearchIcon from '@mui/icons-material/Search';
import MuiAlert from '@mui/material/Alert';
import {baseURL} from '../config';
import { getAllOrg } from '../apis/api';
import { addRole, getAllFeature, updateRole, roleDelete, getRoles } from '../apis/role-mgmt/api';
import { createPermission, deletePermission, editPermission } from '../utils/helpers';


const RoleManagement = () => {

    const userData = JSON.parse(localStorage.getItem('userData'));
    const permissions = JSON.parse(localStorage.getItem('permissions'));
    const [selectedOrg, setSelectedOrg] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchDropdown, setSearchDropdown] = useState('role');
    const [openAddRoleModal, setOpenAddRoleModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [features, setFeatures] = useState([]);
    const [organisation, setOrganisation] = useState([]);
    const [selectedOrgId, setSelectedOrgId] = useState('');
    const [roles, setRoles] = useState([]);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [role, setRole] = useState({
        role: {
            org_id: null,
            name: "",
            user_permissions: {}
        }
    });
    const [addPermissions, setAddPermissions] = useState([]);
    const [deleteRole, setDeleteRole] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [deleteRoleData, setDeleteRoleData] = useState({});
    const [openUpdateRoleModal, setOpenUpdateRoleModal] = useState(false);
    const [updateRoleData, setUpdateRoleData] = useState({});


    const getFeatures = useCallback(async (orgId) => {

        if (!orgId) return;

        setLoading(true);
        try {
            const response = await getAllFeature(baseURL, orgId);
            const data = await response.json();
            if (data.data) {
                setLoading(false);
                return data;
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }, []);

    const getAllOrganistations = useCallback(async () => {

        setLoading(true);
        try {
            const response = await getAllOrg(baseURL);
            const data = await response.json();
            if (data.data) {
                setLoading(false);
                return data;
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }, []);

    const getAllRoles = useCallback(async (orgid) => {

        setLoading(true);
        try {
            const response = await getRoles(baseURL, orgid);
            const data = await response.json();
            if (data.data) {
                setLoading(false);
                return data;
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }, []);

    const hasCreatePermission = createPermission('role_mgmt', permissions);
    const hasDeletePermission = deletePermission('role_mgmt', permissions);
    const hasUpdatePermission = editPermission('role_mgmt', permissions);

    const handleOrgChange = (event) => {
        const selectedId = event.target.value;
        const selectedOrgName = organisation.find(org => org.id === selectedId)?.name || '';
        setSelectedOrgId(selectedId);
        setSelectedOrg(selectedOrgName);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        if (event.target.value === '') {
            setFilteredRoles(roles);
            return;
        } else {
            const filtered = roles.filter(role => role.name.toLowerCase().includes(event.target.value.toLowerCase()));
            setFilteredRoles(filtered);
        }

    };

    const handleOpen = () => {
        setOpenAddRoleModal(true);
    };

    function formatFeatureName(name) {
        return name.replace(/_/g, ' ')
            .replace(/\b\w/g, c => c.toUpperCase());
    }

    useEffect(() => {
        const fetchInitialData = async () => {

            const org = await getAllOrganistations();
            if (selectedOrgId) {
                const ft = await getFeatures(selectedOrgId);
                setFeatures(ft.data.features);
            }
            setOrganisation(org.data.orgs);
        };

        fetchInitialData();
    }, [getAllOrganistations, getFeatures, selectedOrgId]);

    useEffect(() => {

        const fetchData = async () => {
            if (selectedOrgId) {
                const role = await getAllRoles(selectedOrgId);
                setRoles(role?.data?.roles);
                setFilteredRoles(role?.data?.roles);
            }
        };
        fetchData();

    }, [selectedOrgId, getAllRoles]);


    const handleAddRole = async () => {

        const userPermissions = {
            permissions: features.map(feature => ({
                feature_id: feature.id,
                feature_name: feature.name,
                permissions: {
                    view: addPermissions[feature.id]?.view || false,
                    create: addPermissions[feature.id]?.create || false,
                    edit: addPermissions[feature.id]?.edit || false,
                    delete: addPermissions[feature.id]?.delete || false,
                }
            }))
        };

        const roleData = {
            role: {
                org_id: selectedOrgId,
                name: role.role.name,
                user_permissions: userPermissions
            },
            user_id: userData?.id
        };

        setLoading(true);
        try {
            const response = await addRole(baseURL, roleData);
            const data = await response.json();
            setLoading(false);
            if (data.data) {
                setOpenAddRoleModal(false);
                setRole({
                    role: {
                        org_id: null,
                        name: "",
                        user_permissions: {}
                    }
                });
                setAddPermissions({});
                setSnackbarOpen(true);
                setSnackbarMessage('Role added successfully');
                setSnackbarSeverity('success');
                const updatedRoles = await getAllRoles(selectedOrgId);
                // Update the roles state with the new list of roles
                setRoles(updatedRoles?.data?.roles || []);
                setFilteredRoles(updatedRoles?.data?.roles || []);

            }else{
                setSnackbarOpen(true);
                setSnackbarMessage('Error adding role');
                setSnackbarSeverity('error');
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    };

    const handlePermissionChange = (featureId, permissionType) => (event) => {


        setAddPermissions({
            ...addPermissions,
            [featureId]: {
                ...addPermissions[featureId],
                [permissionType]: event.target.checked
            }
        });

        setUpdateRoleData({
            ...updateRoleData,
            user_permissions: {
                id: updateRoleData?.user_permissions?.id,
                permissions: updateRoleData?.user_permissions?.permissions?.map(permission => {
                    if (permission.feature_id === featureId) {
                        return {
                            ...permission,
                            permissions: {
                                ...permission.permissions,
                                [permissionType]: event.target.checked
                            }
                        }
                    }
                    return permission;
                })
            }
        });

    };

    const handleDeleteRole = async () => {
        setLoading(true);

        try {
            const response = await roleDelete(baseURL, deleteRoleData, userData?.id);
            const data = await response.json();
            if (data.data) {
                setLoading(false);
                setDeleteRole(false);
                getAllRoles(selectedOrgId);
                setSnackbarOpen(true);
                setSnackbarMessage('Role deleted successfully');
                setSnackbarSeverity('success');
                setRoles(prevRoles => prevRoles.filter(role => role.id !== deleteRoleData.id));
                setFilteredRoles(prevRoles => prevRoles.filter(role => role.id !== deleteRoleData.id));
            } else {
                
                setLoading(false);
                setDeleteRole(false);
                setSnackbarOpen(true);
                setSnackbarMessage('Error deleting data');
                setSnackbarSeverity('error');
            }
        } catch (err) {
            setLoading(false);
            setDeleteRole(false);
            setSnackbarOpen(true);
            setSnackbarMessage('Error deleting data');
            setSnackbarSeverity('error');
            console.log(err);
        }
    };

    const handleUpdateRole = async () => {

        try {

            const response = await updateRole(baseURL, updateRoleData, userData?.id);
            const data = await response.json();
            if (data.data) {
                setLoading(false);
                setOpenUpdateRoleModal(false);
                setSnackbarOpen(true);
                setSnackbarMessage('Role updated successfully');
                setSnackbarSeverity('success');
                const updatedRoles = await getAllRoles(selectedOrgId);
                setRoles(updatedRoles?.data?.roles || []);
                setFilteredRoles(updatedRoles?.data?.roles || []);
            }
            else {
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage('Error updating role');
                setSnackbarSeverity('error');
            }

        } catch (err) {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarMessage('Error updating role');
            setSnackbarSeverity('error');


        }
    }


    return (
        <>
            <Layout>
                <div style={{ height: '100vh', marginTop: 90 }}>
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 25 }}>List of Roles</Typography>
                        <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>(Search using role name) </Typography>
                    </div>
                    <Grid container style={{ marginTop: 50 }} spacing={2} justifyContent={"center"} >
                        <Grid item xs={12} md={1} >
                            <FormControl sx={{ width: "100%" }} >
                                <InputLabel id="demo-simple-select-label" color='success'>Org</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedOrgId}
                                    label="Org"
                                    color='success'
                                    onChange={handleOrgChange}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {organisation.map((org, index) => (
                                        <MenuItem key={index} value={org.id}>{org.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <TextField
                                    fullWidth
                                    label={selectedOrg === "" ? "Please select organisation" : searchDropdown ? `Search by ${searchDropdown.toUpperCase()}` : 'Search'}
                                    value={searchQuery}
                                    color='success'
                                    variant="outlined"
                                    disabled={selectedOrg === "" ? true : false}
                                    onChange={handleSearchChange}
                                    onKeyPress={e => e.key === 'Enter'}
                                    InputProps={{
                                        startAdornment: (
                                            <TextField
                                                select
                                                value={searchDropdown}
                                                color='success'
                                                sx={{ minWidth: 120 }}
                                                variant='standard'
                                                disabled={selectedOrg === "" ? true : false}
                                                onChange={event => {
                                                    const sD = event.target.value;
                                                    setSearchDropdown(sD);
                                                    setSearchQuery('');
                                                }}
                                            >
                                                <MenuItem value={"role"}>NAME</MenuItem>
                                            </TextField>
                                        ),
                                        endAdornment: (
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        ),
                                    }}
                                />
                                {hasCreatePermission && (
                                    <Button
                                        onClick={handleOpen}
                                        style={{
                                            borderRadius: '50%',
                                            marginLeft: 10,
                                            width: '45px',
                                            height: '55px',
                                            minWidth: '55px',
                                            minHeight: '55px',
                                            fontSize: '1.2rem',
                                        }}
                                        variant='contained'
                                        color='success'
                                        disabled={selectedOrg === "" ? true : false}
                                    >
                                        <IoMdAdd />
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ marginLeft: 65 }}>
                        <TableContainer component={Paper} style={{ marginTop: 20 }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Name
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Org ID
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedOrg === "" ? (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                Select Organisation to view roles
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        Array.isArray(filteredRoles) && filteredRoles.length > 0 ? (
                                            filteredRoles.map((role, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">
                                                        {role.name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {role.org_id}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            hasUpdatePermission && (

                                                                <Button variant="outlined" color="primary" style={{ marginRight: 10 }}
                                                                    onClick={() => {
                                                                        setOpenUpdateRoleModal(true);
                                                                        setUpdateRoleData(role);
                                                                    }}
                                                                >
                                                                    Update
                                                                </Button>
                                                            )
                                                        }
                                                        {
                                                            hasDeletePermission && (
                                                                <Button variant="outlined" color="error"
                                                                    onClick={() => {
                                                                        setDeleteRole(true);
                                                                        setDeleteRoleData(role);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            )
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={6} align="center" >
                                                    <Typography> No roles found for this organisation</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                </div>

                <Dialog open={openAddRoleModal} onClose={() => setOpenAddRoleModal(false)} disableEnforceFocus maxWidth="md"
                    onBackdropClick={() => {
                        setOpenAddRoleModal(false)
                        setRole({
                            role: {
                                org_id: null,
                                name: "",
                                user_permissions: {}
                            }
                        });
                        // setPermissions([]);
                    }}
                >
                    <div style={{ backgroundColor: "#d0e7b7" }}>
                        <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Add Role</DialogTitle>
                    </div>
                    <DialogContent>
                        <div>
                            <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>
                                You are adding a new role for <span style={{ fontWeight: 'bold' }}>{selectedOrg.charAt(0).toUpperCase() + selectedOrg.slice(1)}</span> organisation
                            </Typography>
                            <TextField
                                fullWidth
                                label="Role Name"
                                variant="outlined"
                                color='success'
                                style={{ marginTop: 30 }}
                                value={role.role.name}
                                onChange={(e) => setRole({ role: { ...role.role, name: e.target.value } })}
                            />
                        </div>
                        <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontWeight: 'bold', marginTop: 2 }}>
                            Permissions
                        </Typography>
                        <Grid container spacing={2} style={{ padding: 20 }}>
                            {Array.isArray(features) && features.length === 0 ?
                                (
                                    <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>
                                        No features for this org
                                    </Typography>
                                )
                                : (
                                    features.map((feature, index) => (
                                        <Grid item xs={12} key={feature.id}>
                                            <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                                                {index + 1}. {formatFeatureName(feature.name)}
                                            </Typography>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox color="success"
                                                            checked={addPermissions[feature.id]?.view || false}
                                                            onChange={handlePermissionChange(feature.id, 'view')}
                                                        />
                                                    }
                                                    label="View"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox color="success"
                                                        checked={addPermissions[feature.id]?.create || false}
                                                        onChange={handlePermissionChange(feature.id, 'create')}
                                                    />}
                                                    label="Create"


                                                />
                                                <FormControlLabel
                                                    control={<Checkbox color="success"
                                                        checked={addPermissions[feature.id]?.edit || false}
                                                        onChange={handlePermissionChange(feature.id, 'edit')}
                                                    />}
                                                    label="Edit"
                                                />
                                                <FormControlLabel
                                                    control={<Checkbox color="success"
                                                        checked={addPermissions[feature.id]?.delete || false}
                                                        onChange={handlePermissionChange(feature.id, 'delete')}
                                                    />}
                                                    label="Delete"
                                                />
                                            </div>
                                        </Grid>
                                    ))
                                )
                            }

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => {
                            setOpenAddRoleModal(false)
                            setRole({
                                role: {
                                    org_id: null,
                                    name: "",
                                    user_permissions: {}
                                }
                            });

                        }}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleAddRole} color="success">
                            {loading ? <CircularProgress color='success' size={24} /> : 'Add Role'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={deleteRole} onClose={() => setDeleteRole(false)} disableEnforceFocus maxWidth="md">
                    <div style={{ backgroundColor: "#d0e7b7" }}>
                        <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Delete Role</DialogTitle>
                    </div>
                    <DialogContent>
                        <Typography>
                            Are you sure you want to delete this role?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => setDeleteRole(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={() => {
                            handleDeleteRole();

                        }} color="error">
                            {loading ? <CircularProgress color='error' size={24} /> : 'Delete Role'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openUpdateRoleModal} onClose={() => setOpenUpdateRoleModal(false)} disableEnforceFocus maxWidth="md">
                    <div style={{ backgroundColor: "#d0e7b7" }}>
                        <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>Update Role</DialogTitle>
                    </div>
                    <DialogContent>
                        <div>
                            <TextField
                                fullWidth
                                label="Role Name"
                                variant="outlined"
                                color='success'
                                style={{ marginTop: 30 }}
                                value={updateRoleData.name}
                                onChange={(e) => setUpdateRoleData({ ...updateRoleData, name: e.target.value })}
                            // onChange={(e) => setRole({ role: { ...role.role, name: e.target.value } })}
                            />
                        </div>
                        <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontWeight: 'bold', marginTop: 2 }}>
                            Permissions
                        </Typography>
                        <Grid container spacing={2} style={{ padding: 20 }}>
                            {
                                updateRoleData?.user_permissions?.permissions?.map((permission, index) => (

                                    <Grid item xs={12} key={permission.feature_id}>
                                        <Typography variant="subtitle1" sx={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                                            {index + 1}. {formatFeatureName(permission.feature_name)}
                                        </Typography>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color="success"
                                                        checked={permission.permissions.view || false}
                                                        onChange={handlePermissionChange(permission.feature_id, 'view')}
                                                    />
                                                }
                                                label="View"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox color="success"
                                                    checked={permission.permissions.create || false}
                                                    onChange={handlePermissionChange(permission.feature_id, 'create')}
                                                />}
                                                label="Create"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox color="success"
                                                    checked={permission.permissions.edit || false}
                                                    onChange={handlePermissionChange(permission.feature_id, 'edit')}
                                                />}
                                                label="Edit"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox color="success"
                                                    checked={permission.permissions.delete || false}
                                                    onChange={handlePermissionChange(permission.feature_id, 'delete')}
                                                />}
                                                label="Delete"
                                            />

                                        </div>
                                    </Grid>
                                ))
                            }
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => {
                            setOpenUpdateRoleModal(false)
                            setUpdateRoleData({});
                            setAddPermissions({});
                        }}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleUpdateRole} color="success">
                            {loading ? <CircularProgress color='success' size={24} /> : 'Update Role'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                    <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>

            </Layout>
        </>
    );
}

export default RoleManagement;
