import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { get360Stats } from "../apis/dashboard/api";
import { fetchStatsFailure, fetchStatsSuccess } from "../reducers/dashboard";
import { baseURL } from "../config";
import Heatmap from "../components/HeatMap";
import ProgramStatsProgressbar from "../components/ProgramStatsProgressbar";
import AgricultureIcon from '@mui/icons-material/Agriculture';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupsIcon from '@mui/icons-material/Groups';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { motion } from 'framer-motion';

const Dashboard360Stats = () => {
  const dispatch = useDispatch();
  const [isHoveredFarmer, setIsHoveredFarmer] = useState(false);
  const [isHoveredProgram, setIsHoveredProgram] = useState(false);
  const [isHoveredFarm, setIsHoveredFarm] = useState(false);
  const [isHoveredSoil, setIsHoveredSoil] = useState(false);

  const stats = useSelector((state) => state.stats);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const org_id = userData.org.id;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const result = await get360Stats(baseURL, org_id);
        dispatch(fetchStatsSuccess(result.data));
      } catch (error) {
        dispatch(fetchStatsFailure({ error: error.message }));
      }
    };

    fetchStats();
  }, [org_id,dispatch]);
  return (
    <Layout>
      <div className="w-full flex flex-col pl-[65px] pt-[75px] gap-5 pb-[10px] relative h-[100dvh]">
        <div className="w-full h-full flex  flex-row gap-5 px-[30px] py-[30px] ">
          <div className="w-full flex flex-row gap-5 items-center justify-center  h-full">
            <div className="w-full h-full  grid grid-cols-2 gap-5 items-center">
 
        <motion.div 
      className="w-full h-full border-2 border-[#F2F5F4] flex flex-col rounded-xl overflow-hidden relative"
      onHoverStart={() => setIsHoveredFarmer(true)}
      onHoverEnd={() => setIsHoveredFarmer(false)}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      style={{
        boxShadow: isHoveredFarmer
          ? '0 0 25px rgba(2, 132, 118, 0.6), 0 0 50px rgba(2, 132, 118, 0.3)'
          : '0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2)',
        transition: 'box-shadow 0.3s ease-in-out',
      }}
    >
      <motion.div 
        className="h-[80px] border-b-2 border-[#F2F5F4] flex items-center bg-[#028476]" 
        style={{
          padding: 4, 
          width: '100%',
          border: '1px dashed #028476',
          boxShadow: '0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)',
          borderRadius: '10px',
        }}
        animate={{ boxShadow: isHoveredFarmer ? '0 0 15px rgba(2, 132, 118, 0.4), 0 0 30px rgba(2, 132, 118, 0.2)' : '0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)' }}
        transition={{ duration: 0.3 }}
      >
        <h1 className="font-bold text-[20px] text-center w-full text-white">
          Farmers
        </h1>
      </motion.div>
      <div className="w-full h-full flex justify-center items-center text-[30px] text-[#028476] font-bold">
        {stats.farmer.count}
      </div>
      
      <motion.div
        className="absolute inset-0 flex justify-center items-center bg-[#028476]"
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: isHoveredFarmer ? 0 : '100%', opacity: isHoveredFarmer ? 1 : 0 }}
        transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
      >
        <motion.div
          initial={{ scale: 0.5, rotate: -180 }}
          animate={{ scale: isHoveredFarmer ? 1 : 0.5, rotate: isHoveredFarmer ? 0 : -180 }}
          transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
        >
                  <div className="w-full flex flex-col h-full justify-center items-center">
                  <h1 className="font-bold text-white text-[32px]">        {stats.farmer.count ? stats.farmer.count : 0}
                  </h1>

          <GroupsIcon style={{ fontSize: 100, color: 'white' }} />
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
        <motion.div 
      className="w-full h-full border-2 border-[#F2F5F4] flex flex-col rounded-xl overflow-hidden relative"
      onHoverStart={() => setIsHoveredProgram(true)}
      onHoverEnd={() => setIsHoveredProgram(false)}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      style={{
        boxShadow: isHoveredFarmer
          ? '0 0 25px rgba(2, 132, 118, 0.6), 0 0 50px rgba(2, 132, 118, 0.3)'
          : '0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2)',
        transition: 'box-shadow 0.3s ease-in-out',
      }}
    >
      <motion.div 
        className="h-[80px] border-b-2 border-[#F2F5F4] flex items-center bg-[#028476]" 
        style={{
          padding: 4, 
          width: '100%',
          border: '1px dashed #028476',
          boxShadow: '0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)',
          borderRadius: '10px',
        }}
        animate={{ boxShadow: isHoveredProgram ? '0 0 15px rgba(2, 132, 118, 0.4), 0 0 30px rgba(2, 132, 118, 0.2)' : '0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)' }}
        transition={{ duration: 0.3 }}
      >
        <h1 className="font-bold text-[20px] text-center w-full text-white">
        Ongoing Programs
        </h1>
      </motion.div>
      <div className="w-[80%] mx-auto h-full flex justify-center items-center text-[30px] text-[#028476] font-bold">
      <ProgramStatsProgressbar
                    value={stats.program.progress ? stats.program.progress : 0}
                    count={ stats?.program?.count ? stats?.program?.count : 0}
                  />
      </div>
      
      <motion.div
        className="absolute inset-0 flex justify-center items-center bg-[#028476]"
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: isHoveredProgram ? 0 : '100%', opacity: isHoveredProgram ? 1 : 0 }}
        transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
      >
        <motion.div
          initial={{ scale: 0.5, rotate: -180 }}
          animate={{ scale: isHoveredProgram ? 1 : 0.5, rotate: isHoveredProgram ? 0 : -180 }}
          transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
        >
                  <div className="w-full flex flex-col h-full justify-center items-center">
                  <h1 className="font-bold text-white text-[32px]">{stats.program.count ? stats.program.count : 0}</h1>

          <ListAltIcon style={{ fontSize: 100, color: 'white' }} />
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
    <motion.div 
      className="w-full h-full border-2 border-[#F2F5F4] flex flex-col rounded-xl overflow-hidden relative"
      onHoverStart={() => setIsHoveredFarm(true)}
      onHoverEnd={() => setIsHoveredFarm(false)}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      style={{
        boxShadow: isHoveredFarmer
          ? '0 0 25px rgba(2, 132, 118, 0.6), 0 0 50px rgba(2, 132, 118, 0.3)'
          : '0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2)',
        transition: 'box-shadow 0.3s ease-in-out',
      }}
    >
      <motion.div 
        className="h-[80px] border-b-2 border-[#F2F5F4] flex items-center bg-[#028476]" 
        style={{
          padding: 4, 
          width: '100%',
          border: '1px dashed #028476',
          boxShadow: '0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)',
          borderRadius: '10px',
        }}
        animate={{ boxShadow: isHoveredFarm ? '0 0 15px rgba(2, 132, 118, 0.4), 0 0 30px rgba(2, 132, 118, 0.2)' : '0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)' }}
        transition={{ duration: 0.3 }}
      >
        <h1 className="font-bold text-[20px] text-center w-full text-white">
          Farms
        </h1>
      </motion.div>
      <div className="w-full h-full flex flex-col gap-5 justify-center items-center">
                  <div className="flex flex-row w-full justify-center text-[20px]">
                    Area Covered:{" "}
                    <span className="font-bold text-[#028476]">
                      {stats.farm.area_covered
                        ? stats.farm.area_covered.toFixed(2)
                        : "0"}{" "}
                      {stats.farm.area_covered ? "acres" :""}
                    </span>
                  </div>
                  <div className="flex flex-row w-full justify-center text-[20px]">
                    Total Farms:{" "}
                    <span className="font-bold text-[#028476]">{stats.farm.count ? stats.farm.count : 0}</span>
                  </div>
                </div>
      <motion.div
        className="absolute inset-0 flex justify-center items-center bg-[#028476]"
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: isHoveredFarm ? 0 : '100%', opacity: isHoveredFarm ? 1 : 0 }}
        transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
      >
        <motion.div
          initial={{ scale: 0.5, rotate: -180 }}
          animate={{ scale: isHoveredFarm ? 1 : 0.5, rotate: isHoveredFarm ? 0 : -180 }}
          transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
        >
                  <div className="w-full flex flex-col h-full justify-center items-center">
                  <h1 className="font-bold text-white text-[32px]">{stats.farm.count ? stats.farm.count : 0}</h1>

          <AgricultureIcon style={{ fontSize: 100, color: 'white' }} />
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
    <motion.div 
      className="w-full h-full border-2 border-[#F2F5F4] flex flex-col rounded-xl overflow-hidden relative"
      onHoverStart={() => setIsHoveredSoil(true)}
      onHoverEnd={() => setIsHoveredSoil(false)}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      style={{
        boxShadow: isHoveredFarmer
          ? '0 0 25px rgba(2, 132, 118, 0.6), 0 0 50px rgba(2, 132, 118, 0.3)'
          : '0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2)',
        transition: 'box-shadow 0.3s ease-in-out',
      }}
    >
      <motion.div 
        className="h-[80px] border-b-2 border-[#F2F5F4] flex items-center bg-[#028476]" 
        style={{
          padding: 4, 
          width: '100%',
          border: '1px dashed #028476',
          boxShadow: '0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)',
          borderRadius: '10px',
        }}
        animate={{ boxShadow: isHoveredSoil ? '0 0 15px rgba(2, 132, 118, 0.4), 0 0 30px rgba(2, 132, 118, 0.2)' : '0 0 10px rgba(2, 132, 118, 0.2), 0 0 20px rgba(2, 132, 118, 0.1)' }}
        transition={{ duration: 0.3 }}
      >
        <h1 className="font-bold text-[20px] text-center w-full text-white">
        Soil Test Reports
        </h1>
      </motion.div>
      <div className="w-full h-full flex justify-center items-center text-[30px] text-[#028476] font-bold">
      {stats.soil_reports.count ? stats.soil_reports.count : 0}
      </div>
      {/* <div className="w-full h-full flex justify-center items-center text-[30px] text-[#028476] font-bold">
        {stats.farmer.count}
      </div> */}
      
      <motion.div
        className="absolute inset-0 flex justify-center items-center bg-[#028476]"
        initial={{ y: '100%', opacity: 0 }}
        animate={{ y: isHoveredSoil ? 0 : '100%', opacity: isHoveredSoil ? 1 : 0 }}
        transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
      >
        <motion.div
          initial={{ scale: 0.5, rotate: -180 }}
          animate={{ scale: isHoveredSoil ? 1 : 0.5, rotate: isHoveredSoil ? 0 : -180 }}
          transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }}
        >
                  <div className="w-full flex flex-col h-full justify-center items-center">
                  <h1 className="font-bold text-white text-[32px]">      {stats.soil_reports.count ? stats.soil_reports.count : 0}
                  </h1>

          <DescriptionIcon style={{ fontSize: 100, color: 'white' }} />
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
   
            </div>
            <div className="w-full h-full  flex flex-row gap-5 items-center">
              <div className="w-full h-full border-2 border-[#F2F5F4] flex flex-col rounded-xl overflow-hidden"   style={{
        boxShadow:'0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.2)',
        transition: 'box-shadow 0.3s ease-in-out',
      }}>
                <div className="h-[80px] border-b-2 border-[#F2F5F4] flex items-center bg-[#028476]">
                  <h1 className="font-bold text-[20px] text-center w-full text-white">
                    Locations
                  </h1>
                </div>
                <div className="w-full h-full flex justify-center items-center ">
                  <Heatmap />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard360Stats;
