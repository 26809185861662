import React from 'react';
import { Button, Typography } from '@mui/material';


const ChatElement = ({ user, onUserClick, selectedPhone }) => {

    return (
      <>

      
        {user && user.data && user.data.data.map((data, index) => (
          <Button
            key={index}
            sx={{
              borderRadius: 1,
              marginTop: 2,
              marginBottom: 1,
              marginLeft: 10,
              marginRight: 2,
              padding: '10px 20px',
              backgroundColor: selectedPhone === data.phone ? '#cfd8dc' : 'white', // Highlight the selected user
              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
            }}
  
            onClick={() => onUserClick(data.phone)}
          >
            <div style={{ color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  
              <div >
                <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                  {data.fullname}
                </Typography>
              </div>
              <div style={{ marginLeft: 10 }}>
                <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                  {data.phone}
                </Typography>
              </div>
  
            </div>
          </Button>
        ))}
      </>
    );
  };


export default ChatElement;