import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Paper, Typography, TextField, Button, Select, MenuItem, Snackbar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MuiAlert from '@mui/material/Alert';
import {baseURL} from '../config';
import { useNavigate } from 'react-router-dom';
import {BEARER_TOKEN} from '../config'


const EditLeadDetails = ({ open, handleClose, leadData }) => {


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [userData, setUserData] = useState({});
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const navigate = useNavigate();


    const getEmptyData = () => {
        return {

            lead: {
                id: "",
                fullName: "",
                clientType: "",
                prefferedLanguage: "",
                companyName: "",
                phone: "",
                email: "",

            }
        }
    }
    const [editData, setEditData] = useState(getEmptyData());

    const getUserData = useCallback(() => {
        const userData = localStorage.getItem('userData');
        if (userData) {
            setUserData(JSON.parse(userData));
        }
    }, [])


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const validatePhone = (phone) => {

        const phoneRegex = /^[0-9]{10,}$/;
        return phoneRegex.test(phone);
    };


    const getUpdatedLead = useCallback(() => {


        const apiUrl = `${baseURL}/api/mgmt/leads/getLead`;

        const requestData = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + BEARER_TOKEN
                // Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', // Replace with your actual access token
            },
            body: JSON.stringify({

                "id": leadData && leadData.data && leadData.data.lead && leadData.data.lead.id,
            }),
        };

        fetch(apiUrl, requestData)
            .then((response) => response.json())
            .then((data) => {
                setUserData(data);
                navigate('/lead-details', { state: { leadData: data } });
            })
            .catch((error) => {
                console.error('API Error:', error);
            });

    },[leadData,navigate]);


    const handleEditRequirement = useCallback(() => {

        //check if phone number and email is valid

      

        if (!validateEmail(editData.lead.email)) {

            setEmailError('Enter valid email ID');
        }

        if (!validatePhone(editData.lead.phone)) {

            setPhoneError('Enter valid phone number');
        }
        const payload = {
            userId: userData.id,
            ...editData,
            lead: {
                ...editData.lead,
                id: leadData && leadData.data && leadData.data.lead && leadData.data.lead.id
            }
        }

       
        fetch(`${baseURL}/api/mgmt/leads/updateLead`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + BEARER_TOKEN
                 // Replace with your actual access token
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                // Handle the API response as needed
            
                if (!data.error) {
                    // Show the success Snackbar and clear the form fields
                    setSnackbarOpen(true);
                    setSnackbarMessage('Lead updated successfully');
                    setSnackbarSeverity('success');
                    handleClose();
                    getUpdatedLead();

                } else {
                    console.log(data);
                }
            })
            .catch(error => {
                console.error('Error making API call:', error);
            });

    },[editData,getUpdatedLead,handleClose,leadData,userData.id]);



    useEffect(() => {
        const {id, fullName, clientType, preferredLanguage, companyName, phone, email} = leadData.data.lead;
        setEditData({
            lead: {
                id, fullName, clientType, preferredLanguage, companyName, phone, email
            }
        });
        getUserData();

    }, [open,getUserData,leadData.data.lead]);




    return (
        <Modal open={open} onClose={handleClose}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: 900 }}>
                <Paper style={{ padding: 20 }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 20,

                        }}
                    >
                        <Button style={{ color: 'gray' }} onClick={handleClose}>Cancel</Button>
                        <Button style={{ color: '#FDB67B' }} onClick={handleEditRequirement}>Save</Button>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>Lead Details</Typography>
                        <EditIcon />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                        <div>
                            <Typography style={{ fontFamily: 'sen' }}>Full Name</Typography>
                            <TextField
                                value={editData && editData.lead && editData.lead.fullName}
                                onChange={(e) =>
                                    setEditData({
                                        ...editData,
                                        lead: {
                                            ...editData.lead,
                                            fullName: e.target.value
                                        }

                                    })
                                }
                               
                        


                            ></TextField>
                        </div>
                        <div>
                            <Typography style={{ fontFamily: 'sen' }}>Client Type</Typography>
                            <TextField
                                value={editData && editData.lead && editData.lead.clientType}
                                onChange={(e) => setEditData({
                                    ...editData,
                                    lead: {
                                        ...editData.lead,
                                        clientType: e.target.value
                                    },
                                })}

                              
                           

                            ></TextField>
                        </div>
                        <div style={{ width: '180px' }}>
                            <Typography style={{ fontFamily: 'sen' }}>Preferred Language</Typography>
                            <Select
                                value={editData && editData.lead && editData.lead.preferredLanguage}
                                onChange={(e) => setEditData({
                                    ...editData,
                                    lead: {
                                        ...editData.lead,
                                        preferredLanguage: e.target.value
                                    }
                                })}
                                sx={{ width: '170px' }}
                                name="preferredLanguage"

                            >
                                <MenuItem value="English">English</MenuItem>
                                <MenuItem value="Hindi">Hindi</MenuItem>
                                <MenuItem value="Marathi">Marathi</MenuItem>
                                {/* Add more options as needed */}
                            </Select>

                        </div>
                        <div>
                            <Typography style={{ fontFamily: 'sen' }}>Company Name</Typography>
                            <TextField
                                value={editData && editData.lead && editData.lead.companyName}
                                onChange={(e) =>
                                    setEditData({
                                        ...editData,
                                        lead: {
                                            ...editData.lead,
                                            companyName: e.target.value
                                        },
                                    })
                                }

                            ></TextField>

                        </div>


                    </div>
                    <div style={{ display: 'flex', marginTop: 5 }}>
                        <div>
                            <Typography style={{ fontFamily: 'sen' }}>Email</Typography>
                            <TextField
                                error={emailError !== ''}
                                value={editData && editData.lead && editData.lead.email}
                                onChange={(e) =>
                                    setEditData({
                                        ...editData,
                                        lead: {
                                            ...editData.lead,
                                            email: e.target.value
                                        },
                                    })
                                }></TextField>


                        </div>

                        <div style={{marginLeft: 20}}>
                            <Typography style={{ fontFamily: 'sen' }}>Phone</Typography>
                            <TextField
                                error={phoneError !== ''}
                                value={editData && editData.lead && editData.lead.phone}
                                onChange={(e) =>
                                    setEditData({
                                        ...editData,
                                        lead: {
                                            ...editData.lead,
                                            phone: e.target.value
                                        },
                                    })
                                }></TextField>

                        </div>
                    </div>

                </Paper>
                <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                    <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </div >


        </Modal >

    );
};

export default EditLeadDetails