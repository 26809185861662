import React, { useCallback, useEffect, useState } from 'react'
import Layout from './Layout'
import { Grid, Paper, TextField, Select, MenuItem, FormControl, InputLabel, Typography, Button } from '@mui/material'
import {baseURL} from '../config';
import { useParams } from 'react-router-dom';
import MultiselectionMap from '../components/MultiselectionMap';
import { useSelector } from 'react-redux';
import { Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearCoordinate } from '../reducers/coordinates';
import MuiAlert from '@mui/material/Alert';
import { clearLocation } from '../reducers/location';
import { IoArrowBack } from "react-icons/io5";
import { getOrgId } from '../apis/api';
import { getZoneTypes, handleAddZone } from '../apis/farm-mgmt/zone';
import { getFarmByOrg } from '../apis/farm-mgmt/farm';
import { calculateZoneArea } from '../apis/farm-mgmt/zone';
import { transformBoundaries } from '../utils/helpers';

const AddZone = () => {

    const [zoneTypes, setZoneTypes] = useState([]);
    const { farmId, userId } = useParams();
    const [orgId, setOrgId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [newArea, setNewArea] = useState(null);
    const [farmData, setFarmData] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const zoneBoundaries = useSelector(state => state.coordinateReducer.coordinate);


    const fetchZoneTypes = useCallback(async () => {

        try {
            
            const response = await getZoneTypes(baseURL);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            return data.data.zone_types;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);

    const fetchOrgId = useCallback(async (userId) => {
        try {

            const response = await getOrgId(baseURL, userId);
            const data = await response.json();
            return data.data.org_id;

        } catch (error) {
            console.error('Error fetching organization ID:', error);
        }
    }, []);

    const getFarmById = useCallback(async (farmId, orgId) => {

        const requestData = {
            "farm_id": parseInt(farmId, 10),
            "org_id": orgId
        };

        try {

            const response = await getFarmByOrg(baseURL, requestData);
            const data = await response.json();
            return data?.data || {};
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);

    const defaultSizeUnit = farmData?.farm?.farm?.size_unit || "acre";

    const getEmptyData = useCallback(()=> {
        return {
            name: "",
            size: "",
            size_unit: defaultSizeUnit,
            zone_type_id: {
                id: "",
            },
            zone_boundaries: []
        }
    },[defaultSizeUnit])

    const [zone, setZone] = useState(getEmptyData());

    const transformZoneBoundaries = useCallback(() => {

        const transformedBoundaries = transformBoundaries(zoneBoundaries);
        return transformedBoundaries;
    }, [zoneBoundaries]);

    const calculateArea = useCallback(async () => {

        const transformedBoundaries = transformZoneBoundaries();

        const requestData = {
            "zone_boundaries": transformedBoundaries,
            "unit": zone.size_unit
        };

        if(requestData.zone_boundaries.length === 0) {
            return;
        }
        try {
            const response = await calculateZoneArea(baseURL, requestData);
            const data = await response.json();
            setZone(prevZone => ({
                ...prevZone,
                size: data?.data?.area
            }));
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }

    }, [transformZoneBoundaries, zone.size_unit]);


    useEffect(() => {

        const fetchInitialData = async () => {

            const zoneTypes = await fetchZoneTypes();
            const orgId = await fetchOrgId(userId);

            if (!orgId) {
                return;
            }

            const farmData = await getFarmById(farmId, orgId);

            setOrgId(orgId);
            setFarmData(farmData);
            setZoneTypes(zoneTypes);
        }
        fetchInitialData();

    }, [fetchZoneTypes, fetchOrgId, userId, farmId, getFarmById]);

    const addZone = useCallback(async () => {

        const zoneBoundaries = transformZoneBoundaries();

        const requestData = {

            "user_id": userId,
            "org_id": orgId,
            "farm_id": parseInt(farmId, 10),
            "name": zone.name,
            "size": Number.isFinite(parseFloat(newArea)) ? parseFloat(newArea) : parseFloat(zone.size, 10),
            "size_unit": farmData?.farm?.farm?.size_unit,
            "zone_type": {
                "id": zone.zone_type_id.id
            },
            "zone_boundaries": zoneBoundaries
        };

        if (!requestData.name || !requestData.size || !requestData.size_unit || !requestData.zone_type.id || requestData.zone_boundaries.length === 0) {
            setSnackbarMessage('Please fill all fields');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        try {
 
            const response = await handleAddZone(baseURL, requestData);
            console.log("RESPONSE", response);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data?.error?.code || "Something went wrong");
            }

            getEmptyData();
            dispatch(clearCoordinate());
            setSnackbarOpen(true);
            setSnackbarMessage('Zone added successfully');
            setSnackbarSeverity('success');
            setTimeout(() => {
                navigate(`/userdetails/${userId}`);
            }, 2000);
        } catch (error) {
            setSnackbarMessage(error.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    }, [zone, farmId, userId, transformZoneBoundaries, orgId, navigate, dispatch, farmData?.farm?.farm?.size_unit, newArea, getEmptyData]);


    useEffect(() => {
        calculateArea();
    }, [calculateArea]);


    return (
        <Layout>
            <div style={{ backgroundColor: "#ECF1F1", height: "100vh", overflow: 'auto', marginTop: 65 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>

                            <Button style={{
                                borderRadius: '50%',
                                width: '35px',
                                height: '45px',
                                minWidth: '45px',
                                minHeight: '45px',
                                fontSize: '1.2rem',
                                color: 'white',
                                backgroundColor: '#888',
                            }}
                                variant='contained'
                                onClick={() => {
                                    navigate(`/userdetails/${userId}`);
                                    dispatch(clearLocation());
                                }
                                }
                            >
                                <IoArrowBack />
                            </Button>
                            <Typography style={{ fontSize: '25px', fontWeight: 'bold', fontFamily: 'sen', marginLeft: 10 }}>
                                <span style={{ color: 'gray' }}>Add Zone</span>
                            </Typography>
                        </div>

                        <Paper style={{ marginTop: 30, marginBottom :30 }}>

                            <Grid container spacing={2} style={{ paddingBottom: 20 }} direction={"column"}>
                                <Grid item xs={12} sm={6}>
                                    <MultiselectionMap calculateArea={async (zoneBoundaries) => {
                                        const data = await calculateArea(zoneBoundaries, zone.size_unit);
                                        console.log("DATA", data?.data?.area);
                                        setNewArea(data?.data?.area);
                                    }} farmData={farmData} />
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ padding: 2 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', marginBottom: 5 }}>Zone Name</Typography>
                                        <TextField
                                            color='success'
                                            label="Enter zone name"
                                            value={zone.name}
                                            onChange={(e) => setZone({ ...zone, name: e.target.value })}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', marginBottom: 5 }}>Zone Type</Typography>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label" color='success'>Select an option</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                color='success'
                                                value={zone.zone_type_id.id || ""}
                                                label="Select an option"
                                                onChange={(e) => setZone({ ...zone, zone_type_id: { id: e.target.value } })}
                                            >
                                                {zoneTypes.map(zoneType => (
                                                    <MenuItem key={zoneType.id} value={zoneType.id}>{zoneType.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontFamily: 'sen', fontWeight: 'bold', marginLeft: 11, marginBottom: 5 }}>Zone Area</div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                                            <TextField
                                                color='success'
                                                label="Enter zone size"
                                                style={{ flex: 1, marginRight: 10 }}
                                                value={zone.size}
                                                disabled
                                                onChange={(e) => setZone({ ...zone, size: e.target.value })}
                                            />
                                            <FormControl>
                                                <InputLabel>Units</InputLabel>
                                                <Select
                                                    color='success'
                                                    value={farmData?.farm?.farm?.size_unit || ""}
                                                    disabled
                                                    onChange={(e) => setZone({ ...zone, size_unit: e.target.value })}
                                                    style={{ minWidth: 120 }}
                                                >
                                                    <MenuItem value="acre">Acres</MenuItem>
                                                    <MenuItem value="hectare">Hectare</MenuItem>
                                                    <MenuItem value="sqft">Sqft</MenuItem>
                                                    <MenuItem value="sqmt">Sqmt</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div style={{ textAlign: "right" }} >
                                        <Button variant="contained" style={{ marginTop: 20, backgroundColor: "#A6B5A4", fontFamily: 'sen', marginRight: 5 }} onClick={
                                            () => {
                                                navigate(`/userdetails/${userId}`);
                                                dispatch(clearLocation());
                                                dispatch(clearCoordinate())
                                            }
                                        }>Cancel</Button>
                                        <Button
                                            variant='contained'
                                            color='success'
                                            style={{ marginTop: 20, marginRight: 10 }}
                                            onClick={addZone}
                                        >
                                            Add Zone
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ marginTop: 6 }}
                onClose={() => setSnackbarOpen(false)}
                severity={snackbarSeverity}
            >
                <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Layout>
    )
}

export default AddZone