import {BEARER_TOKEN} from '../../config'

export const listByRole = async (
    baseURL,
    orgID
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-user/listByRole`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN,
             // Replace with your actual access token
            'Cookie': 'access_token=0e6b257b38e521034fb00206393c399e83e81ad0ba7eab970fa3215cc5f8b3e9cdac889683d24b86ea2d2cdaa22e8ee426e698a76fa3b9ae2e72a63e83a29988'
        },
        body: JSON.stringify({
            "org_id": orgID,
            "role_name": "agronomist"
        })
    })

    return response
}

export const getAgroUsers = async (
    agroID
) => {
    const response = await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/listAll', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            "agronomist_id": agroID,
        }),
    })

    return response;
}

export const getChatMessages = async (
    userID,
    phone
) => {

    const response =  await fetch('https://v3.api.phyfarm.com/api/mgmt/agronomistchat/getChat', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            "id": userID,
            "phone": phone
        }),
    })

    return response;
}