import {BEARER_TOKEN} from '../../config'

// fetch all plat users
export const getAllUsers = async (
    baseUrl,
    payload,
) => {

    const response = await fetch(`${baseUrl}/api/mgmt/phyfarmUsers/listAll`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
             // Replace with your actual access token
        },
        body: JSON.stringify(payload),
    });

    return response
}

//get a user by id
export const getUserById = async (
    baseURL,
    userId
) => {

    const response = await fetch(`${baseURL}/api/mgmt/phyfarmUsers/getUserById`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
             // Replace with your actual access token
        },
        body: JSON.stringify({
            "user_id": userId
        }),
    })

    return response;
}

//verify a user
export const verifyUser = async (
    baseURL,
    payload
) => {
    const response = await fetch(`${baseURL}/api/mgmt/phyfarmUsers/verify`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
             // Replace with your actual access token
        },
        body: JSON.stringify({
            "user_id": payload
        }),
    })

    return response;
}