import React, { useState, useEffect} from 'react';
import { Paper, TextField, Button, Typography, CircularProgress, Snackbar } from '@mui/material';
import Img from '../images/Phyfarm-App-footer.png'
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom'
import { Alert } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { forgotPassword } from '../apis/auth/api';

const ForgotPassword = () => {


    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const isSmScreen = useMediaQuery('(max-width:850px)');
    const [styles, setStyles] = useState({});
    

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    const handleEmailChange = (event) => {
        setEmail(event.target.value); // Update email state
    };

    const handleSendEmail = async () => {

        if (email === '' ) {
            setError('Empty fields');
            setSnackbarOpen(true);
            return; // Exit the function if fields are empty
        }
        try {

            setLoading(true);
            const response = forgotPassword(email);
            const data = await response.json();
            if(data.data){
                navigate("/confirmation", { replace: true })
                localStorage.setItem('resetMail', JSON.stringify(email));
            }
            else if(data.error.status === 400) {
                setError(
                    "This email does not exist");
                setSnackbarOpen(true);
            }else {
                setError('An error occurred while trying to sending link.');
                setSnackbarOpen(true);
            }
        } catch (error) {
       
            setError('An error occurred while sending link.');
            setLoading(false);
            setSnackbarOpen(true);
        }
    };


    useEffect(() => {
        if (isSmScreen) {
            setStyles({
                container: { width: '100%', height: '100%', maxWidth: 850 },
                // gridContainer: { display: 'flex', flexDirection: 'row', alignItems: 'flex-start' },
                paper: { position: "absolute", top: 150,margin: 10,  padding: 20, display: 'flex', flexDirection: 'column' }, 
            });
        } else {
            setStyles({
                container: { display: 'flex', flexDirection: 'column', zIndex: 1, position: 'relative' },
                // gridContainer: { flex: 1 },
                paper: {
                    padding: '30px',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    left: '30%',
                    marginTop: '190px',
                    marginLeft: '50px',
                    width: '400px',
                    height: '340px'
                },
               
            });
        }
    }, [isSmScreen]);

    return (
        <>


            <div

            style={styles.container}
                // style={{
                //     display: 'flex',
                //     flexDirection: 'column',
                //     zIndex: 1,
                //     position: 'relative',
                // }}
            >


                <Paper
                    elevation={5}
                    style={styles.paper}
                    // style={{
                    //     padding: '30px',
                    //     borderRadius: '15px',
                    //     width: '400px',
                    //     height: '340px',
                    //     display: 'flex',
                    //     flexDirection: 'column',
                    //     position: 'absolute',
                    //     left: '30%',
                    //     marginTop: '190px',
                    //     marginLeft: '50px'
                    // }}
                >
                    <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: 'bold', fontFamily:'sen'}}>
                       Forgot Password
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '30px', color: 'black', fontFamily: 'sen' }}>
                        Please enter your registered email for reset password link.
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            onChange={handleEmailChange}
                            InputProps={{
                                startAdornment: <EmailIcon style={{ marginRight: 8, color: 'gray' }} />,
                            }}
                            error={email === '' && error !== ''}

                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                      
                    </div>
                    <Button variant="contained" color="success" onClick={handleSendEmail} style={{ width: '100%', marginTop: '50px' }}>
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Send Link'}
                    </Button>
                </Paper>
            </div>

            <div >
                <img style={{ height: '100vh', width: '100vw', backgroundRepeat: 'no-repeat' }} src={Img} alt="Background" />
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Alert onClose={handleSnackbarClose} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </>

    );
};

export default ForgotPassword;