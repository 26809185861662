import React from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ProgramChecklist = ({progress}) => {
  return (
    <Stack sx={{ width: '100%', height: '100%',paddingY:2}} spacing={2}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={progress}   sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#028476',
                borderRadius:20
              },
              height:8,
              borderRadius:20
            }}/>
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="textSecondary" sx={{fontWeight:'700'}}>{`${Math.round(progress)}%`}</Typography>
        </Box>
      </Box>
    </Stack>
  );
}

export default ProgramChecklist;
