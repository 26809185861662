export const getAllTags = async (baseUrl, orgId, page, perPage, token) => {
  const response = await fetch(`${baseUrl}/api/mgmt/360-tags/list`, {
    method: "POST",
    headers: {
      accessToken: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      org_id: orgId,
      page: page,
      per_page: perPage,
    }),
  });

  return response;
};

export const addTags = async (baseUrl, orgId, token, name) => {
  const response = await fetch(`${baseUrl}/api/mgmt/360-tags/create`, {
    method: "POST",
    headers: {
      accessToken: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      org_id: orgId,
      name,
    }),
  });

  return response;
};

export const updateTags = async (baseUrl, orgId, token, name, tagId) => {
  const response = await fetch(`${baseUrl}/api/mgmt/360-tags/update`, {
    method: "POST",
    headers: {
      accessToken: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      org_id: orgId,
      name,
      id: tagId,
    }),
  });

  return response;
};

export const deleteTags = async (baseUrl, orgId, token, tagId, name) => {
  const response = await fetch(`${baseUrl}/api/mgmt/360-tags/delete`, {
    method: "POST",
    headers: {
      accessToken: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      org_id: orgId,
      id: tagId,
      name
    }),
  });

  return response;
};
