import React, { useCallback, useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    IconButton,
    Typography,
    Button,
    Divider,
    Paper,
    Modal,
    CircularProgress

} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { locationSuccess } from '../reducers/location';
import Autocomplete from '@mui/material/Autocomplete';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import useMediaQuery from '@mui/material/useMediaQuery';



const LocationMap = ({ open, handleClose, onChange }) => {


    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [latitude, setLat] = useState(23.073331);
    const [longitude, setLong] = useState(77.075442);
    const [isMarkerShown, setIsMarkerShown] = useState(false);
    const [formattedAddress, setformattedAddress] = useState('');
    const [zoom, setZoom] = useState(4);
    const isSmScreen = useMediaQuery('(max-width:850px)');

    const [styles, setStyles] = useState({});

    useEffect(() => {
        if (isSmScreen) {
            setStyles({
                container: { position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', maxWidth: 850 },
                gridContainer: { display: 'flex', flexDirection: 'row', alignItems: 'flex-start' },
                paper: { padding: 0, display: 'flex', flexDirection: 'column', height: '100%' },
                mapGrid: { padding: '16px', height: 480, overflow: 'hidden' },
                confirmLocationButton: { backgroundColor: '#5F9C6A', textAlign: 'right', width: '100%' }
            });
        } else {
            setStyles({
                container: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', maxWidth: 850 },
                gridContainer: { flex: 1 },
                paper: { padding: 20, display: 'flex', flexDirection: 'column', height: '100%', maxHeight: 630 },
                mapGrid: { padding: '16px', height: '100%', overflow: 'hidden' },
                confirmLocationButton: { backgroundColor: '#5F9C6A', textAlign: 'right' }
            });
        }
    }, [isSmScreen]);

    const dispatch = useDispatch();

    const fetchSuggestions = (inputValue) => {
        // You should make an actual API request here with the provided inputValue
        fetch(`https://dev.phyfarm.com/api/mgmt/googleapis/autocomplete?input=${inputValue}&sessiontoken=2d204b76-9888-490b-ab9b-ffde09ab80dc&components=country%3AIN`)
            .then((response) => response.json())

            .then((data) => {
                if (data.status === 'OK' && data.predictions) {
                    // Assuming the response has a "predictions" array
                    setSuggestions(data.predictions);

                } else {
                    console.error('No predictions or invalid response:', data);
                }
            })
            .catch((error) => {
                console.error('API request failed:', error);
            });
    };

    const handleConfirmLocation = () => {
        handleClose();
    }

    const onSelectLocation = useCallback((location) => {



        if (location) {
            setLoading(true);

            fetch(`https://dev.phyfarm.com/api/mgmt/googleapis/getCoordinates?address=${location.replace(/\s+/g, '')}&sessiontoken=5c3b47ad-e9b9-4664-8607-9aa7e11424d2&components=country%3AIN`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'OK' && data.results.length > 0) {
                        const { lat, lng } = data.results[0].geometry.location;
                        setLat(lat);
                        setLong(lng);
                        setIsMarkerShown(true);
                        setZoom(16);




                        fetch(`https://dev.phyfarm.com/api/mgmt/googleapis/getAddress?latlng=${lat}%2C${lng}&sessiontoken=7ab8bd12-4ef4-4eb8-9540-3f65d2794e2a`)
                            .then((response) => response.json())
                            .then((addressData) => {
                                if (addressData.status === 'OK' && addressData.results.length > 0) {


                                    const firstResult = addressData && addressData.results && Array.isArray(addressData.results) && addressData.results.length >= 1
                                        ? addressData.results[0]
                                        : null;


                                    console.log('FIRST RESULT', firstResult);

                                    if (firstResult) {
                                        const address = firstResult.formatted_address;
                                        const pincode = firstResult.address_components.find(el => el.types.includes('postal_code'))?.long_name;
                                        const city = firstResult.address_components.find(el => el.types.includes('administrative_area_level_2'))?.long_name;
                                        const state = firstResult.address_components.find(el => el.types.includes('administrative_area_level_1'))?.short_name;
                                        const country = firstResult.address_components.find(el => el.types.includes('country'))?.long_name;
                                        const latitude = firstResult.geometry.location.lat;
                                        const longitude = firstResult.geometry.location.lng;


                                        setformattedAddress(address)
                                        dispatch(locationSuccess({ pincode, city, state, country, latitude, longitude, address }));

                                        if (typeof onChange === 'function') {
                                            onChange({
                                                pincode, city, state, country,
                                                latitude: lat,
                                                longitude: lng,
                                                address,
                                            });
                                        }
                                    }


                                }
                            })
                            .catch((error) => {
                                console.error('API request for address failed:', error);
                            })
                            .finally(() => {
                                setLoading(false);
                            });


                    }

                    else {
                        console.error('No coordinates data or invalid response:', data);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.error('API request for coordinates failed:', error);
                    setLoading(false);
                });
        }



    }, [dispatch, onChange]);


    const MyMapComponent = withScriptjs(withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={props.zoom}
            defaultCenter={props.center}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
        </GoogleMap>
    ))
    )



    return (

        <Modal open={open} onClose={false}>
            <div style={styles.container}>
                <Paper style={styles.paper}>

                    <Grid container style={styles.gridContainer}>

                        <Grid item xs={isSmScreen ? 12 : 6} style={styles.mapGrid}>


                            <div style={{ padding: '16px' }}>
                                {/* You can add any content here */}

                                <MyMapComponent
                                    isMarkerShown={isMarkerShown}
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwMUqV9i_a7ILMTIwmgxBAzymf_T6hIR4&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `480px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    latitude={latitude}
                                    longitude={longitude}
                                    center={{ lat: latitude, lng: longitude }}
                                    zoom={zoom}
                                />

                            </div>
                        </Grid>
                        <Grid item xs={isSmScreen ? 12 : 6} style={{ padding: '16px', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ padding: '16px', display: 'flex', flexDirection: 'column' }}>
                                <Typography style={{ fontFamily: 'sen' }}>Pin Location</Typography>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={handleClose}
                                    style={{ position: 'absolute', top: 5, right: 10 }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Divider />
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={suggestions.map((option) => option.description)}
                                    sx={{ width: 320, marginTop: 2 }}
                                    renderInput={(params) => <TextField {...params} label="Search" />}

                                    onChange={(event, newValue) => onSelectLocation(newValue)}
                                    onInputChange={(event, newInputValue) => {
                                        fetchSuggestions(newInputValue);
                                    }}

                                />
                                <div style={{ marginTop: 30 }}>
                                    <FmdGoodOutlinedIcon style={{ color: 'green' }} />
                                    <Typography style={{ fontWeight: 'bold', fontFamily: 'sen' }}>Location Selected</Typography>
                                    <Typography style={{ fontFamily: 'sen', overflow: 'auto' }} >{formattedAddress}</Typography>
                                </div>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 'auto' }}>
                                <Button variant="contained" style={styles.confirmLocationButton} onClick={handleConfirmLocation}>
                                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Confirm Location'}
                                </Button>
                            </div>
                        </Grid>

                    </Grid>
                </Paper>
            </div>

        </Modal>

    );
};


export default LocationMap;