import React, { useState, useEffect, useCallback } from 'react'
import Layout from '../pages/Layout'
import { TextField, Typography, Button, CircularProgress, TablePagination } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
// import { useDispatch } from 'react-redux';
// import { Select, MenuItem } from '@mui/material';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { SlCalender } from 'react-icons/sl';
import {baseURL} from '../config';
import { useNavigate } from 'react-router-dom';
import { LuBadgeInfo } from 'react-icons/lu';
import {BEARER_TOKEN} from '../config'
// import { leadSuccess } from '../reducers/leadData';

const Leads = () => {




    // const [status, setStatus] = useState("active");

    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [userData, setUserData] = useState({});
    const [leadData, setLeadData] = useState({});
    const [loading, setLoading] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    const navigate = useNavigate();

    // const dispatch = useDispatch();




    const getUserleadDataFromLocalStorage = () => {
        const userleadDataFromLocalStorage = localStorage.getItem('userData');
        if (userleadDataFromLocalStorage) {
            // If leadData exists in localStorage, parse and set it in the state
            setUserData(JSON.parse(userleadDataFromLocalStorage));
        }
    };


    const fetchleadData = useCallback((filters) => {

        setLoading(true);

        const apiUrl = `${baseURL}/api/mgmt/leads/listLeads`;

        const requestData = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + BEARER_TOKEN
                // Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', // Replace with your actual access token
            },
        };

        if (Object.keys(filters).length > 0) {
            requestData.body = JSON.stringify({
                pagination: {
                    current_page: 1,
                },
                filters,
            });
        } else {
            requestData.body = JSON.stringify({
                pagination: {
                    current_page: page + 1,
                },
                filters: {},
            });
        }


        fetch(apiUrl, requestData)
            .then((response) => response.json())
            .then((data) => {
                console.log("DATA", data);
                setLeadData(data);
                setTotalCount(data.data.leads.total_count);
                setLoading(false);
                // console.log(data)  // Update the data state with the fetched data
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    }, [page]);



    useEffect(() => {
        getUserleadDataFromLocalStorage();
    }, []);


    useEffect(() => {
        fetchleadData({});
    }, [page, fetchleadData]);


    const handleChangePage = useCallback((event, newPage) => {
        setPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback(event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, []);



    // const handleStatusChange = (event) => {
    //     setStatus(event.target.value);
    // }


    const handleCalendarClick = () => {
        setDatePickerOpen(!isDatePickerOpen);
    }



    function isNumeric(inputString) {
        return /^\d+$/.test(inputString);
    }

    function isTenDigitNumber(inputString) {
        return /^\d{10}$/.test(inputString);
    }

    const getLeadData = (leadID) => {


        const apiUrl = `${baseURL}/api/mgmt/leads/getLead`;

        const requestData = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + BEARER_TOKEN
                // Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', // Replace with your actual access token
            },
            body: JSON.stringify({

                "id": leadID,
            }),
        };

        fetch(apiUrl, requestData)
            .then((response) => response.json())
            .then((data) => {
                setUserData(data);
                navigate('/lead-details', { state: { leadData: data } });
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    }


    const handleSearchClick = () => {
        let filters = {};
        if (searchQuery.includes('@')) {
            filters.email = searchQuery;
        }

        if (isNumeric(searchQuery) && isTenDigitNumber(searchQuery)) {
            filters.phone = searchQuery;
        }

        fetchleadData(filters);
    };

    console.log(userData)



    return (
        <Layout>
            <div style={{ marginLeft: 280 }}>

                <div style={{ padding: 10, width: 1100 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            style={{ width: 1100, marginRight: 16 }} // Add marginRight to create space
                            label='Search'
                            InputProps={{
                                endAdornment: <Button><SearchIcon style={{ fontSize: 24, color: 'gray' }} onClick={handleSearchClick} /></Button>, // Increase fontSize for a larger icon
                            }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />

                        {
                            userData && userData.role && userData.role.permissions && userData.role.permissions.leads_mgmt && userData.role.permissions.leads_mgmt.create ? <Link to="/add-lead" style={{ textDecoration: 'none' }}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    style={{
                                        borderRadius: '50%',  // Set the border radius to 50% for a circle
                                        width: '40px',       // Adjust the width to make it smaller
                                        height: '40px',      // Adjust the height to make it smaller
                                        minWidth: '40px',    // Ensure a minimum width for the button
                                        minHeight: '40px',   // Ensure a minimum height for the button
                                        fontSize: '1.2rem',  // Adjust the font size as needed
                                    }}
                                >
                                    +
                                </Button>
                            </Link> : null
                        }

                        <IoIosNotificationsOutline style={{ fontSize: 30, marginLeft: 20 }} />
                        <SlCalender style={{ marginLeft: 20 }} onClick={handleCalendarClick} />

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 40 }}>
                        <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>Leads<IconButton><FilterAltIcon /></IconButton></Typography>

                    </div>

                </div>
            </div>

            <div>
                <div>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage=""
                        labelDisplayedRows={({ from, to, count }) => `Navigate to Next Page: ${from}-${to} of ${count}`}
                    />
                </div>
            </div>

            <div>
                {
                    loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Name
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Phone No.
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Email ID
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Lead ID
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Lead Owner
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Company Name
                                        </TableCell>
                                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                            Lead Information
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {leadData && leadData.data && leadData.data.leads && Array.isArray(leadData.data.leads.leads) && leadData.data.leads.leads.length > 0 ? (
                                        leadData.data.leads.leads.map((item, index) => (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                                <TableCell align="center">{item.fullName}</TableCell>
                                                <TableCell align="center">{item.phone}</TableCell>
                                                <TableCell align="center">{item.email}</TableCell>
                                                <TableCell align="center">{item.id}</TableCell>
                                                <TableCell align="center">{item.createdBy}</TableCell>
                                                <TableCell align="center">{item.companyName}</TableCell>
                                                <TableCell align="center">
                                                    <Button onClick={() => getLeadData(item.id)}><LuBadgeInfo /></Button>
                                                </TableCell>

                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell align="center" colSpan={6}>
                                                {leadData &&
                                                    leadData.data &&
                                                    leadData.data.leads &&
                                                    leadData.leadData.leads.total_count === 0 ? (
                                                    <Typography variant="subtitle1">No leadData available.</Typography>
                                                ) : null}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }

            </div >



        </Layout >

    )
}

export default Leads