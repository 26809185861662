import React, { useCallback, useEffect, useState, useRef, Suspense } from 'react';
import Layout from './Layout';
import { Stack, Typography, MenuItem, Select, InputLabel, FormControl, Button } from '@mui/material';
import moment from 'moment';
import {baseURL} from '../config';
import { getAgroUsers, getChatMessages, listByRole } from '../apis/location-chats/api';
import { formatTimeTo12HR } from '../utils/helpers';
import Loading from '../components/Loading';

const ChatElement = ({ user, onUserClick }) => {
    return (
        <>
            {user ? (
                user.length > 0 ? (
                    user.map((data, index) => (
                        <Button
                            key={index}
                            sx={{
                                borderRadius: 1,
                                marginTop: 2,
                                marginBottom: 3,
                                marginLeft: 9,
                                marginRight: 2,
                                padding: '10px 20px',
                                backgroundColor: 'white',
                                boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
                            }}
                            onClick={() => onUserClick(data.id, data.phone)}
                        >
                            <div style={{ color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>
                                    <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                                        {data.fullname}
                                    </Typography>
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                                        {data.phone}
                                    </Typography>
                                </div>
                            </div>
                        </Button>
                    ))
                ) : (
                    <Typography style={{ fontFamily: 'sen', textAlign: 'center' }}>Select admin to view users</Typography>
                )
            ) : (
                <Typography style={{ fontFamily: 'sen', textAlign: 'center' }}>No users for this admin</Typography>
            )}
        </>
    );
};

const AgroChatListing = () => {

    const [agroName, setAgroName] = useState([]);
    const [agroID, setAgroID] = useState('');
    const [agroUsers, setAgroUsers] = useState([]);
    const [userChat, setUserChat] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const orgID = userData?.org?.id;

    const chatContainerRef = useRef(null);

    let prevDate = null;

    const listAgronomist = useCallback(async (orgID) => {

        if (!orgID) return;
        try {
            const response = await listByRole(baseURL, orgID);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching agro user', error);
        }

    }, []);

    const listAgroUsers = useCallback(async (agroID) => {

        if(!agroID) return;

        try {
            const response = await getAgroUsers(agroID);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching agro user', error);
        }
    }, []);


    useEffect(() => {
        const fetchInitialData = async () => {
            const data = await listAgronomist(orgID);
            if (data.data.users.length > 0) {
                setAgroName(data.data.users);
            }
        }
        fetchInitialData();
    }, [listAgronomist, orgID]);

    useEffect(() => {
        const fetchAgroUsers = async () => {
            if (agroID) {
                const agroUsers = await listAgroUsers(agroID);
                if (agroUsers.data.data.length > 0) {
                    setAgroUsers(agroUsers.data.data);
                }
            }
        }
        fetchAgroUsers();
    }, [agroID, listAgroUsers]);

    const fetchChatData = async (id, phone) => {

        if (!id || !phone) return;

        setIsLoading(true);
        try{

        const response = await getChatMessages(id, phone);
        const data = await response.json();
        if (data.data.data.chat.length > 0) {
            setUserChat(data.data.data.chat);
            setIsLoading(false);
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }

        }catch(error){
            console.error('Error fetching chat data', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [userChat]);

    return (
        <Layout>
            <Stack direction="row">
                <div style={{ position: 'fixed', width: 400, backgroundColor: "#F8F5F5", boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25', height: '100vh', overflowY: 'auto', marginTop: 60 }}>
                    <div>
                        <Typography style={{ fontFamily: 'sen', padding: 8, fontWeight: 'bold', textAlign: 'center', fontSize: 25, marginTop: 5, marginLeft: 60 }}>Org Chats</Typography>
                    </div>
                    <Stack>
                        <FormControl sx={{ marginTop: 2, marginBottom: 3, marginLeft: 10, marginRight: 2 }}>
                            <InputLabel id="demo-simple-select-label">Select Admin</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={agroID}
                                label="Agronomists"
                                style={{ backgroundColor: "white" }}
                                onChange={(e) => {
                                    setAgroID(e.target.value);
                                    setUserChat([]); // Clear userChat when a new agronomist is selected
                                }}
                            >
                                {
                                    agroName && agroName.map((agro) => (
                                        <MenuItem key={agro.id} value={agro.id}>{agro.full_name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack>
                        <ChatElement user={agroUsers} onUserClick={(id, phone) => fetchChatData(id, phone)} />
                    </Stack>
                </div>
                <div ref={chatContainerRef} style={{ marginLeft: 400, height: '100vh', width: "calc(100vw - 400px)", backgroundColor: 'white', overflowY: 'auto', marginTop: 90 }}>
                    {
                        isLoading ? (
                            <Loading />
                        ) : (
                            userChat && Array.isArray(userChat) ? userChat.map((chat, index) => {
                                const currentDate = moment(chat.created_at).format('MMM D, YYYY');
                                const showDate = prevDate !== currentDate;
                                prevDate = currentDate;

                                return (
                                    <React.Fragment key={index}>
                                        {showDate && (
                                            <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                                <div style={{ backgroundColor: '#EFEDED', padding: '5px 10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                                    <Typography style={{ color: '#333', fontWeight: 'bold', fontFamily: 'sen' }}>
                                                        {currentDate}
                                                    </Typography>
                                                </div>
                                            </div>
                                        )}
                                        <Stack direction="row" justifyContent={chat.role === "user" ? "start" : "end"} alignItems="center" key={index}>
                                            <div style={{ padding: 12, backgroundColor: chat.role === "user" ? "#F4F7FB" : "#E7F2E7", color: "#285428", borderRadius: 8, width: "50%", marginBottom: 15, maxWidth: '100%', marginLeft: 10, marginRight: 10 }}>
                                                {chat && chat.message_type === "text" ? (
                                                    <Typography variant="body2" style={{ color: "#285428" }}>
                                                        {chat.message.split('\n').map((line, index) => (
                                                            <React.Fragment key={index}>
                                                                {line}
                                                                <br />
                                                            </React.Fragment>
                                                        ))}
                                                    </Typography>
                                                ) : (
                                                    <Suspense fallback={<Loading />}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                            <img style={{ maxWidth: '100%', maxHeight: '300px', borderRadius: '8px', cursor: 'pointer' }} src={chat.url} alt="user_img" />
                                                        </div>
                                                    </Suspense>
                                                )}
                                                <div style={{ textAlign: 'right' }}>
                                                    <Typography variant="caption" style={{ color: '#7E7E7E' }}>{formatTimeTo12HR(chat.created_at)}</Typography>
                                                </div>
                                            </div>
                                        </Stack>
                                    </React.Fragment>
                                );
                            }) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Typography style={{ fontFamily: 'sen' }}>
                                        Select a admin to view the chat
                                    </Typography>
                                </div>
                            )
                        )
                    }
                </div>
            </Stack>
        </Layout>
    );
};

export default AgroChatListing;