import {BEARER_TOKEN} from '../../config'

export const searchFarms = async (
    baseURL,
    payload
) => {

    const response = fetch(baseURL + '/api/mgmt/farmcrm/searchFarm', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })

    return response;
}

export const getEnvsFor = async (
    baseURL,
    payload
) => {

    const response = fetch(baseURL + '/api/mgmt/env/listEnvsCms', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })

    return response;
}

export const listSensors = async (
    baseURL,
    payload
) => {
    const response = fetch(baseURL + '/api/mgmt/device/listSensorsCms', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })

    return response;
}

export const latestSensorData = async (
    
    baseURL,
    payload
) => {
    const response = await fetch(baseURL + '/api/envDataCrm/listMultipleEnvLatestValue', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });

    return response;
}
