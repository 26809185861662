export const get360Stats = async (baseURL, org_id) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = userData.access_token;
  const response = await fetch(`${baseURL}/api/mgmt/360-stats/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accessToken: accessToken,
    },
    body: JSON.stringify({
      org_id,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch stats");
  }

  return response.json();
};
export const getSoilTestReport = async (baseURL, page, per_page, org_id,farmer_id) => {


    farmer_id=farmer_id || undefined

  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = userData.access_token;

  const response = await fetch(`${baseURL}/api/mgmt/360-soil-reports/list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accessToken: accessToken,
    },
    body: JSON.stringify({
      page,
      per_page,
      filters: {
        org_id,
        farmer_id
      },
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch soil test report");
  }

  return response.json();
};


export const uploadSoilReport = async (baseURL, org_id, file) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData.access_token;
  
    const formData = new FormData();
    formData.append('org_id', org_id);
    formData.append('file', file);
  
    const response = await fetch(`${baseURL}/api/mgmt/360-soil-reports/bulkUpload`, {
      method: 'POST',
      headers: {
        'accessToken': accessToken,
      },
      body: formData,
    });
  
    if (!response.ok) {
      throw new Error('Failed to upload soil report');
    }

  
    return response.json();
  };