import React, { useState, useEffect, useCallback } from 'react';
import { Typography, TextField, Button, Select, MenuItem, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {baseURL} from '../config';
import Layout from '../pages/Layout';
import { useParams } from 'react-router-dom';
import LocationMap from './LocationMap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import {BEARER_TOKEN} from '../config'




const EditRequirement = () => {

    const { id, leadId } = useParams();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [leadData, setLeadData] = useState({});
    
    const [requirementData, setRequirementData] = useState({});
    const [locationChange, setLocationChangeOpen] = useState(false);

   



    const handleOpenMapChange = () => {
        setLocationChangeOpen(true);
    }

    const handleCloseMapChange = () => {
        setLocationChangeOpen(false);
    }
   

    const getLeadData = useCallback(() => {


        const apiUrl = `${baseURL}/api/mgmt/leads/getLead`;

        const requestData = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + BEARER_TOKEN
                // Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', // Replace with your actual access token
            },
            body: JSON.stringify({

                "id": leadId,
            }),
        };

        fetch(apiUrl, requestData)
            .then((response) => response.json())
            .then((data) => {
                setLeadData(data);
           
            })
            .catch((error) => {
                console.error('API Error:', error);
            });
    }, [leadId]);





    useEffect(() => {
        getLeadData();
    }, [getLeadData]);


    const handleLocationChange = (locationData) => {

     
        // Use the locationData to update the farm data in setFarmData
        setRequirementData((prevRequirementData) => ({
            ...prevRequirementData,
            data: {
                ...prevRequirementData.data,
                lead_requirement: {
                    ...prevRequirementData.data.lead_requirement,
                 farm:{
                    ...prevRequirementData.data.lead_requirement.farm,
                    location : locationData.address,
                    latitude : locationData.latitude,
                    longitude : locationData.longitude
                 }
                }
            }
        }));
    };


    const getRequirementData = useCallback(() => {

        fetch(`${baseURL}/api/mgmt/leads/getLeadsRequirement`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + BEARER_TOKEN
                 // Replace with your actual access token
            },
            body: JSON.stringify({
                id,
                leadId
            }),
        })
            .then(response => response.json())
            .then(data => {
             
                setRequirementData(data);
            })
            .catch(error => {
                console.error('Error fetching organization ID:', error);
            });
    }, [id, leadId])


    useEffect(() => {
        getRequirementData();
    }, [id, leadId, getRequirementData]);




    const handleSave = () => {

        console.log(requirementData);

        const formattedDate = new Date(requirementData.data.lead_requirement.leadDate).toISOString();
        // console.log(farmData);

        // const { city, state, pincode, country, lat, long } = farmData.location;
        // const { address } = locationFromRedux;
        // console.log(farmData.location);

        const payload = {
            userId: id,
            leadRequirements:{
                id,
                leadId,
                leadStage: "abc",
                leadDate: formattedDate,
                customerNote: requirementData.data.lead_requirement.customerNote,
                currentProduce: requirementData.data.lead_requirement.currentProduce,
                productID: "22A",
                isFarmAvailable: true,
                farm:{
                    name: requirementData.data.lead_requirement.farm.name,
                    size: parseInt(requirementData.data.lead_requirement.farm.size,10),
                    sizeUnit: requirementData.data.lead_requirement.farm.sizeUnit,
                    type: "abc",
                    location:requirementData.data.lead_requirement.farm.location,
                    latitude:requirementData.data.lead_requirement.farm.latitude,
                    longitude:requirementData.data.lead_requirement.farm.longitude,
                }

            }

      
            
        }
        // console.log('PAYLOAD',payload);
        fetch(`${baseURL}/api/mgmt/leads/updateLeadRequirement`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + BEARER_TOKEN
                 // Replace with your actual access token
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                // Handle the API response as needed
                console.log('API response:', data);
                if (!data.error) {

                    // Show the success Snackbar and clear the form fields
                    setSnackbarOpen(true);
                    setSnackbarMessage('Farm updated successfully');
                    setSnackbarSeverity('success');
                    // handleClose();
                    // getRequirementData();


                } else {
                    console.log(data);
                }
            })
            .catch(error => {
                console.error('Error making API call:', error);
            });

    }


    const MyMapComponent = withScriptjs(withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={props.zoom}
            defaultCenter={props.center}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
        </GoogleMap>
    ))
    );

console.log('REQ DATA:',requirementData);

    return (

        <Layout>
           <div style={{ marginLeft: 280 }}>
                <div style={{ padding: 10, width: '80%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography style={{ fontSize: '25px', fontWeight: 'bold', fontFamily: 'sen' }}>
                                <span style={{ color: 'gray' }}>Leads</span> <span>&gt;</span> {leadData && leadData.data && leadData.data.lead && leadData.data.lead.fullName} <span>&gt;</span> Edit Requirement
                            </Typography>
                        </div>
                    </div>
                    <form >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: 20,
                                marginTop: 30
                            }}
                        >
                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>Customer Requirements</Typography>

                        </div>


                        <div>
                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', marginBottom: 10 }}>Notes by Customer</Typography>
                            <TextField
                            value={requirementData && requirementData.data && requirementData.data.lead_requirement && requirementData.data.lead_requirement.customerNote}
                             style={{ width: '100%' }} multiline rows={3} cols={3} 
                             onChange={(e) =>
                                setRequirementData({
                                    ...requirementData,
                                    data: {
                                        ...requirementData.data,
                                        lead_requirement: {
                                            ...requirementData.data.lead_requirement,
                                            customerNote: e.target.value
                                        },
                                    },
                                })
                            }
                        
                              />
                            

                            <div style={{display: 'flex',marginTop: 15}}>

                                <div>
                                    <Typography style={{fontFamily: 'sen'}}>Lead Date</Typography>
                                    <TextField variant="outlined" type="date" style={{ marginTop: 5 }} 
                                       onChange={(e) =>
                                        setRequirementData({
                                            ...requirementData,
                                            data: {
                                                ...requirementData.data,
                                                lead_requirement: {
                                                    ...requirementData.data.lead_requirement,
                                                    leadDate: e.target.value
                                                },
                                            },
                                        })
                                    }
                                
                                    />
                                </div>
                                <div style={{marginLeft: 20}}>
                                    <Typography style={{fontFamily: 'sen'}}>Intrested In</Typography>
                                    <TextField variant="outlined" style={{ marginTop: 5 }} />
                                </div>
                                <div style={{marginLeft: 20}}>
                                    <Typography  style={{fontFamily: 'sen'}}>Current Produce</Typography>
                                    <TextField
                                       onChange={(e) =>
                                        setRequirementData({
                                            ...requirementData,
                                            data: {
                                                ...requirementData.data,
                                                lead_requirement: {
                                                    ...requirementData.data.lead_requirement,
                                                    currentProduce: e.target.value
                                                },
                                            },
                                        })
                                    }
                                
                                     value={requirementData && requirementData.data && requirementData.data.lead_requirement && requirementData.data.lead_requirement.currentProduce}
                                     variant="outlined" style={{ marginTop: 5 }} />
                                </div>

                            </div>
                        </div>

                        <div>
                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' , marginTop: 20}}>Farm Location</Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography

                                    style={{ fontFamily: 'sen' }}
                                    // value={editData && editData.lead && editData.lead.fullName}
                                    onChange={(e) => setRequirementData({
                                        ...requirementData,
                                        data: {
                                          ...requirementData.data,
                                          lead_requirement: {
                                            ...requirementData.data.lead_requirement,
                                            farm: {
                                              ...requirementData.data.lead_requirement.farm,
                                              location: e.target.value, // Update farmName with the new value
                                            },
                                          },
                                        },
                                      })
                                }

                                >{requirementData && requirementData.data && requirementData.data.lead_requirement && requirementData.data.lead_requirement.farm && requirementData.data.lead_requirement.farm.location}</Typography>
                                <Button onClick={handleOpenMapChange} color='success'> Change</Button>
                            </div>
                            <MyMapComponent
                                isMarkerShown={true}
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwMUqV9i_a7ILMTIwmgxBAzymf_T6hIR4&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `480px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                lat={requirementData?.data?.lead_requirement?.farm?.latitude}
                                lng={requirementData?.data?.lead_requirement?.farm?.longitude}
                                // onChange={updateAddress}
                                center={{ lat: requirementData?.data?.lead_requirement?.farm?.latitude, lng: requirementData?.data?.lead_requirement?.farm?.longitude }}
                                zoom={16}
                            />
                         <LocationMap open={locationChange} handleClose={handleCloseMapChange} onChange={handleLocationChange} handleHideFarm={handleCloseMapChange} />
                        </div>

                        <div style={{ display: 'flex', marginTop: 10 }}>

                            <div>
                                <Typography style={{ fontFamily: 'sen' }}>Farm Name</Typography>
                                <TextField
                                    value={requirementData && requirementData.data && requirementData.data.lead_requirement && requirementData.data.lead_requirement.farm && requirementData.data.lead_requirement.farm.name}
                                    onChange={(e) => setRequirementData({
                                        ...requirementData,
                                        data: {
                                          ...requirementData.data,
                                          lead_requirement: {
                                            ...requirementData.data.lead_requirement,
                                            farm: {
                                              ...requirementData.data.lead_requirement.farm,
                                              name: e.target.value, // Update farmName with the new value
                                            },
                                          },
                                        },
                                      })
                                }
                                ></TextField>
                            </div>

                            <div style={{ marginLeft: 20 }}>
                                <Typography style={{ fontFamily: 'sen' }}>Farm Size</Typography>
                                <TextField
                                    value={requirementData && requirementData.data && requirementData.data.lead_requirement && requirementData.data.lead_requirement.farm && requirementData.data.lead_requirement.farm.size}
                                    onChange={(e) =>
                                        setRequirementData({
                                            ...requirementData,
                                            data: {
                                              ...requirementData.data,
                                              lead_requirement: {
                                                ...requirementData.data.lead_requirement,
                                                farm: {
                                                  ...requirementData.data.lead_requirement.farm,
                                                  size: e.target.value, // Update farmSize with the new value
                                                },
                                              },
                                            },
                                          })
                                    }

                                ></TextField>

                            </div>
                            <div style={{ width: '180px', marginLeft: 20 }}>
                                <Typography style={{ fontFamily: 'sen' }}>Unit</Typography>
                                <Select
                                    value={requirementData && requirementData.data && requirementData.data.lead_requirement && requirementData.data.lead_requirement.farm && requirementData.data.lead_requirement.farm.sizeUnit}
                                    onChange={(e) => setRequirementData({
                                        ...requirementData,
                                        data: {
                                          ...requirementData.data,
                                          lead_requirement: {
                                            ...requirementData.data.lead_requirement,
                                            farm: {
                                              ...requirementData.data.lead_requirement.farm,
                                              sizeUnit: e.target.value, // Update Unit with the new value
                                            },
                                          },
                                        },
                                      })
                                }
                                    sx={{ width: '170px' }}
                                    name="preferredLanguage"

                                >
                                    <MenuItem value="acre">Acres</MenuItem>
                                    <MenuItem value="hectare">Hectare</MenuItem>
                                    <MenuItem value="sqft">Sqft</MenuItem>
                                    <MenuItem value="sqmt">Sqmt</MenuItem>
                                    {/* Add more options as needed */}
                                </Select>

                            </div>

                        </div>
                       




                        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
                            <Button variant="contained" style={{ marginTop: '40px', width: '600px', backgroundColor: "#A6B5A4", fontFamily: 'sen', marginRight: 25 }}>Cancel</Button>
                            <Button variant="contained" color="success" style={{ marginTop: '40px', width: '600px', fontFamily: 'sen' }}
                               onClick={handleSave}
                            >Save Changes</Button>

                        </div>
                        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                            <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                                {snackbarMessage}
                            </MuiAlert>
                        </Snackbar>
                    </form>
                </div>

                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                    <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </div>



        </Layout>

    );
};

export default EditRequirement