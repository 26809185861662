import React, { useState, useCallback, useEffect } from 'react'
import Layout from './Layout';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Grid, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { IoInformationCircleOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { FaEye } from "react-icons/fa";
import Farm from '../components/Farm';
import ViewFarm from '../components/ViewFarm';
import { getOrgId } from '../apis/api';
import { listFarmsByOrgID } from '../apis/farm-mgmt/farm';
import { BEARER_TOKEN } from '../config';
import { createPermission } from '../utils/helpers';


const UserDetails = () => {

    const [userData, setUserData] = useState([]);
    const [farmdata, setFarmData] = useState([]);
    const [orgId, setOrgId] = useState(null);
    const [selectedFarm, setSelectedFarm] = useState(null);
    const [openFarm, setOpenFarm] = useState(false);
    const navigate = useNavigate();
    const { userId } = useParams();
    const [openViewFarm, setOpenViewFarm] = useState(false);
    const baseURL = useSelector((state) => state.environmentReducer.environment);
    const loginUser = JSON.parse(localStorage.getItem('permissions'));


    const getUserbyID = useCallback(async (userId) => {

        if (!userId) return;

        try {
            const apiUrl = `${baseURL}/api/mgmt/phyfarmUsers/getUserById`;

            const requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + BEARER_TOKEN
                    // Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', // Replace with your actual access token
                },
                body: JSON.stringify({

                    "user_id": userId,
                }),
            };

            const response = await fetch(apiUrl, requestData)
            const data = await response.json();
            
            return data;
        } catch (error) {


            console.error('API Error:', error);
        }
    }, [baseURL]);


    const handleAddFarm = () => {
        const fullName = `${userData?.data?.user?.firstName} ${userData?.data?.user?.lastName}`;
        navigate(`/register-form/${fullName}/${userId}`);
    }

    //get orgId for the user
    const fetchOrgId = useCallback(async (baseURL, userId) => {

        if (!userId) return;
        try {
            const response = await getOrgId(baseURL, userId);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching organization ID:', error);
        }
    }, []);

    const fetchFarmData = useCallback(async (orgId) => {

        if (!orgId) return;

        try {

            const response = await listFarmsByOrgID(baseURL, orgId)
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching farm data:', error);
        }
    }, [baseURL]);

    const handleOpenFarm = (farm) => {
        setSelectedFarm(farm);
    };

    const handleCloseFarm = () => {
        setOpenFarm(false);
    }

    const handleOpenViewFarm = (farm) => {
        setSelectedFarm(farm);
    };

    const handleCloseViewFarm = () => {
        setOpenViewFarm(false);
    }

    const getFarmData = useCallback(async (orgId) => {

        if (!orgId) return;

        try {
            const response = await listFarmsByOrgID(baseURL, orgId)
            const data = await response.json();
            setFarmData(data?.data?.farms);
        } catch (error) {
            console.error('Error fetching farm data:', error);
        }
    }, [baseURL]);


    const hasCreatePermission = createPermission('farm_mgmt', loginUser);

    useEffect(() => {

        const fetchInitialData = async () => {

            const user = await getUserbyID(userId);
            if (user?.data?.user) {
                setUserData(user);
            }
            const orgId = await fetchOrgId(baseURL, userId);


            if (orgId?.data?.org_id) {
                setOrgId(orgId?.data?.org_id);
            }
            const farmData = await fetchFarmData(orgId?.data?.org_id);
            if (farmData?.data?.farms) {
                setFarmData(farmData?.data?.farms);
            }
        }
        fetchInitialData();
    }, [userId, getUserbyID, fetchOrgId, fetchFarmData, baseURL])


    return (
        <>
            <Layout>
                <div style={{ marginTop: 60, marginLeft: 60 }}>


                    <Grid container spacing={2} style={{ backgroundColor: "#F7F5F5", height: "95vh", marginTop: 60 }}>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Paper style={{ marginLeft: 10, marginTop: 10, backgroundColor: "#ECF1F1" }}>
                                <div style={{ padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Avatar aria-label="recipe" style={{ width: 80, height: 80, fontSize: 25, fontFamily: 'sen' }}>
                                            {userData?.data?.user?.firstName[0]}
                                        </Avatar>
                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', marginTop: 10, fontSize: 30 }}>
                                            {userData?.data?.user?.firstName + ' ' + userData?.data?.user?.lastName}
                                        </Typography>

                                    </div>
                                </div>
                                <hr />
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, padding: 20 }}>
                                    <div>
                                        <div style={{ marginBottom: '20px' }}> {/* Add marginBottom for spacing */}
                                            <Typography style={{ fontFamily: 'sen' }}>Name</Typography>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 15 }}>{userData?.data?.user?.firstName + ' ' + userData?.data?.user?.lastName}</Typography>
                                        </div>
                                        <div style={{ marginBottom: '20px' }}> {/* Add marginBottom for spacing */}
                                            <Typography style={{ fontFamily: 'sen' }}>Phone</Typography>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 15 }}>{userData?.data?.user?.phone}</Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ marginBottom: '20px' }}>
                                            <Typography style={{ fontFamily: 'sen' }}>Email</Typography>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 15 }}>{userData?.data?.user?.email}</Typography>
                                        </div>
                                        <div style={{ marginBottom: '20px' }}>
                                            <Typography style={{ fontFamily: 'sen' }}>Org ID</Typography>
                                            <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 15 }}>{orgId}</Typography>
                                        </div>

                                    </div>
                                </div>
                            </Paper>

                        </Grid>

                        <Grid item xs={12} sm={6} md={8} lg={9}>

                            <div style={{ margin: 10 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                    <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 20 }}>
                                        Farm Management
                                    </Typography>
                                    {
                                        hasCreatePermission && (

                                            <Button variant="contained" color='success' style={{ color: 'white', fontFamily: 'sen', fontWeight: 'bold' }} onClick={handleAddFarm}>
                                                + Add Farm
                                            </Button>
                                        )}
                                </div>
                            </div>

                            <div style={{ margin: 10, height: "80vh", overflowY: "auto" }}>

                                <Grid container spacing={2} >
                                    {farmdata && Array.isArray(farmdata) && farmdata.length > 0 ? (
                                        farmdata.map((farm, index) => (
                                            <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                                                <Card style={{ marginBottom: '10px', marginTop: 10 }}>
                                                    <CardHeader
                                                        title={
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                                                                    {farm?.farm?.name}
                                                                </Typography>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <IconButton onClick={() => {
                                                                        setOpenViewFarm(true);
                                                                        handleOpenViewFarm(farm);
                                                                    }}>
                                                                        <FaEye style={{ color: "green" }} />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => {
                                                                        setOpenFarm(true);
                                                                        handleOpenFarm(farm);
                                                                    }}>
                                                                        <IoInformationCircleOutline style={{ color: "green" }} />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        }
                                                    ></CardHeader>
                                                    <CardContent>
                                                        <div style={{ display: 'flex', flexDirection: "column" }}>
                                                            <div style={{ height: 100, overflowY: "scroll" }}>
                                                                <Typography style={{ fontFamily: 'sen', fontSize: 15, color: "gray", overflowWrap: 'break-word' }}>
                                                                    {farm.location.address}, {farm.location.city}, {farm.location.state}
                                                                </Typography>
                                                            </div>
                                                            <div style={{ marginTop: 10 }}>
                                                                <label>Size</label>
                                                                <Typography style={{ fontFamily: 'sen', fontSize: 15, color: "gray" }}>
                                                                    {farm?.farm?.size} {farm?.farm?.size_unit}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Typography style={{ fontFamily: 'sen', fontSize: 20, marginTop: 20, marginLeft: 20 }}>
                                            You dont have any farms yet. Click on the + Add Farm button to add a farm.
                                        </Typography>
                                    )}
                                </Grid>
                            </div>

                        </Grid>
                    </Grid>
                </div>

                {selectedFarm && <ViewFarm farmData={selectedFarm} openViewFarm={openViewFarm} onClose={handleCloseViewFarm} userId={userId} />}

                {/* <UserNotes open={openNotes} handleClose={handleOpenNotes} data={userData} />
            <UserTask open={isOpenTask} handleClose={handleOpenTask} />
            <UserFiles open={isOpenFiles} handleClose={handleOpenFiles} />
            <UserReminder open={isOpenReminders} handleClose={handleOpenReminders} />
            <AddDevice open={addDevice} handleClose={handleAddDeviceOpen} />
            <UpdateUser open={updateUser} handleClose={handleUpdateUser} data={userData} /> */}

            </Layout>
            {selectedFarm && <Farm farmData={selectedFarm} open={openFarm} onClose={handleCloseFarm} userId={userId} fetchFarmData={getFarmData} />}
        </>
    )
}

export default UserDetails