import React, { useCallback, useEffect, useState } from "react";
import Layout from "./Layout";
import {
    Grid,
    Paper,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Button,
    Snackbar,
} from "@mui/material";
import {baseURL} from "../config";
import { useParams } from "react-router-dom";
import MultiselectionMap from "../components/MultiselectionMap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { clearLocation } from "../reducers/location";
import { getOrgId } from '../apis/api';
import { calculateZoneArea, handleEditZone } from "../apis/farm-mgmt/zone";
import { getFarmByOrg } from "../apis/farm-mgmt/farm";
import { getZoneTypes } from "../apis/farm-mgmt/zone";

const EditZone = () => {

    const [zoneTypes, setZoneTypes] = useState([]);
    const { farmId, zoneId, userId } = useParams();
    const [farmData, setFarmData] = useState({});
    const [orgId, setOrgId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [newArea, setNewArea] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const zoneBoundaries = useSelector(
        (state) => state.coordinateReducer.coordinate
    );
    const [zone, setZone] = useState({});

    const fetchZoneTypes = useCallback(async () => {

        try {

            const response = await getZoneTypes(baseURL);
            const data = await response.json();
            return data.data.zone_types;
            
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, []);

    const fetchOrgId = useCallback(async (userId) => {
        if (!userId) return;

        try {
            const response = await getOrgId(baseURL, userId);
            const data = await response.json();
            return data.data.org_id;
        } catch (error) {
            console.error("Error fetching organization ID:", error);
        }
    }, []);

    const getFarmById = useCallback(async (farmId, orgId) => {;

        const requestData = {
            farm_id: parseInt(farmId, 10),
            org_id: orgId,
        };

        try {
      
            const response = await getFarmByOrg(baseURL, requestData);
            const data = await response.json();
            return data?.data;
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, []);

    const transformZoneBoundaries = useCallback((zoneBoundaries) => {
        const transformedBoundaries =
            Array.isArray(zoneBoundaries) &&
            zoneBoundaries.map((boundary) => {
                return {
                    latitude: boundary.latitude || boundary.lat,
                    longitude: boundary.longitude || boundary.lng,
                };
            });

        return transformedBoundaries;
    }, []);

    const calculateArea = useCallback(async (zoneBoundaries, size_unit) => {

            const updatedZoneBoundaries = transformZoneBoundaries(zoneBoundaries);

            if (
                !Array.isArray(updatedZoneBoundaries) ||
                updatedZoneBoundaries.some(
                    (boundary) =>boundary.latitude === null || boundary.longitude === null
                )
            ) {
                return;
            }

            const requestData = {
                zone_boundaries: updatedZoneBoundaries,
                unit: size_unit || "",
            };

            try {

                const response = await calculateZoneArea(baseURL, requestData);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                return data;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        [transformZoneBoundaries]
    );

    useEffect(() => {

        const fetchInitialData = async () => {

            const zoneTypes = await fetchZoneTypes();
            const orgId = await fetchOrgId(userId);

            if (!orgId) {
                return;
            }

            const farmData = await getFarmById(farmId, orgId);
            const editZone = farmData?.farm?.zones.find(
                (z) => z.id === parseInt(zoneId, 10)
            );

            setFarmData(farmData);
            setOrgId(orgId);
            setZone(editZone);
            setZoneTypes(zoneTypes);
        };

        fetchInitialData();

    }, [fetchZoneTypes, fetchOrgId, getFarmById, farmId, zoneId, userId])

    const updateZone = useCallback(async () => {

        const { name, size, size_unit } = zone;
        const transformedBoundaries = transformZoneBoundaries(zoneBoundaries);

        const payload = {
            user_id: userId,
            org_id: orgId,
            farm_id: parseInt(farmId, 10),
            id: parseInt(zoneId, 10),
            name: name,
            size: Number.isFinite(parseFloat(newArea)) ? parseFloat(newArea) : parseFloat(size, 10),
            size_unit: size_unit,
            zone_type: {
                id: zone?.zone_type?.id,
            },
            zone_boundaries: transformedBoundaries,
        };

        try {

            const response = await handleEditZone(baseURL, payload);
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data?.error?.code || "Something went wrong");
            }

            setSnackbarMessage("Zone updated successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setTimeout(() => {
                setNewArea(null);
                navigate(`/userdetails/${userId}`);
            }, 2000);
        } catch (error) {
            setSnackbarMessage(error.message);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    }, [
        zone,
        farmId,
        userId,
        orgId,
        navigate,
        zoneId,
        zoneBoundaries,
        newArea,
        transformZoneBoundaries,
    ]);

    return (
        <Layout>
            <div style={{ backgroundColor: "#ECF1F1", height: "100vh", marginTop: 65 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8} sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}>

                        <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>


                            <Button style={{

                                borderRadius: '50%',
                                width: '35px',
                                height: '45px',
                                minWidth: '45px',
                                minHeight: '45px',
                                fontSize: '1.2rem',
                                color: 'white',
                                backgroundColor: '#888',
                            }}
                                variant='contained'
                                onClick={() => {
                                    navigate(`/userdetails/${userId}`);
                                    dispatch(clearLocation());
                                }
                                }
                            >
                                <IoArrowBack />
                            </Button>
                            <Typography style={{ fontSize: '25px', fontWeight: 'bold', fontFamily: 'sen', marginLeft: 10 }}>
                                <span style={{ color: 'gray' }}>Edit Zone</span>
                            </Typography>
                        </div>
                        <Paper style={{ marginTop: 30 }}>
                            <Grid container spacing={2} style={{ paddingBottom: 20 }} direction={"column"}>
                                <Grid item xs={12} sm={6}>
                                    <MultiselectionMap calculateArea={async (zoneBoundaries) => {
                                        const data = await calculateArea(zoneBoundaries, zone.size_unit);
                                       
                                        setNewArea(data?.data?.area);
                                    }} farmData={farmData}
                                        editingZoneId={zone && zone.id}
                                        initialZoneBoundaries={zone && zone.zone_boundaries}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ padding: 2 }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 10,
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontFamily: "sen",
                                                fontWeight: "bold",
                                                marginBottom: 5,
                                            }}
                                        >
                                            Zone Name
                                        </Typography>
                                        <TextField
                                            color="success"
                                            label="Enter zone name"
                                            value={zone && zone.name ? zone.name : ""}
                                            onChange={(e) =>
                                                setZone({ ...zone, name: e.target.value })
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 10,
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontFamily: "sen",
                                                fontWeight: "bold",
                                                marginBottom: 5,
                                            }}
                                        >
                                            Zone Type
                                        </Typography>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label" color="success">
                                                Select an option
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                color="success"
                                                value={
                                                    zone && zone.zone_type && zone.zone_type.id
                                                        ? zone.zone_type.id
                                                        : ""
                                                }
                                                label="Select an option"
                                                onChange={(e) =>
                                                    setZone({
                                                        ...zone,
                                                        zone_type: {
                                                            id: e.target.value,
                                                        },
                                                    })
                                                }
                                            >
                                                {zoneTypes.map((zoneType) => (
                                                    <MenuItem key={zoneType.id} value={zoneType.id}>
                                                        {zoneType.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div
                                            style={{
                                                fontFamily: "sen",
                                                fontWeight: "bold",
                                                marginLeft: 11,
                                                marginBottom: 5,
                                            }}
                                        >
                                            Zone Area
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                padding: 10,
                                            }}
                                        >
                                            <TextField
                                                color="success"
                                                label="Enter zone size"
                                                disabled
                                                style={{ flex: 1, marginRight: 10 }}
                                                value={newArea ? newArea : zone && zone.size ? zone.size : ""}
                                            />
                                            <FormControl>
                                                <InputLabel>Units</InputLabel>
                                                <Select
                                                    color="success"
                                                    value={
                                                        zone && zone.size_unit ? zone.size_unit : "acre"
                                                    }
                                                    disabled
                                                    style={{ minWidth: 120 }}
                                                >
                                                    <MenuItem value="acre">Acres</MenuItem>
                                                    <MenuItem value="hectare">Hectare</MenuItem>
                                                    <MenuItem value="sqft">Sqft</MenuItem>
                                                    <MenuItem value="sqmt">Sqmt</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                        <Button
                                            style={{ marginTop: 20, backgroundColor: "#A6B5A4", fontFamily: 'sen', marginRight: 5 }}
                                            variant="contained"
                                            onClick={() => navigate(`/userdetails/${userId}`)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            style={{ marginTop: 20, marginRight: 10 }}
                                            onClick={updateZone}
                                        >
                                            Update Zone
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>

                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{ marginTop: 6 }}
                onClose={() => setSnackbarOpen(false)}
            >
                <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Layout>
    );
};

export default EditZone;