import React from 'react';

import { Modal, Paper, Typography} from '@mui/material';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import OrderTimeline from './OrderTimeline';


// import baseURL from '../config';


const OrderDetails = ({ open, handleClose }) => {

    //   console.log(data);

    // const [fieldErrors, setFieldErrors] = useState({});
    // const [snackbarOpen, setSnackbarOpen] = useState(false);
    // const [loading, setLoading] = useState(false);

    // const handleSnackbarClose = () => {
    //     setSnackbarOpen(false);
    // };

    // const handleChange = () => {
    //     console.log('changed');
    // }


    // const OrderDetails = () => {
    //     try {
    //       const errors = {};
    //       let hasErrors = false;

    //       setLoading(true);

    //       for (const key in formData) {
    //         if (!formData[key]) {
    //           errors[key] = 'This field is required';
    //           hasErrors = true;
    //           setLoading(false);
    //         }
    //       }

    //       setFieldErrors(errors);

    //       if (hasErrors) {
    //         return;
    //       }

    //       // Step 1: Register the user
    //       fetch(`${baseURL}/api/users`, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(formData),
    //       })
    //         .then((response) => response.json())
    //         .then((data) => {
    //           // Handle the API response here 
    //           console.log('API Response:', data);
    //           if (data.success) {
    //             // Step 2: Fetch the user data based on the provided email
    //             fetch(`${baseURL}/api/mgmt/phyfarmUsers/listAll`, {
    //               method: 'POST',
    //               headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', // Replace with your actual access token
    //               },
    //               body: JSON.stringify({
    //                 "pagination": {
    //                   "current_page": 1,
    //                 },
    //                 "filters": {
    //                   "email": formData.email, // Use the email from the form data
    //                 }
    //               }),
    //             })
    //               .then((response) => response.json())
    //               .then((userData) => {
    //                 if (userData.data.users.users.length > 0) {
    //                   // Step 3: Verify the user using their ID
    //                   const userId = userData.data.users.users[0].id;
    //                   const user = userData.data.users.users[0];
    //                   const fullName = `${user.firstName} ${user.lastName}`;
    //                   navigate(`/register-form/${fullName}/${userId}`);
    //                   // Assuming the user ID is in the first result
    //                   fetch(`${baseURL}/api/mgmt/phyfarmUsers/verify`, {
    //                     method: 'POST',
    //                     headers: {
    //                       'Content-Type': 'application/json',
    //                       'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', // Replace with your actual access token
    //                     },
    //                     body: JSON.stringify({
    //                       "user_id": userId,
    //                     }),
    //                   })
    //                     .then((response) => response.json())
    //                     .then((verificationData) => {
    //                       console.log('User Verification Data:', verificationData);

    //                       if (verificationData.success) {
    //                         // Clear the form fields and close the modal
    //                         setFormData({
    //                           firstName: '',
    //                           lastName: '',
    //                           email: '',
    //                           phone: '',
    //                           password: '',
    //                           checked: [],
    //                         });
    //                         handleClose(); // Close the modal
    //                         navigate('/register-form'); // Navigate to the register-form page only if the user is verified
    //                       }
    //                     })
    //                     .catch((verificationError) => {
    //                       console.error('User Verification Error:', verificationError);
    //                     });
    //                 }
    //               })
    //               .catch((fetchError) => {
    //                 console.error('User Data Fetch Error:', fetchError);
    //               });
    //           }
    //         })
    //         .finally(() => {
    //           setLoading(false);
    //         });
    //     } catch (error) {
    //       // Handle any errors here
    //       console.error('API Error:', error.error.message);
    //       alert('Error:', error.message);
    //       setLoading(false);
    //     }
    // };




    return (
        <Modal open={open} onClose={handleClose}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: 800 }}>
                <Paper style={{ padding: 20, height: 350 }}>
                    <Typography variant="h6" gutterBottom style={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                        Order Details
                    </Typography>
                    <div style={{ backgroundColor: '#A6B5A4', height: 245, borderRadius: 8, padding: 20 }}>
                        <Typography style={{ color: 'white' , fontWeight: 'bold'}}>Current Status</Typography>
                        <div style={{ backgroundColor: '#E7F2E7' }}>
                        <OrderTimeline/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-start', color: 'white', marginTop: 10}}>
                            <div>
                                <Typography style={{ fontFamily: 'Sen',marginLeft: 20}}>Order ID</Typography>
                                <Typography style={{ fontFamily: 'Sen',marginLeft: 20, fontWeight: 'bold'}}>123456</Typography>
                            </div>
                            <div>
                                <Typography style={{ fontFamily: 'Sen', marginLeft: 20}}>Product</Typography>
                                <Typography style={{ fontFamily: 'Sen', marginLeft: 20,fontWeight: 'bold'}}>PhySense</Typography>
                            </div>
                            <div><Typography style={{ fontFamily: 'Sen', marginLeft: 20}}>Date of Order</Typography>
                                <Typography style={{ fontFamily: 'Sen', marginLeft: 20, fontWeight: 'bold'}}>12/05/2023</Typography>

                            </div>
                        </div>
                    </div>

                </Paper>
            </div>

        </Modal>
    );
};

export default OrderDetails;