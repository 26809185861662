import {BEARER_TOKEN} from '../../config'

export const getAllPricing = async (
    baseUrl,
) => {

    const response = await fetch (`${baseUrl}/api/mgmt/pricing/listPricingCms`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            filters: {},
        })
    });

    return response;
}

export const listPayments = async (
    baseUrl,
    payload,
) => {

    const response = await fetch (`${baseUrl}/api/mgmt/payments/listPaymetsCms`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    return response;
}

export const paymentStatus = async (
    baseUrl,
    payload,
) => {

    const response = await fetch (`${baseUrl}/api/mgmt/payments/getOrgPaymentStatusCms`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    return response;
}

export const updatePaymentStatus = async (
    baseUrl,
    payload,
) => {

    const response = await fetch (`${baseUrl}/api/mgmt/payments/updatePayment`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
    });

    return response;
}

export const deletePayment = async (
    baseUrl,
    payload,
) => {

    const response = await fetch (`${baseUrl}/api/mgmt/payments/deletePaymentCms`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    return response;
}
