import React, { useState, useCallback, useEffect } from 'react'
import Layout from '../pages/Layout';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Rating, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import InputLabel from '@mui/material/InputLabel';
import AttachmentIcon from '@mui/icons-material/Attachment';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import UserTask from './UserTask';
import Button from '@mui/material/Button';
import EditLeadDetails from './EditLeadDetails';
import {baseURL} from '../config';
import { useNavigate } from 'react-router-dom';
import UserFiles from './UserFiles';
import { useLocation } from 'react-router-dom';
import UserNotes from './UserNotes';
import UserReminder from './UserReminder';
import ListRequirement from './ListRequirement';
import {BEARER_TOKEN} from '../config'



const ITEM_HEIGHT = 59;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const LeadDetails = () => {

    const location = useLocation();
    const leadData = location.state.leadData
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [openNotes, setOpenNotes] = useState(false);
    const [isOpenFiles, setIsOpenFiles] = useState(false);
    const [isOpenTask, setIsOpenTask] = useState(false);
    const [isOpenReminders, setIsOpenReminders] = useState(false);
    const [requirements, setRequirements] = useState([]);
    const [editLead, setEditLead] = useState(false);
    const [updatedLead, setUpdatedLead] = useState({});
    const [editLeadDetails, setEditLeadDetails] = useState(false);

    const handleCancelEdit = () => {
        setEditLead(!editLead);
        updateLeadDetails();
    }

    const getUserleadDataFromLocalStorage = () => {
        const userleadDataFromLocalStorage = localStorage.getItem('userData');

        if (userleadDataFromLocalStorage) {
            // If leadData exists in localStorage, parse and set it in the state
            setUserData(JSON.parse(userleadDataFromLocalStorage));
        }

    };

    const formatCreatedAtDate = (createdAt) => {
        const date = new Date(createdAt);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleOpenNotes = () => {
        setOpenNotes(!openNotes);
    };

    const handleOpenFiles = () => {
        setIsOpenFiles(!isOpenFiles);
    };

    const handleOpenTask = () => {
        setIsOpenTask(!isOpenTask);
    };

    const handleOpenReminders = () => {
        setIsOpenReminders(!isOpenReminders);
    };

    const handleEditLeadDetails = () => {
        setEditLeadDetails(!editLeadDetails);
    }

    //get orgId for the user
    console.log('UserDAta',userData);
    const updateLeadDetails = () => {
    

        const payload = {
            userId: "xyz",
            ...updatedLead
            
        };
        console.log(payload);

        fetch(`${baseURL}/api/mgmt/leads/updateLead`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + BEARER_TOKEN
                 // Replace with your actual access token
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                // setOrgId(dat);
                // console.log(data);
                // setOrgId(data.data.org_id);
            })
            .catch(error => {
                console.error('Error fetching organization ID:', error);
            });
    };

    const getRequirementData = useCallback(() => {

        fetch(`${baseURL}/api/mgmt/leads/listLeadsRequirement`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + BEARER_TOKEN
                 // Replace with your actual access token
            },
            body: JSON.stringify({
                leadId: leadData.data.lead.id,
            }),
        })

            .then(response => response.json())
            .then(data => {

                setRequirements(data.data.leadRequirements);

            }).catch(error => {
                console.error('Error fetching organization ID:', error);
            });
    }, [leadData.data.lead.id]);

    const handleAddRequirement = () => {
        // const fullName = `${userData.data.user.firstName} ${userData.data.user.lastName}`;
        navigate(`/add-requirement/${leadData.data.lead.id}`);
    };


    useEffect(() => {
        getUserleadDataFromLocalStorage();
        getRequirementData();
    }, [getRequirementData]);


    return (
        <Layout>

            <Grid container style={{ backgroundColor: '#F8F5F5', height: 1200, overflowY: 'auto' }}>
                <Grid item xs={12} md={10} lg={8} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    <div style={{ paddingTop: 55 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography style={{ fontSize: '25px', fontWeight: 'bold', fontFamily: 'sen' }}>
                                    <span style={{ color: 'gray' }}>Leads</span> <span>&gt;</span> {leadData.data.lead.fullName}
                                </Typography>
                            </div>
                            <div style={{ marginLeft: 300 }}>
                                <FormControl variant="outlined" style={{ marginLeft: 'auto', width: 120, backgroundColor: 'white' }}>
                                    <InputLabel id="test-select-label">Actions</InputLabel>
                                    <Select
                                        autoWidth
                                        MenuProps={MenuProps}
                                        label="Actions"
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        <List>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar style={{ backgroundColor: 'white', color: 'black' }}>
                                                        <DescriptionOutlinedIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Notes" />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="notes-action">
                                                        <Button color='success' onClick={handleOpenNotes}>+</Button>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar style={{ backgroundColor: 'white', color: 'black' }}>
                                                        <TaskOutlinedIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Task" />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="task-action">
                                                        <Button color='success' onClick={handleOpenTask}>+</Button>
                                                        {/* Add your action component for Task here */}
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar style={{ backgroundColor: 'white', color: 'black' }}>
                                                        <AttachmentIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Attachment" />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="attachment-action">
                                                        <Button color='success' onClick={handleOpenFiles}>+</Button>
                                                        {/* Add your action component for Attachment here */}
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar style={{ backgroundColor: 'white', color: 'black' }}>
                                                        <NotificationsNoneIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Reminder" />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="reminder-action">
                                                        <Button color='success' onClick={handleOpenReminders}>+</Button>
                                                        {/* Add your action component for Reminder here */}
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            {/* Other action items go here */}
                                        </List>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ marginTop: 50 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6} style={{ width: 600 }}>
                                    <Card

                                        sx={{ maxWidth: 980, maxHeight: 530, padding: 2 }}
                                    >
                                        {
                                            editLead ? (<>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Button style={{ textAlign: 'left', color: 'gray' }} onClick={handleCancelEdit}>Cancel</Button>
                                                    <Button style={{ textAlign: 'right', color: '#FDB67B' }} onClick={handleCancelEdit}>Save</Button>
                                                </div>
                                            </>


                                            ) : null
                                        }
                                     
                                        <CardHeader
                                            avatar={
                                                <Avatar aria-label="recipe">
                                                    {leadData.data.lead.fullName[0]}
                                                </Avatar>
                                            }
                                            action={
                                                <>
                                                    <IconButton aria-label="settings">
                                                        {
                                                            editLead ? null : (
                                                                <Rating max={1}></Rating>
                                                            )
                                                        }

                                                    </IconButton>
                                                    <IconButton aria-label="settings">
                                                        {
                                                            editLead ? null : (
                                                                <EditIcon onClick={setEditLeadDetails} />
                                                            )
                                                        }

                                                    </IconButton>

                                                </>


                                            }
                                            title={

                                                editLead ? (
                                                    <TextField size="small" onChange={(e) => setUpdatedLead({...updatedLead, lead:{...updatedLead.lead, fullname: e.target.value}})}></TextField>


                                                ) : (
                                                    <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                                                        {leadData.data.lead.fullName}
                                                    </Typography>
                                                )

                                            }
                                            subheader={
                                                <Select label style={{ width: 160 }}

                                                >

                                                    <MenuItem value="enquiry">Enquiry</MenuItem>
                                                    <MenuItem value="converted to lead">Converted to Lead</MenuItem>
                                                    <MenuItem value="lead won">Lead Won</MenuItem>
                                                    <MenuItem value="lead lost">Lead Lost</MenuItem>
                                                    <MenuItem value="spam">Spam Lead</MenuItem>
                                                </Select>
                                            }
                                        />

                                        <CardContent>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>

                                                            <>
                                                                <div>
                                                                    <div style={{ marginBottom: '20px' }}> {/* Add marginBottom for spacing */}
                                                                        <Typography style={{ fontFamily: 'sen' }}>Phone</Typography>
                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>{leadData.data.lead.phone}</Typography>
                                                                    </div>
                                                                    <div style={{ marginBottom: '20px' }}> {/* Add marginBottom for spacing */}
                                                                        <Typography style={{ fontFamily: 'sen' }}>Customer ID</Typography>
                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>{leadData.data.lead.id}</Typography>
                                                                    </div>
                                                                    <div style={{ marginBottom: '20px' }}> {/* Add marginBottom for spacing */}
                                                                        <Typography style={{ fontFamily: 'sen' }}>Date</Typography>
                                                                        {/* <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>{leadData.createdAt}</Typography> */}
                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                                                                            {formatCreatedAtDate(leadData.data.lead.createdAt)}
                                                                        </Typography>
                                                                    </div>
                                                                    <div style={{ marginBottom: '20px' }}> {/* Add marginBottom for spacing */}
                                                                        <Typography style={{ fontFamily: 'sen' }}>Client Type</Typography>
                                                                        {/* <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>{leadData.createdAt}</Typography> */}
                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>
                                                                            {leadData.data.lead.clientType}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div style={{ marginBottom: '20px' }}>
                                                                        <Typography style={{ fontFamily: 'sen' }}>Email</Typography>
                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>{leadData.data.lead.email}</Typography>
                                                                    </div>
                                                                    <div style={{ marginBottom: '20px' }}>
                                                                        <Typography style={{ fontFamily: 'sen' }}>Company Name</Typography>
                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>{leadData.data.lead.companyName}</Typography>
                                                                    </div>
                                                                    <div style={{ marginBottom: '20px' }}>
                                                                        <Typography style={{ fontFamily: 'sen' }}>Lead Owner</Typography>
                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>{leadData.data.lead.id}</Typography>
                                                                    </div>
                                                                    <div style={{ marginBottom: '20px' }}>
                                                                        <Typography style={{ fontFamily: 'sen' }}>Preferred Language</Typography>
                                                                        <Typography style={{ fontFamily: 'sen', fontWeight: 'bold' }}>{leadData.data.lead.preferredLanguage}</Typography>
                                                                    </div>

                                                                </div>
                                                            </>
                                              

                                            </div>
                                        </CardContent>
                                    </Card>
                                    <div>Activity</div>
                                </Grid>
                                <Grid item xs={12} md={6} style={{ height: 600 }}>

                                    {
                                        requirements.map((requirement) => (
                                            <ListRequirement
                                               leadData={leadData}
                                                requirement={requirement}
                                                getRequirementData={getRequirementData}
                                            />
                                        ))
                                    }



                                    <Button size="large" variant='success' onClick={handleAddRequirement} style={{ backgroundColor: "#E7F2E7", width: '100%', marginBottom: 10 }}>+ Add Requirement</Button>

                                </Grid>

                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {/* <UserNotes open={openNotes} handleClose={handleOpenNotes} data={userData}/> */}
            <UserNotes open={openNotes} handleClose={handleOpenNotes} />
            <UserTask open={isOpenTask} handleClose={handleOpenTask} />
            <UserFiles open={isOpenFiles} handleClose={handleOpenFiles} />
            <UserReminder open={isOpenReminders} handleClose={handleOpenReminders} />
            <EditLeadDetails open={editLeadDetails} handleClose={handleEditLeadDetails} leadData={leadData} />

        </Layout>

    )
}

export default LeadDetails