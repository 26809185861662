import React, { useEffect, useState, useCallback } from 'react'
import Layout from '../pages/Layout'
import { Typography, Button, Grid, Paper } from '@mui/material'
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {baseURL,BEARER_TOKEN} from '../config';
import LocationMap from '../components/LocationMap';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import FarmMap from '../components/FarmMap';
import { clearLocation } from '../reducers/location';
import { clearCoordinate } from '../reducers/coordinates';
import { IoArrowBack } from "react-icons/io5";
import { getOrgId } from '../apis/api';
import { updateFarm } from '../apis/farm-mgmt/farm';
import { calculateZoneArea } from '../apis/farm-mgmt/zone';


const EditFarm = () => {

    const { farmId, userId } = useParams();
    const [orgId, setOrgId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [farmData, setFarmData] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const [newArea, setNewArea] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const locationFromRedux = useSelector(state => state.locationReducer.location);


    const fetchOrgId = useCallback(async (userId) => {
        if (!userId) return;

        try {
            const response = await getOrgId(baseURL, userId);
            const data = await response.json();
            return data.data.org_id;
        } catch (error) {
            console.error("Error fetching organization ID:", error);
        }
    }, []);

    const getFarmById = useCallback(async (farmId, orgId) => {

        const url = `${baseURL}/api/mgmt/farmcrm/getFarmById`;
        const headers = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + BEARER_TOKEN
        };

        const requestData = {
            farm_id: parseInt(farmId, 10),
            org_id: orgId,
        };

        try {
            let response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, []);

    const handleMapOpen = useCallback(() => {
        setOpenModal(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpenModal(false);
    }, []);

    const transformZoneBoundaries = useCallback((zoneBoundaries) => {
        const transformedBoundaries =
            Array.isArray(zoneBoundaries) &&
            zoneBoundaries.map((boundary) => {
                return {
                    latitude: boundary.latitude || boundary.lat,
                    longitude: boundary.longitude || boundary.lng,
                };
            });

        return transformedBoundaries;
    }, []);

    const calculateArea = useCallback(async (zoneBoundaries, size_unit) => {

            const updatedZoneBoundaries = transformZoneBoundaries(zoneBoundaries);

            if (
                !Array.isArray(updatedZoneBoundaries) ||
                updatedZoneBoundaries.some(
                    (boundary) =>
                        boundary.latitude === null || boundary.longitude === null
                )
            ) {
                return;
            }

            const requestData = {
                zone_boundaries: updatedZoneBoundaries,
                unit: size_unit || "",
            };

            try {

                const response = await calculateZoneArea(baseURL, requestData);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const data = await response.json();
                return data;
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        [transformZoneBoundaries]
    );

    const handleSave = useCallback(async () => {

        setLoading(true);

        const payload = {
            user_id: userId,
            org: orgId ? { id: orgId } : { name: farmData?.farm?.name },
            farm: {
                id: parseInt(farmId, 10),
                name: farmData?.farm?.name,
                size: Number.isFinite(parseFloat(newArea)) ? parseFloat(newArea) : parseFloat(farmData?.farm?.size),
                size_unit: farmData?.farm?.size_unit
            },
            location: {
                id: farmData?.location?.id,
                address: farmData?.location?.address,
                city: farmData?.location?.city,
                state: farmData?.location?.state,
                country: farmData?.location?.country,
                pincode: farmData?.location?.pincode,
                latitude: farmData?.location?.latitude,
                longitude: farmData?.location?.longitude
            },
            farm_boundaries: farmData?.farm_boundaries
        };

        try {

            const response = await updateFarm(baseURL, payload);
            const data = await response.json();

            if (data?.data?.farm_id) {
                setSnackbarOpen(true);
                setSnackbarMessage('Farm updated successfully!');
                setSnackbarSeverity('success');
                setTimeout(() => {
                    navigate(`/userdetails/${userId}`);
                }, 2000);
            } else {
                setSnackbarOpen(true);
                setSnackbarMessage('There was an error while updating your farm');
                setSnackbarSeverity('error');
            }
        } catch (error) {
            setSnackbarOpen(true);
            setSnackbarMessage('There was an error while updating your farm');
            setSnackbarSeverity('error');
        }

        setLoading(false);
    }, [farmData, farmId, navigate, orgId, userId, newArea]);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const orgIdResult = await fetchOrgId(userId);
                if (!orgIdResult) {
                    return;
                }
                const farmDataResult = await getFarmById(farmId, orgIdResult);
                setFarmData(farmDataResult?.farm);
                setOrgId(orgIdResult);
            } catch (error) {
                console.error("Error fetching initial data:", error);
            }
        };

        fetchInitialData();
    }, [userId, fetchOrgId, farmId, getFarmById]);


    return (
        <Layout>
            <div style={{ backgroundColor: "#ECF1F1", height: "100vh", overflow: 'auto', marginTop: 65 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>


                            <Button style={{

                                borderRadius: '50%',
                                width: '35px',
                                height: '45px',
                                minWidth: '45px',
                                minHeight: '45px',
                                fontSize: '1.2rem',
                                color: 'white',
                                backgroundColor: '#888',
                            }}
                                variant='contained'
                                onClick={() => {
                                    navigate(`/userdetails/${userId}`);
                                    dispatch(clearLocation());
                                }
                                }
                            >
                                <IoArrowBack />
                            </Button>
                            <Typography style={{ fontSize: '25px', fontWeight: 'bold', fontFamily: 'sen', marginLeft: 10 }}>
                                <span style={{ color: 'gray' }}>Edit Farm</span>
                            </Typography>
                        </div>
                        <Paper style={{ marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label style={{ marginBottom: 10, fontFamily: 'sen', fontWeight: 'bold' }}>Farm Name</label>
                                    <TextField color='success' label="Enter farm name" fullWidth style={{ marginRight: '10px', width: "50%" }} value={farmData?.farm?.name || ""}
                                        onChange={(e) => setFarmData({ ...farmData, farm: { ...farmData.farm, name: e.target.value } })}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 18 }}>
                                    <label style={{ marginBottom: 10, fontFamily: 'sen', fontWeight: 'bold' }}>How much area your farm covers?</label>
                                    <TextField label="Enter farm size" style={{ marginRight: '10px', width: "50%" }}
                                        value={newArea ? newArea : farmData?.farm?.size || ""}
                                        disabled
                                        color='success'
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 18 }}>
                                    <label style={{ marginBottom: 10, fontFamily: 'sen', fontWeight: 'bold' }}>Units</label>
                                    <FormControl sx={{ width: "50%" }}>
                                        <InputLabel id="demo-simple-select-label" color='success'>Select an option</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            color='success'
                                            value={farmData?.farm?.size_unit || ""}
                                            label="Select an option"
                                            disabled
                                            onChange={(e) => setFarmData({ ...farmData, farm: { ...farmData.farm, size_unit: e.target.value } })}
                                        >
                                            <MenuItem value="acre">Acres</MenuItem>
                                            <MenuItem value="hectare">Hectare</MenuItem>
                                            <MenuItem value="sqft">Sqft</MenuItem>
                                            <MenuItem value="sqmt">Sqmt</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 18 }}>
                                    <label style={{ marginBottom: 10, fontFamily: 'sen', fontWeight: 'bold' }}>Where is your farm located?</label>
                                    {farmData && farmData.location && farmData.location.address ? (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', border: farmData.location.address ? '2px solid green' : '2px solid red', borderRadius: 5, alignItems: "center", padding: 10 }}>
                                            {
                                                <Typography style={{ marginRight: 5 }}>{farmData?.location?.address}</Typography>
                                            }
                                            <Button color='success' disabled onClick={handleMapOpen} sx={{ marginLeft: 5 }}>Change</Button>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                                            <Button variant="outlined" color='success' onClick={handleMapOpen} style={{ backgroundColor: 'white', color: 'black', width: 800 }}>
                                                <AddLocationAltOutlinedIcon style={{ marginRight: 5 }} />
                                                Pin location
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 18 }}>
                                    {
                                        Array.isArray(farmData?.farm_boundaries) && farmData.farm_boundaries.length > 0 ? (
                                            <FarmMap location={locationFromRedux} initialFarmBoundaries={farmData?.farm_boundaries} calculateArea={async (farmBoundariesFromRedux) => {
                                                const data = await calculateArea(farmBoundariesFromRedux, farmData?.farm?.size_unit);
                                                setNewArea(data?.data?.area)
                                                // setFarmData({ ...farmData, farm: { ...farmData.farm, size: parseFloat(data?.data?.area, 10) } });
                                            }} />

                                        ) : (
                                            <FarmMap location={locationFromRedux} calculateArea={async (coordinates) => {
                                                const data = await calculateArea(coordinates, farmData?.farm?.size_unit);
                                                setNewArea(data?.data?.area)
                                                setFarmData({ ...farmData, farm: { ...farmData.farm, size: parseFloat(data?.data?.area, 10) } });
                                            }} />
                                        )

                                    }
                                </div>
                            </div>
                        </Paper>
                        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 15 }}>
                            <Button variant="contained" style={{ marginBottom: 20, width: '600px', backgroundColor: "#A6B5A4", fontFamily: 'sen', marginRight: 25 }} onClick={
                                () => {
                                    navigate(`/userdetails/${userId}`)
                                    dispatch(clearLocation());
                                    dispatch(clearCoordinate());
                                }
                            }>Cancel</Button>
                            <Button variant="contained" color="success" style={{ marginBottom: 20, width: '600px', fontFamily: 'sen' }}
                                onClick={handleSave}
                            > {loading ? (
                                <CircularProgress size={24} color="success" />
                            ) : (
                                "Update Farm"
                            )}</Button>
                        </div>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </div>
            <LocationMap style={{ display: openModal ? 'block' : 'none' }} open={openModal} handleClose={handleClose} />
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} style={{ marginTop: 60 }}>
                <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </Layout>
    )
}

export default EditFarm;
