import React, { useState } from 'react';
import { Modal, Paper, Typography, TextField, Button, IconButton, CircularProgress, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import { getAllUsers, verifyUser } from '../apis/platform-users/api';


const RegisterUser = ({ open, handleClose, baseURL}) => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        checked: []
    });
    const [fieldErrors, setFieldErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSignUp = async () => {
        try {
            const errors = {};
            let hasErrors = false;

            setLoading(true);

            for (const key in formData) {
                if (!formData[key]) {
                    errors[key] = 'This field is required';
                    hasErrors = true;
                }
            }

            setFieldErrors(errors);

            if (hasErrors) {
                setLoading(false);
                return;
            }

            // Send sign-up request to the server
            const response = await fetch(`${baseURL}/api/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            console.log('API Response:', data);

            if (data.success) {
                // Fetch the user data based on the provided email
                const usersResponse = await getAllUsers(baseURL, {
                    "pagination": {
                        "current_page": 1,
                    },
                    "filters": {
                        "email": formData.email,
                    }
                });

                const userData = await usersResponse.json();

                if (userData.data.users.users.length > 0) {
                    // Verify the user using their ID
                    const userId = userData.data.users.users[0].id;
                    const user = userData.data.users.users[0];
                    const fullName = `${user.firstName} ${user.lastName}`;
                    navigate(`/register-form/${fullName}/${userId}`);

                    const verificationResponse = await verifyUser(baseURL, userId);
                    const verificationData = await verificationResponse.json();

                    console.log('User Verification Data:', verificationData);

                    if (verificationData.success) {
                        // Clear the form fields and close the modal
                        setFormData({
                            firstName: '',
                            lastName: '',
                            email: '',
                            phone: '',
                            password: '',
                            checked: [],
                        });
                        handleClose(); // Close the modal
                        navigate('/register-form'); // Navigate to the register-form page only if the user is verified
                    }
                }
            }
        } catch (error) {
            console.error('API Error:', error);
            alert('Error:', error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <Modal open={open} onClose={handleClose}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450 }}>
                <Paper style={{ padding: 20 }}>
                    <Typography variant="h4" gutterBottom style={{ fontFamily: 'sen' }}>
                        Register a User
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{ fontFamily: 'sen' }}>
                        Get started with Phyfarm
                    </Typography>
                    <TextField label="First Name" fullWidth name="firstName" onChange={handleChange} style={{ marginBottom: 10, fontFamily: 'sen' }} error={fieldErrors.firstName !== undefined} helperText={fieldErrors.firstName} />
                    <TextField label="Last Name" fullWidth name="lastName" onChange={handleChange} style={{ marginBottom: 10, fontFamily: 'sen' }} error={fieldErrors.lastName !== undefined} helperText={fieldErrors.lastName} />
                    <TextField label="Email Address" fullWidth name="email" onChange={handleChange} style={{ marginBottom: 10, fontFamily: 'sen' }} error={fieldErrors.email !== undefined} helperText={fieldErrors.email} />
                    <TextField label="Phone Number" fullWidth name="phone" onChange={handleChange} style={{ marginBottom: 10, fontFamily: 'sen' }} error={fieldErrors.phone !== undefined} helperText={fieldErrors.phone} />
                    <TextField
                        label="Password"
                        fullWidth
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={toggleShowPassword} edge="end">
                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleChange}
                        style={{ marginBottom: 10, fontFamily: 'sen' }}
                        error={fieldErrors.password !== undefined}
                        helperText={fieldErrors.password}
                    />
                    <TextField
                        label="Confirm Password"
                        fullWidth
                        type={showConfirmPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={toggleShowConfirmPassword} edge="end">
                                        {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        style={{ marginBottom: 10, fontFamily: 'sen' }}
                    />
                    <Typography variant="body2" style={{ marginBottom: 10, textAlign: 'center', fontFamily: 'sen' }}>
                        By clicking on Sign Up, you agree to Phyfarm's Terms and Conditions and Privacy Policy.
                    </Typography>
                    <Button variant="contained" style={{ backgroundColor: '#5F9C6A' }} fullWidth onClick={handleSignUp}>
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign IN'}
                    </Button>

                </Paper>
            </div>

        </Modal>
    );
};

export default RegisterUser;