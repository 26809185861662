import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Grid, IconButton } from '@mui/material';
import NavigationBar from '../components/NavigationBar';
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

// import { axisClasses } from '@mui/x-charts';
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
// import { ResponsiveBar } from '@nivo/bar';
import {baseURL} from '../config';
import { checkAuth } from '../apis/auth/api';
import { dashboardCounts } from '../apis/agro-chat/api';


const AgroDashboard = () => {

    // const [latitude, setLat] = useState(23.073331);
    // const [longitude, setLong] = useState(77.075442);
    // const [isMarkerShown, setIsMarkerShown] = useState(true);
    // const [zoom, setZoom] = useState(4);
    const [agroDataLocalStorage, setAgroDataLocalStorage] = useState({});
    const [chatCounts, setChatCounts] = useState({});
    const navigate = useNavigate();
    // const latitude = 23.073331
    // const longitude = 77.075442
    // const isMarkerShown = true;
    // const zoom = 4;


    const getAgroData = useCallback(() => {
        const agroData = localStorage.getItem('userData');
        if (agroData) {
            setAgroDataLocalStorage(JSON.parse(agroData));
        }
    }, []);

    const checkAuthApi = useCallback(async (token) => {
        try {
            const response = await checkAuth(baseURL, token);
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error('Authentication check error:', error);
        }
    }, []);

    useEffect(() => {
        getAgroData();
    }, [getAgroData]);

    const agroID = agroDataLocalStorage.id;

    useEffect(() => {
        if (agroDataLocalStorage.access_token) {
            checkAuthApi(agroDataLocalStorage.access_token);
        }
    }, [agroDataLocalStorage, checkAuthApi]);

    // const MyMapComponent = withScriptjs(withGoogleMap((props) => (
    //     <GoogleMap
    //         defaultZoom={props.zoom}
    //         defaultCenter={props.center}
    //     >
    //         {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
    //     </GoogleMap>
    // )));

    // const chartSetting = {
    //     yAxis: [
    //         {
    //             label: 'Tasks',
    //         },
    //     ],
    //     width: 450,
    //     height: 350,
    //     sx: {
    //         [`.${axisClasses.left} .${axisClasses.label}`]: {
    //             transform: 'translate(-20px, 0)',
    //         },
    //     },
    // };

    // const dataset = [
    //     {
    //         task: 3,
    //         day: 'Mon',
    //     },
    //     {
    //         task: 4,
    //         day: 'Tue',
    //     },
    //     {
    //         task: 6,
    //         day: 'Wed',
    //     },
    //     {
    //         task: 5,
    //         day: 'Thu',
    //     },
    //     {
    //         task: 7,
    //         day: 'Fri',
    //     },
    //     {
    //         task: 3,
    //         day: 'Sat',
    //     },
    //     {
    //         task: 8,
    //         day: 'Sun',
    //     },
    // ];

    const getChatCounts = useCallback(async () => {

        if (!agroID) {
            return;
        }
        const response = await dashboardCounts(agroID);
        const data = await response.json();
        setChatCounts(data.data)

    }, [agroID]);

    useEffect(() => {
        getChatCounts();
    }, [getChatCounts]);


    const handleUnreadChats = () => {
        navigate("/users-chat?unread=true")
    }


    return (
        <div style={{ backgroundColor: '#F7F6F0', height: 'auto' }}>
            <NavigationBar />
            <div style={{ overflowY: 'auto', height: '90vh', display: 'flex', flexDirection: 'column', marginTop: 85, marginLeft: 50 }}>


                <Grid container style={{ marginLeft: 12, display: 'flex', alignContent: 'center', justifyContent: 'center' }} spacing={2}>
                    <Grid item xs={10} style={{ padding: 30 }}>
                        <div style={{ width: '100%', backgroundColor: '#FFFDFD', height: 'auto', padding: 30, borderRadius: 20 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>Chat Stats</Typography>
                                {/* <Select defaultValue="daily" style={{ minWidth: 120 }} size='small'>
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                </Select> */}
                            </div>
                            <div style={{ marginTop: 30 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <div style={{ border: '3px solid #E5E5E5', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', padding: 20, height: '100%', borderRadius: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography style={{ fontSize: 80, color: '#5F9C6A', fontWeight: 'bold' }}>{chatCounts && chatCounts.total_chats}</Typography>
                                            <Typography style={{ fontWeight: 'bold' }}>Total Chats</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div style={{ border: '3px solid #E5E5E5', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', padding: 20, height: '100%', borderRadius: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                                            {
                                                chatCounts && chatCounts.unread_chats > 0 && <IconButton style={{ position: 'absolute', top: 5, right: 5 }} onClick={handleUnreadChats}><FaExternalLinkAlt /></IconButton>
                                            }

                                            <Typography style={{ fontSize: 80, color: '#5F9C6A', fontWeight: 'bold' }}>{chatCounts && chatCounts.unread_chats}</Typography>
                                            <Typography style={{ fontWeight: 'bold' }}>Unread Chats</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div style={{ border: '3px solid #E5E5E5', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', padding: 20, height: '100%', borderRadius: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography style={{ fontSize: 80, color: '#5F9C6A', fontWeight: 'bold' }}>{chatCounts && chatCounts.tasks_assigned}</Typography>
                                            <Typography style={{ fontWeight: 'bold' }}>Tasks Assigned</Typography>
                                        </div>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={4}>
                                            <div style={{ border: '3px solid #E5E5E5', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', padding: 20, height: '100%', borderRadius: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Typography style={{ fontSize: 80, color: '#5F9C6A', fontWeight: 'bold' }}>73</Typography>
                                                <Typography style={{ fontWeight: 'bold' }}>Active Chats</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div style={{ border: '3px solid #E5E5E5', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', padding: 20, height: '100%', borderRadius: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Typography style={{ fontSize: 80, color: '#5F9C6A', fontWeight: 'bold' }}>31</Typography>
                                                <Typography style={{ fontWeight: 'bold' }}>Dropped Chats</Typography>
                                            </div>
                                        </Grid> */}
                                </Grid>
                            </div>
                            <div style={{ marginTop: 20, paddingLeft: 50, paddingRight: 50 }}>
                                <Grid container spacing={2}>

                                    {/* <Grid item xs={12} sm={4}>
                                            <div style={{ border: '3px solid #E5E5E5', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', padding: 20, height: '100%', borderRadius: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Typography style={{ fontSize: 40, color: '#5F9C6A', fontWeight: 'bold' }}>43</Typography>
                                                <Typography style={{ fontWeight: 'bold' }}>Tasks Completed</Typography>
                                            </div>
                                        </Grid> */}
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                {/* <div style={{

                    marginLeft: 180,
                    backgroundColor: '#FFFDFD',
                    borderRadius: 20,
                    padding: 30,
                    width: '80%',
                }}>
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: 30 }}>Task Stats</Typography>
                    <ResponsiveBar
                        data={dataset}
                        keys={['task']}
                        indexBy="day"

                        margin={{ top: 40, right: 130, bottom: 50, left: 20 }}
                        colors={["#5F9C6A"]}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Tasks',
                            legendPosition: 'middle',
                            legendOffset: -40,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        width={800}
                        height={400}
                    />
                </div> */}

                {/* <Grid container style={{ marginLeft: 12, display: 'flex', alignContent: 'center', justifyContent: 'center' }} spacing={2}>
                    <Grid item xs={10} style={{ padding: 30 }}>

                        <div style={{ width: '100%', backgroundColor: '#FFFDFD', height: 'auto', padding: 30, borderRadius: 20 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>User Stats</Typography>
                                <Select defaultValue="daily" style={{ minWidth: 120 }} size='small'>
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                </Select>
                            </div>

                            <Grid container spacing={2}>
                                <Grid item xs={7}>
                                    <MyMapComponent
                                        isMarkerShown={isMarkerShown}
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwMUqV9i_a7ILMTIwmgxBAzymf_T6hIR4&v=3.exp&libraries=geometry,drawing,places"
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `300px`, width: `auto`, borderRadius: `20px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                        latitude={latitude}
                                        longitude={longitude}
                                        center={{ lat: latitude, lng: longitude }}
                                        zoom={zoom}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <BarChart
                                        dataset={dataset}
                                        colors={['#316B31']}
                                        xAxis={[{ scaleType: 'band', dataKey: 'day' }]}
                                        series={[
                                            { dataKey: 'user' },
                                        ]}
                                        {...chartSetting}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                </Grid> */}
            </div>
        </div>
    );
}

export default AgroDashboard;
