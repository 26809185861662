import React, { useState, useEffect, useCallback, useRef } from 'react';
import Layout from '../pages/Layout';
import {
    Button,
    TextField,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import { IoIosWifi } from 'react-icons/io';
import { LuWifiOff } from 'react-icons/lu';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { CircularProgress } from '@mui/material';
import Switch from '@mui/material/Switch';
import { getEnvsFor, searchFarms, listSensors, latestSensorData } from '../apis/list-farms/api';

const ListFarms = () => {

    const [searchDropdown, setSearchDropdown] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [responseData, setResponseData] = useState([]);
    const [farmId, setFarmId] = useState('');
    const [envData, setEnvData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isProd, setIsProd] = useState(false);
    const [orgID, setOrgID] = useState('');
    const [selectedHubId, setSelectedHubId] = useState('');
    const [sensorID, setSensorID] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [multipleEnvs, setMultipleEnvs] = useState([]);
    const selectedHubIdRef = useRef(null);

    const handleChangeEnvironment = useCallback(() => {
        setIsProd(!isProd);
        setSearchDropdown('');
        setSearchQuery('');
        setResponseData([]);
        setFarmId('');
        setEnvData([]);
        setOrgID('');
        setSelectedHubId('');
        selectedHubIdRef.current = null;
        setSensors([]);
    }, [isProd]);

    const baseURL = isProd ? 'https://v3.api.phyfarm.com' : 'https://dev.phyfarm.com';
    const timer = useRef(null);

    const debounce = (func, delay) => {
        return function (...args) {
            const context = this;
            clearTimeout(timer.current);
            timer.current = setTimeout(() => func.apply(context, args), delay);
        };
    };

    const getLatestValue = useCallback(async (orgID, farmID, sensorID, selectedHubId) => {

        if (!orgID || !farmID || !sensorID || !selectedHubId) return;
        try {
            const requestData = {
                org: {
                    id: orgID
                },
                farm: {
                    id: farmID
                },
                filters: {
                    sensors: sensorID,
                    envId: selectedHubId,
                    type: [],
                    startTime: "2024-04-18 06:37:08.305Z",
                    endTime: "2024-04-18 06:52:08.305Z"
                }
            };

            const response = await latestSensorData(baseURL, requestData);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setMultipleEnvs(data?.data?.env_data);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);

        }
    }, [baseURL]);

    const getSensorsFor = useCallback(async (orgID, selectedHubId, farmId) => {

        if (!orgID || !selectedHubId || !farmId) return;

        const requestData = {
            pagination: {
                current_page: 1
            },
            org: {
                id: orgID
            },
            farm: {
                id: farmId
            },
            orders: [],
            filters: {
                sensor_id: [],
                envId: selectedHubId
            },
            fields: {}

        };

        await listSensors(baseURL, requestData)
            .then(response => response.json())
            .then(data => {
                setSensors(data?.data?.sensors || []);
                setSensorID(data?.data?.sensors?.map(sensor => sensor._id) || []);
                getLatestValue(orgID, farmId, data?.data?.sensors?.map(sensor => sensor._id) || [], selectedHubId);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                alert(error, 'An error occurred while fetching data');
            });

    }, [baseURL, getLatestValue]);

    const getAllEnvsFor = useCallback(async (orgID, farmId) => {

        if (!orgID || !farmId) return;

        const payload = {
            org_id: orgID,
            farm_id: farmId
        };

        setLoading(true);

        try {

            const response = await getEnvsFor(baseURL, payload);
            const data = await response.json();
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            if (data?.data === null) {
                setEnvData([]);
                setSelectedHubId('');
                selectedHubIdRef.current = null;
                setSensors([]);
                setLoading(false);
                return;
            }
            setEnvData(data?.data || []);
            if (selectedHubIdRef.current == null) {
                if (!data?.data || data?.data.length === 0) {
                    setSelectedHubId('');
                    selectedHubIdRef.current = null;
                    setSensors([]);
                } else {
                    const newSelectedHubId = data?.data[0]?.env_id || '';
                    setSelectedHubId(newSelectedHubId);
                    getSensorsFor(orgID, newSelectedHubId, farmId);
                    selectedHubIdRef.current = newSelectedHubId;
                }
            }
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
            setLoading(false);
        }
    }, [baseURL, getSensorsFor]);

    const getAllFarms = useCallback(async (searchQuery, searchDropdown) => {

        if (searchQuery === '' && searchDropdown !== '') {
            return;
        }

        const requestData = {
            email: searchQuery
        };

        setLoading(true);
        const response = await searchFarms(baseURL, requestData);
        const data = await response.json();
        setResponseData(data?.data?.farms || []);
        setLoading(false);

        if (data?.data?.farms?.length > 0) {
            const firstFarmId = data.data.farms[0].farm.id;
            const orgId = data.data.farms[0].org.id;
            setOrgID(orgId);
            setFarmId(firstFarmId);
            getAllEnvsFor(orgId, firstFarmId);
        } else {
            setOrgID('');
            setFarmId('');
            setEnvData([]);
            setSelectedHubId('');
            selectedHubIdRef.current = null;
            setSensors([]);
        }

    }, [baseURL, getAllEnvsFor]);

    const getAllFarmsDebounced = debounce(getAllFarms, 500);

    const handleSearchChange = (e) => {
        const sQ = e.target.value;
        setSearchQuery(sQ);
        getAllFarmsDebounced(sQ, searchDropdown);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            getAllEnvsFor(orgID, farmId);
            getSensorsFor(orgID, selectedHubId, farmId);
            getLatestValue(orgID, farmId, sensorID, selectedHubId);
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [orgID, farmId, getAllEnvsFor, getSensorsFor, selectedHubId, getLatestValue, sensorID, sensors]);

    return (
        <Layout>
            <div style={{ height: '100vh', overflowX: 'hidden', marginTop: 90 }}>
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', fontSize: 25 }}>List of Farms</Typography>
                    <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray' }}>(Enter email to get farms & sensors) </Typography>
                </div>
                <Grid container style={{ marginTop: 50 }} spacing={1} justifyContent={'center'}>
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <TextField
                            fullWidth
                            label={searchDropdown ? `Search by ${searchDropdown.toUpperCase()} ID` : 'Search'}
                            value={searchQuery}
                            color='success'
                            variant='outlined'
                            onChange={handleSearchChange}
                            onKeyPress={e => e.key === 'Enter' && getAllFarms(searchQuery, searchDropdown)}
                            InputProps={{
                                startAdornment: (
                                    <TextField
                                        select
                                        value={searchDropdown}
                                        color='success'
                                        sx={{ minWidth: 120 }}
                                        variant='standard'
                                        onChange={event => {
                                            const sD = event.target.value;
                                            setSearchDropdown(sD);
                                            getAllFarms(searchQuery, sD);
                                        }}
                                    >
                                        <MenuItem value={'email'}>EMAIL ID</MenuItem>
                                    </TextField>
                                ),
                                endAdornment: (
                                    <IconButton onClick={() => getAllFarms(searchQuery, searchDropdown)}>
                                        <SearchIcon />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: 5 }} spacing={2} alignItems='center' justifyContent="center">
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <FormControl fullWidth >
                            <InputLabel id='demo-simple-select-helper-label'>Farms</InputLabel>
                            <Select
                                labelId='demo-simple-select-helper-label'
                                id='demo-simple-select-helper'
                                value={farmId}
                                label='Farms'
                                color='success'
                                fullWidth
                                onChange={e => {
                                    const newFarmId = e.target.value;
                                    setFarmId(newFarmId);
                                    setSelectedHubId('');
                                    selectedHubIdRef.current = null;
                                    getAllEnvsFor(orgID, newFarmId);
                                }}
                            >
                                {responseData.length ? (
                                    responseData.map((item, index) => (
                                        <MenuItem key={index} value={item?.farm?.id}>{item?.farm?.name}</MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>Search user to get Farms</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={5}>
                        <div style={{ border: '1px solid gray', borderRadius: '5px', backgroundColor: '#f1f8e9', justifyContent: 'center', overflowX: 'hidden', '&::-webkit-scrollbar': { display: 'none' } }}>
                            {envData.length > 0 ? (
                                <div style={{ display: 'flex', overflowX: 'auto', marginTop: 10, marginBottom: 10 }}>
                                    {envData.map((item, index) => (
                                        <Button
                                            key={index}
                                            style={{
                                                backgroundColor: selectedHubId === item.env_id ? '#5F9C6A' : 'white',
                                                color: selectedHubId === item.env_id ? 'white' : 'gray',
                                                border: '1px solid gray',
                                                fontFamily: 'sen',
                                                padding: '5px',
                                                marginLeft: 20,
                                                fontSize: 12,
                                                minWidth: 150
                                            }}
                                            onClick={() => {
                                                setSelectedHubId(item.env_id);
                                                selectedHubIdRef.current = item.env_id;
                                                getSensorsFor(orgID, item.env_id, farmId);
                                            }}
                                        >
                                            {item.active ? <IoIosWifi style={{ color: selectedHubId === item.env_id ? 'white' : 'gray', marginRight: 8 }} size={'20'} /> : <LuWifiOff style={{ color: selectedHubId === item.env_id ? 'white' : 'gray', marginRight: 8 }} size={'20'} />}
                                            {item.env_name}
                                        </Button>
                                    ))}
                                </div>
                            ) : (
                                <div style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray', fontWeight: 'bold', marginRight: 10, textAlign: 'center' }}>No Hubs</Typography>
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: 30 }}>
                    <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray', fontWeight: isProd ? '' : 'bold', marginRight: 1 }}>DEV</Typography>
                    <Switch
                        checked={isProd}
                        onChange={handleChangeEnvironment}
                        color='success'
                    />
                    <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray', fontWeight: isProd ? 'bold' : '', marginLeft: 1 }}>PROD</Typography>
                </div>
                <hr style={{ marginTop: 20 }} />
                <div style={{ padding: 50, marginLeft: 40 }}>

                    <Typography style={{ fontFamily: 'sen', fontWeight: 'bold', color: 'gray', fontSize: 25 }}>Sensors</Typography>
                    <Grid container spacing={2} justifyContent="center" sx={{ marginTop: 2 }}>
                        {loading ? (
                            <CircularProgress style={{ marginLeft: '50%' }} />
                        ) : (
                            Array.isArray(sensors) && sensors.length > 0 ? sensors.map((sensor, index) => (
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={index}>
                                    <Card sx={{ minWidth: 300, backgroundColor: '#f1f8e9', border: '2px solid darkGreen', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 #f1f8e9' }}>
                                        <CardActionArea>
                                            <CardContent sx={{ minHeight: 80 }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography gutterBottom component='div'>
                                                        {sensor.name} ({sensor.type})
                                                    </Typography>
                                                    {sensor.online ? <IoIosWifi style={{ color: 'green' }} size={'30'} /> : <LuWifiOff style={{ color: 'red' }} size={'30'} />}
                                                </div>
                                                <div>
                                                    {Array.isArray(multipleEnvs) && multipleEnvs.length > 0 ? (
                                                        multipleEnvs.map((env, index) => {
                                                            let foundMatchingEntry = false;
                                                            return (
                                                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    {
                                                                        env.sensor_id === sensor.macAddress ? (
                                                                            env.data && env.data.length > 0 ? (
                                                                                <>
                                                                                    <Typography color='text.secondary'>
                                                                                        {env.name}
                                                                                    </Typography>
                                                                                    <Typography sx={{ fontWeight: "bold", color: "green" }} color='text.secondary'>
                                                                                        {env.data[0].y}
                                                                                    </Typography>
                                                                                </>
                                                                            ) :
                                                                                (
                                                                                    <Typography style={{ fontSize: 10 }}>
                                                                                        N/A
                                                                                    </Typography>
                                                                                )
                                                                        ) : (
                                                                            foundMatchingEntry = true,
                                                                            null
                                                                        )
                                                                    }
                                                                    {foundMatchingEntry && index === multipleEnvs.length - 1 && (
                                                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                                                                            <Typography style={{ fontSize: 15, color: "green", fontWeight: "bold" }}>
                                                                                N/A
                                                                            </Typography>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <Typography style={{ fontSize: 10 }}>
                                                            N/A
                                                        </Typography>
                                                    )}
                                                </div>

                                                <Typography variant='body2' color='text.secondary'></Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )) : (
                                <Typography style={{ fontFamily: 'sen', fontSize: 16, color: 'gray', fontWeight: 'bold', marginLeft: 15, textAlign: 'center' }}>No Sensors</Typography>
                            )
                        )}
                    </Grid>

                </div>

            </div>
        </Layout>
    );
};

export default ListFarms;