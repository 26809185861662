import {BEARER_TOKEN} from '../../config'

export const getDevices = async (
baseURL,
payload,
) => {
    
    const response = await fetch(`${baseURL}/api/device/listDevicesByEnv`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c`
        },
        body: JSON.stringify(payload)

    })

    return response;
}

export const deleteDevice = async (
baseURL,
payload
) => {
    const response = await fetch(`${baseURL}api/device/deleteDeviceById`, {
        method: 'POST',
        headers: {

            'Authorization': 'Bearer ' + BEARER_TOKEN,
            
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    })

    return response;
}