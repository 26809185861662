
import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Paper, Typography, Grid, IconButton, CircularProgress, TextField } from '@mui/material';
import { IoMdCloseCircle } from "react-icons/io";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dropzone from 'react-dropzone';
import { IoMdSend } from "react-icons/io";
import { sendAMediaMessage } from '../apis/agro-chat/api';

const UserFiles = ({ open, handleClose, userChat, fetchUserChats, getMediaMessage }) => {

  const [agroDataLocalStorage, setAgroDataLocalStorage] = useState({});
  const [loading , setLoading] = useState(false);
  const [message, setMessage] = useState('');
  

  const getAgroData = useCallback(() => {
    const agroData = localStorage.getItem('userData');
    if (agroData) {
      setAgroDataLocalStorage(JSON.parse(agroData));
    }
  }, [])

  const agroID = agroDataLocalStorage?.id;
  useEffect(() => {
    getAgroData();
  }, [getAgroData]);


  const [uploadedFiles, setUploadedFiles] = useState([]);

  const chatID = userChat && userChat.id;
  const phone = userChat && userChat.phone;


  const handleDrop = (acceptedFiles) => {
    setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
  }

  const handleRemove = (index) => {

    const newFiles = [...uploadedFiles]
    newFiles.splice(index, 1);
    setUploadedFiles(newFiles);
  }
  

  const handleSave = async () => {

    setLoading(true);

    const formData = new FormData();
    formData.append('chat_id', chatID);
    formData.append('country_code', '91');
    formData.append('phone', phone);
    formData.append('agronomist_id', agroID);
    formData.append('message', message);
    formData.append('message_type', 'image');

    uploadedFiles.forEach((file) => {
      formData.append('files', file);
    });

    console.log(formData);

    try {
      const response = sendAMediaMessage(formData);
      if (response.ok) {
        setLoading(false);
        handleClose();
        fetchUserChats();
        setUploadedFiles([]);
        getMediaMessage();
      } else {
        console.error('Failed to send media');
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', maxWidth: 750 }}>
        <Paper style={{ padding: 24, backgroundColor: '#D9D3D3' }}>
          <Typography variant="h4" gutterBottom style={{ fontFamily: 'sen' }}>
            Upload Images
          </Typography>

          <Typography variant="body1" gutterBottom style={{ fontFamily: 'sen', marginBottom: 10 }}>
            Supported formats:png,jpeg, jpg
          </Typography>

          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={{
                background: '#EFEDED',
                border: '1px dashed orange',
                textAlign: 'center',
                height: 142,
              }}>
                <input {...getInputProps()} />

                <CloudUploadIcon style={{ fontSize: '40px', marginTop: 12, color: '#5F9C6A' }} />
                <p
                  style={{ fontFamily: 'sen', fontSize: 20, margin: '10px auto' }}
                >Click to upload or drag and drop</p>

              </div>
            )}

          </Dropzone>

          {uploadedFiles.length > 0 && (
            <div style={{ marginTop: 20 }}>
              <Typography variant="h6" style={{ marginBottom: 10 }}>
                Uploaded Files:
              </Typography>
              <Grid container spacing={2}>
                {uploadedFiles.map((file, index) => (
                  <Grid item key={index}>
                    <div style={{ position: 'relative' }}>
                      <img src={URL.createObjectURL(file)} alt={`uploaded-file-${index}`} style={{ width: 120, height: 120 }} />
                      <IconButton onClick={() => handleRemove(index)} style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'white', marginTop: 2, marginLeft: 3 }}>
                        <IoMdCloseCircle size={12} />
                      </IconButton>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}


          <div style={{ display: 'flex', marginTop: 20, justifyContent:'flex-end'}}>

            <TextField placeholder='Add a caption...' color='success' onChange={(event) => setMessage(event.target.value)} fullWidth sx={{ backgroundColor: '#F0EDED' }}></TextField>
            <IconButton onClick={handleSave} style={{ backgroundColor: '#5F9C6A', color: 'white', height: '20%', marginLeft: 8, marginTop: 10 }}>
             {
              loading ? <CircularProgress sx={{color:'white'}}/> :<IoMdSend style={{ marginLeft: 4 }} />
             } 
            </IconButton>
          </div>
        </Paper>
      </div>

    </Modal>
  );
};


export default UserFiles;

