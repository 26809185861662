import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polygon,
} from "react-google-maps";
import { useSelector } from "react-redux";
import { Modal, IconButton, Typography } from "@mui/material";
import { IoMdClose } from "react-icons/io";


const WrappedMap = withScriptjs(
    withGoogleMap(({
        defaultCenter,
        handleMapClick,
        farmBoundaries,
        zones
    }) => {
        const mapRef = useRef(null);

        const farmBoundariesTransformed = Array.isArray(farmBoundaries) && farmBoundaries.map((boundary) => ({
            lat: boundary.latitude,
            lng: boundary.longitude,
        }));

        const fitBounds = useCallback(() => {
            const bounds = new window.google.maps.LatLngBounds();
            Array.isArray(farmBoundariesTransformed) && farmBoundariesTransformed.map(item => bounds.extend(item));
            mapRef.current.fitBounds(bounds);
        }, [farmBoundariesTransformed]);

        useEffect(() => {
            fitBounds();
        }, [fitBounds]);

        return (
            <GoogleMap
                defaultZoom={16}
                ref={mapRef}
                defaultCenter={defaultCenter}
                options={{
                    mapTypeId: "satellite",
                }}
            >

                {farmBoundaries && (
                    <Polygon
                        paths={farmBoundariesTransformed}
                        options={{ strokeColor: "red" }}
                        onClick={handleMapClick}
                    />
                )}
                {zones && zones.map(zone => (
                    <Polygon paths={zone && zone.zone_boundaries && zone.zone_boundaries.map(b => (
                        { lat: b.latitude, lng: b.longitude }
                    ))} options={{ strokeColor: "blue", fillColor: 'blue' }} />
                ))}
            </GoogleMap>
        )
    })
);

const ViewFarm = ({ openViewFarm, onClose, farmData }) => {


    const location = useSelector((state) => state.locationReducer.location);
    const { latitude, longitude } = location;
    const centerCurrent = { lat: latitude, lng: longitude };
    const [defaultCenter, setDefaultCenter] = useState(centerCurrent);
    const farmBoundaries = farmData?.farm_boundaries;
    const zones = farmData?.zones;

    useEffect(() => {

        if (Array.isArray(farmBoundaries) && farmBoundaries.length > 0) {

            const sumLat = farmBoundaries.reduce((acc, loc) => acc + loc.latitude, 0);
            const sumLng = farmBoundaries.reduce((acc, loc) => acc + loc.longitude, 0);
            const avgLat = sumLat / farmBoundaries.length;
            const avgLng = sumLng / farmBoundaries.length;

            setDefaultCenter({ lat: avgLat, lng: avgLng });

        }
    }, [farmBoundaries]);

    return (
        <>
            <Modal style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: "#D0E7B6",
                padding: '20px',
                borderRadius: '8px',
                width: '80%',
                height: '70%',
                maxHeight: '90vh', // Adjust width as needed
                maxWidth: '80vw', // Limit width to viewport width
                overflow: 'auto'
            }} open={openViewFarm} onClose={onClose}>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography style={{ textAlign: 'center', fontFamily: 'sen', fontSize: 25, fontWeight: "bold", color: 'white' }}>Map View</Typography>
                        <div style={{ marginBottom: '15px' }}> {/* Add some margin for separation */}
                            <Typography style={{ textAlign: 'center', fontFamily: 'sen', fontSize: 15, fontWeight: "bold", color: 'white' }}>(Farm and Zone boundaries)</Typography>
                        </div>
                        <IconButton style={{ right: '10px', position: 'absolute', zIndex: '999', backgroundColor: "white", color: 'black' }} onClick={onClose}>
                            <IoMdClose />
                        </IconButton>
                    </div>

                    <WrappedMap
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwMUqV9i_a7ILMTIwmgxBAzymf_T6hIR4&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `500px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        defaultCenter={defaultCenter}
                        zones={zones}
                        farmBoundaries={farmBoundaries}
                    />
                </div>

            </Modal>
        </>
    );
};

export default ViewFarm;