import {BEARER_TOKEN} from '../../config'

export const getAllFeature = async (
    baseURL,
    orgId
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-features/listAll`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c`
        },
        body: JSON.stringify({
            "org_id": orgId // change this org id to the selected org id from dropdown
        })
    });

    return response;
}

export const getRoles = async (
    baseURL,
    orgid
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-roles/listAll`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            org_id: orgid,
        })
    });
    return response;
}

export const addRole = async (
    baseURL,
    roleData
) => {
    const response = await fetch(`${baseURL}/api/mgmt/360-roles/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c`
        },
        body: JSON.stringify(roleData)
    });
    return response;
}

export const roleDelete = async (
    baseURL,
    deleteRoleData,
    userID
) => {

    const response = await fetch(`${baseURL}/api/mgmt/360-roles/delete`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            role: {
                ...deleteRoleData
            },
            user_id: userID
        })
    });

    return response ;
}

export const updateRole = async (
    baseURL,
    updateRoleData,
    userId
) => {
    const response = await fetch(`${baseURL}/api/mgmt/360-roles/update`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + BEARER_TOKEN
            // 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        },
        body: JSON.stringify({
            role: {
                ...updateRoleData
            },
            user_id: userId
        })
    });
    return response;
}