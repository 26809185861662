import React, { useState, useEffect } from 'react';
import { Paper, TextField, Button, Typography, InputAdornment, IconButton, CircularProgress, Snackbar } from '@mui/material';
import Img from '../images/Phyfarm-App-footer.png'
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom'
import { Alert } from '@mui/material';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { resetPassword } from '../apis/auth/api';


const ResetPassword = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const isSmScreen = useMediaQuery('(max-width:850px)');
    const [styles, setStyles] = useState({});
    const location = useLocation();
    const pathNameParts = location.pathname.split('/');
    const getToken = pathNameParts[2];
   
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    useEffect(() => {
        if (isSmScreen) {
            setStyles({
                container: { width: '100%', height: '100%', maxWidth: 850 },
           
                paper: { position: "absolute", top: 150,margin: 30,  padding: 20, display: 'flex', flexDirection: 'column' }, 
            });
        } else {
            setStyles({
                container: { display: 'flex', flexDirection: 'column', zIndex: 1, position: 'relative' },
               
                paper: {
                    padding: '30px',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    left: '30%',
                    marginTop: '190px',
                    marginLeft: '50px',
                    width: '400px',
                    height: '340px'
                },
               
            });
        }
    }, [isSmScreen]);

    const handleLogin = async () => {

        if (confirmPassword === '' || password === '') {
            setError('Empty fields');
            setSnackbarOpen(true);
            return; // Exit the function if fields are empty
        }

        if (confirmPassword !== password) {
            setError('Password does not match');
            setSnackbarOpen(true);
            return;
        }
        try {
            const response = await resetPassword(getToken, password);
            const data = await response.json();
            if (data.data) {
                setSuccessSnackbarOpen(true);
                setTimeout(() => {
                    navigate("/", { replace: true });
                }, 2000);

            } else if (data.error) {
                setError('An error occurred while trying to reset password.');
                setSnackbarOpen(true);
            }

        } catch (error) {
           
            setError('An error occurred while trying to reset password.');
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    return (
        <>
            <div
                // style={{
                //     display: 'flex',
                //     flexDirection: 'column',
                //     zIndex: 1,
                //     position: 'relative',
                // }}
                style={styles.container}
            >
                <Paper
                    elevation={5}
                    style={styles.paper}
                    // style={{
                    //     padding: '30px',
                    //     borderRadius: '15px',
                    //     width: '400px',
                    //     height: '340px',
                    //     display: 'flex',
                    //     flexDirection: 'column',
                    //     position: 'absolute',
                    //     left: '30%',
                    //     marginTop: '190px',
                    //     marginLeft: '50px'
                    // }}
                >
                    <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: 'bold', fontFamily: 'sen' }}>
                        Reset Password
                    </Typography>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

                        <TextField
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            onChange={handlePasswordChange}
                            InputProps={{
                                startAdornment: <VpnKeyIcon style={{ marginRight: 8, color: 'gray' }} />,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={togglePasswordVisibility}>
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={password === '' && error !== ''}

                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <TextField
                            label="Confirm Password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            onChange={handleConfirmPasswordChange}
                            InputProps={{
                                startAdornment: <VpnKeyIcon style={{ marginRight: 8, color: 'gray' }} />,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={toggleConfirmPasswordVisibility}>
                                            {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={confirmPassword === '' && error !== ''}
                        />
                    </div>
                    <Button variant="contained" color="success" onClick={handleLogin} style={{ width: '100%', marginTop: '40px' }}>
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                    </Button>
                </Paper>
            </div>

            <div >
                <img style={{ height: '100vh', width: '100vw', backgroundRepeat: 'no-repeat' }} src={Img} alt="Background" />
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000} // Adjust as needed
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Alert onClose={handleSnackbarClose} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                open={successSnackbarOpen}
                autoHideDuration={6000} 
                onClose={() => setSuccessSnackbarOpen(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Alert onClose={() => setSuccessSnackbarOpen(false)} severity="success">
                    Password reset successful
                </Alert>
            </Snackbar>
        </>

    );
};

export default ResetPassword;