import { configureStore } from '@reduxjs/toolkit'
import loginReducer from '../reducers/reducers';
import locationReducer from '../reducers/location';
import coordinateReducer from '../reducers/coordinates';
import leadReducer from '../reducers/leadData';
import environmentReducer from '../reducers/environment';
import { soilTestReportReducer, statsReducer } from '../reducers/dashboard';
import programReducer from '../reducers/programs';


const store = configureStore({
    reducer: {
        loginReducer,
        locationReducer,
        leadReducer,
        coordinateReducer,
        environmentReducer,
        stats: statsReducer,
        soilReport: soilTestReportReducer,
        program: programReducer,
    }
});

export default store;