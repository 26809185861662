import React from 'react';
import { GoogleMap, useLoadScript, HeatmapLayer } from '@react-google-maps/api';

const mapContainerStyle = {
    height: '100%',
    width: '100%',

};

const center = {
    lat: 20.5937,
    lng: 78.9629,
};

const locations = [
    { lat: 19.7515, lng: 75.7139, weight: 40 }, 
    { lat: 26.8467, lng: 80.9462, weight: 15 },
    { lat: 15.3173, lng: 75.7139, weight: 3 }, 
    { lat: 27.0238, lng: 74.2179, weight: 2 },  
];

const mapOptions = {
    radius: 20,
    opacity: 0.6,
};

const Heatmap = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBQMFPwfrJDq4NX8F9TE6f_HYVpJ53qRwk',
        libraries: ['visualization'],
    });

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <GoogleMap mapContainerStyle={mapContainerStyle} zoom={5} center={center}>
            <HeatmapLayer data={locations.map(location => new window.google.maps.LatLng(location.lat, location.lng))} options={mapOptions} />
        </GoogleMap>
    );
};

export default Heatmap;
