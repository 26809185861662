import { createSlice } from "@reduxjs/toolkit";

const coordinateSlice = createSlice({
    name: 'coordinate',
    initialState: {
        coordinate:[],
    },
    reducers: {
      

        coordinateSuccess: (state, action) => {
            state.coordinate = action.payload;

        },
        clearCoordinate: (state) => {
            state.coordinate = {};
        },
    }
});

export default coordinateSlice.reducer;

export const { coordinateSuccess, clearCoordinate } = coordinateSlice.actions;


