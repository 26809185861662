import React from "react";
import Layout from "./Layout";
import {
  Typography,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import SearchIcon from "@mui/icons-material/Search";
import useTags from "../hooks/Tags/useTagsListing";
import useTagsAdd from "../hooks/Tags/useTagsAdd";
import useTagsDelete from "../hooks/Tags/useTagsDelete";
import useTagsUpdate from "../hooks/Tags/useTagsUpdate";
import MuiAlert from "@mui/material/Alert";
import {
  createPermission,
  deletePermission,
  editPermission,
} from "../utils/helpers";

const Tags = () => {
  const userData = JSON.parse(localStorage.getItem("permissions"));

  const {
    state,
    tagsPerPage,
    handleSearchChange,
    setSearchDropdown,
    listAllTags,
  } = useTags();

  const {
    state: addState,
    addTagHandler,
    handleOpenDialog,
    handleCloseDialog,
    handleAddTagChange,
    handleSnackbarClose: handleAddSnackbarClose,
  } = useTagsAdd();

  const {
    state: deleteState,
    deleteTagHandler,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    setDeleteTag,
    handleSnackbarClose: handleDeleteSnackbarClose,
  } = useTagsDelete();

  const {
    state: updateState,
    updateTagHandler,
    handleOpenUpdateDialog,
    handleCloseUpdateDialog,
    handleNameChange,
    handleSnackbarClose: handleUpdateSnackbarClose,
  } = useTagsUpdate();

  const handleAddTagClick = async () => {
    const success = await addTagHandler(addState.name);
    if (success) {
      listAllTags(state.currentPage);
    }
  };

  const handleDelete = async () => {
    const response = await deleteTagHandler(
      deleteState.tagId,
      deleteState.name
    );
    if (response) {
      if (state.tags.length === 1 && state.currentPage > 1) {
        listAllTags(state.currentPage - 1);
      } else {
        listAllTags(state.currentPage);
      }
    }
  };

  const handleUpdate = async () => {
    const response = await updateTagHandler();
    if (response) {
      listAllTags(state.currentPage);
    }
  };

  const hasCreatePermission = createPermission("tags_360_mgmt", userData);
  const hasDeletePermission = deletePermission("tags_360_mgmt", userData);
  const hasUpdatePermission = editPermission("tags_360_mgmt", userData);

  return (
    <Layout>
      <div style={{ height: "100vh", marginTop: 90 }}>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Typography
            style={{ fontFamily: "Sen", fontWeight: "bold", fontSize: 25 }}
          >
            List of Tags
          </Typography>
          <Typography
            style={{ fontFamily: "Sen", fontSize: 16, color: "gray" }}
          >
            (Search using tag name)
          </Typography>
        </div>
        <Grid
          container
          style={{ marginTop: 50 }}
          spacing={1}
          justifyContent={"center"}
        >
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <div style={{ display: "flex" }}>
              <TextField
                fullWidth
                label={`Search by ${state.searchDropdown.toUpperCase()}`}
                value={state.searchQuery}
                color="success"
                variant="outlined"
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <TextField
                      select
                      value={state.searchDropdown}
                      color="success"
                      sx={{ minWidth: 120 }}
                      variant="standard"
                      onChange={(event) => {
                        const sD = event.target.value;
                        setSearchDropdown(sD);
                        handleSearchChange({ target: { value: "" } });
                      }}
                    >
                      <MenuItem value={"name"}>TAG NAME</MenuItem>
                    </TextField>
                  ),
                  endAdornment: (
                    <IconButton onClick={handleSearchChange}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
              {hasCreatePermission && (
                <Button
                  onClick={handleOpenDialog}
                  style={{
                    borderRadius: "50%",
                    marginLeft: 5,
                    width: "45px",
                    height: "55px",
                    minWidth: "55px",
                    minHeight: "55px",
                    fontSize: "1.2rem",
                  }}
                  variant="contained"
                  color="success"
                >
                  <IoMdAdd />
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        {state.loading ? (
          <div style={{ textAlign: "center", marginBottom: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ marginLeft: 65 }}>
            <TableContainer component={Paper} style={{ marginTop: 20 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Tag Name
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Status
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state?.tags?.length > 0 ? (
                    state?.tags.map((tag, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{tag.name}</TableCell>
                        <TableCell
                          align="center"
                          style={{ color: tag.active ? "green" : "red" }}
                        >
                          {tag.active ? "ACTIVE" : "INACTIVE"}
                        </TableCell>
                        <TableCell align="center">
                          {hasUpdatePermission && (
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ marginRight: 10 }}
                              onClick={() => {
                                handleOpenUpdateDialog(tag.id, tag.name);
                              }}
                            >
                              Update
                            </Button>
                          )}
                          {hasDeletePermission && (
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                setDeleteTag(tag.id, tag.name);
                                handleOpenDeleteDialog();
                              }}
                            >
                              Delete
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={state.totalTags}
                rowsPerPage={tagsPerPage.current}
                page={state.currentPage - 1}
                onPageChange={(e, newPage) => {
                  listAllTags(newPage + 1);
                }}
                labelDisplayedRows={({ from, to, count }) => (
                  <Typography variant="body2">
                    <strong>Navigate to Next Page:</strong> {from}-{to} of {count}
                  </Typography>
                )}
                rowsPerPageOptions={[]}
              />
            </TableContainer>
          </div>
        )}
      </div>
      <Dialog
        open={addState.open}
        onClose={handleCloseDialog}
        maxWidth="xs"
        fullWidth
        onBackdropClick={() => {
          handleCloseDialog();
          addState.error = null;
        }}
      >
        <div style={{ backgroundColor: "#d0e7b7" }}>
          <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>
            Add a Tag
          </DialogTitle>
        </div>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Tag Name"
            color="success"
            fullWidth
            value={addState.name}
            onChange={handleAddTagChange}
            error={Boolean(addState.error)}
            helperText={addState.error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="outlined" color="error">
            Cancel
          </Button>
          <Button
            onClick={handleAddTagClick}
            variant="outlined"
            color="success"
          >
            {addState.loading ? <CircularProgress size={24} /> : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={updateState.open}
        onClose={handleCloseUpdateDialog}
        maxWidth="xs"
        fullWidth
        onBackdropClick={() => {
          handleCloseUpdateDialog();
          updateState.error = null;
        }}
      >
        <div style={{ backgroundColor: "#d0e7b7" }}>
          <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>
            Update Tag
          </DialogTitle>
        </div>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tag Name"
            type="text"
            fullWidth
            color="success"
            error={Boolean(updateState.error)}
            helperText={updateState.error}
            variant="outlined"
            value={updateState.name}
            onChange={handleNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseUpdateDialog}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            color="success"
            disabled={updateState.loading}
            variant="outlined"
          >
            {updateState.loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteState.open}
        onClose={handleCloseDeleteDialog}
        maxWidth="xs"
        fullWidth
      >
        <div style={{ backgroundColor: "#d0e7b7" }}>
          <DialogTitle sx={{ fontWeight: "bold", fontFamily: "sen" }}>
            Delete Tag
          </DialogTitle>
        </div>
        <DialogContent>Are you sure you want to delete this tag?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="outlined">
            {deleteState.loading ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={addState.snackbarOpen}
        autoHideDuration={6000}
        onClose={handleAddSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={handleAddSnackbarClose}
          severity={addState.snackbarSeverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {addState.snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={deleteState.snackbarOpen}
        autoHideDuration={6000}
        onClose={handleDeleteSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={handleDeleteSnackbarClose}
          severity={deleteState.snackbarSeverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {deleteState.snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={updateState.snackbarOpen}
        autoHideDuration={6000}
        onClose={handleUpdateSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={handleUpdateSnackbarClose}
          severity={updateState.snackbarSeverity}
          variant="filled"
        >
          {updateState.snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Layout>
  );
};

export default Tags;
