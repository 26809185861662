import { createSlice } from "@reduxjs/toolkit";

// const PROD_BASE_URL = 'https://v3.api.phyfarm.com';
const DEV_BASE_URL = 'https://dev.phyfarm.com';

const INITIAL_ENVIRONMENT = localStorage.getItem('environment') ? JSON.parse(localStorage.getItem('environment')) : DEV_BASE_URL;

const environmentSlice = createSlice({
    name: 'environment',
    initialState: {
        environment: INITIAL_ENVIRONMENT,
    },
    reducers: {
        environmentSuccess: (state, action) => {
            localStorage.setItem('environment', JSON.stringify(action.payload));
            state.environment = action.payload;
        },
        clearEnvironment: (state) => {
            state.environment = {};
        },
    }
});

export default environmentSlice.reducer;

export const { environmentSuccess, clearEnvironment } = environmentSlice.actions;